import { createAsyncThunk } from "@reduxjs/toolkit";
import performance from "../services/dex";
import { DRAFT, PERFORMANCE_MANAGEMENT } from "../../constants";

//get performancestaticData detail action
export const getUserPerformanceStaticData = createAsyncThunk(
  "getUserPerformanceStaticData",
  async (args: any, { rejectWithValue }) => {
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/getUserPerformanceStaticData`;
    try {
      // const response = await performance.get(url);
      // const result = response.data;
      const result = {
        someStaticData: "",
        performanceYears: [2024, 2023, 2022, 2021, 2020, 2019, 2018],
      };
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get quarterlyCheckinDataEmployee detail action
export const quarterlyCheckinDataEmployee = createAsyncThunk(
  "quarterlyCheckinDataEmployee",
  async (args: any, { rejectWithValue }) => {
    const { year } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/quarterly-checkin?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get goal template
export const getGoalTemplate = createAsyncThunk(
  "getGoalTemplate",
  async (args: any, { rejectWithValue }) => {
    const { year, questionnaireCategory, goalId = "" } = args;
    let isEdit = false;
    let isPublic = false;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/template?year=${year}&questionnaireCategory=${questionnaireCategory}`;
    if (goalId) {
      let id = goalId.split("-");
      const [isSf, gid] = id;
      if (isSf === "sf") {
        url += `&sfGoalId=${gid}`;
      } else if (isSf === "dex") {
        url += `&goalId=${gid}`;
      } else {
        url += `&publicGoalId=${gid}`;
        isPublic = true;
      }
      isEdit = true;
    }

    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result, questionnaireCategory, isEdit, isPublic };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//save template
export const saveGoal = createAsyncThunk(
  "saveGoal",
  async (args: any, { rejectWithValue }) => {
    const {
      year,
      questionnaireCategory,
      status,
      payload,
      method = "post",
      goalId = "",
      sfGoalId,
      publicGoalId,
    } = args;
    const params = { year, status, questionnaireCategory };
    if (sfGoalId) {
      params["sfGoalId"] = sfGoalId;
    } else if (goalId) {
      params["goalId"] = goalId;
    } else if (publicGoalId) {
      if (questionnaireCategory === "WEB_MILESTONE") {
        params["goalId"] = publicGoalId;
      } else {
        params["publicGoalId"] = publicGoalId;
      }
    }

    try {
      let response;
      if (method === "post") {
        response = await performance.post(
          `${PERFORMANCE_MANAGEMENT}/api/v1/goal`,
          payload,
          { params }
        );
      } else {
        response = await performance.put(
          `${PERFORMANCE_MANAGEMENT}/api/v1/goal`,
          payload,
          { params }
        );
      }
      const result = response.data;
      return { result, questionnaireCategory, status };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//save Goal comment
export const saveGoalComment = createAsyncThunk(
  "saveGoalComment",
  async (args: any, { rejectWithValue }) => {
    const {
      year,
      userId,
      payload,
      goalId = "",
      sfGoalId = "",
      index,
      category,
      message = "",
    } = args;
    let id = goalId ? goalId : sfGoalId;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/manager/comment?year=${year}&reporteeId=${userId}&goalId=${id}`;
    try {
      const response = await performance.post(url, payload);
      const result = response.data;
      return { result, index, payload, category, message };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// archive goal
export const archiveGoal = createAsyncThunk(
  "archiveGoal",
  async (args: any, { rejectWithValue }) => {
    const { payload } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/archive`;
    try {
      const response = await performance.post(url, payload);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//save Goal comment
export const submitGoal = createAsyncThunk(
  "submitGoal",
  async (args: any, { rejectWithValue }) => {
    const { year } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/submit?year=${year}`;
    try {
      const response = await performance.post(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//post quarterly checkin data employee
export const postQuarterlyCheckinCommentEmployee = createAsyncThunk(
  "postQuarterlyCheckinCommentEmployee",
  async (args: any, { rejectWithValue }) => {
    const { year, quarter, comment } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/quarterlyCheckin?year=${year}&quarter=${quarter}`;
    try {
      const request = { comment: comment };
      const response = await performance.post(url, request);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//post quarterly checkin data employee
export const AnnualYear = createAsyncThunk(
  "AnnualYear",
  async (args: any, { rejectWithValue }) => {
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/years-dropdown`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get Employee List in Manager View
export const getEmployeeListData = createAsyncThunk(
  "getEmployeeListData",
  async (args: any, { rejectWithValue }) => {
    const { year, searchString } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/manager/details`;
    if (searchString) {
      url += `?searchString=${searchString}`;
    }
    //const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/manager/details?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get Employee goals List in Manager View
export const getEmployeeGoalListData = createAsyncThunk(
  "getEmployeeGoalListData",
  async (args: any, { rejectWithValue }) => {
    const { year, reporteeId } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/manager/goaldetails?year=${year}`;
    if (reporteeId) {
      url += `&reporteeId=${reporteeId}`;
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get all archive goals
export const getAllArchiveGoals = createAsyncThunk(
  "getAllArchiveGoals",
  async (_, { rejectWithValue }) => {
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/archive`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//delete archive goal
export const deleteArchiveGoal = createAsyncThunk(
  "deleteArchiveGoal",
  async ({ goalId = "" }: any, { rejectWithValue }) => {
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/archive?goalIds=${goalId}`;
    try {
      const response = await performance.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get all goals template
export const deleteGoal = createAsyncThunk(
  "deleteGoal",
  async (params: any, { rejectWithValue }) => {
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal`;
    try {
      const response = await performance.delete(url, { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//restore archive goal
export const getRestoreArchiveGoal = createAsyncThunk(
  "getrestoreArchiveGoal",
  async ({ sfGoalIds = "" }: any, { rejectWithValue }) => {
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/restore/archive?sfGoalIds=${sfGoalIds}`;
    try {
      const response = await performance.post(url);
      return response?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//post cascade goal by manager
export const postCasecadeGoalCommentManager = createAsyncThunk(
  "postCasecadeGoalCommentManager",
  async (args: any, { rejectWithValue }) => {
    const { year } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/landing-page?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//cascade to employee from the list
export const cascadeToEmployeeList = createAsyncThunk(
  "cascadeToEmployeeList",
  async (args: any, { rejectWithValue }) => {
    const { year, searchString } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/manager/details`;
    if (searchString) {
      url += `?searchString=${searchString}`;
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Quarterly checkin comment by manager
export const saveQuarterlyCheckinComments = createAsyncThunk(
  "saveQuarterlyCheckinComments",
  async (args: any, { rejectWithValue }) => {
    const { year, quarter, reporteeId, comment } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/manager/quarterlyCheckin?year=${year}&quarter=${quarter}&reporteeId=${reporteeId}`;
    try {
      const request = { comment: comment };
      const response = await performance.post(url, request);
      const result = response.data || {};
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Quarterly checkin comment details by manager
export const getQuarterlyCheckinComments = createAsyncThunk(
  "getQuarterlyCheckinComments",
  async (args: any, { rejectWithValue }) => {
    const { year, reporteeId } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/manager/quarterly-checkin?year=${year}&reporteeId=${reporteeId}`;
    try {
      const response = await performance.get(url);
      const result = response.data || {};
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//contribute to employee from the list
export const getContributePublicGoalList = createAsyncThunk(
  "getContributePublicGoalList",
  async (args: any, { rejectWithValue }) => {
    const { searchString } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/contribute/search-public-goal`;
    if (searchString) {
      url += `?searchString=${searchString}`;
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//contribute to employee from the list
export const getContributeViewGoal = createAsyncThunk(
  "getContributeViewGoal",
  async (args: any, { rejectWithValue }) => {
    const { questionnaireCategory, publicGoalId } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/template?questionnaireCategory=${questionnaireCategory}&year=2024&publicGoalId=${publicGoalId}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//view goal
export const ViewGoalAction = createAsyncThunk(
  "ViewGoalAction",
  async (args: any, { rejectWithValue }) => {
    const { year, goalId } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal?year=${year}`;
    if (goalId) {
      let id = goalId.split("-");
      const [isSf, gid] = id;
      if (isSf === "sf") {
        url += `&sfGoalId=${gid}`;
      } else if (isSf === "dex") {
        url += `&goalId=${gid}`;
      } else {
        url += `&publicGoalId=${gid}`;
      }
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//ASP form steps
export const getAspFormSteps = createAsyncThunk(
  "getAspFormSteps",
  async (args: any, { rejectWithValue }) => {
    const { appraisalId, year, reporteeId } = args;
    console.log("args", appraisalId, reporteeId, year);
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/form-step?year=${year}`;
    if (appraisalId) {
      url += `&appraisalId=${appraisalId}`;
    }
    if (reporteeId) {
      url += `&reporteeId=${reporteeId}`;
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//ASP form employee details
export const getAspFormEmployeeDetail = createAsyncThunk(
  "getAspFormEmployeeDetail",
  async (args: any, { rejectWithValue }) => {
    const { year, reporteeId } = args;
    const url = reporteeId
      ? `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/landing-page?year=${year}&reporteeId=${reporteeId}`
      : `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/landing-page?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//ASP form employee goals details
export const getAspFormEmployeeGoalDetails = createAsyncThunk(
  "getAspFormEmployeeGoalDetails",
  async (args: any, { rejectWithValue }) => {
    const { appraisalId, year, reporteeId } = args;
    const url = reporteeId
      ? `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/goals?year=${year}&reporteeId=${reporteeId}`
      : `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/goals?year=${year}&appraisalId=${appraisalId}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//ASP form employee signature goals details
export const getAspFormEmployeeGoalSignature = createAsyncThunk(
  "getAspFormEmployeeGoalSignature",
  async (args: any, { rejectWithValue }) => {
    const { appraisalId, year } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/signature-infos?year=${year}&appraisalId=${appraisalId}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//ASP form employee signature goals post details
export const postAspFormEmployeeGoalSign = createAsyncThunk(
  "postAspFormEmployeeGoalSign",
  async (args: any, { rejectWithValue }) => {
    const { year, request, appraisalId, reporteeId } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/signForm?year=${year}`;
    if (appraisalId) {
      url += `&appraisalId=${appraisalId}`;
    } else if (reporteeId) {
      url += `&reporteeId=${reporteeId}`;
    }
    try {
      const response = await performance.post(url, request);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//ASP form employee goals details
export const saveAspFormDetails = createAsyncThunk(
  "saveAspFormDetails",
  async (args: any, { rejectWithValue }) => {
    const {
      appraisalId,
      year,
      request,
      reporteeId,
      message = "",
      additionalInfo = "",
      status = "",
    } = args;
    let url = reporteeId
      ? `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/goals?year=${year}&reporteeId=${reporteeId}`
      : `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/goals?year=${year}&appraisalId=${appraisalId}`;
    if (status === DRAFT) {
      url += `&status=${status}`;
    }
    try {
      const response = await performance.post(url, request);
      const result = response.data;
      return { result, message, additionalInfo };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get Employee Data Manager View
export const getEmployeeDataManagerView = createAsyncThunk(
  "getEmployeeDataManagerView",
  async (args: any, { rejectWithValue }) => {
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/managerOverview?reporteeId=${args?.reporteeId}&year=${args?.year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get Submitted Goal Manager View
export const getSubmittedGoals = createAsyncThunk(
  "getSubmittedGoals",
  async (args: any, { rejectWithValue }) => {
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/manager/team-goals?year=${
      args?.year ? args?.year : new Date()?.getFullYear()
    }`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get dropDown years
export const getDropDownYears = createAsyncThunk(
  "getDropDownYears",
  async (args: any, { rejectWithValue }) => {
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/years-dropdown`;
    if (args?.reporteeId) url += `?reporteeId=${args?.reporteeId}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get smart game template
export const getSmartGameTemplate = createAsyncThunk(
  "getSmartGameTemplate",
  async (args: any, { rejectWithValue }) => {
    const { year } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/template?questionnaireCategory=WEB_PERFORMANCE&year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get Employee overview data
export const getEmployeeOverviewDetails = createAsyncThunk(
  "getEmployeeOverviewDetails",
  async (args: any, { rejectWithValue }) => {
    const { year } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/employeeOverview?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//behavioural Competency form employee
export const getBehaviouralEmployeeDetails = createAsyncThunk(
  "getBehaviouralEmployeeDetails",
  async (args: any, { rejectWithValue }) => {
    const { userId, year, reporteeId } = args;
    const url = reporteeId
      ? `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/behaviour-competency?reporteeId=${reporteeId}&year=${year}`
      : `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/behaviour-competency?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Save behavioural Competency form employee
export const saveBehaviouralEmployeeDetails = createAsyncThunk(
  "saveBehaviouralEmployeeDetails",
  async (args: any, { rejectWithValue }) => {
    const {
      userId,
      year,
      request,
      reporteeId,
      message = "",
      additionalInfo = "",
      status = "",
      appraisalId = "",
    } = args;
    let url = reporteeId
      ? `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/behaviour-competency?reporteeId=${reporteeId}&year=${year}`
      : `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/behaviour-competency?year=${year}`;
    if (appraisalId) url += `&appraisalId=${appraisalId}`;
    if (status === DRAFT) url += `&status=${status}`;
    try {
      const response = await performance.post(url, request);
      const result = response.data;
      return { result, message, additionalInfo };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get all goals template
export const getAllGoalTemplate = createAsyncThunk(
  "getAllGoalTemplate",
  async (args: any, { rejectWithValue }) => {
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/landing-page`;
    if (args?.year) {
      url += `?year=${args?.year}`;
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//post call cascade to employee from the list
export const postCascadeToEmployeeList = createAsyncThunk(
  "postCascadeToEmployeeList",
  async (args: any, { rejectWithValue }) => {
    const { year, request } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/manager/cascade?year=${year}`;
    try {
      const response = await performance.post(url, request);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//move form to next step
export const submitYearEndReview = createAsyncThunk(
  "submitYearEndReview",
  async (args: any, { rejectWithValue }) => {
    const { year, reporteeId = null } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/sendToNextStep?year=${year}`;
    if (reporteeId) {
      url += `&reporteeId=${reporteeId}`;
    }
    try {
      const response = await performance.get(url);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const performanceToPotentialManager = createAsyncThunk(
  "performanceToPotentialManager",
  async (args: any, { rejectWithValue }) => {
    const { year, reporteeId, apprisalId } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/performanceToPotential?reporteeId=${reporteeId}&year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Read Comment
export const viewManagerComment = createAsyncThunk(
  "viewManagerComment",
  async (args: any, { rejectWithValue }) => {
    const { id, index, category } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/goal/comment?goalId=${id}`;
    try {
      const response = await performance.put(url);
      const result = response.data;
      return { result, index, category };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const submitP2P = createAsyncThunk(
  "submitP2P",
  async (args: any, { rejectWithValue }) => {
    const { reporteeId, userId, body, year } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/dex/performance-review/performanceToPotential?reporteeId=${reporteeId}&userId=${userId}&year=${year}`;
    try {
      const response = await performance.post(url, body);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// overAllFormRatingManager
export const getOverallFormRating = createAsyncThunk(
  "getOverallFormRating",
  async (args: any, { rejectWithValue }) => {
    const { year, reporteeId } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/overallFormRating?year=${year}&reporteeId=${reporteeId}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//overall form rating post
export const postOverallFormRating = createAsyncThunk(
  "overallFormRating",
  async (args: any, { rejectWithValue }) => {
    const {
      year,
      reporteeId,
      payload,
      message = "",
      additionalInfo = "",
      status = "",
    } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/overallFormRating?year=${year}&reporteeId=${reporteeId}`;
    if (status === DRAFT) {
      url += `&status=${status}`;
    }
    try {
      const response = await performance.post(url, payload);
      const result = response.data;
      return { result, message, additionalInfo };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//overall form rating
export const getPendingReivewsManager = createAsyncThunk(
  "getPendingReivewsManager",
  async (args: any, { rejectWithValue }) => {
    const { year, type } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/manager/pending-review?year=${year}&type=${type}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//ASP form employee signature goals post details
export const postAspFormeGoalSign = createAsyncThunk(
  "postAspFormeGoalSign",
  async (args: any, { rejectWithValue }) => {
    const { year, request, appraisalId, reporteeId } = args;
    let url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/signForm?year=${year}`;
    if (appraisalId) {
      url += `&appraisalId=${appraisalId}`;
    } else if (reporteeId) {
      url += `&reporteeId=${reporteeId}`;
    }
    try {
      const response = await performance.post(url, request);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getReporteeSignatureAndOverAllForm = createAsyncThunk(
  "getReporteeSignatureAndOverAllForm",
  async (args: any, { rejectWithValue }) => {
    const { appraisalId = "", year, reporteeId = "" } = args;
    let id = reporteeId
      ? `&reporteeId=${reporteeId}`
      : `&appraisalId=${appraisalId}`;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/signature-infos?year=${year}${id}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEmployeeSignatureAndOverAllForm = createAsyncThunk(
  "getEmployeeSignatureAndOverAllForm",
  async (args: any, { rejectWithValue }) => {
    const { appraisalId = "", year, reporteeId = "" } = args;
    let id = reporteeId
      ? `&reporteeId=${reporteeId}`
      : `&appraisalId=${appraisalId}`;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/signature-infos?year=${year}${id}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCopyGoalPlanYears = createAsyncThunk(
  "getCopyGoalPlanYears",
  async (args: any, { rejectWithValue }) => {
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/copyGoalPlan/years-dropdown`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get previous year goal
export const getEmployeePreviousGoals = createAsyncThunk(
  "getEmployeePreviousGoals",
  async (args: any, { rejectWithValue }) => {
    const { year } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/copyGoalPlan/getGoals?year=${year}`;
    try {
      const response = await performance.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// copy goals to current year
export const copyGoalsToCurrentYear = createAsyncThunk(
  "copyGoalsToCurrentYear",
  async (args: any, { rejectWithValue }) => {
    const { year, payload } = args;
    const url = `${PERFORMANCE_MANAGEMENT}/api/v1/performance-review/copyGoalPlan/saveGoals?year=${year}`;
    try {
      const response = await performance.post(url, payload);
      const result = response.data;
      return { result };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
