import { useEffect, useState } from "react";
import "./Programs.scss";
import { Col, DatePicker, Form, Input, Row, Upload } from "antd";
import { GreyCalendar as Calendar } from "../../../components/common/svg/calendar";
import { Export, Frame } from "../../common/svg/Export";
import { CloseOutlined } from "@ant-design/icons";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import {
  ASP_START_NEXT,
  CANCEL,
  CHALLENGES,
  CREATE_PROGRAM,
  CREATE_PROGRAM_TITLE,
  DELETE,
  DELETE_IMG,
  DELETE_IMG_MSG,
  INNOVATION,
  INNOVATION_TITLE,
  MYWORK_URL,
  SAVE,
  START,
} from "../../../constants";
import ModalDialog from "../../common/modal/modal";
import Trash from "../../../themes/assets/images/svg/Trash.svg";
const { TextArea } = Input;
const CreateProgram = (props: any) => {
  const [enableNext, setEnableNext] = useState<Boolean>(false);
  const [enableSave, setEnableSave] = useState<Boolean>(false);
  const [deleteImgPopup, setDeleteImgPopup] = useState<Boolean>(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const navigate = useNavigate();
  const items = [
    {
      title: (
        <Link
          to={`/${START}/${MYWORK_URL}/${INNOVATION}`}
          className="link-highlight"
        >
          {INNOVATION_TITLE}
        </Link>
      ),
    },

    {
      title: CREATE_PROGRAM_TITLE,
    },
  ];
  useEffect(() => {
    if (Object.entries(values || {}).every((item) => item?.[1] === undefined))
      setEnableSave(false);
    else setEnableSave(true);
    form
      .validateFields({ validateOnly: true })
      .then(() => setEnableNext(true))
      .catch(() => setEnableNext(false));
  }, [form, values]);

  const openDeleteImgPopup = () => setDeleteImgPopup(true);
  const closeDeleteImgPopup = () => setDeleteImgPopup(false);
  const deleteImg = () => {
    form.setFieldValue("background", undefined);
    closeDeleteImgPopup();
  };
  const onClickCancel = () => {};
  const onClickNext = () => {
    navigate(
      `/${START}/${MYWORK_URL}/${INNOVATION}/${CREATE_PROGRAM}/${CHALLENGES}`
    );
  };
  const onClickSave = () => {};
  return (
    <div id="create-program-page" className="wrapper">
      <BreadcrumbComponent items={items} />
      <div className="dex-title">{CREATE_PROGRAM_TITLE}</div>
      <Form form={form} layout="vertical" className="form">
        <div className="form-container">
          <Form.Item
            label="Name of the program"
            name={"programName"}
            required={true}
            rules={[{ required: true }]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            label="Short Description of the Program"
            name={"desc"}
            required={true}
            rules={[{ required: true }]}
          >
            <TextArea
              autoSize={{ minRows: 3, maxRows: 10 }}
              count={{ show: true, max: 500 }}
              className="input"
            />
          </Form.Item>
          <Row justify={"space-between"} gutter={20}>
            <Col flex={1}>
              <Form.Item
                label="Start date"
                name={"startDate"}
                required={true}
                rules={[{ required: true }]}
              >
                <DatePicker suffixIcon={<Calendar />} className="date-picker" />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="End date"
                name={"endDate"}
                required={true}
                rules={[{ required: true }]}
              >
                <DatePicker
                  suffixIcon={<Calendar />}
                  className="date-picker input"
                />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Background"
                name={"background"}
                required={true}
                rules={[{ required: true }]}
              >
                {form.getFieldValue("background") ? (
                  <div className="uploaded">
                    <div className="left">
                      <Frame />
                      <div className="upload-txt">
                        {form.getFieldValue("background").file.name}
                      </div>
                    </div>
                    <CloseOutlined
                      onClick={openDeleteImgPopup}
                      className="cursor-pointer"
                    />
                  </div>
                ) : (
                  <Upload showUploadList={false}>
                    <div className="upload">
                      <div className="upload-txt">Upload image</div>
                      <Export />
                    </div>
                  </Upload>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="form-footer">
          <div className="btn-container">
            <div
              id="create-program-cancel-btn"
              onClick={onClickCancel}
              className="btn cancel"
            >
              {CANCEL}
            </div>
            {enableSave && (
              <div
                id="create-program-save-btn"
                onClick={onClickSave}
                className="btn save"
              >
                {SAVE}
              </div>
            )}
            <div
              id="create-program-next-btn"
              onClick={onClickNext}
              className={`btn ${enableNext ? "next-enabled" : "next-disabled"}`}
            >
              {ASP_START_NEXT}
            </div>
          </div>
        </div>
        <ModalDialog
          openModal={deleteImgPopup}
          modalImage={Trash}
          modalImageClasses={"draft-popup-heading-image"}
          DialogHeading={DELETE_IMG}
          closeIcon={false}
          indexScroll={{ display: "none" }}
          modalHeadingStyles={{ dislay: "none" }}
          popupDivider={{ display: "none" }}
          msgContent={DELETE_IMG_MSG}
          okTxt={DELETE}
          cancelTxt={CANCEL}
          cancelBtn={closeDeleteImgPopup}
          okBtn={deleteImg}
          modalSubHeading={"modalSubHeading"}
          modalCenterImgStyles={{ display: "none" }}
          modalStyles={"backpopup delete-milestone-popup draft-popup-section"}
          maskClosable={false}
          isSingleButton={false}
        />
      </Form>
    </div>
  );
};

export default CreateProgram;
