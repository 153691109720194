import { Form, Input } from "antd";
const { TextArea } = Input;
const FormTextArea = (props: any) => {
  const {
    textAreaFormLabel,
    textAreaFormName,
    handleChange,
    formItemClassName,
    textAreaFormValue,
    onChange,
    textAreaFormPlaceholder,
    textAreaMaxlength,
    textAreaLength,
    textAreaRules,
    textAreaCount,
    textAreaFormDefaultValue
  } = props || {};

  return (
    <Form.Item
      label={textAreaFormLabel}
      name={textAreaFormName}
      rules={textAreaRules}
      className={formItemClassName}
    >
      <TextArea
        defaultValue={textAreaFormDefaultValue}
        value={textAreaFormValue}
        placeholder={textAreaFormPlaceholder}
        onChange={onChange}
        maxLength={textAreaMaxlength}
        autoSize={true}
      />
      {/* {textAreaCount && (
        <span className={textAreaLength}>
          {textAreaCount}/{textAreaMaxlength}
        </span>
      )} */}
    </Form.Item>
  );
};

export default FormTextArea;
