import React, { useEffect, useState, useRef } from "react";
import "./CreateTimeSheet.css";
import "../CalendarComponent.css";
import DropDown from "../DropDown";
import Calendar from "react-calendar";
import { PROJECT_TITLE, SHIFT_TYPE, WEEK_TITLE } from "../../../../constants";
import Office_Briefcase from "../../../../themes/assets/images/svg/Briefcase.svg";
import Time_Calender from "../../../../themes/assets/images/svg/CalendarBlank.svg";
import Shift_Type_Icon from "../../../../themes/assets/images/svg/Sun.svg";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { useParams } from "react-router-dom";
import CalendarComponent from "../CalendarComponent";

const CreateDropDownOption = (props) => {
  const {
    shiftTypes,
    dropDownSuggestions,
    projects,
    weekHandler,
    shiftHandler,
    timesheetId,
    displayWeek,
    timesheetData,
    projectTitle,
    isDisplayWeekDisabled = false,
  } = props;

  const joinProject = projects?.toString();
  const param = useParams();
  const { shift: shiftName } = param || {};
  const [project = ""] = projects || [];
  const [shift = { name: "" }] = shiftTypes || [];
  const [defaultShiftSelection, setDefaultShiftSelection] = useState(
    shiftName || shift?.name
  );
  useEffect(() => {
    if (timesheetId) {
      setDefaultShiftSelection(timesheetData?.shiftType);
    }
  }, [timesheetData]);
  const items: MenuProps["items"] = shiftTypes?.map((item: any) => ({
    key: `${item.name}`,
    label: item.name,
  }));
  const handleDropDownClick: MenuProps["onClick"] = (e) => {
    const shift = e?.key;
    setDefaultShiftSelection(shift);
    shiftHandler(shift);
  };
  const menuProps = {
    items,
    selectable: true,
    defaultSelectedKeys: [defaultShiftSelection],
    onClick: handleDropDownClick,
  };
  const [selectedWeekStart, setSelectedWeekStart] = useState<string | null>(
    null
  );
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };
  const calendarRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="create-date-otr">
      <div className="dropdown-otr week-day-drop">
        <span>
          <img src={Time_Calender} /> {WEEK_TITLE}
        </span>
        {timesheetId ? (
          <div className="demo-box">
            <div>{displayWeek}</div>
          </div>
        ) : (
          <>
            <input
              type="text"
              value={selectedDate || displayWeek}
              placeholder="Select Week"
              readOnly
              onClick={toggleCalendar}
              className="date-picker-input"
              disabled={isDisplayWeekDisabled}
            />
            {showCalendar && (
              <div className="calendar-popup" ref={calendarRef}>
                <CalendarComponent
                  weekHandler={weekHandler}
                  initialSelect={selectedDate || displayWeek}
                  selectedWeekDateObj={(week) => {
                    setSelectedDate(week);
                    setShowCalendar(false);
                  }}
                  selectedWeekStart={(startWeek) =>
                    setSelectedWeekStart(startWeek)
                  }
                  selectedWeekEnd={() => null}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="dropdown-otr shift-day-drop">
        <span>
          <img src={Shift_Type_Icon} /> {SHIFT_TYPE}
        </span>
        <div className="dropdown-container">
          <Dropdown menu={menuProps}>
            <Button>
              <Space>
                {defaultShiftSelection}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="dropdown-otr project-title">
        <span>
          <img src={Office_Briefcase} /> {PROJECT_TITLE} {joinProject}
        </span>
      </div>
    </div>
  );
};

export default CreateDropDownOption;
