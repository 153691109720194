import React, { useState } from "react";
import "./CommonForm.css";
import {
  Input,
  Form,
  Select,
  Col,
  Row,
  DatePicker,
  Upload,
  AutoComplete,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ContentTablecard from "../common/table/contentTable";
import {
  getContentType,
  CONTENT_DETAILS,
  UPLOAD_BUTTON,
  getAllCountries,
  getUserType,
  getCategoryType,
} from "../../constants";

const { RangePicker } = DatePicker;
export default function Content() {
  const [form] = Form.useForm();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const [options, setOptions] = useState<{ value: string }[]>([]);

  const onSearch = (searchText: string) => {
    setOptions(!searchText ? [] : []);
  };

  const onSelect = (data: string) => {
    console.log("onSelect", data);
  };

  const onContentChange = (value: string) => {
    switch (value) {
      case "male":
        form.setFieldsValue({ note: "Hi, man!" });
        break;
      case "female":
        form.setFieldsValue({ note: "Hi, lady!" });
        break;
      case "other":
        form.setFieldsValue({ note: "Hi there!" });
        break;
      default:
    }
  };

  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <div className="form-container">
      {/* <h1>Contents</h1> */}
      <div>
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          className="myspace_content"
        >
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="custom-form-group">
                <Form.Item
                  name="Type"
                  label="Type"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select a type"
                    onChange={onContentChange}
                    options={getContentType()}
                    allowClear
                  ></Select>
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="custom-form-group">
                <Form.Item
                  name="Read Duration"
                  label="Read Duration"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="custom-form-group">
                <Form.Item
                  name="Summary"
                  label="Summary"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="custom-form-group">
                <Form.Item
                  name="User Type"
                  label="User type"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="User Type"
                    onChange={onContentChange}
                    options={getUserType()}
                    allowClear
                  ></Select>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="custom-form-group">
                <Form.Item
                  name="Title"
                  label="Title"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="custom-form-group">
                <Form.Item
                  name="Country"
                  label="Country"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Country"
                    onChange={onContentChange}
                    options={getAllCountries()}
                    allowClear
                  ></Select>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="custom-form-group">
                <Form.Item
                  name="Category"
                  label="Category"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select a category"
                    onChange={onContentChange}
                    options={getCategoryType()}
                    allowClear
                  ></Select>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="custom-form-group">
                <Form.Item
                  name="Author ID"
                  label="Author Id"
                  rules={[{ required: true }]}
                >
                  <AutoComplete
                    className="auto-form-class"
                    options={options}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    placeholder="Author ID"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="date-custom-form-group custom-form-group">
                <Form.Item
                  name="Start Date & End Date"
                  label="Start Date & End Date"
                  rules={[{ required: true }]}
                >
                  <RangePicker />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="file-upload custom-form-group">
                <Form.Item
                  name="Upload"
                  label="Upload"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[{ required: true }]}
                >
                  <Upload action="/upload.do" listType="picture-card">
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>{UPLOAD_BUTTON}</div>
                    </div>
                  </Upload>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="content-details">
        <h3>{CONTENT_DETAILS}</h3>
        <ContentTablecard />
      </div>
    </div>
  );
}
