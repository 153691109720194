import React, { useState, useEffect } from "react";
import "./ReactionPopup.css";
import { Modal } from "antd";
import { Spin } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  getDetailedReaction,
  getReactionCount,
} from "../../../redux/actions/card_details";
import AvatarProfile from "../../Avatar/AvatarProfile";

function ReactionPopup(props: any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useAppDispatch();
  const handleClick = (index: any, item: any) => {
    if (item?.key !== "ALL")
      dispatch(
        getDetailedReaction({
          contentId: props?.data?.contentId,
          category: props?.data?.category,
          reactionType: item?.key,
        })
      );
    else
      dispatch(
        getDetailedReaction({
          contentId: props?.data?.contentId,
          category: props?.data?.category,
        })
      );
    setActiveIndex(index);
  };

  useEffect(() => {
    dispatch(
      getDetailedReaction({
        contentId: props?.data?.contentId,
        category: props?.data?.category,
      })
    );
    dispatch(
      getReactionCount({
        contentId: props?.data?.contentId,
      })
    );
  }, []);

  const cardDetails: any = useAppSelector((state) => state?.cardDetails);
  const { reactionDetails, reactionCount, detailedReactionLoading } =
    cardDetails;

  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        className="reaction_popup"
        title="Reactions"
        open={props.isModalOpen}
        onCancel={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.handleCancel();
        }}
      >
        <div className="tabs">
          {reactionCount?.map((item: any, index: any) => {
            return (
              <button
                className={`tab ${checkActive(index, "active")}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(index, item);
                }}
              >
                {item?.key == "ALL" ? (
                  `All ${item?.count}`
                ) : (
                  <>
                    <img src={item?.mediaResponse?.url} className="icons" />{" "}
                    {item?.count}
                  </>
                )}
                {item.label}
              </button>
            );
          })}
        </div>
        <Spin spinning={detailedReactionLoading}>
          <div className="panels">
            {reactionCount?.map((item: any, index: any) => {
              return (
                <div className={`panel ${checkActive(index, "active")}`}>
                  {reactionDetails?.map((profile: any, profileIndex: any) => {
                    return (
                      <div className="profile-pic-area">
                        <div className="profile-pic">
                          <div className="profile-picture">
                            <AvatarProfile
                              profilePic={profile?.mediaResponse?.url}
                              name={profile?.userName}
                              size={37}
                            />
                          </div>

                          <img
                            className="profile-reactions"
                            src={profile?.displayType?.url}
                          />
                        </div>

                        <span className="profile-name">
                          {profile?.userName}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Spin>
      </Modal>
    </div>
  );
}

export default ReactionPopup;
