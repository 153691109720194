import * as React from "react";
export const RightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6}
    height={10}
    fill="none"
    {...props}
  >
    <path
      fill="#0642B5"
      d="M.876 9.743a.618.618 0 0 1-.442-.183.629.629 0 0 1 0-.883l3.7-3.7-3.75-3.75a.629.629 0 0 1 0-.884.629.629 0 0 1 .884 0L5.284 4.36a.88.88 0 0 1 0 1.242L1.318 9.568a.618.618 0 0 1-.442.184v-.009Z"
    />
  </svg>
);
