import React, { useState } from "react";
import "./CreateTimeSheet.css";
import OutlineButton from "../../../common/button/OutlineButton";
import { Button } from "antd";
import {
  ADD_COMMENT,
  PREVIEW,
  SAVE_DRAFT_BUTTON,
  TOTAL_TITLE,
  TOTAL_WORK_HOURS,
  TOTAL_WORK_HOURS_WK,
} from "../../../../constants";

const CreateTimeSheetFooter = (props) => {
  const {
    totalHours,
    previewPop,
    isDisabled = true,
    saveHandler,
    hideDraftBtn,
    defaultHoursAndMinutes,
    isLeastHours,
  } = props;
  return (
    <div className="footer_sec-otr" id="footer_sec-otr">
      <div className="main-footer_container" id="main-footer_container">
        <p
          className={isLeastHours ? "total_hr-otr1" : "total_hr-otr1 less"}
          id="total_work-foot"
        >{`${TOTAL_WORK_HOURS} ${defaultHoursAndMinutes?.hours} ${TOTAL_WORK_HOURS_WK}`}</p>
        <div className="footer_sec" id="footer_sec">
          {/* <p className="total-hours">{TOTAL_TITLE} {totalHours} Hrs</p>
          <div className="button-view">
            <Button type="default" className="default">
              {ADD_COMMENT}
            </Button>
          </div> */}
          {hideDraftBtn ? null : (
            <div className="button-view" id="button-view">
              <Button
                type="default"
                className="heightlight"
                id="heightlight"
                onClick={saveHandler}
              >
                {SAVE_DRAFT_BUTTON}
              </Button>
            </div>
          )}

          <div className="button-view" id="button-view">
            <Button
              type="default"
              className={`${isDisabled ? "preview" : ""}`}
              id="preview"
              onClick={previewPop}
            >
              {PREVIEW}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTimeSheetFooter;
