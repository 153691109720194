import React from "react";

export const calendarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2V6M8 2V6M3 10H21M5 3.75H19C20.1046 3.75 21 4.64543 21 5.75V19.75C21 20.8546 20.1046 21.75 19 21.75H5C3.89543 21.75 3 20.8546 3 19.75V5.75C3 4.64543 3.89543 3.75 5 3.75Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GreyCalendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#474747"
        d="M17.25 18.129H2.75a.875.875 0 01-.875-.875V3.945c0-.483.392-.875.875-.875h14.5c.483 0 .875.392.875.875v13.309a.875.875 0 01-.875.875zm-14.125-1.25h13.75V4.32H3.125V16.88z"
      ></path>
      <path
        fill="#474747"
        d="M6.232 5.775a.63.63 0 01-.625-.625V2.5a.63.63 0 01.625-.625.63.63 0 01.625.625v2.65a.63.63 0 01-.625.625zM13.75 5.775a.63.63 0 01-.625-.625V2.5a.63.63 0 01.625-.625.63.63 0 01.625.625v2.65a.63.63 0 01-.625.625zM17.409 8.125H3.242a.63.63 0 01-.625-.625.63.63 0 01.625-.625H17.41a.63.63 0 01.625.625.63.63 0 01-.625.625z"
      ></path>
    </svg>
  );
};
