import { createSlice, current } from "@reduxjs/toolkit";
import {
  applyJob,
  downloadResume,
  getApplicationFormDetails,
  getIJPVisibleStatus,
  getJobDetail,
  getJobOpportunitiesList,
  getLandingPageDetials,
  getResume,
  submitApplication,
  uploadResume,
} from "../actions/opportunities";
import {
  base64ToArrayBuffer,
  download,
  saveByteArray,
} from "../../components/utils/util";
import {
  FAILURE,
  SOMETHING_WENT_WRONG,
  SUCCESS,
  UPLOAD_FAILED_TITLE,
} from "../../constants";

type OpportunitiesStruct = {
  loading: Boolean;
  loadingLandingPage: Boolean;
  error: Boolean;
  jobsData: any;
  jobsDetailData: Object;
  activeJobData: Object;
  configuration: any;
  fileInfo: any;
  applicationFormDetails: any;
  IJPVisible: Boolean;
  landingPageData: any;
  landingPagePopUp: boolean;
  submitApplicationResponse: any;
};

const initialDetailState: OpportunitiesStruct = {
  loading: false,
  loadingLandingPage: false,
  error: false,
  jobsData: {
    jobType: "",
    applied: [],
    all: [],
  },
  jobsDetailData: {},
  activeJobData: {},
  configuration: {
    isShowNotification: false,
    isModal: false,
    isEnabled: true,
    alertMessage: { type: "warning", message: "" },
    loading: false,
  },
  fileInfo: {},
  applicationFormDetails: {},
  IJPVisible: false,
  landingPageData: {},
  landingPagePopUp: false,
  submitApplicationResponse: {},
};

export const opportunities: any = createSlice({
  name: "opportunities",
  initialState: initialDetailState,
  reducers: {
    saveActiveJob: (state, action) => {
      state.activeJobData = action?.payload;
    },
    setApplicationDetails: (state, action) => {
      state.applicationFormDetails = action.payload;
    },
    notificationAction: (state, action) => {
      const { isShowNotification, message, type, additionalInfo } =
        action.payload;
      state.configuration.isShowNotification = isShowNotification;
      state.configuration.alertMessage = { type, message, additionalInfo };
    },
    downloadAction: (state, action) => {
      const { fileName, fileType: mimeType, fileContent } = state.fileInfo;
      var base64String = base64ToArrayBuffer(fileContent);
      saveByteArray(`${fileName}`, base64String, mimeType);
      //const res = download({ fileName, mimeType, fileContent })
    },
    landingPagePopUpAction: (state, action) => {
      state.landingPagePopUp = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobOpportunitiesList.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getJobOpportunitiesList.fulfilled, (state: any, action) => {
      state.loading = false;

      if (action?.payload?.jobType === "appliedOpp") {
        state.jobsData.applied = action?.payload?.data;
      } else state.jobsData.all = action.payload?.data;
    });
    builder.addCase(getJobOpportunitiesList.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getJobDetail.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.jobsDetailData = {};
      state.applicationFormDetails = {};
    });
    builder.addCase(getJobDetail.fulfilled, (state: any, action) => {
      state.loading = false;
      state.jobsDetailData = action?.payload;
    });
    builder.addCase(getJobDetail.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(applyJob.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(applyJob.fulfilled, (state: any, action) => {
      state.loading = false;
    });
    builder.addCase(applyJob.rejected, (state: any, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(downloadResume.pending, (state) => {
      //state.loading = true;
    });
    builder.addCase(downloadResume.fulfilled, (state, action) => {
      //state.loading = false;
      //state.details = action.payload;
      const { fileName, mimeType, fileContent } = action.payload;
      const res = download({ fileName, mimeType, fileContent });
    });
    builder.addCase(downloadResume.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getResume.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getResume.fulfilled, (state, action) => {
      state.loading = false;
      state.fileInfo = action.payload;
    });
    builder.addCase(getResume.rejected, (state: any, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(uploadResume.pending, (state) => {
      //state.loading = true;
    });
    builder.addCase(uploadResume.fulfilled, (state, action) => {
      const {
        error,
        status,
        message,
        headerMsg = "",
        subHeadermsg = "",
      } = action?.payload;
      const statusConvertion = status?.toLowerCase();
      if (statusConvertion === SUCCESS) {
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: SUCCESS,
          message: message,
          //additionalInfo: message,
        };
      } else {
        state.configuration.isShowNotification = true;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: FAILURE,
          message: message,
          // additionalInfo: message,
        };
      }
    });
    builder.addCase(uploadResume.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload;

      const {
        status,
        message,
        headerMsg = "",
        subHeadermsg = "",
      } = action?.payload?.response?.data;
      const statusConvertion = status?.toLowerCase();
      if (statusConvertion === SUCCESS) {
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: SUCCESS,
          message: message,
          //additionalInfo: message,
        };
      } else {
        state.configuration.isShowNotification = true;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: FAILURE,
          message: message,
          // additionalInfo: message,
        };
      }
    });
    // application form get api
    builder.addCase(getApplicationFormDetails.pending, (state: any) => {
      state.loading = true;
    });
    builder.addCase(
      getApplicationFormDetails.fulfilled,
      (state: any, action: any) => {
        let result = action?.payload?.data;

        const primarySkillData = result?.skills?.primarySkill.join(", ") || "";
        const SecondarySkillData =
          result?.skills?.secondarySkill.join(", ") || "";
        const yeaarOfPassing =
          result?.education?.length > 0 && result?.education[0]?.endDate
            ? new Date(result?.education[0]?.endDate).toLocaleDateString(
                "en-us",
                {
                  year: "numeric",
                }
              )
            : "";
        let formData = {
          skills: result?.skills,
          resumeBasicDetails: [
            {
              title: "Location",
              value: result?.personalInfo?.currentWorkLocation || "",
            },
            {
              title: "Total Experience (Years)",
              value: result?.personalInfo?.totalYearsOfExperience || "",
            },
            {
              title: "Date of Joining",
              value: result?.companyProfile?.dateOfJoining || "",
            },
            {
              title: "Current Project",
              value: result?.companyProfile?.currentProject || "",
            },
            {
              title: "Status",
              value: result?.companyProfile?.status || "",
            },
            {
              title: "Assignment End Date",
              value: result?.companyProfile?.assignmentEndDate || "",
            },
            {
              title: "Primary Skill Set",
              value: primarySkillData,
            },
            {
              title: "Secondary Skill Set",
              value: SecondarySkillData,
            },
          ],

          educationDetails: [
            {
              title: "Highest Qualification",
              value: result?.education[0]?.degree,
              isMandatory: true,
            },
            {
              title: "Year of Passing Out",
              value: yeaarOfPassing || "",
              isMandatory: true,
            },
          ],
          achievements: {
            value: "",
          },
          projectDetails: result?.projectDetails,
          profileDetails: result?.personalInfo,
          certificateAttachments: [],
          achievementsDetails: {
            highlightOfAchievements: "",
            initiativesParticipated: "",
          },
          status: result?.companyProfile?.status,
        };
        state.applicationFormDetails = formData;
        state.loading = false;
      }
    );
    builder.addCase(
      getApplicationFormDetails.rejected,
      (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload;
        state.configuration.isShowNotification = true;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: FAILURE,
          message: action.payload?.genericMessage
            ? action.payload?.genericMessage
            : SOMETHING_WENT_WRONG,
        };
      }
    );

    //get IJP Visible status
    builder.addCase(getIJPVisibleStatus.pending, (state: any) => {});
    builder.addCase(
      getIJPVisibleStatus.fulfilled,
      (state: any, action: any) => {
        state.IJPVisible = action?.payload?.visible;
      }
    );
    builder.addCase(
      getIJPVisibleStatus.rejected,
      (state: any, action: any) => {}
    );

    //get Landing Page details
    builder.addCase(getLandingPageDetials.pending, (state: any) => {
      state.loadingLandingPage = true;
      state.error = false;
    });
    builder.addCase(
      getLandingPageDetials.fulfilled,
      (state: any, action: any) => {
        state.loadingLandingPage = false;
        state.landingPageData = action?.payload;
      }
    );
    builder.addCase(
      getLandingPageDetials.rejected,
      (state: any, action: any) => {
        state.loadingLandingPage = false;
        state.error = true;
      }
    );

    //submit Application
    builder.addCase(submitApplication.pending, (state: any) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(submitApplication.fulfilled, (state: any, action: any) => {
      state.loading = false;

      if (action?.payload?.genericMessage !== "Failure") {
        state.landingPagePopUp = true;
      }
      state.submitApplicationResponse = action?.payload;
    });
    builder.addCase(submitApplication.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default opportunities.reducer;
export const {
  saveActiveJob,
  notificationAction,
  downloadAction,
  setApplicationDetails,
  landingPagePopUpAction,
} = opportunities.actions;
