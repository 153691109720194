import React, { useState } from "react";
import moment from "moment";
import "./ShiftTypeComponent.css";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, message, Space, Tooltip } from "antd";
import { RadioFillIcon } from "../../common/svg/RadioFillIcon";
import { RadioEmptyIcon } from "../../common/svg/RadioEmptyIcon";
const ShiftTypeComponent = (props) => {
  const { name, webImgUrl, index, activeIndex, shiftHandler } = props;
  const shiftselectionHandler = (e) => {
    e.preventDefault();
    shiftHandler(index);
  };
  return (
    <a id="shift-selection" onClick={shiftselectionHandler}>
      <div
        className={
          activeIndex === index
            ? "timesheet-shift-box-container div-active"
            : "timesheet-shift-box-container"
        }
      >
        <div className="timesheet-shift-box">
          <div id="row-shift-view" className="row-shift-view">
            <img id="shift-type-icon" src={webImgUrl} />
            {activeIndex === index ? <RadioFillIcon /> : <RadioEmptyIcon />}
          </div>
          <div id="text-shift-title" className="text-shift-title">
            {name}
          </div>
        </div>
      </div>
    </a>
  );
};

export default ShiftTypeComponent;
