import React from "react";

function Quotes() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="40"
      fill="none"
      viewBox="0 0 62 40"
    >
      <path
        fill="#D5366A"
        fillOpacity="0.5"
        d="M9.174 40c-.549 0-1.114 0-1.769-.09a4.087 4.087 0 01-2.175-.847 4.173 4.173 0 01-1.373-1.91 4.23 4.23 0 01-.132-2.358 4.19 4.19 0 011.152-2.053 9.3 9.3 0 002.37-3.374c.529-1.29.764-2.685.689-4.081a13.342 13.342 0 01-6.14-6.866 13.575 13.575 0 01-.278-9.263 12.568 12.568 0 013.13-5A13.29 13.29 0 0111.12.387a13.16 13.16 0 017.459.348 14.9 14.9 0 016.676 4.622 15.205 15.205 0 013.313 7.475c.87 4.95.106 10.051-2.174 14.516v.143C20.949 36.165 15.75 40 9.174 40zm-.655-4.498c3.908.197 8.31-1.147 14.004-10.323a18.733 18.733 0 001.768-11.47 10.824 10.824 0 00-2.315-5.38 10.608 10.608 0 00-4.758-3.347 8.728 8.728 0 00-5.036-.25 8.816 8.816 0 00-4.352 2.58 8.172 8.172 0 00-2.016 3.298 9.073 9.073 0 00.359 6.562 8.894 8.894 0 004.68 4.549c.34.137.64.359.875.643.235.285.396.624.469.987a13.635 13.635 0 01-.335 6.543 13.493 13.493 0 01-3.343 5.608zM42.22 40c-.566 0-1.114 0-1.768-.09a4.087 4.087 0 01-2.175-.847 4.173 4.173 0 01-1.373-1.91 4.229 4.229 0 01-.132-2.358 4.19 4.19 0 011.152-2.053 9.299 9.299 0 002.369-3.374c.53-1.29.765-2.685.69-4.081a13.36 13.36 0 01-6.135-6.869 13.593 13.593 0 01-.284-9.26 12.733 12.733 0 013.077-5 13.308 13.308 0 016.49-3.78 13.179 13.179 0 017.478.357 14.864 14.864 0 016.68 4.618 15.169 15.169 0 013.31 7.479c.87 4.95.105 10.051-2.175 14.516a1.07 1.07 0 010 .143C53.995 36.165 48.779 40 42.218 40zm-.672-4.498c3.925.197 8.31-1.147 14.021-10.323a18.516 18.516 0 001.768-11.47.656.656 0 000-.178 10.643 10.643 0 00-2.333-5.297 10.427 10.427 0 00-4.74-3.252 8.728 8.728 0 00-5.035-.25 8.815 8.815 0 00-4.353 2.58 7.941 7.941 0 00-1.962 3.298 9.073 9.073 0 00.359 6.562 8.894 8.894 0 004.68 4.549c.335.141.632.364.863.648.23.284.39.622.463.982.418 2.18.296 4.432-.355 6.552a13.455 13.455 0 01-3.376 5.599z"
      ></path>
    </svg>
  );
}

export default Quotes;
