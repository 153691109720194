import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import { START } from "../../constants";
import n from "../navigation/Route.Names";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./AdminPanel.css";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import { useEffect, useState } from "react";
import { Spin, Divider } from "antd";
import "./index.css";
import AdminProfileOne from "../../themes/assets/images/svg/admin-profile-one.svg";
import downloadicon from "../../themes/assets/images/svg/Downloadicon.svg";
import {
  adminPersonaEmployeePreview,
  adminPersonaDownloadData,
} from "../../redux/actions/timesheet";
import { dailyComment } from "../utils/util";
import AvatarProfile from "../Avatar/AvatarProfile";
import ToastMessage from "../common/ToastMsg/toastMessage";
import ToastClose from "../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../themes/assets/images/performance/svg/ToastError.svg";

const AdminPreview = (props) => {
  const param = useParams();
  const [visibleReminder, setVisibleReminder] = useState(false);
  const {
    adminEmployeePreviewData,
    adminEmployeePreviewDataLoading,
    adminPersonaEmployeeTimesheetsData,
    adminPersonaDownloadDataLoading,
    sendReminder
  } = useAppSelector((state: any) => state.timesheet);
  const dispatch = useAppDispatch();
  const adminEmployeeData = adminPersonaEmployeeTimesheetsData?.data;
  const previewData = adminPersonaEmployeeTimesheetsData;
  const previewShow = previewData?.data;
  useEffect(() => {
    const reqObj = {
      timesheetId: param?.timesheetId,
      weekStartDate: param?.weekStartDate,
      weekEndDate: param?.weekEndDate,
    };
    dispatch(adminPersonaEmployeePreview(reqObj));
  }, []);
  const reminderSuccess = !sendReminder?.error;

  const items = [
    {
      title: (
        <Link
          to={`/${START}`}
          // onClick={(e) => redirection(e, TIMESHEET_BREADCRUMB)}
          className="link-highlight"
        >
          {"My Work"}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`../${n.MYWORK_URL}/AdminPanel`}
          // onClick={(e) => redirection(e, TIMESHEET_BREADCRUMB)}
          className="link-highlight"
        >
          Admin
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`../${n.MYWORK_URL}/AdminPanel/EmployeeTimesheet/${adminEmployeeData?.userId}`}
          // onClick={(e) => redirection(e, TIMESHEET_BREADCRUMB)}
          className="link-highlight"
        >
          My Team
        </Link>
      ),
    },
    {
      title: "Employee",
    },
  ];

  const handleClose = () => {
    setVisibleReminder(false);
  };
  
  const clickOnDownloadFileIcon = () => {
    dispatch(
      adminPersonaDownloadData({
        userId: adminEmployeeData?.userId,
        startDate: param?.weekStartDate,
        endDate: param?.weekEndDate,
      })
    )
      .unwrap()
      .then(() => {
        setVisibleReminder(true);
      });
  };
  console.log("preview", previewShow);

  return (
    <Spin spinning={adminEmployeePreviewDataLoading}>
      <div className="main_container_approval">
        <div className="approvals-fin-breadcrumb">
          <div className="hidestricky_breadcrump">
            <BreadcrumbComponent items={items} />
          </div>
        </div>
        <div className="approval_tab_parent">
          <div
            id="timesheet_panel"
            className="timesheet_panel timesheet_admin-panel"
          >
            <div id="all_manager_activities" className="all_manager_activities">
              <div className="emp_profile-detail">
                <span className="profile_sec">
                  <AvatarProfile
                    className="avatar-admin-profile"
                    name={adminEmployeeData?.userName}
                    profilePic={adminEmployeeData?.imgUrl}
                  />
                  <div className="profile_details">
                    <div className="profile_text">
                      {adminEmployeeData?.userName}
                    </div>
                    <div className="profile_desc">
                      {adminEmployeeData?.designation}
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className="approval-fin-preview">
            <div>
              <div className="preview_finance_timesheet">
                <div>{adminEmployeePreviewData?.data?.projectTitle}</div>
                <div className="preview_time_module">
                  <div className="preview_timesheet_time">
                    {`(${adminEmployeePreviewData?.data?.displayWeek})`}
                  </div>
                  <div
                    className={`preview_display_status ${adminEmployeePreviewData?.data?.status}`}
                  >
                    {adminEmployeePreviewData?.data?.status}
                  </div>
                </div>
              </div>
              <div className="preview-tsm-borderbox">
                {adminEmployeePreviewData?.data?.tsProjects?.map(
                  (preview, index) => (
                    <div className="preview-project-division-fin">
                      <div className="preview_company_title">
                        <div>
                          {index + 1}. {preview?.projectName}
                        </div>
                        <div>{`${preview?.hours
                          .toString()
                          .padStart(2, "0")}:${preview?.minutes
                          .toString()
                          .padStart(2, "0")} hrs`}</div>
                      </div>
                      <div className="preview_work_display-fin">
                        {preview?.recordsList?.map(
                          (dates) =>
                            dates?.hours > 0 && (
                              <div className="preview_dual_work_timesheet-fin">
                                <div className="leave-holiday-preview-view">
                                  <div>{dates?.dayOfWeek}</div>
                                  {dates?.leaveApplied || dates?.halfDay ? (
                                    <div className="leave-holiday-preview-text">{`(${dates?.leaveLabel})`}</div>
                                  ) : null}
                                  {dates?.holiday ? (
                                    <div className="leave-holiday-preview-text">{`(${dates?.leaveLabel})`}</div>
                                  ) : null}
                                </div>

                                <div>{`${dates?.hours
                                  .toString()
                                  .padStart(2, "0")}:${dates?.minutes
                                  .toString()
                                  .padStart(2, "0")} hrs`}</div>
                              </div>
                            )
                        )}
                      </div>
                      <>
                        {adminEmployeePreviewData?.data?.tsProjects?.length ===
                          1 && (
                          <div className="preview_total_tsm">
                            <div>Total</div>
                            <div>
                              {" "}
                              {`${adminEmployeePreviewData?.data?.totalTime?.hours
                                .toString()
                                .padStart(
                                  2,
                                  "0"
                                )}:${adminEmployeePreviewData?.data?.totalTime?.minutes
                                .toString()
                                .padStart(2, "0")} hrs`}
                            </div>
                          </div>
                        )}
                      </>
                      {dailyComment(preview?.recordsList) && (
                    <>
                      <Divider className="tsm_preview_divider" />
                      <div className="preview_total_tsm">Daily Comment</div>
                      {adminEmployeePreviewData?.data?.recordsList?.map(
                        (comment) =>
                          comment?.comments && (
                            <>
                              <div className="preview_day_daily">
                                <span className="preview_daily_day">
                                  <span>{comment.dayOfWeek}</span> :{" "}
                                  <span className="comments">
                                    {comment.comments}
                                  </span>
                                </span>
                              </div>
                            </>
                          )
                      )}
                    </>
                  )}
                  {preview?.overallComments && (
                    <>
                      <Divider className="tsm_preview_divider" />
                      <div className="preview_overall_tsm">
                        <div className="preview_total_tsm overall">
                          Overall Comment
                        </div>
                        <div className="preview_total_tsm_content">
                          {preview?.overallComments}
                        </div>
                      </div>
                    </>
                  )}
                      
                    </div>
                  )
                )}
                {props?.selectedStatus === "Rejected" && (
                <>
                  <Divider className="tsm_preview_divider" />
                  <div className="preview-manager-box">
                    <div className="preview-manager-comments">
                      Manager Comments
                    </div>
                    <div className="preview-manager-box-comments">
                      {previewShow?.managerComments}
                    </div>
                  </div>
                </>
              )}
                
                <>
                {adminEmployeePreviewData?.data?.tsProjects?.length > 1 && (
                  <div className="preview_total_tsm">
                    <div>Total</div>
                    <div>
                      {" "}
                      {`${adminEmployeePreviewData?.data?.totalTime?.hours
                        .toString()
                        .padStart(
                          2,
                          "0"
                        )}:${adminEmployeePreviewData?.data?.totalTime?.minutes
                        .toString()
                        .padStart(2, "0")} hrs`}
                    </div>
                    
                  </div>
                )}
                </>
                </div>
              </div>
            </div>
            
          </div>
          {adminPersonaDownloadDataLoading ? (
            <div className="download-button-fin-container">
              <button className="download-button-fin">
                <Spin spinning={adminPersonaDownloadDataLoading} />
              </button>
            </div>
          ) : (
            <div className="download-button-fin-container">
              <button
                className="download-button-fin"
                onClick={clickOnDownloadFileIcon}
              >
                <img
                  src={downloadicon}
                  alt="Download Icon"
                  className="export-button-icon"
                />
                Download
              </button>
            </div>
          )}
        </div>
        {visibleReminder && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess
              ? "Downloaded Successfully"
              : "Download Unsuccessfull"
          }
          toastMsgPara={
            reminderSuccess
              ? "File has been successfully downloaded"
              : "Unable to download the file, please try later"
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
    </Spin>
  );
};

export default AdminPreview;
