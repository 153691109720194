import * as React from "react";
export const RadioEmptyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <rect
      width={18.5}
      height={18.5}
      x={0.84}
      y={1.701}
      stroke="#8F8F8F"
      strokeWidth={1.5}
      rx={9.25}
    />
  </svg>
);
