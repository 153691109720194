import {
  ACTIVE_TEXT,
  ACTIVITIES,
  FAME_BOOK,
  INNOVATION_TITLE,
  TOAST_MSG_MAXCOUNT,
  TOAST_MSG_PLACEMENT,
  TOAST_MSG_TOPVALUE,
  TOAST_NOTIFICATION_DURATION,
} from "../../constants";
import { Link, useNavigate } from "react-router-dom";
import { InnovationView, OverView, LPFamebook } from "./Components";
import { useEffect, useState } from "react";
import { getFameBooks } from "../../redux/actions/innovation";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import ToastNotification from "../common/ToastNotification/ToastNotification";
import {
  NotificationCloseIcon,
  NotificationSucessIcon,
} from "../common/svg/svg";
import { getLoggedInUserData } from "../utils/util";

const FAMEBOOK = ["Innovator", "Challenger"];

const OrchestratorLanding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [myActivitiesTab, setMyActivitiesTab] = useState<String>(
    ACTIVITIES?.[0]
  );
  const [famebookTab, setFamebookTab] = useState<String>(FAMEBOOK?.[0]);
  const {
    fameBookLoading,
    fameBooks,
    fameBookFailure,

    postChallenge,
    postChallengeLoading,
    postChallengeFailure,
  } = useAppSelector((state: any) => state?.innovation);
  const toastErrorConfiguration = postChallengeFailure?.status?.toLowerCase();
  const statusActive = postChallenge?.status === ACTIVE_TEXT;
  const toastSuccessConfiguration = !statusActive
    ? postChallenge?.status?.toLowerCase()
    : null;
  const toastStatus = toastErrorConfiguration || toastSuccessConfiguration;
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const isUserOrchestrator =
    getLoggedInUserData()?.userGroups?.includes("ORCHESTRATOR");

  useEffect(() => {
    if (toastStatus) {
      setToastVisible(true);
    }
  }, []);

  useEffect(() => {
    if (famebookTab === FAMEBOOK[0])
      dispatch(getFameBooks({ type: FAMEBOOK[0].toUpperCase() }));
    else dispatch(getFameBooks({ type: FAMEBOOK[1].toUpperCase() }));
  }, [famebookTab]);

  const fameBookUI = () => {
    return (
      <div className="famebook-section">
        <div className="semibold-text lg-text black-text">{FAME_BOOK}</div>
        <div className="famebook">
          <div className="space-between">
            <div className="tab-row row-center">
              {FAMEBOOK?.map((item: any, index: number) => (
                <div
                  className={`sm-text tab cursor-pointer ${
                    famebookTab === item
                      ? "active-tab blue-text semibold-text"
                      : "inactive-tab dark-black-text normal-text"
                  }`}
                  id={`${item}-tab`}
                  key={`${index}`}
                  onClick={() => setFamebookTab(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className="famebook-card-section">
            <LPFamebook
              loading={fameBookLoading}
              data={fameBooks}
              fameBookFailureError={fameBookFailure}
            />
          </div>
        </div>
      </div>
    );
  };

  const orchestratorUI = () => {
    return (
      <div className="body">
        <InnovationView activities={ACTIVITIES} />
        {/* orchestrator overview */}

        <div className="overview-outer-wrapper">
          <div className="semibold-text lg-text black-text overview-header">
            Overview
          </div>
          <OverView activities={ACTIVITIES} programTitle={"All Programs"} />
        </div>
        {/* orchestrator - famebook view */}
        {fameBookUI()}
      </div>
    );
  };

  return (
    <div id="orchestrator-landing-page" className="innovation-wrapper">
      {toastVisible && (
        <ToastNotification
          type={toastStatus}
          message={
            toastStatus ? postChallenge.message : postChallengeFailure.message
          }
          description={
            toastStatus
              ? postChallenge.genericMessage
              : postChallengeFailure.genericMessage
          }
          placement={TOAST_MSG_PLACEMENT}
          topValue={TOAST_MSG_TOPVALUE}
          maxCount={TOAST_MSG_MAXCOUNT}
          notificationDuration={TOAST_NOTIFICATION_DURATION}
          toastClassName={
            toastStatus ? "dex-success-toast-msg" : "dex-error-toast-msg"
          }
          icon={<NotificationSucessIcon />}
          closeIcon={<NotificationCloseIcon />}
        />
      )}
      <div className="dex-title">{INNOVATION_TITLE}</div>

      {isUserOrchestrator ? (
        orchestratorUI()
      ) : (
        <div className="dex-title center">Access Denied</div>
      )}
    </div>
  );
};

export default OrchestratorLanding;
