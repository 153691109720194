import { configureStore } from "@reduxjs/toolkit";
import webLanding from "../reducers/web_landing";
import cardDetails from "../reducers/card_details";
import storyDetails from "../reducers/story_details";
import calender_events from "../reducers/calender_event";
import EmployeeProfileSlice from "../reducers/auth";
//import admin from '../reducers/admin';
import tableSlice from "../reducers/tableSlice";
import mySpaceSlice from "../reducers/mySpaceSlice";
import new_goal from "../reducers/new_goal";
import cardDetail from "../reducers/details";
import landing from "../reducers/landing";
import certification from "../reducers/certification";
import goals from "../reducers/goals";
import paybooks from "../reducers/paybooks";
import performance from "../reducers/performance";
import gamification from "../reducers/gamification";
import holiday from "../reducers/my_space";
import timesheet from "../reducers/timesheet";
import bartle_test from "../reducers/bartle_test";
import notification from "../reducers/notification";
import leavemanagement from "../reducers/leavemanagement";
import innovation from "../reducers/innovation";
import opportunities from "../reducers/opportunities";

const store = configureStore({
  reducer: {
    webLanding: webLanding,
    auth: EmployeeProfileSlice,
    cardDetails: cardDetails,
    storyDetails: storyDetails,
    calender_events: calender_events,
    tableDetails: tableSlice,
    //admin: admin,
    mySpace: mySpaceSlice,
    newGoal: new_goal,
    cardDetail: cardDetail,
    landing: landing,
    certification: certification,
    goals: goals,
    paybooks: paybooks,
    performance: performance,
    gamification: gamification,
    holidayList: holiday,
    bartle_test: bartle_test,
    timesheet: timesheet,
    notification: notification,
    leavemanagement: leavemanagement,
    innovation,
    opportunities: opportunities,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
