import { createAsyncThunk } from "@reduxjs/toolkit";
import ebooks from "../services/dex";
// import api from "../services/api";

//All Ebooks
export const getBooks = createAsyncThunk(
  "getBooks",
  async (data: any, { rejectWithValue }) => {
    const countId = data?.countId || 3;
    const readingNow = data?.readNow || false;
    const url = `dexapi/api/v1/ebooks?readingNow=${readingNow}&start=1&count=${countId}`;
    try {
      const response = await ebooks.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//All Ebooks
export const eBookLog = createAsyncThunk(
  "eBookLog",
  async (data: any, { rejectWithValue }) => {
    const { id, activePage } = data;
    const url = `dexapi/api/v1/ebook-read-log?refId=${id}&activePage=${activePage}`;
    try {
      const response = await ebooks.post(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBooksDetail = createAsyncThunk(
  "getBooksDetail",
  async (data: any, { rejectWithValue }) => {
    const { code, page } = data;
    const url = `dexapi/api/v1/pages?code=${code}&requestedPages=${page}`;
    try {
      const response = await ebooks.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBookSummary = createAsyncThunk(
  "getBooksDetail",
  async (data: any, { rejectWithValue }) => {
    const { code, page, countId = 3, readNow = false } = data;
    const url = `dexapi/api/v1/ebooks-summary?code=${code}&readingNow=${readNow}&start=1&count=${countId}`;
    try {
      const response = await ebooks.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBooksSection = createAsyncThunk(
  "getBooksSection",
  async (data: any, { rejectWithValue }) => {
    const { code, page } = data;
    const url = `dexapi/api/v1/sections?code=${code}`;
    try {
      const response = await ebooks.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
