import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../services/dex";
//import {CardDetails} from '../../components/separation/clearance-detail/ClearanceInterface'
import { VOIC_OF_YOU_ICON, E_BOOKS_ICON } from "../../constants";
import { maxBy } from "../../components/utils/util";
import { handleComment } from "../reducers/story_details";

//upload attachment action
export const getCardContentDetail = createAsyncThunk(
  "getCardContentDetail",
  async (data: any, { rejectWithValue }) => {
    const { isPagination = false, isScroll = false, iconId } = data;
    try {
      if (iconId === E_BOOKS_ICON) {
        return {
          contentType: iconId,
          details: [],
        };
      } else if (iconId === VOIC_OF_YOU_ICON) {
        const url = `dex-webapi/api/v1/webapi/survey-questions?isJustOut=false&isPagination=${isPagination}`;
        try {
          const response = await api.get(url);
          const result = response.data;
          const { details, contentType } = result;
          const newresult = details.map((item) => ({
            ...item,
            response: "",
            high: item?.surveyOption
              ? maxBy(item?.surveyOption, "percentage")
              : "",
          }));
          return {
            contentType,
            details: newresult,
            isPagination: isPagination,
            isScroll: isScroll,
          };
        } catch (error) {
          return rejectWithValue(error);
        }
      } else {
        const url = `dex-webapi/api/v1/webapi/contents?contentType=${iconId}&isPagination=${isPagination}`;
        try {
          if (iconId === null || iconId === undefined) return;
          const response = await api.get(url);

          let result = response.data;
          if (result?.contentType === "ALTIVERSE")
            result.contentType = "ALTIFEED";
          result.isPagination = isPagination;
          result.isScroll = isScroll;
          return result;
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

//upload attachment action
export const saveSuervey = createAsyncThunk(
  "saveSuervey",
  async (data: any, { rejectWithValue }) => {
    try {
      const { index, request } = data;
      const url = `dex-webapi/api/v1/webapi/survey-response`;
      try {
        let response = await api.post(url, request);
        const result = response.data;
        const newresult = {
          ...result,
          high: result?.surveyOption
            ? maxBy(result?.surveyOption, "percentage")
            : "",
        };
        return { newresult, index };
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);
//upload attachment action
export const reactionAction = createAsyncThunk(
  "reactionAction",
  async (data: any, { rejectWithValue }) => {
    try {
      const { index, ...request } = data;
      const url = `dex-webapi/api/v1/webapi/reactions`;
      try {
        let response: any;
        if (data?.active) {
          response = await api.put(url, request);
        } else {
          response = await api.post(url, request);
        }
        const result = response.data;
        const { thresholdPopup, intervalPopup, points } = result;
        return { ...result, index, thresholdPopup, intervalPopup, points };
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const reactionDelete = createAsyncThunk(
  "reactionDelete",
  async (data: any, { rejectWithValue }) => {
    try {
      const { index, ...request } = data;
      const url = `dex-webapi/api/v1/webapi/reactions?contentId=${request?.referenceId}&reactionType=${request?.reactionType}`;
      try {
        let response: any;
        console.log("data", data);
        response = await api.delete(url);
        const result = response.data;
        console.log("resss", response);
        return { ...result, index };
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const bookmarkAction = createAsyncThunk(
  "bookmarkAction",
  async (data: any, { rejectWithValue }) => {
    try {
      const url = `dex-webapi/api/v1/webapi/bookmark?contentId=${data?.data?.contentId}`;
      let response: any;
      response = await api.post(url, {});
      const result = response.data;
      return { index: data?.index, ...result };
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const bookmarkDelete = createAsyncThunk(
  "bookmarkDelete",
  async (data: any, { rejectWithValue }) => {
    try {
      const url = `dex-webapi/api/v1/webapi/bookmark?contentId=${data?.contentId}`;
      try {
        let response: any;
        response = await api.delete(url);
        const result = response?.data;
        return {
          index: data?.index,
          ...result,
          isRemoveBookmark: data.isRemoveBookmark,
          contentId: data?.contentId,
        };
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const getDetailedReaction = createAsyncThunk(
  "getDetailedReaction",
  async (data: any, { rejectWithValue }) => {
    try {
      const { ...request } = data;
      const url = `dex-webapi/api/v1/webapi/reactions?contentId=${data?.contentId
        }&category=CONTENT${data?.reactionType ? `&reactionType=` + data?.reactionType : ""
        }`;
      try {
        let response: any;
        response = await api.get(url, request);
        const result = response.data;
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const getReactionCount = createAsyncThunk(
  "getReactionCount",
  async (data: any, { rejectWithValue }) => {
    try {
      const { ...request } = data;
      const url = `dex-webapi/api/v1/webapi/reactions/count?contentId=${data?.contentId}&category=CONTENT`;
      try {
        let response: any;
        response = await api.get(url, request);
        const result = response.data;
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const getComments = createAsyncThunk(
  "getComments",
  async (data: any, { rejectWithValue }) => {
    try {
      const url = `dex-webapi/api/v1/webapi/comments?category=${data?.category}&contentId=${data?.contentId}`;
      try {
        let response: any;
        response = await api.get(url);
        console.log("response", response);
        const result = response.data;
        console.log("index 1", data);
        return { index: data?.index, result };
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const postComments = createAsyncThunk(
  "postComments",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const { ...request } = data;
      const url = `dex-webapi/api/v1/webapi/comments`;
      try {
        let response: any;
        response = await api.post(url, request);
        const result = response.data;
        if (response.data) {
          dispatch(handleComment({ addComment: true }));
        }
        const { thresholdPopup, intervalPopup, points } = result;
        return { ...result, thresholdPopup, intervalPopup, points };
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const editComments = createAsyncThunk(
  "editComments",
  async (data: any, { rejectWithValue }) => {
    try {
      const { ...request } = data;

      // reqBody
      // {
      //   "category": "CONTENT",
      //   "comment": "testing 5",
      //   "level": "1",
      //   "postedBy": "7735",
      //   "referenceId": "65366f0f3bd33f2514f46609",
      //   "userName": "vverma7735"
      // }

      const url = `dex-webapi/api/v1/webapi/comments/${data?.contentId}`;
      try {
        let response: any;
        response = await api.put(url, request);
        const result = response.data;
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const deleteComments = createAsyncThunk(
  "deleteComments",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const url = `dex-webapi/api/v1/webapi/comments/${data?.data?.id}`;
      try {
        let response: any;
        response = await api.delete(url);
        if (response.data) {
          dispatch(handleComment({ addComment: false }));
        }
        const result = response.data;
        return { index: data?.index, result, parentIndex: data?.parentIndex };
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const likeComment = createAsyncThunk(
  "likeComment",
  async (data: any, { rejectWithValue }) => {
    try {
      const url = `dex-webapi/api/v1/webapi/likes`;
      try {
        let response: any;
        const { index, parentIndex } = data;
        response = await api.post(url, {
          category: data?.category,
          referenceId: data?.referenceId,
        });
        const result = response.data;
        console.log("response", response);
        return { index, parentIndex, result };
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const unlikeComment = createAsyncThunk(
  "unlikeComment",
  async (data: any, { rejectWithValue }) => {
    try {
      const url = `dex-webapi/api/v1/webapi/likes`;
      try {
        const { index, parentIndex } = data;
        let response: any;
        response = await api.put(url, {
          category: data?.category,
          referenceId: data?.referenceId,
        });
        const result = response.data;
        console.log("response", response);
        return { index, parentIndex, result };
      } catch (error) {
        return rejectWithValue(error);
      }
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);

export const editCommentAction = createAsyncThunk(
  "editCommentAction",
  async (data: any, { rejectWithValue }) => {
    try {
      const contentData = data;
      const url = `dex-webapi/api/v1/webapi/comments/${contentData?.id}`;
      let response: any;
      response = await api.put(url, {
        category: contentData?.category,
        comment: contentData?.comment,
        level: contentData?.level,
        referenceId: contentData?.id,
      });
      const result = response.data;
      return {
        index: contentData?.index,
        result,
        parentIndex: contentData?.parentIndex,
      };
    } catch (er) {
      console.log(er);
      return rejectWithValue(er);
    }
  }
);
