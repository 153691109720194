import "./Innovation.scss";
import "./Programs/Programs.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  ADD_CHALLENEGES_TITLE,
  ASP_START_NEXT,
  CANCEL,
  CREATE_PROGRAM,
  CREATE_PROGRAM_TITLE,
  INNOVATION,
  INNOVATION_TITLE,
  MYWORK_URL,
  NO_CHALLENGES,
  NO_CHALLENGES_DESC,
  PREVIEW_PROGRAM,
  START,
} from "../../constants";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import { BCCard } from "./Programs/PreviewProgram";
import { useEffect, useState } from "react";
import { Checkbox } from "antd";
import Notfound from "../common/svg/NotFound";
const PreviewProgram = (props: any) => {
  const [enableNext, setEnableNext] = useState<Boolean>(false);
  const [checkedList, setCheckedList] = useState<String[]>([]);
  let challengesFound = false;
  const navigate = useNavigate();
  const items = [
    {
      title: (
        <Link
          to={`/${START}/${MYWORK_URL}/${INNOVATION}`}
          className="link-highlight"
        >
          {INNOVATION_TITLE}
        </Link>
      ),
    },

    {
      title: CREATE_PROGRAM_TITLE,
    },
  ];

  const onClickCancel = () => {};
  const onClickNext = () => {
    navigate(
      `/${START}/${MYWORK_URL}/${INNOVATION}/${CREATE_PROGRAM}/${PREVIEW_PROGRAM}`
    );
  };

  const onChange = (e: any, index: number) => {
    const isChecked = e.target.checked;

    if (isChecked) setCheckedList([...checkedList, index.toString()]);
    else setCheckedList(checkedList.filter((i) => i !== index.toString()));
  };
  useEffect(() => {
    if (checkedList.length > 0) setEnableNext(true);
    else setEnableNext(false);
  }, [checkedList]);
  return (
    <div id="challenges-page" className="wrapper">
      <BreadcrumbComponent items={items} />
      <div className="dex-title">{ADD_CHALLENEGES_TITLE}</div>
      <div className="preview-program-body">
        {challengesFound ? (
          <div className="not-found-section">
            <Notfound />
            <div className="center">
              <span className="title">{NO_CHALLENGES}</span>
              <br></br>
              <span className="title small light">{NO_CHALLENGES_DESC}</span>
            </div>
          </div>
        ) : (
          <div className="info-section" style={{ marginBottom: 100 }}>
            <div className="bc-info-section">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 0, 0, 0, 0, 0, 0].map(
                (item, index) => (
                  <div className="bc-card bc-padding">
                    <Checkbox
                      onChange={(e) => {
                        onChange(e, index);
                      }}
                    >
                      <BCCard border={false} />
                    </Checkbox>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
      <div className="form-footer">
        <div className="btn-container">
          <div
            id="challenges-cancel-btn"
            onClick={onClickCancel}
            className="btn cancel"
          >
            {CANCEL}
          </div>

          <div
            id="challenges-next-btn"
            onClick={onClickNext}
            className={`btn ${enableNext ? "next-enabled" : "next-disabled"}`}
          >
            {ASP_START_NEXT}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewProgram;
