import React from "react";
import "./Learning.css";
import ISMS from "../../themes/assets/images/ISMS.png";
import POSH from "../../themes/assets/images/POSH.png";
import Coursera from "../../themes/assets/images/Coursera.png";
import MediaPartner from "../../themes/assets/images/Media_Partners.png";
import { ALTIMETRIK_DIGITAL_ACADEMY } from "../../constants";
import amplitude from "amplitude-js";
import { getLoggedInUserData } from "../utils/util";

const LearningCard = ({ url = "", icon, title, amplitudeName }) => {
  const onClick = () => {
    amplitude.getInstance().logEvent(amplitudeName);
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <div onClick={onClick} className="card-container-learning">
      <img src={icon} alt="" className="icon-learning" />
      <div className="card-title-learning">{title}</div>
    </div>
  );
};

const Learning = () => {
  const getUserCountry = () => {
    return getLoggedInUserData()?.userGroups?.includes("COUNTRY_INDIA")
      ? "India"
      : getLoggedInUserData()?.userGroups?.includes("COUNTRY_USA")
      ? "USA"
      : getLoggedInUserData()?.userGroups?.includes("COUNTRY_COLOMBIA")
      ? "Colombia"
      : getLoggedInUserData()?.userGroups?.includes("COUNTRY_MEXICO")
      ? "Mexico"
      : getLoggedInUserData()?.userGroups?.includes("COUNTRY_CANADA")
      ? "Canada"
      : getLoggedInUserData()?.userGroups?.includes("COUNTRY_SINGAPORE")
      ? "Singapore"
      : getLoggedInUserData()?.userGroups?.includes("COUNTRY_MALAYSIA")
      ? "Malaysia"
      : getLoggedInUserData()?.userGroups?.includes("COUNTRY_URUGUAY")
      ? "Uruguay"
      : "Global";
  };
  let userCountry = getUserCountry();

  function LearningsBasedOnCountry(props) {
    const { country } = props;
    switch (country) {
      case "India":
        return (
          <>
            <LearningCard
              url="https://lms.altimetrik.com"
              icon={POSH}
              title="POSH"
              amplitudeName={"Clicked POSH"}
            />
            <LearningCard
              url="https://lms.altimetrik.com"
              icon={ISMS}
              title="ISMS"
              amplitudeName={"Clicked ISMS"}
            />
          </>
        );
      case "USA":
        return (
          <>
            <LearningCard
              url="https://uslms.altimetrik.com"
              icon={ISMS}
              title="Information Secuity Awarness"
              amplitudeName={"Clicked ISMS"}
            />
            <LearningCard
              url="https://www.oblearn.com/login"
              icon={MediaPartner}
              title="Media Partner"
              amplitudeName={"Clicked Media Partner"}
            />
          </>
        );
      case "Uruguay":
        return (
          <>
            <LearningCard
              url="https://uylms.altimetrik.com"
              icon={POSH}
              title="POSH"
              amplitudeName={"Clicked POSH"}
            />

            <LearningCard
              url="https://uylms.altimetrik.com"
              icon={ISMS}
              title="ISMS"
              amplitudeName={"Clicked ISMS"}
            />
          </>
        );
      default:
        return null;
    }
  }

  return (
    <div className="learning-container">
      <div className="title-learning">{ALTIMETRIK_DIGITAL_ACADEMY}</div>
      <div className="cards-container">
        <LearningsBasedOnCountry country={userCountry} />
        <LearningCard
          url="https://coursera.altimetrik.com/"
          icon={Coursera}
          title="Coursera"
          amplitudeName={"Clicked Coursera"}
        />
      </div>
    </div>
  );
};

export default Learning;
