import { Button, Modal } from "antd";
import React, { useState } from "react";
import "./PastHolidayComponent.css";
import holidayLogo from "../../../themes/assets/images/holoday_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { DateFormat, dateSplit } from "../../utils/util";

const PastHolidayComponent = (props) => {
  const { holiday } = props;
  const [singleHoliday = { date: "", name: "", holidayClass: "" }] =
    holiday || [];

  const navigate = useNavigate();
  const clickOnViewAll = () => {
    window.open("/web/holiday_calendar", "_blank");
  };
  return (
    <div className="holiday-box-container">
      <div className="holiday-box-top-row-view">
        <div id="past-holiday-text" className="past-holiday-text">
          Past Holiday
        </div>
        <div
          id="view-all-text"
          className="view-all-text"
          onClick={() => clickOnViewAll()}
        >
          View All
        </div>
      </div>
      <div className="holiday-border-view">
        <div className="month-holday-row-view">
          <div className="month-column-view">
            <div id="txt-month" className="txt-month">
              {dateSplit(singleHoliday.date, "month")}
            </div>
            <div id="txt-date" className="txt-date">
              {dateSplit(singleHoliday.date, "date")}
            </div>
          </div>
          <div className="holiday-column-view">
            <div id="txt-holiday" className="txt-holiday">
              {singleHoliday.name}
            </div>
            <div className="holiday-day-row-view">
              <div id="txt-day" className="txt-day">
                {dateSplit(singleHoliday.date, "dayname")} |{" "}
              </div>
              <div id="txt-holiday-type" className="txt-holiday-type">
                {singleHoliday.holidayClass}
              </div>
            </div>
          </div>
        </div>
        <img
          id="logo-image"
          src={singleHoliday?.mediaResponse?.referenceUrl?.url}
          className="logo-image"
        />
      </div>
    </div>
  );
};

export default PastHolidayComponent;
