import OutlineButton from "../button/OutlineButton";
import FillButton from "../button/FillButton";
import { Form } from "antd";
import "./FixedFooter.scss";

const FixedFooter = (props: any) => {
  const {
    outlineButtonText,
    outlineBtnStyles,
    outlineBtnAction,
    fillButtonIsHtmlType,
    fillButtonText,
    fillBtnStyles,
    disabled,
    fixedFooterBottom,
    editOutlineBtn,
    editOutlineBtnStyles,
    editOutlineButtonText,
    fillBtnAction,
    editOutlineBtnAction,
    fillBtnId,
    outlineBtnId,
    editOutlineBtnId,
    isOutline = true
  } = props || {};
  return (
    <div className={"dex-fixed-footer-area" + " " + fixedFooterBottom}>
      <Form.Item>
        {isOutline && (<OutlineButton
          backText={outlineButtonText}
          outlineBtnStyles={outlineBtnStyles}
          outlineBtnAction={outlineBtnAction}
          outlineBtnId={outlineBtnId}
        />)}
        {editOutlineBtn && (
          <OutlineButton
            backText={editOutlineButtonText}
            outlineBtnStyles={editOutlineBtnStyles}
            outlineBtnAction={editOutlineBtnAction}
            outlineBtnId={editOutlineBtnId}
          />
        )}
        <FillButton
          isHtmlType={fillButtonIsHtmlType}
          fillBtnTxt={fillButtonText}
          fillBtnStyles={fillBtnStyles}
          fillBtnAction={fillBtnAction}
          disabled={disabled}
          fillBtnId={fillBtnId}
        />
      </Form.Item>
    </div>
  );
};

export default FixedFooter;
