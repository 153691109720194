import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import "./index.css";
import React, { useEffect, useState } from "react";
import { Select, Spin, Table, TableProps } from "antd";
import UpDown from "../../themes/assets/images/svg/CaretUpDown.svg";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import type { TableRowSelection } from "antd/es/table/interface";
import {
  paginationLeftIcon,
  paginationRightIcon,
} from "../common/svg/paginationIcons";
import { PreviewApprovals, RejecetedModal } from "./ApprovalsModal";
import AvatarProfile from "../Avatar/AvatarProfile";
import {
  getGroupByWeek,
  getManagerTimesheet,
  getPreviewManager,
  putApproveManager,
  putRejectManager,
  cleareApproveTimesheetData,
  sendReminderNotification,
} from "../../redux/actions/timesheet";
import ToastMessage from "../common/ToastMsg/toastMessage";
import ToastClose from "../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../themes/assets/images/performance/svg/ToastError.svg";
import { Skeleton } from "antd";
import { TIMESHEET_PENDING } from "../../constants";
import amplitude from "amplitude-js";

interface DataType {
  key: React.Key;
  title: React.ReactNode;
  date: React.ReactNode;
  hours: React.ReactNode;
  status: React.ReactNode;
  action: React.ReactNode;
  displayDates: React.ReactNode;
  weekStartDate: React.ReactNode;
  weekEndDate: React.ReactNode;
  userId: React.ReactNode;
}

interface Reject {
  userId: React.ReactNode;
  weekStartDate: React.ReactNode;
  weekEndDate: React.ReactNode;
}
interface Preview {
  timesheetId: any;
  weekStartDate: React.ReactNode;
  weekEndDate: React.ReactNode;
}

export const ManagerFlowSkeleton = ({ managerLoading }) => {
  return (
    <>
      {Array.from({ length: 1 }).map((index) => (
        <Skeleton.Button
          active={managerLoading}
          className="manager-tsm-board-top-skeleton"
        />
      ))}
    </>
  );
};

const ManagerTimesheet = ({
  selected,
  selectionState,
  groupByWeek,
  searchData,
  approvedBulk,
  managerUserTotal,
}) => {
  const dispatch = useAppDispatch();
  const {
    managerTimesheet,
    groupByWeekTsm,
    previewSelected,
    approvalMananger,
    rejectManager,
    managerLoading,
    loadingSendReminder,
    sendReminder,
  } = useAppSelector((state: any) => state.timesheet);
  const groupByWeekManager = groupByWeekTsm;
  const [isApprove, setIsApprove] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [selectionPreview, setSelectionPreview] = useState(false);
  const [selectionCount, setSelectionCount] = useState(0);
  const [previewDisplay, setPreviewDisplay] = useState(false);
  const [rejectState, setRejectState] = useState<Reject | null>(null);
  const [approveState, setApproveState] = useState<Reject | null>(null);
  const [pageChange, setPageChange] = useState(true);
  const [pageView, setPageView] = useState(0);
  const [visible, setVisible] = useState(false);
  const [previewReject, setPreviewReject] = useState(false);
  const [start, setStart] = useState(0);
  const [count, setCount] = useState(10);
  const [isPageStart, setIsPageStart] = useState(1);
  const [rejectSuccess, setRejectSuccess] = useState(false);
  const [reminderVisible, setReminderVisible] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [loadingUser, setLoadingUser] = useState({
    userId: null,
    weekStartDate: null,
  });

  const managerUserLength =
    managerTimesheet?.data?.timeSheetList?.[0]?.totalCount;
  useEffect(() => {
    if (managerUserLength !== undefined) {
      managerUserTotal(managerUserLength);
    }
  }, [managerUserTotal]);
  const approveSuccess = !approvalMananger?.error;
  const reminderSuccess = !sendReminder?.error;

  useEffect(() => {
    if (selectionState == TIMESHEET_PENDING) {
      setPageChange(true);
    } else {
      setPageChange(false);
    }
  }, [selectionState]);

  useEffect(() => {
    const selectedStatus = selectionState;
    const args = {
      status: selectedStatus || "Pending",
      start: start,
      count: 10,
    };
    dispatch(getManagerTimesheet(args));
  }, [start, selectionState, groupByWeek]);

  useEffect(() => {
    if (groupByWeek) {
      const selectedStatus = selectionState;
      dispatch(getGroupByWeek(selectedStatus));
    }
  }, [groupByWeek]);

  const handlePreviewOpen = () => {
    if (isApprove === true || isReject === true) {
      setSelectionPreview(false);
    }
  };
  const handlePreviewClose = () => {
    setSelectionPreview(false);
    setVisible(true);
  };
  const handleCloseIcon = () => {
    setSelectionPreview(false);
  };
  const handlePreviewApproveClose = () => {
    setSelectionPreview(false);
    // setVisible(true);
  };
  const handleApprove = (props) => {
    const newApproveState = {
      userId: props?.userId,
      weekStartDate: props?.weekStartDate,
      weekEndDate: props?.weekEndDate,
    };
    setApproveState(newApproveState);

    if (approveState) {
      const approveBody = {
        tsDetails: [
          {
            userId: props?.userId,
            weekStartDate: props?.weekStartDate,
            weekEndDate: props?.weekEndDate,
          },
        ],
        comments: "",
      };
      dispatch(putApproveManager(approveBody));
    }
  };
  useEffect(() => {
    if (Object.keys(approvalMananger)?.length > 0) {
      setVisible(true);
      if (approveSuccess) {
        const selectedStatus = selectionState;
        const args = {
          status: selectedStatus || "Pending",
          start: start,
          count: 10,
        };
        dispatch(getManagerTimesheet(args));
        amplitude.getInstance().logEvent("Successful Timesheet Approval");
      } else {
        amplitude.getInstance().logEvent("UnSuccessful Timesheet Approval");
      }
    }
    if (rejectSuccess) {
      const selectedStatus = selectionState;
      const args = {
        status: selectedStatus || "Pending",
        start: start,
        count: 10,
      };
      dispatch(getManagerTimesheet(args));
      setTimeout(() => {
        setRejectSuccess(false);
      }, 1500);
      amplitude.getInstance().logEvent("Successful Timesheet Rejection");
    } else {
      amplitude.getInstance().logEvent("UnSuccessful Timesheet Rejection");
    }
  }, [approvalMananger, rejectSuccess]);

  useEffect(() => {
    if (Object.keys(sendReminder)?.length > 0) {
      setReminderVisible(true);
    }
  }, [sendReminder]);

  useEffect(() => {
    if (!loadingSendReminder) {
      setLoadingUser({ userId: null, weekStartDate: null });
    }
  }, [loadingSendReminder]);

  const clickOnSendRemider = (item) => {
    try {
      let payLoadArr = [
        {
          userId: item?.userId,
          weekStartDate: item?.weekStartDate,
          weekEndDate: item?.weekEndDate,
        },
      ];
      dispatch(sendReminderNotification(payLoadArr));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleApprovePreview = (props) => {
    const newApproveState = {
      userId: props?.userId,
      weekStartDate: props?.weekStartDate,
      weekEndDate: props?.weekEndDate,
    };
    setApproveState(newApproveState);
  };
  const handleReject = (props) => {
    const newRejectState: Reject = {
      userId: props?.userId,
      weekStartDate: props?.weekStartDate,
      weekEndDate: props?.weekEndDate,
    };
    setRejectState(newRejectState);
    setIsReject(!isReject);
    setPreviewReject(!previewReject);
  };
  const handleRejectPreview = (props) => {
    const newRejectState: Reject = {
      userId: props?.userId,
      weekStartDate: props?.weekStartDate,
      weekEndDate: props?.weekEndDate,
    };
    setRejectState(newRejectState);
  };
  // if (managerUserLength > 10) {
  //   setPreviewDisplay(true);
  // }
  const selectedStatus = selectionState;
  const managerDisplayData = searchData
    ? searchData
    : managerTimesheet?.data?.timeSheetList;
  //Default List
  const managerData = managerDisplayData?.map((manager, index) => ({
    key:
      selectionState !== "Not Submitted"
        ? manager?.id
        : `${manager?.userId}_${manager?.weekStartDate}`,
    userId: manager?.userId,
    weekStartDate: manager?.weekStartDate,
    weekEndDate: manager?.weekEndDate,
    title: (
      <div className="manager_tsm_container">
        <AvatarProfile
          name={manager?.userName}
          profilePic={manager?.userImgUrl}
        />
        <div className="manager_tsm_titles">
          <div className="timesheet_manager_titles">{manager?.userName}</div>
          <div className="timesheet_regular">{manager?.designation}</div>
        </div>
      </div>
    ),
    date: <div className="timesheet_regular">{manager?.weekDisplayName}</div>,
    hours: (
      <div className="timesheet_regular">
        {manager?.totalTime?.hours.toString().padStart(2, "0")}:
        {manager?.totalTime?.minutes.toString().padStart(2, "0")} hrs
      </div>
    ),
    comment: (
      <div className="timesheet_regular">{manager?.managerComments}</div>
    ),
    status: (
      <div className={`timesheet_status ${manager?.status}`}>
        {manager?.status}
      </div>
    ),
    action:
      selectionState !== "Not Submitted" ? (
        <div className="check_buttons_tsm">
          <button
            className="check_true_button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleApprove(manager);
            }}
          >
            <CheckOutlined style={{ color: "#008000" }} />
          </button>
          <button
            className="check_false_button"
            onClick={() => handleReject(manager)}
          >
            <CloseOutlined style={{ color: "#CC0000" }} />
          </button>
        </div>
      ) : loadingSendReminder &&
        loadingUser?.userId === manager?.userId &&
        loadingUser?.weekStartDate === manager?.weekStartDate ? (
        <div className="manager_loading_notsubmitted">
          <Spin spinning={loadingSendReminder} />
          {/* {setLoadingUser()}; */}
        </div>
      ) : (
        <div
          onClick={(e) => {
            console.log("manager", manager);
            e.stopPropagation();
            e.preventDefault();
            clickOnSendRemider(manager);
            setLoadingIndex(index);
            handleUserInfo();
            setLoadingUser({
              userId: manager.userId,
              weekStartDate: manager.weekStartDate,
            });
          }}
          className="manager-action-sendreminder"
        >
          Send Reminder
        </div>
      ),
  }));

  const PaginationActivities = () => {
    const ITEMS_PER_PAGE = "Items per page:";
    const total = managerUserLength;
    const goToPreviousPage = () => {
      if (start > 0) {
        const newStart = Math.max(0, start - count);
        setStart(newStart);
        setIsPageStart(newStart + 1);
      }
    };
    const goToNextPage = () => {
      if (start + count < total) {
        const newStart = Math.min(start + count, total);
        setStart(newStart);
        setIsPageStart(newStart + 1);
      }
    };
    const handleValueChange = (value) => {
      setCount(value);
      setStart(0);
      setIsPageStart(1);
    };
    const displayEnd = Math.min(start + count, total);

    return (
      <div className="pagination-timesheet">
        {ITEMS_PER_PAGE}{" "}
        <Select
          defaultValue={10}
          options={[{ value: 10, label: 10 }]}
          onChange={handleValueChange}
        />
        <div className="pagination-timesheet-text">
          {`${isPageStart} - ${displayEnd} of ${total}`}{" "}
        </div>
        <span className="parent_pagination_icons">
          <span
            className={`pagination_left_icon ${start === 0 ? "disabled" : ""}`}
            onClick={goToPreviousPage}
          >
            {paginationLeftIcon()}
          </span>
          <span
            className={`pagination_right_icon ${start + count >= total ? "disabled" : ""
              }`}
            onClick={goToNextPage}
          >
            {paginationRightIcon()}
          </span>
        </span>
      </div>
    );
  };

  const otherPageColumns = [
    {
      title: (
        <span className="activities_title">
          {/* <AvatarProfile name={items?.displayName}
                profilePic={items?.mediaUrl}/> */}
          <div className="activities_title_text">Title Name</div>{" "}
          <img src={UpDown} alt="" />
        </span>
      ),
      dataIndex: "title",
      key: "title",
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Timesheet Date</div>{" "}
          <img src={UpDown} alt="" />
        </span>
      ),
      dataIndex: "date",
      key: "date",
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Worked Hours</div>{" "}
          <img src={UpDown} alt="" />
        </span>
      ),
      dataIndex: "hours",
      key: "hours",
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      onCell: (record, rowIndex) => ({
        onClick: () => handleRowClick(record),
      }),
    },
  ];
  const rejectedColumns = [
    {
      title: (
        <span className="activities_title">
          {/* <AvatarProfile name={items?.displayName}
                profilePic={items?.mediaUrl}/> */}
          <div className="activities_title_text">Title Name</div>{" "}
          <img src={UpDown} alt="" />
        </span>
      ),
      dataIndex: "title",
      key: "title",
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Timesheet Date</div>{" "}
          <img src={UpDown} alt="" />
        </span>
      ),
      dataIndex: "date",
      key: "date",
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Worked Hours</div>{" "}
          <img src={UpDown} alt="" />
        </span>
      ),
      dataIndex: "hours",
      key: "hours",
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 300,
      onCell: (record) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      onCell: (record, rowIndex) => ({
        onClick: () => handleRowClick(record),
      }),
    },
  ];
  const notSubmittedColumns = [
    {
      title: (
        <span className="activities_title">
          {/* <AvatarProfile name={items?.displayName}
                profilePic={items?.mediaUrl}/> */}
          <div className="activities_title_text">Title Name</div>{" "}
          <img src={UpDown} alt="" />
        </span>
      ),
      dataIndex: "title",
      key: "title",
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Timesheet Date</div>{" "}
          <img src={UpDown} alt="" />
        </span>
      ),
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const managerColumns =
    selectionState === "Not Submitted"
      ? notSubmittedColumns
      : selectionState === "Rejected"
      ? rejectedColumns
      : pageChange
        ? [
          ...otherPageColumns,
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
          },
        ]
        : otherPageColumns;

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<Reject[]>([]);

  useEffect(() => {
    if (approvedBulk || selectionState) {
      setSelectedRowKeys([]);
      setSelectedRowData([]);
      if (selectedStatus === "Pending") {
        const args = {
          status: "Pending",
          start: start,
          count: 10,
        };
        dispatch(getManagerTimesheet(args));
      }
    }
  }, [approvedBulk, selectionState]);
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[]
  ) => {
    const transformedRowsData: Reject[] = selectedRows?.map((row) => ({
      userId: row?.userId,
      weekStartDate: row?.weekStartDate,
      weekEndDate: row?.weekEndDate,
    }));
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRowData(transformedRowsData);
    setSelectionCount(selectedRowKeys?.length);
    setLoadingIndex(null);
  };
  useEffect(() => {}, [selectedRowData, selectedRowKeys?.length]);
  useEffect(() => {
    selected(selectedRowData);
  }, [selectedRowData]);

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "checkbox",
  };
  const [previewUser, setPreviewUser] = useState<Preview | null>(null);
  const handleRowClick = (record) => {
    const userPreview: Preview = {
      timesheetId: record?.key as string | number,
      weekStartDate: record?.weekStartDate,
      weekEndDate: record?.weekEndDate,
    };
    setPreviewUser(userPreview);
  };
  const handleOnRejectChange = (stateChange) => {
    handleReject(stateChange);
    setVisible(false);
  };
  const handleClose = () => {
    setVisible(false);
    setReminderVisible(false);
    dispatch(cleareApproveTimesheetData({}));
  };
  const handleUserInfo = () => {
    setSelectedRowKeys([]);
    setSelectedRowData([]);
  };

  return (
    <div id="timesheet_panel" className="timesheet_panel">
      <div id="all_manager_activities" className="all_manager_activities">
        {managerLoading ? (
          <ManagerFlowSkeleton managerLoading={managerLoading} />
        ) : groupByWeek &&
          (groupByWeekManager?.data != undefined ||
            groupByWeekManager?.data != null) ? (
          Object.keys(groupByWeekManager?.data)?.map((week) => {
            const managerDataGroupByWeek = groupByWeekManager?.data[week]?.map(
              (manager, index) => ({
                key:
                  selectionState !== "Not Submitted"
                    ? manager?.id
                    : `${manager?.userId}_${manager?.weekStartDate}`,
                userId: manager?.userId,
                weekStartDate: manager?.weekStartDate,
                weekEndDate: manager?.weekEndDate,
                title: (
                  <div className="manager_tsm_container">
                    <AvatarProfile
                      name={manager?.userName}
                      profilePic={manager?.userImgUrl}
                    />
                    <div className="manager_tsm_titles">
                      <div className="timesheet_manager_titles">
                        {manager?.userName}
                      </div>
                      <div className="timesheet_regular">
                        {manager?.designation}
                      </div>
                    </div>
                  </div>
                ),
                date: (
                  <div className="timesheet_regular">
                    {manager?.weekDisplayName}
                  </div>
                ),
                hours: (
                  <div className="timesheet_regular">
                    {manager?.totalTime?.hours.toString().padStart(2, "0")}:
                    {manager?.totalTime?.minutes.toString().padStart(2, "0")}{" "}
                    hrs
                  </div>
                ),
                comment: (
                  <div className="timesheet_regular">
                    {manager?.managerComments}
                  </div>
                ),
                status: (
                  <div className={`timesheet_status ${manager?.status}`}>
                    {manager?.status}
                  </div>
                ),
                action:
                  selectionState !== "Not Submitted" ? (
                    <div className="check_buttons_tsm">
                      <button
                        className="check_true_button"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleApprove(manager);
                        }}
                      >
                        <CheckOutlined style={{ color: "#008000" }} />
                      </button>
                      <button
                        className="check_false_button"
                        onClick={() => handleReject(manager)}
                      >
                        <CloseOutlined style={{ color: "#CC0000" }} />
                      </button>
                    </div>
                  ) : loadingSendReminder &&
                    loadingUser?.userId === manager?.userId &&
                    loadingUser?.weekStartDate === manager?.weekStartDate ? (
                    <div className="manager_loading_notsubmitted">
                      <Spin spinning={loadingSendReminder} />
                    </div>
                  ) : (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        clickOnSendRemider(manager);
                        handleUserInfo();
                        setLoadingUser({
                          userId: manager.userId,
                          weekStartDate: manager.weekStartDate,
                        });
                      }}
                      className="manager-action-sendreminder"
                    >
                      Send Reminder
                    </div>
                  ),
              })
            );
            return (
              <div className="manager_week_tsm">
                <div className="manager_week_date_title">{week}</div>
                <Table<DataType>
                  key={week}
                  className="manager_activities_table"
                  onRow={(record, index) => ({
                    onClick: () => {
                      if (
                        !isApprove &&
                        !isReject &&
                        selectionState !== "Not Submitted"
                      ) {
                        setSelectionPreview(!selectionPreview);
                      }
                    },
                  })}
                  rowSelection={
                    selectedStatus === "Pending" ||
                    selectedStatus === "Not Submitted"
                      ? rowSelection
                      : undefined
                  }
                  columns={managerColumns}
                  dataSource={managerDataGroupByWeek}
                />
              </div>
            );
          })
        ) : (
          <Table<DataType>
            className="manager_activities_table"
            onRow={(record, index) => ({
              onClick: () => {
                if (
                  !isApprove &&
                  !isReject &&
                  selectionState !== "Not Submitted"
                ) {
                  setSelectionPreview(!selectionPreview);
                  handleRowClick(record);
                  handleApprovePreview(record);
                  handleRejectPreview(record);
                  dispatch(cleareApproveTimesheetData({}));
                }
              },
            })}
            rowSelection={
              selectedStatus === "Pending" || selectedStatus === "Not Submitted"
                ? rowSelection
                : undefined
            }
            columns={managerColumns}
            dataSource={managerData}
          />
        )}
      </div>
      {groupByWeek || searchData?.length >= 10
        ? null
        : managerUserLength > 10 && <PaginationActivities />}
      {selectionPreview && (
        <PreviewApprovals
          userInformation={previewUser}
          openPreview={handlePreviewOpen}
          closePreview={handlePreviewClose}
          closePreviewIcon={handleCloseIcon}
          onRejectChange={handleOnRejectChange}
          approvePreview={approveState}
          rejectPreview={rejectState}
          selectedStatus={selectedStatus}
          approvepreviewClose={handlePreviewApproveClose}
        />
      )}
      <RejecetedModal
        open={isReject}
        close={handleReject}
        data={rejectState}
        isRejectSuccess={(val) => setRejectSuccess(val)}
      />
      {visible && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            approveSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={approveSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            approveSuccess
              ? approvalMananger?.headerMsg
              : approvalMananger?.error?.errorHeaderMsg
          }
          toastMsgPara={
            approveSuccess
              ? approvalMananger?.subHeadermsg
              : approvalMananger?.error?.errorMessage
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      {reminderVisible && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess
              ? sendReminder?.headerMsg
              : sendReminder?.error?.errorHeaderMsg
          }
          toastMsgPara={
            reminderSuccess
              ? sendReminder?.subHeadermsg
              : sendReminder?.error?.errorMessage
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
    </div>
  );
};

export default ManagerTimesheet;
