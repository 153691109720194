import React from "react";

function LinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0642B5"
        d="M17.69 21.72H3.3c-.58 0-1.05-.47-1.05-1.05V6.29c0-.58.47-1.05 1.05-1.05h7.74c.41 0 .75.34.75.75s-.34.75-.75.75H3.75v13.48h13.48v-7.31c0-.41.34-.75.75-.75s.75.34.75.75v7.76c0 .58-.47 1.05-1.05 1.05h.01z"
      ></path>
      <path
        fill="#0642B5"
        d="M13.02 11.75c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06l7.83-7.83c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-7.83 7.83c-.15.15-.34.22-.53.22z"
      ></path>
      <path
        fill="#0642B5"
        d="M21 10.83c-.41 0-.75-.34-.75-.75V3.77h-6.31c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6.76c.58 0 1.05.47 1.05 1.05v6.76c0 .41-.34.75-.75.75z"
      ></path>
    </svg>
  );
}

export default LinkIcon;
