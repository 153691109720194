import React, { useEffect } from "react";
import { Button, Card, Col, Row, Spin } from "antd";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import n from "../../navigation/Route.Names";
import {
  CHALLENGEDETAIL_BREADCRUMB_URL,
  CHALLENGEDETAIL_CHALLENGE_ID,
  CREATE_BUSINESS_CHALLENGE,
  EDIT_BUSINESS_CHALLENGE,
  INNNOVATION_BREADCRUMB_TITLE,
  INNOVATION,
  SURVEY_TABLE_DELETE_BTN,
  SURVEY_TABLE_EDIT_BTN,
} from "../../../constants";
import { EditIcon } from "../../common/svg/svg";
import {
  InnovationDataCardPreview,
  InnovationDataTitle,
  InnovationOtherDataCardPreview,
} from "../../common/card/InnovationDataPreview";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { getMyActivityChallengeDetails } from "../../../redux/actions/innovation";
import FixedFooter from "../../common/FixedFooterButtons/FixedFooter";

const breadbrumbItems = [
  {
    title: (
      <Link
        to={`/${n.START}/${n.MYWORK_URL}/${INNOVATION}`}
        className="link-highlight"
      >
        {INNNOVATION_BREADCRUMB_TITLE}
      </Link>
    ),
  },
  {
    title: `${CHALLENGEDETAIL_BREADCRUMB_URL}`,
  },
];

const BusinessChallengeDetail = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { challengeDetails, challengeDetailsLoading, challengeDetailsFailure } =
    useAppSelector((state: any) => state?.innovation);
  useEffect(() => {
    dispatch(getMyActivityChallengeDetails({ responseId: params?.responseId }));
  }, []);
  const challengeDetailValues: any = [];

  challengeDetails?.submittedResponses?.[0]?.sections?.[0]?.questionsAnswered?.forEach(
    (q: any) => {
      challengeDetailValues.push(q?.submittedResponse);
    }
  );

  const editHandler = () => {
    navigate(
      `/web/${n.MYWORK_URL}/${INNOVATION}/${EDIT_BUSINESS_CHALLENGE}/${params?.responseId}`
    );
  };
  const deleteHandler = () => {
    // delete
  };
  return (
    <Spin spinning={challengeDetailsLoading}>
      <div
        id="business-challenge-detail"
        className="dex-header-spacing business-chellenge-preview business-challenge-detail-page"
      >
        <BreadcrumbComponent items={breadbrumbItems} />
        <InnovationDataTitle
          innovationPreviewPageTitle={challengeDetails?.title}
        />

        <Card>
          <InnovationOtherDataCardPreview
            innovationRowChallengeIdTitle={CHALLENGEDETAIL_CHALLENGE_ID}
            innovationRowChallengeIdData={challengeDetails?.challengeId}
            innovationPreviewPageStatus={true}
            innovationPreviewPageStatusDetails={
              challengeDetails?.workflowSteps?.displayName
            }
            innovationPreviewPageStatusStyle={{
              backgroundColor: challengeDetails?.workflowSteps?.bgColor,
              color: challengeDetails?.workflowSteps?.textColor,
            }}
          />
          {challengeDetailValues?.map((item: any, index: any) => {
            return (
              <InnovationDataCardPreview
                innovationPreviewChallengeDetails={true}
                innovationRowNatureTitle={item?.question}
                innovationRowNatureData={item?.answer}
                key={index}
              />
            );
          })}
        </Card>
        <FixedFooter
          outlineBtnStyles={"no-buttons"}
          editOutlineBtn={true}
          editOutlineButtonText={SURVEY_TABLE_DELETE_BTN}
          editOutlineBtnStyles={"footer-edit-outline-btn"}
          editOutlineBtnId={"footer-edit-outline-btn"}
          editOutlineBtnAction={deleteHandler}
          fillButtonText={SURVEY_TABLE_EDIT_BTN}
          fillBtnStyles={"footer-fill-btn"}
          fillBtnId={"footer-fill-btn"}
          fixedFooterBottom={"innovation-footer-btns"}
          fillBtnAction={editHandler}
        />
      </div>
    </Spin>
  );
};

export default BusinessChallengeDetail;
