import React, { useEffect, useState } from "react";
import "./ApplicationPreview.css";
import {
  AchivementDataCard,
  ApplicantDetails,
  CheckboxComponent,
  FormBasicDetailComponent,
  PDFCard,
  PreviewModal,
  ProjectDetaislProgressSteps,
  StickyFooter,
} from "../components/index";
import {
  APPLICATION_FORM_PREVIEW,
  APPLY_BUTTON,
  JOB_DETAILS,
  OPPORTUNITIES,
  PROJECT_DETAILS_TITLE,
} from "../../../constants";
import profileImg from "../../../themes/assets/images/contributor/profile-two.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Modal, notification, Spin } from "antd";
import previewModalimg from "../../../themes/assets/images/previewModal.png";
import { useDispatch } from "react-redux";
import { submitApplication } from "../../../redux/actions/opportunities";
import { ToastErrorIcon } from "../../common/svg/svg";
import { landingPagePopUpAction } from "../../../redux/reducers/opportunities";
import amplitude from "amplitude-js";
import { fileSize } from "../../utils/util";

function findLocationValue(data, key) {
  const locationValue = data.find((item) => item.title === key)?.value;
  return locationValue;
}

const ApplicationPreview = () => {
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();

  const { loading, applicationFormDetails } =
    useAppSelector((state: any) => state.opportunities) || {};

  const [openModal, setOpenModal] = useState(false);

  const onClickPdfCard = () => {};

  const onClickPreviewAndApply = () => {
    setOpenModal(true);
  };

  const onClickEdit = () => {
    navigate(`/web/my_work/opportunities/detail/application/${param?.id}`);
  };

  const openErrorNotification = (message, desc) => {
    api.open({
      message: message,
      description: desc,
      placement: "topRight",
      className: "custom-toast-area custom-error-toast",
      icon: <ToastErrorIcon />,
    });
  };

  async function getBase64asString(file) {
    const reader: any = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  const onClickApply = async () => {
    const certificateRequestBody = await Promise.all(
      applicationFormDetails?.certificateAttachments?.map(async (item) => {
        return {
          name: "",
          sfAttachment: {
            userId: "",
            fileName: item?.name,
            fileContent: await getBase64asString(item),
          },
        };
      })
    );

    const body = {
      certificateRequestBody,
      talentCentralInputs: {
        jobReqId: param?.id,
        location: findLocationValue(
          applicationFormDetails?.resumeBasicDetails,
          "Location"
        ),
        totalExperience: findLocationValue(
          applicationFormDetails?.resumeBasicDetails,
          "Total Experience (Years)"
        ),
        employeeEmailId: applicationFormDetails?.profileDetails?.email,
        employeeId: "",
        employeeName: applicationFormDetails?.profileDetails?.name,
        dateOfJoining: findLocationValue(
          applicationFormDetails?.resumeBasicDetails,
          "Date of Joining"
        ),
        currentProject: findLocationValue(
          applicationFormDetails?.resumeBasicDetails,
          "Current Project"
        ),
        empType: applicationFormDetails?.status,
        assignmentEndDate: findLocationValue(
          applicationFormDetails?.resumeBasicDetails,
          "Assignment End Date"
        ),
        primarySkillSet: findLocationValue(
          applicationFormDetails?.resumeBasicDetails,
          "Primary Skill Set"
        ),
        secondarySkillSet: findLocationValue(
          applicationFormDetails?.resumeBasicDetails,
          "Secondary Skill Set"
        ),
      },
      achievements: applicationFormDetails?.achievementsDetails,
      educationDetails: {
        highestQualification: findLocationValue(
          applicationFormDetails?.educationDetails,
          "Highest Qualification"
        ),
        yearOfPassingOut: findLocationValue(
          applicationFormDetails?.educationDetails,
          "Year of Passing Out"
        ),
      },
      projectDetails: applicationFormDetails?.projectDetails?.map(
        (item: any) => {
          return {
            projectName: item?.projectName,
            organizationName: item?.organizationName,
            projectDescription: item?.projectDescription,
            startDate: item?.startDate,
            endDate: item?.endDate,
            skillNames: item?.skillNames,
          };
        }
      ),

      sendEmail: applicationFormDetails?.sendEmail
        ? applicationFormDetails?.sendEmail
        : false,
    };

    onClickCancel(); //close the popup

    dispatch(submitApplication({ body: body }))
      ?.unwrap()
      ?.then((res) => {
        if (res?.genericMessage === "Failure") {
          openErrorNotification(res?.status, res?.message);
          amplitude.getInstance().logEvent("Unsuccessful Job Submit");
          return;
        } else {
          amplitude.getInstance().logEvent("Submitted Job Application");
          navigate("/web/my_work/opportunities/applied");
        }
      })
      .catch((res) => {
        openErrorNotification(res?.status, res?.message);
        amplitude.getInstance().logEvent("Unsuccessful Job Submit");
      });
  };

  const onClickCancel = () => {
    setOpenModal(false);
  };

  const stepData = applicationFormDetails?.projectDetails?.map((item: any) => {
    return {
      title: item?.projectName,
      subTitle: item?.skillNames,
      description: item?.startDate + item?.endDate,
    };
  });

  const items = [
    {
      title: (
        <Link to={`/web/my_work/opportunities`} className="link-highlight">
          {OPPORTUNITIES}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/web/my_work/opportunities/detail`}
          className="link-highlight"
        >
          {JOB_DETAILS}
        </Link>
      ),
    },
    {
      title: APPLICATION_FORM_PREVIEW,
    },
  ];

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "All your form data will be lost";
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload, {
  //     capture: true,
  //   });

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  //tempCode

  useEffect(() => {
    onClickPdfCard();
  }, []);

  return (
    <Spin spinning={loading}>
      {contextHolder}
      <div className="application-preview-container">
        <div className="breadcrumb-container">
          <BreadcrumbComponent items={items} />
        </div>
        <div className="application-title">{APPLICATION_FORM_PREVIEW}</div>
        <ApplicantDetails
          // profileImg={profileImg}
          userName={applicationFormDetails?.profileDetails?.name}
          userEmailAdress={applicationFormDetails?.profileDetails?.email}
          designation={applicationFormDetails?.profileDetails?.role}
        />
        <FormBasicDetailComponent
          formDetails={applicationFormDetails?.resumeBasicDetails}
        />
        <ProjectDetaislProgressSteps
          projectDetailsArr={stepData}
          title={PROJECT_DETAILS_TITLE}
        />
        <div className="achivement-card-container">
          {(applicationFormDetails?.certificateAttachments?.length > 0 ||
            applicationFormDetails?.achievementsDetails
              ?.highlightOfAchievements ||
            applicationFormDetails?.achievementsDetails
              ?.initiativesParticipated) && (
            <div className="achivements-container">
              {applicationFormDetails?.certificateAttachments?.length > 0 && (
                <div className="section-title">Certificate & Achievements</div>
              )}
              <div className="pdf-scroll-holder">
                {applicationFormDetails?.certificateAttachments?.map(
                  (item, index) => {
                    return (
                      <PDFCard
                        key={index}
                        pdfName={item?.name}
                        pdfSize={fileSize({ size: item?.size })}
                        onClickClose={false}
                      />
                    );
                  }
                )}
              </div>

              <AchivementDataCard
                key={"highlights"}
                data={{
                  title: "Highlights of Achievements",
                  value:
                    applicationFormDetails?.achievementsDetails
                      ?.highlightOfAchievements,
                }}
              />
              <AchivementDataCard
                key={"initiatives"}
                data={{
                  title: "Initiatives that you have participated in",
                  value:
                    applicationFormDetails?.achievementsDetails
                      ?.initiativesParticipated,
                }}
              />
            </div>
          )}
          <div className="achivements-container">
            <div className="section-title">Education Details</div>
            {applicationFormDetails?.educationDetails?.map((item, index) => {
              return <AchivementDataCard key={index} data={item} />;
            })}
          </div>
          <CheckboxComponent
            checkbox={applicationFormDetails?.sendEmail}
            onClickCheckbox={() => {}}
          />
        </div>
      </div>
      <StickyFooter
        onClickApply={onClickPreviewAndApply}
        onClickEdit={onClickEdit}
        disabled={false}
        editBtn={true}
        nextBtnText={APPLY_BUTTON}
      />
      <PreviewModal
        isOpen={openModal}
        img={previewModalimg}
        title={"Applying to this job"}
        subtitle="Are you sure you want apply to this job?"
        nextBtnText={"Apply"}
        cancelBtnText={"Cancel"}
        nextBtnOnClick={onClickApply}
        cancelBtnOnClick={onClickCancel}
      />
    </Spin>
  );
};

export default ApplicationPreview;
