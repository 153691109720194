import { Card } from "antd";
import React from "react";
import "./index.scss"
import ReactionCard from "../Reactions";


const IdeaList = () => {
    return <div>
        <Card className="common-card-font" bordered={false}>
            <div className="custom_card_head card_head-otr">
                <div className="card_head-logo">
                    <div className="card_head_title">
                        <h5>02 Submitted Ideas</h5>
                    </div>
                </div>
                <div className="custom_card_cont-otr card_cont-otr">
                    <div className="card_cont">
                        <h4>An Innovative Employee Attendance Tracking Attendance Tracking System</h4>
                        <div className="readMoreContent">
                            <p id="" className={"summaryText"}>
                                a comprehensive solution that not only records daily in-time and out-time punctuality but a...
                            </p>
                        </div>
                    </div>
                </div>
                <div className="card_foot-otr">
                    <div className="card_foot-cmd">
                        <div className="card_reactioncount">
                            <div className="card_reactioncount reactions_card_count">
                            </div>
                        </div>
                    </div>
                </div>
                <ReactionCard
                    renderComment={() => null}
                    onClickCommentsCount={() => { }}>
                </ReactionCard>
            </div>
        </Card>
    </div>
}

export default IdeaList;