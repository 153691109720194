import { useEffect, useState } from "react";
import {
  ASP_START_NEXT,
  BACK,
  CREATE_BUSINESS_CHALLENGE,
  CREATE_BUSINESS_CHALLENGE_TITLE,
  ENTER_DESCRIPTION_LENGTH,
  ENTER_DESCRIPTION_TEXT,
  ENTER_TITLE_TEXT,
  INNOVATION,
  INNNOVATION_BREADCRUMB_TITLE,
  PREVIEW_BUSINESS_CHALLENGE,
  SELECT_TEXT,
  DRAFT_CREATE_CHALLENGE_TITLE,
  DRAFT_CREATE_CHALLENGE_PARA,
  SAVE_DRAFT_TEXT,
  DISCARD_BUTTON,
  SAVE,
} from "../../../constants";
import n from "../../navigation/Route.Names";
import "./BusinessChallenge.scss";
import { Button, Card, Col, Form, Row, Spin } from "antd";
import FormSelect from "../../common/FormSelect/FormSelect";
import { DropDownArrowIcon } from "../../common/svg/svg";
import FormTextArea from "../../common/FormTextArea/FormTextArea";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import FormInput from "../../common/FormInput/FormInput";
import FixedFooter from "../../common/FixedFooterButtons/FixedFooter";
import {
  getCreateTemplate,
  postCreateChallenge,
} from "../../../redux/actions/innovation";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import "../Innovation.scss";
import ToastNotification from "../../common/ToastNotification/ToastNotification";
import ModalDialog from "../../common/modal/modal";
import SubmitChallengeImage from "../../../themes/assets/images/performance/svg/DraftGoal.svg";
import { getLoggedInUserData } from "../../utils/util";

const BusinessChallengeCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userId } = getLoggedInUserData();

  const [form] = Form.useForm();
  const location = useLocation();

  const { templateData, templateDataLoading, templateDataFailure } =
    useAppSelector((state: any) => state.innovation);

  const { isEdited, editedData } = location?.state || {};

  const values = Form.useWatch([], form);

  const [formValid, setFormValid] = useState<boolean>(false);
  const [draftVisible, setDraftVisible] = useState<boolean>(false);
  const [textAreaValues, setTextAreaValues] = useState({});
  const [previewObj, setPreviewObj] = useState({} as any);
  const [templateObj, setTemplateObj] = useState([] as any);
  const [templateListData, setTemplateListData] = useState({} as any);

  useEffect(() => {
    window.history.replaceState(location.pathname, "");
    dispatch(
      getCreateTemplate({
        category: "PROBLEM_STATEMENT",
        // responseId: "6b858fe2-783b-4b69-8e3a-efde8484c4d9",
      })
    );
  }, [dispatch]);

  // useEffect(() => {
  //   const handlePopState = (event) => {
  //     event.preventDefault();
  //     showDraftChallengeModal();
  //   };
  //   window.addEventListener("popstate", handlePopState);
  //   window.history.pushState({ modalOpened: false }, "");

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, []);

  useEffect(() => {
    const tempQuestionListArray = templateData?.pagesList
      ?.map((pageListItem) =>
        pageListItem?.sectionList?.map((sectionItem) =>
          sectionItem?.questionsList?.map((questionItem) => {
            return questionItem;
          })
        )
      )
      .shift()
      ?.reduce((acc, val) => acc.concat(val), []);

    setTemplateObj(tempQuestionListArray);
    setPreviewObj({ questionListArray: tempQuestionListArray });

    if (isEdited) {
      setTemplateListData(editedData);
    } else {
      setTemplateListData(templateData);
    }
  }, [editedData, isEdited, templateData]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then((res) => {
        setFormValid(Object.keys(res).length !== 0);
      })
      .catch(() => setFormValid(false));
  }, [form, values]);

  const handleTextAreaChange = (field) => (e: any) => {
    const value = e.target.value; // Directly target the value
    setTextAreaValues((prev) => ({ ...prev, [field]: value.length }));
    ansCallback(field, value);
  };

  const onChangeInput = (data: any) => (e: any) => {
    const value = e.target.value;
    ansCallback(data?.questionId, value);
  };

  const onSelectOption = (quesId: string, value: string) => {
    ansCallback(quesId, value);
  };

  const showDraftChallengeModal = () => {
    const value = form.getFieldsValue();
    const formValue = Object.keys(value)
      .map((e) => {
        return value[e];
      })
      .some((el) => el !== undefined);
    if (formValue) {
      setDraftVisible(true);
    } else {
      navigate(`/${n.START}/${n.MYWORK_URL}/${INNOVATION}`);
    }
  };

  const breadbrumbItems = [
    {
      title: (
        <Button className="link-highlight" onClick={showDraftChallengeModal}>
          {INNNOVATION_BREADCRUMB_TITLE}
        </Button>
      ),
    },
    {
      title: `${CREATE_BUSINESS_CHALLENGE_TITLE}`,
    },
  ];

  const getOptionsList = (arr: any) => {
    let newArray = new Array();
    const oldKey = "option";
    const newKey = "value";

    arr.forEach((obj: any) => {
      let newObj = {};
      const keys = Object.keys(obj);
      keys.forEach((key) => {
        if (key === oldKey) {
          Object.assign(newObj, { [newKey]: obj[oldKey] });
        } else {
          Object.assign(newObj, { [key]: obj[key] });
        }
      });
      newArray.push(newObj);
    });
    return newArray;
  };

  const ansCallback = (questionId: string, value: any) => {
    const tempQuestionListArray = templateObj?.map((item: any) =>
      item.questionId === questionId ? { ...item, answer: value } : item
    );
    setTemplateObj(tempQuestionListArray);
    setPreviewObj({ ...previewObj, questionListArray: tempQuestionListArray });
  };

  const onFinish = () => {
    const { pages } = constructSavePayload();
    const reqBody = {
      templateId: templateListData?.templateId,
      type: "BUSINESS_PROBLEM",
      submittedBy: userId,
      questionarrieResponse: pages,
      status: templateListData?.status,
    };
    dispatch(
      postCreateChallenge({
        submitFlag: false,
        reqBody,
      })
    )
      .unwrap()
      .then(() => {
        navigate(
          `/web/${n.MYWORK_URL}/${INNOVATION}/${CREATE_BUSINESS_CHALLENGE}/${PREVIEW_BUSINESS_CHALLENGE}`,
          {
            state: {
              previewObj,
              templateData: templateListData,
            },
          }
        );
      });
    // Handle form submission logic here
  };

  const constructSavePayload = () => {
    const value = form.getFieldsValue();
    let newObj: any = {
      pages: [],
    };
    let sectionFormatList: any = [];
    let pageFormatList: any = [];

    const formattedPagesList = templateListData?.pagesList?.map(
      (pageItem: any, pageIndex: number) => {
        let formatPageId = pageItem.pageId;
        pageItem.sectionList.map((sectionData: any, sIndex: number) => {
          let questionsAnswered: any = [];
          let formatsections = {
            sectionId: sectionData.sectionId,
            questionsAnswered: null,
          };
          const questionList = sectionData?.questionsList?.map(
            (questionItem: any, qIndex: number) => {
              if (questionItem.type === "DROPDOWN") {
                const formKeys = Object.keys(value);
                const result = questionItem?.optionsList?.filter(
                  (optionItem: any) => {
                    return formKeys.some(() => {
                      return (
                        optionItem.option === value[questionItem.questionId]
                      );
                    });
                  }
                );

                const questionId = questionItem?.questionId;
                const answer = result?.[0]?.optionId;
                questionsAnswered.push({
                  questionId: questionId,
                  answer: answer,
                });
                return {
                  ...questionItem,
                  answer: answer,
                };
              } else {
                const questionId = questionItem?.questionId;
                const answer = value[questionId];
                questionsAnswered.push({
                  questionId: questionId,
                  answer: answer,
                });

                return {
                  ...questionItem,
                  answer: answer,
                };
              }
            }
          );
          formatsections["questionsAnswered"] = questionsAnswered;
          sectionFormatList.push(formatsections);
          return { ...sectionData, questionList: questionList };
        });
        pageFormatList.push(formatPageId);
        return {
          pageId: formatPageId,
          sections: sectionFormatList,
        };
      }
    );
    newObj["pages"] = formattedPagesList;
    return newObj;
  };

  const onClickSave = () => {
    const { pages } = constructSavePayload();
    const reqBody = {
      templateId: templateListData?.templateId,
      type: "BUSINESS_PROBLEM",
      submittedBy: userId,
      questionarrieResponse: pages,
      status: templateListData?.status,
    };
    dispatch(
      postCreateChallenge({
        submitFlag: false,
        reqBody,
      })
    )
      .unwrap()
      .then(() => {
        setDraftVisible(false);
        navigate(`/${n.START}/${n.MYWORK_URL}/${INNOVATION}`);
      });
  };

  const onClickCancel = () => {
    showDraftChallengeModal();
  };

  const CountValues = (props: any) => {
    return (
      <>
        <span className="character-limit-styles">
          {props?.totalCount}/{ENTER_DESCRIPTION_LENGTH}
        </span>
      </>
    );
  };

  const hideModal = () => {
    setDraftVisible(false);
    navigate(`/web/${n.MYWORK_URL}/${INNOVATION}`, { replace: true });
  };

  return (
    <>
      <Spin spinning={templateDataLoading}>
        <div
          id="create-business-challenge-section"
          className="dex-header-spacing create-business-challenge-section"
        >
          <BreadcrumbComponent items={breadbrumbItems} />
          <h1 className="dex-title create-business-challenge-title">
            {CREATE_BUSINESS_CHALLENGE_TITLE}
          </h1>
          <Form
            form={form}
            name="businessChallenge"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Card>
              {templateListData?.pagesList?.length > 0 &&
                templateListData?.pagesList.map(
                  (pageItem: any, pageIndex: number) =>
                    pageItem?.sectionList?.map(
                      (sectionItem: any, sectionIndex: number) =>
                        sectionItem?.questionsList.map(
                          (questionItem: any, questionIndex: number) => (
                            <>
                              {questionItem.type === "DROPDOWN" && (
                                <Row>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                  >
                                    <FormSelect
                                      selectFormLabel={
                                        questionItem?.question || ""
                                      }
                                      selectFormPlaceholder={SELECT_TEXT}
                                      selectFormName={questionItem.questionId}
                                      formItemClassName={"custom-form-item"}
                                      selectFormOptions={getOptionsList(
                                        questionItem.optionsList
                                      )}
                                      selectFormRules={[
                                        {
                                          required: questionItem?.mandatory,
                                          message: `${questionItem?.question} is required`,
                                        },
                                      ]}
                                      suffixIcon={<DropDownArrowIcon />}
                                      onChange={(value: any) =>
                                        onSelectOption(
                                          questionItem.questionId,
                                          value
                                        )
                                      }
                                      selectFormDefaultValue={
                                        questionItem?.answer
                                      }
                                    />
                                  </Col>
                                </Row>
                              )}
                              {questionItem.type === "TEXT" && (
                                <Row>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                  >
                                    <FormInput
                                      inputFormLabel={
                                        questionItem?.question || ""
                                      }
                                      inputFormName={questionItem.questionId}
                                      inputFormPlaceholder={ENTER_TITLE_TEXT}
                                      inputRules={[
                                        {
                                          required: questionItem?.mandatory,
                                          message: `${questionItem?.question} is required`,
                                        },
                                      ]}
                                      formItemClassName={"custom-form-item"}
                                      handleChange={onChangeInput(questionItem)}
                                      inputValue={questionItem?.answer}
                                    />
                                  </Col>
                                </Row>
                              )}
                              {questionItem.type === "TEXTAREA" && (
                                <Row>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                  >
                                    <FormTextArea
                                      textAreaFormLabel={
                                        questionItem?.question || ""
                                      }
                                      textAreaFormName={questionItem.questionId}
                                      textAreaFormPlaceholder={
                                        ENTER_DESCRIPTION_TEXT
                                      }
                                      formItemClassName={"custom-form-item"}
                                      textAreaRules={[
                                        {
                                          required: questionItem?.mandatory,
                                          message: `${questionItem?.question} is required`,
                                        },
                                      ]}
                                      onChange={handleTextAreaChange(
                                        questionItem.questionId
                                      )}
                                      textAreaFormDefaultValue={
                                        questionItem?.answer
                                      }
                                      textAreaMaxlength={500}
                                    />
                                    <CountValues
                                      totalCount={
                                        textAreaValues[
                                          questionItem.questionId
                                        ] || 0
                                      }
                                    />
                                  </Col>
                                </Row>
                              )}
                            </>
                          )
                        )
                    )
                )}
            </Card>
            <FixedFooter
              outlineButtonText={BACK}
              // editOutlineBtn={true}
              outlineBtnStyles={"footer-outline-btn"}
              outlineBtnId={"footer-outline-btn"}
              editOutlineButtonText={SAVE}
              editOutlineBtnStyles={"footer-edit-outline-btn"}
              editOutlineBtnId={"footer-save-outline-btn"}
              outlineBtnAction={onClickCancel}
              // editOutlineBtnAction={onClickSave}
              fillButtonIsHtmlType={true}
              fillButtonText={ASP_START_NEXT}
              fillBtnStyles={"footer-fill-btn"}
              fillBtnId={"footer-fill-btn"}
              fixedFooterBottom={"innovation-footer-btns"}
              disabled={!formValid}
            />
          </Form>
        </div>
      </Spin>
      {draftVisible && (
        <ModalDialog
          openModal={draftVisible}
          DialogHeading={DRAFT_CREATE_CHALLENGE_TITLE}
          closeIcon={false}
          indexScroll={{ display: "none" }}
          modalCenterImgStyles={{ display: "none" }}
          modalHeadingStyles={{ display: "none" }}
          popupDivider={{ display: "none" }}
          visible={draftVisible}
          msgContent={DRAFT_CREATE_CHALLENGE_PARA}
          okTxt={SAVE_DRAFT_TEXT}
          cancelTxt={DISCARD_BUTTON}
          cancelBtn={hideModal}
          okBtn={onClickSave}
          onOk={onClickSave}
          onCancel={hideModal}
          modalSubHeading={"modalSubHeading"}
          modalStyles={"delete-milestone-popup submit-challenge-popup"}
          modalContent="archive-goal-sub-title"
          maskClosable={false}
          modalImageClasses={"submit-challenge-popup-img"}
          modalImage={SubmitChallengeImage}
        />
      )}
    </>
  );
};

export default BusinessChallengeCreate;
