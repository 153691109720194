import { useEffect, useState } from "react";
import Modal from "antd/es/modal/Modal";
import "./PreviewSheet.css";
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Spin } from "antd";
import {
  DELETE,
  EDIT_TITLE,
  MY_ACTIVITIES,
  MY_SPACE,
  SUBMIT,
  SURVEY_TABLE_EDIT_BTN,
  START,
  TIMESHEET_BREADCRUMB,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  postCreateTimesheet,
  getPreviewManager,
} from "../../../redux/actions/timesheet";
import { dailyComment } from "../../utils/util";
import { setTimesheetClone } from "../../../redux/reducers/timesheet";

const MyActivitiesPreview = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { previewSelected, loadingPreview, errorPreview } = useAppSelector(
    (state: any) => state.timesheet
  );
  const previewRequest = props?.previewRequest;
  useEffect(() => {
    const preview = {
      timesheetId: previewRequest?.timesheetId || previewRequest?.id,
      weekStartDate: previewRequest?.weekStartDate,
      weekEndDate: previewRequest?.weekEndDate,
    };
    dispatch(getPreviewManager(preview));
  }, [dispatch]);
  const loading = loadingPreview;
  const activitiesPreview = previewSelected?.data;

  const {
    openPreview,
    closePreview,
    pageName,
    deleteHandlerPreview,
    editHandler,
  } = props;
  const [pageState, setPageState] = useState(false);
  useEffect(() => {
    if (pageName === MY_ACTIVITIES) {
      setPageState(!pageState);
    }
  }, [pageName]);

  const clickOnEdit = () => {
    const { weekStartDate, shiftType = "" } = previewRequest;
    dispatch(setTimesheetClone(false));
    navigate(
      `/web/${MY_SPACE}/timesheet/createtimesheet/${shiftType}/${weekStartDate}/${
        previewRequest?.timesheetId || previewRequest?.id
      }`
    );
  };

  return (
    <Modal
      open={openPreview}
      onCancel={closePreview}
      footer={false}
      closeIcon={true}
      centered={true}
      className="preview_tsm_modal"
    >
      <Spin spinning={loading}>
        <div>
          <div className="preview_timesheet">
            <div>{activitiesPreview?.projectTitle}</div>
            <div className="preview_time_module">
              <div className="preview_timesheet_time">
                {activitiesPreview?.displayWeek}
              </div>
              {pageState && (
                <div
                  className={`preview_display_status ${activitiesPreview?.status}`}
                >
                  {activitiesPreview?.status}
                </div>
              )}
            </div>
          </div>
          <div className="preview-tsm-borderbox">
            {
              // tsProjects?.length > 0
              //   ?
              activitiesPreview?.tsProjects?.map((preview, index) => (
                <div className="preview-project-division">
                  <div className="preview_company_title">
                    <div>
                      {index + 1}. {preview?.projectName}
                    </div>
                    <div>{`${preview?.hours
                      .toString()
                      .padStart(2, "0")}.${preview?.minutes
                      .toString()
                      .padStart(2, "0")} hrs`}</div>
                  </div>
                  <div className="preview_work_display">
                    {preview?.recordsList?.map((dates, index) =>
                      dates?.hours <= 0 &&
                      (index === 5 || index === 6) ? null : (
                        <div className="preview_dual_work_timesheet">
                          <div className="leave-holiday-preview-view">
                            <div>{dates?.dayOfWeek}</div>
                            {dates?.leaveApplied || dates?.halfDay ? (
                              <div className="leave-holiday-preview-text">{`(${dates?.leaveLabel})`}</div>
                            ) : null}
                            {dates?.holiday ? (
                              <div className="leave-holiday-preview-text">{`(${dates?.holidayName})`}</div>
                            ) : null}
                          </div>

                          <div>{`${dates?.hours
                            ?.toString()
                            .padStart(2, "0")}:${dates?.minutes
                            ?.toString()
                            .padStart(2, "0")} hrs`}</div>
                        </div>
                      )
                    )}
                  </div>

                  {dailyComment(preview?.recordsList) && (
                    <>
                      <Divider className="tsm_preview_divider" />
                      <div className="preview_total_tsm">Daily Comment</div>
                      {preview?.recordsList?.map(
                        (comment) =>
                          comment?.comments && (
                            <>
                              <div className="preview_day_daily">
                                <span className="preview_daily_day">
                                  <span>{comment.dayOfWeek}</span> :{" "}
                                  <span className="comments">
                                    {comment.comments}
                                  </span>
                                </span>
                              </div>
                            </>
                          )
                      )}
                    </>
                  )}
                  {preview.overallComments && (
                    <>
                      <Divider className="tsm_preview_divider" />
                      <div className="preview_overall_tsm">
                        <div className="preview_total_tsm overall">
                          Overall Comment
                        </div>
                        <div className="preview_total_tsm_content">
                          {preview.overallComments}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))
              // : previewData2?.data?.tsProjects?.recordsList?.map((preview) => (
              //     <div className="preview_work_timesheet">
              //       <div>{preview?.dayOfWeek}</div>
              //       <div>
              //         {preview?.hours}
              //         {"."}
              //         {preview?.minutes} {"hrs"}
              //       </div>
              //     </div>
              //   ))
            }
            <>
              <div className="preview_total_tsm">
                <div>Total</div>
                <div>
                  {" "}
                  {`${activitiesPreview?.totalTime?.hours
                    .toString()
                    .padStart(2, "0")}.${activitiesPreview?.totalTime?.minutes
                    .toString()
                    .padStart(2, "0")} hrs`}
                </div>
              </div>
            </>
            {activitiesPreview?.status === "Rejected" && (
              <>
                <Divider className="tsm_preview_divider" />
                <div className="preview-manager-box">
                  <div className="preview-manager-comments">
                    Manager Comments
                  </div>
                  <div className="preview-manager-box-comments">
                    {activitiesPreview?.managerComments}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="preview_buttons_timesheet">
            <>
              {activitiesPreview?.status === "Draft" ||
              activitiesPreview?.status === "Rejected" ? (
                <button
                  onClick={deleteHandlerPreview}
                  className="timesheet_submit-delete"
                >
                  {DELETE}
                </button>
              ) : (
                <button className="timesheet_submit-delete-transparent" />
              )}
              {activitiesPreview?.status === "Approved" ? null : (
                <button
                  className="timesheet_submit_edit"
                  onClick={() => clickOnEdit()}
                >
                  {SURVEY_TABLE_EDIT_BTN}
                </button>
              )}
            </>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
export default MyActivitiesPreview;
