import { Button, Modal } from "antd";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Left from "../../../themes/assets/images/svg/leave_management/ChevronLeft.svg";
import Right from "../../../themes/assets/images/svg/leave_management/ChevronRight.svg";
import moment from "moment";
import "./MyActivityComponent.css";
import {
  ACTIVITY_COLOR,
  HOURS_TEXT,
  TimeSheetStrings,
} from "../../../constants";

const MyActivityComponent = (props) => {
  const { status, totalTime, weekDisplayName } = props;
  const colors = ACTIVITY_COLOR[status];
  return (
    <div className="activity-container">
      <div id="txt-activity-date" className="txt-activity-date">
        {weekDisplayName}
      </div>
      <div className="activity-row-view">
        <div id="txt-activity-working-hr" className="txt-activity-working-hr">
          {`${TimeSheetStrings?.workingHrText} ${totalTime?.hours.toString().padStart(2, '0')}.${totalTime?.minutes.toString().padStart(2, '0')}`}{" "}
          {HOURS_TEXT}
        </div>
        <img src={Right} />
      </div>
      <div className="status-view" style={{ background: colors?.bgcolor }}>
        <div
          id="txt-activity-status"
          className="txt-activity-status"
          style={{ color: colors?.color }}
        >
          {status}
        </div>
      </div>
    </div>
  );
};

export default MyActivityComponent;
