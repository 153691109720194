import {
  ACTIVE_TEXT,
  ACTIVITIES,
  CREATE_CHALLENGE,
  CREATE_CHALLENGE_DESC,
  EXPLORE_TEAMS,
  FAME_BOOK,
  INNOVATION,
  INNOVATION_EXPLORE_MORE_DESC,
  INNOVATION_HELPDESK,
  INNOVATION_HELPDESK_DESC,
  INNOVATION_HELPDESK_MAIL,
  INNOVATION_INTRANET_LINK,
  INNOVATION_TITLE,
  INNOVATORS_VIEW,
  JOIN_A_TEAM,
  MYWORK_URL,
  MY_ACTIVITIES,
  MY_ACTIVITIES_INNOVATION,
  NOTIFICATION_DURATION,
  START,
  TOAST_MSG_MAXCOUNT,
  TOAST_MSG_PLACEMENT,
  TOAST_MSG_TOPVALUE,
  TOAST_NOTIFICATION_DURATION,
  TOP_IDEAS,
  VIEW_ALL_LINK,
  ORCHESTRATOR,
  ORCHESTRATOR_,
} from "../../constants";
import Mascot from "../../themes/assets/images/svg/Mascot.svg";
import Helpdeskmascot from "../../themes/assets/images/svg/Helpdeskmascot.svg";
import Ideamascot from "../../themes/assets/images/svg/Ideamascot.svg";
import { RhombusLg, RhombusSm } from "../common/svg/YellowVector";
import { Link, useNavigate } from "react-router-dom";
import LinkIcon from "../common/svg/Link";
import {
  CustomCarousel,
  EmployeeCommentsCard,
  LPBanner,
  InnovationView,
  OverView,
  LPIdeaDetail,
  LPMyActivitiesChallenges,
  LPMyActivitiesIdeas,
  LPFamebook,
} from "./Components";
import { useEffect, useState } from "react";
import {
  getBanner,
  getFameBooks,
  getMyActivityChallenges,
  getMyActivityIdeas,
  getTestimonials,
  getTopIdeas,
} from "../../redux/actions/innovation";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import { Flex, Skeleton } from "antd";
import NoDataFoundImg from "../../themes/assets/images/not-found.png";
import ToastNotification from "../common/ToastNotification/ToastNotification";
import {
  NotificationCloseIcon,
  NotificationSucessIcon,
} from "../common/svg/svg";
import { getLoggedInUserData } from "../utils/util";

const FAMEBOOK = ["Innovator", "Challenger"];
const InnovationLanding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [myActivitiesTab, setMyActivitiesTab] = useState<String>(
    ACTIVITIES?.[0]
  );
  const [famebookTab, setFamebookTab] = useState<String>(FAMEBOOK?.[0]);
  const {
    challengesLoading,
    challenges,
    challengesFailure,
    ideasLoading,
    ideas,
    ideasFailure,
    fameBookLoading,
    fameBooks,
    fameBookFailure,
    bannerLoading,
    banner,
    bannerFailure,
    topIdeas,
    topIdeasLoading,
    topIdeasFailure,
    testimonials,
    testimonialsLoading,
    testimonialsFailure,
    postChallenge,
    postChallengeLoading,
    postChallengeFailure,
  } = useAppSelector((state: any) => state?.innovation);
  const toastErrorConfiguration = postChallengeFailure?.status?.toLowerCase();
  const statusActive = postChallenge?.status === ACTIVE_TEXT;
  const toastSuccessConfiguration = !statusActive
    ? postChallenge?.status?.toLowerCase()
    : null;
  const toastStatus = toastErrorConfiguration || toastSuccessConfiguration;
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const isUserOrchestrator =
    getLoggedInUserData()?.userGroups?.includes("ORCHESTRATOR");

  useEffect(() => {
    if (toastStatus) {
      setToastVisible(true);
    }
  }, []);

  useEffect(() => {
    if (myActivitiesTab === ACTIVITIES[0]) dispatch(getMyActivityIdeas({}));
    else dispatch(getMyActivityChallenges({}));
  }, [myActivitiesTab]);
  useEffect(() => {
    if (famebookTab === FAMEBOOK[0])
      dispatch(getFameBooks({ type: FAMEBOOK[0].toUpperCase() }));
    else dispatch(getFameBooks({ type: FAMEBOOK[1].toUpperCase() }));
  }, [famebookTab]);
  useEffect(() => {
    dispatch(getBanner({}));
    // dispatch(getFameBooks({}));
    dispatch(getTopIdeas({}));
    dispatch(getTestimonials({ contentType: "INNOVATION_TESTIMONIAL" }));
  }, []);

  const RenderBanner = (props: any) => {
    const { data } = props;
    return <LPBanner data={data} />;
  };

  const RenderTestimonials = (props: any) => {
    const { data, indexId } = props;
    return (
      <div className="innovators-view">
        {data.map((item: any) => (
          <div className="testimonial-cards">
            <EmployeeCommentsCard data={item} indexId={indexId} />
          </div>
        ))}
      </div>
    );
  };

  const NoDataFound = () => {
    return (
      <div className="nodata-found-section">
        <img
          src={NoDataFoundImg}
          alt="No data found"
          className="nodata-found-img-area"
        />
      </div>
    );
  };

  const fameBookUI = () => {
    return (
      <div className="famebook-section">
        <div className="semibold-text lg-text black-text">{FAME_BOOK}</div>
        <div className="famebook">
          <div className="space-between">
            <div className="tab-row row-center">
              {FAMEBOOK?.map((item: any, index: number) => (
                <div
                  className={`sm-text tab cursor-pointer ${
                    famebookTab === item
                      ? "active-tab blue-text semibold-text"
                      : "inactive-tab dark-black-text normal-text"
                  }`}
                  id={`${item}-tab`}
                  key={`${index}`}
                  onClick={() => setFamebookTab(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className="famebook-card-section">
            <LPFamebook
              loading={fameBookLoading}
              data={fameBooks}
              fameBookFailureError={fameBookFailure}
            />
          </div>
        </div>
      </div>
    );
  };

  const innovatorUI = () => {
    return (
      <div className="body">
        {/* banner */}
        <CustomCarousel
          RenderComp={RenderBanner}
          arrows={true}
          isBanner={true}
          data={banner}
          loading={bannerLoading}
          bannerFailureError={bannerFailure}
        />

        {/* create challenge card */}
        <div className="create-challenge-card">
          <div className="rb-lg">
            <RhombusLg />
          </div>
          <div className="normal-text md-text black-text">
            {CREATE_CHALLENGE_DESC}{" "}
            <Link
              to="create-business-challenge"
              className="link semibold-text cursor-pointer"
              id="create-business-challenge"
            >
              {CREATE_CHALLENGE}
            </Link>
          </div>
          <div className="rb-sm">
            <RhombusSm />
          </div>
          <img src={Mascot} />
        </div>
        {/* my activities */}
        <div className="semibold-text lg-text black-text">{MY_ACTIVITIES}</div>
        <div className="activities">
          <div className="space-between">
            <div className="tab-row row-center">
              {ACTIVITIES.map((item, index) => (
                <div
                  className={`sm-text tab cursor-pointer ${
                    item === myActivitiesTab
                      ? "active-tab blue-text semibold-text"
                      : "inactive-tab dark-black-text normal-text"
                  }`}
                  onClick={() => setMyActivitiesTab(item)}
                  id={`${item}-tab`}
                >
                  {item}
                </div>
              ))}
            </div>
            <div
              className="sm-text semibold-text blue-text cursor-pointer"
              id="my-activities-view-all"
              // onClick={() =>
              //   navigate(
              //     `/${START}/${MYWORK_URL}/${INNOVATION}/${MY_ACTIVITIES_INNOVATION}/${myActivitiesTab.toLowerCase()}`
              //   )
              // }
            >
              {VIEW_ALL_LINK}
            </div>
          </div>
          <div className="activities-card-section">
            {myActivitiesTab === ACTIVITIES[0] && (
              <LPMyActivitiesIdeas
                loading={ideasLoading}
                data={ideas}
                ideasFailureError={ideasFailure}
              />
            )}
            {myActivitiesTab === ACTIVITIES[1] && (
              <LPMyActivitiesChallenges
                loading={challengesLoading}
                data={challenges}
                challengesFailureError={challengesFailure}
              />
            )}
          </div>
        </div>

        {/* explore teams */}
        <div className="semibold-text lg-text black-text">{JOIN_A_TEAM}</div>
        <div className="explore-teams">
          <div className="text-section">
            <div className="semibold-text md-text dark-black-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit!
            </div>
            <div className="dark-black-text md-text normal-text">
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </div>
            <div
              id="explore-teams-btn"
              className="btn semibold-text sm-text cursor-pointer"
            >
              {EXPLORE_TEAMS}
            </div>
          </div>

          <img src={Ideamascot} height={"100%"} />
        </div>
        {/* top ideas */}
        <div className="space-between">
          <div className="semibold-text lg-text black-text">{TOP_IDEAS}</div>
          <div
            id="top-ideas-view-all"
            className="sm-text semibold-text blue-text cursor-pointer"
          >
            {VIEW_ALL_LINK}
          </div>
        </div>

        <div className="ideas">
          {/* <div className="space-between">
          <div className="tab-row row-center">
            {["Ideas", "Challenges"].map((item, index) => (
              <div
                className={`sm-text tab cursor-pointer ${index === 0
                  ? "active-tab blue-text semibold-text"
                  : "inactive-tab dark-black-text normal-text"
                  }`}
                id={`${item}-tab`}
              >
                {item}
              </div>
            ))}
          </div>
          <div
            id="top-ideas-view-all"
            className="sm-text semibold-text blue-text cursor-pointer"
          >
            {VIEW_ALL_LINK}
          </div>
        </div> */}
          <div className="ideas-card-section">
            <LPIdeaDetail
              data={topIdeas}
              loading={topIdeasLoading}
              topIdeasFailureError={topIdeasFailure}
            />
          </div>
        </div>

        {fameBookUI()}

        {/* innovators view */}
        <div className="semibold-text lg-text black-text">
          {INNOVATORS_VIEW}
        </div>
        <CustomCarousel
          RenderComp={RenderTestimonials}
          isBanner={false}
          arrows={false}
          isTestimonial={true}
          testimonialData={testimonials}
          testimonialLoading={testimonialsLoading}
          testimonialsFailureError={testimonialsFailure}
        />
        {/* innovation helpdesk */}
        <div className="innovation-helpdesk">
          <img src={Helpdeskmascot} />
          <div className="text-section">
            <div className="black-text lg-text semibold-text">
              {INNOVATION_HELPDESK}
            </div>
            <div className="black-text normal-text sm-text">
              {INNOVATION_HELPDESK_DESC}{" "}
              <span className="semibold-text">{INNOVATION_HELPDESK_MAIL}</span>
            </div>
          </div>
        </div>
        {/* explore more */}
        <div className="explore-more">
          <div className="normal-text md-text black-text">
            {INNOVATION_EXPLORE_MORE_DESC}
            <a id="innovation-intranet-link-1" href={INNOVATION_INTRANET_LINK}>
              {INNOVATION_INTRANET_LINK}
            </a>
          </div>
          <a
            className="cursor-pointer"
            id="innovation-intranet-link-2"
            href={INNOVATION_INTRANET_LINK}
          >
            <LinkIcon />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div id="innovation-landing-page" className="innovation-wrapper">
      {toastVisible && (
        <ToastNotification
          type={toastStatus}
          message={
            toastStatus ? postChallenge.message : postChallengeFailure.message
          }
          description={
            toastStatus
              ? postChallenge.genericMessage
              : postChallengeFailure.genericMessage
          }
          placement={TOAST_MSG_PLACEMENT}
          topValue={TOAST_MSG_TOPVALUE}
          maxCount={TOAST_MSG_MAXCOUNT}
          notificationDuration={TOAST_NOTIFICATION_DURATION}
          toastClassName={
            toastStatus ? "dex-success-toast-msg" : "dex-error-toast-msg"
          }
          icon={<NotificationSucessIcon />}
          closeIcon={<NotificationCloseIcon />}
        />
      )}
      <div className="dex-title">{INNOVATION_TITLE}</div>

      {innovatorUI()}
    </div>
  );
};

export default InnovationLanding;
