import { Breadcrumb, Card, Col, Divider, Row } from "antd";
import React, { useState } from "react";
import IdeaList from "../Components/IdeaList";
import "./Idea.scss";
import ideathon from "../../../themes/assets/images/ideathon.png";
import IdeaActionModal from "../Components/IdeaActionModal";
import { VerticalDotIcon } from "../../common/svg/verticalDots";

const IdeaLanding = () => {
  const DATA = {
    eventDetails: {
      programId: "189.0.0",
      startDate: "1 Jul 2024",
      endDate: "1 Aug 2024",
    },
    ideaStatus: [
      { id: "1", label: "Close for Idea Submission" },
      { id: "2", label: "Completed" },
      { id: "3", label: "Cancelled" },
    ],
    data: [
      {
        id: 1,
        image: "",
        ideaName: "02 Submitted Ideas",
        title:
          "An Innovative Employee Attendance Tracking Attendance Tracking System",
        summary:
          "a comprehensive solution that not only records daily in-time and out-time punctuality but a...",
      },
      {
        id: 2,
        image: "",
        ideaName: "Sports Infrastructure Management",
        title:
          "An Innovative Employee Attendance Tracking Attendance Tracking System",
        summary:
          "a comprehensive solution that not only records daily in-time and out-time punctuality but a...",
      },
      {
        id: 3,
        image: "",
        ideaName: "Sports Infrastructure Management",
        title:
          "An Innovative Employee Attendance Tracking Attendance Tracking System",
        summary:
          "a comprehensive solution that not only records daily in-time and out-time punctuality but a...",
      },
      {
        id: 4,
        image: "",
        ideaName: "Sports Infrastructure Management",
        title:
          "An Innovative Employee Attendance Tracking Attendance Tracking System",
        summary:
          "a comprehensive solution that not only records daily in-time and out-time punctuality but a...",
      },
      {
        id: 5,
        image: "",
        ideaName: "Sports Infrastructure Management",
        title:
          "An Innovative Employee Attendance Tracking Attendance Tracking System",
        summary:
          "a comprehensive solution that not only records daily in-time and out-time punctuality but a...",
      },
    ],
  };

  const [actionPopOver, setActionPopOver] = useState(false);

  const onSelectAction = (e) => {
    console.log("selected action ", e);
    setActionPopOver(false);
  };

  const onClickAction = () => {
    setActionPopOver(true);
  };

  return (
    <div id="idea-landing-page" className="spacing">
      <Breadcrumb
        className="bread-crumb"
        items={[{ title: "Innovation" }, { title: "Ideathon 2024" }]}
      />
      <div className="gradient">
        <div className="event_container">
          <img src={ideathon} alt="Ideathon" />
        </div>
        <div className="gradient-left-view">
          <div className="gradient-left-status_button">
            Open For Idea Submission
          </div>
          <div className="gradient-left-dot_button" onClick={onClickAction}>
            <VerticalDotIcon />
          </div>
          <IdeaActionModal
            visible={actionPopOver}
            options={DATA.ideaStatus}
            setActionPopOver={setActionPopOver}
            onSelect={onSelectAction}
          />
        </div>
        <div className="center_view">
          <div className="event_detail_container">
            <div className="event_detail_subContainer">
              {Object.keys(DATA.eventDetails)?.map((event, index) => (
                <div className="divider">
                  <div>
                    <div className="label">{DATA.eventDetails[event]}</div>
                    <div className="labelName">{Object.values(event)}</div>
                  </div>

                  {Object.keys(DATA.eventDetails)?.length - 1 !== index && (
                    <Divider type="vertical" style={{ height: "100%" }} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="list-container">
        {DATA?.data.map((item, index) => (
          <IdeaList />
        ))}
      </div>
    </div>
  );
};

export default IdeaLanding;
