import React from "react";

export const RhombusLg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#FFD98E"
        d="M12.778 4.459l.925-1.826-2.03.201c-2.5.249-5.02-.229-7.259-1.375L2.596.527l.203 2.04a13.253 13.253 0 01-1.362 7.294l-.925 1.826 2.03-.201c2.5-.249 5.02.229 7.26 1.375l1.817.932-.202-2.04a13.252 13.252 0 011.361-7.294z"
      ></path>
    </svg>
  );
};
export const RhombusSm = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#FFD98E"
        d="M4.758 10.012l.434 1.274.838-1.047a8.603 8.603 0 013.98-2.781l1.274-.427-1.053-.842A8.71 8.71 0 017.428 2.2L6.994.925l-.838 1.047a8.604 8.604 0 01-3.98 2.781l-1.273.428 1.052.841a8.71 8.71 0 012.803 3.99z"
      ></path>
    </svg>
  );
};
