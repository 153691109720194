import { createAsyncThunk } from "@reduxjs/toolkit";
import dex from "../services/dex";
//get create challenge
export const getCreateTemplate = createAsyncThunk(
  "getCreateTemplate",
  async (args: any, { rejectWithValue }) => {
    const { category, responseId } = args;
    const url = `innovation/api/v1/getDexQuestionnarie?categoryName=${category}${
      responseId ? `&responseId=${responseId}` : ""
    }`;
    try {
      const response = await dex.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMyActivityChallenges = createAsyncThunk(
  "getMyActivityChallenges",
  async (args: any, { rejectWithValue }) => {
    const url = `innovation/api/v1/business-problems`;
    try {
      const response = await dex.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMyActivityIdeas = createAsyncThunk(
  "getMyActivityIdeas",
  async (args: any, { rejectWithValue }) => {
    // query params for the api
    // isFilterByUserId=true, businessProbId=undefined
    const url = `innovation/api/v1/innovation-ideas?isFilterByUserId=true`;
    try {
      const response = await dex.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFameBooks = createAsyncThunk(
  "getFameBooks",
  async (args: any, { rejectWithValue }) => {
    const { type } = args;
    const url = `innovation/api/v1/getFameBooks?categoryType=${type}&start=0&count=4`;
    try {
      const response = await dex.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBanner = createAsyncThunk(
  "getBanner",
  async (args: any, { rejectWithValue }) => {
    const url = `innovation/api/v1/innovation-programs?start=0`;
    try {
      const response = await dex.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postCreateChallenge = createAsyncThunk(
  "postCreateChallenge",
  async (args: any, { rejectWithValue }) => {
    const { submitFlag, responseId, reqBody } = args;
    const url = `innovation/api/v1/business-problems/questionnaire?submitFlag=${submitFlag}${
      responseId ? `&responseId=${responseId}` : ""
    }`;
    try {
      const response = await dex.post(url, reqBody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTopIdeas = createAsyncThunk(
  "getTopIdeas",
  async (args: any, { rejectWithValue }) => {
    const url = `innovation/api/v1/getTopIdeas`;
    try {
      const response = await dex.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTestimonials = createAsyncThunk(
  "getTestimonials",
  async (args: any, { rejectWithValue }) => {
    const { contentType } = args;
    const url = `dex-content/api/v1/contents?contentType=${contentType}`;
    try {
      const response = await dex.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get My Activity Challenge Details
export const getMyActivityChallengeDetails = createAsyncThunk(
  "getMyActivityChallengeDetails",
  async (args: any, { rejectWithValue }) => {
    const { responseId } = args;
    const url = `innovation/api/v1/business-problems/details?refId=${responseId}`;
    try {
      const response = await dex.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMetrics = createAsyncThunk(
  "getMetrics",
  async (args: any, { rejectWithValue }) => {
    const { metricsType, programId = "" } = args;
    let url = `innovation/api/v2/metrics?metricsType=${metricsType}`;
    if (programId) url = `${url}&programId=${programId}`;
    try {
      const response = await dex.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPrograms = createAsyncThunk(
  "getPrograms",
  async (args: any, { rejectWithValue }) => {
    let url = `innovation/api/v1/innovation-programs/list`;
    try {
      const response = await dex.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
