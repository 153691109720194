import React, { useState } from "react";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import {
  BACK,
  CANCEL,
  CREATE_BUSINESS_CHALLENGE,
  CREATE_BUSINESS_CHALLENGE_TITLE,
  INNOVATION,
  INNNOVATION_BREADCRUMB_TITLE,
  PREVIEW_CHALLENGE_TITLE,
  SUBMIT_CHALLENGE_PARA,
  SUBMIT_CHALLENGE_TITLE,
  SUBMIT_TITLE,
  SURVEY_TABLE_EDIT_BTN,

} from "../../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import n from "../../navigation/Route.Names";
import { Card, Col, Row, Spin } from "antd";
import FixedFooter from "../../common/FixedFooterButtons/FixedFooter";
import ModalDialog from "../../common/modal/modal";
import SubmitChallengeImage from "../../../themes/assets/images/performance/svg/DraftGoal.svg";
import "../Innovation.scss";
import { postCreateChallenge } from "../../../redux/actions/innovation";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";

const BusinessChallengePreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { postChallenge, postChallengeLoading } =
    useAppSelector((state: any) => state?.innovation);

  const {
    questionarrieResponse,
    templateId,
    type,
    id,
    responseId,
    businessChallenge,
    status,
    submittedBy,
    createdDate,
  } = postChallenge || {};

  console.log("postChallenge in preview", postChallenge);

  const { previewObj, templateData } = location?.state || {};

  const [submitModalVisible, setSubmitModalVisible] = useState<boolean>(false);

  const getQuestionResponseForEdit = () => {
    templateData?.pagesList?.forEach((pageItem: any, index: number) => {
      pageItem?.sectionList?.forEach(
        (sectionItem: any, sectionIndex: number) => {
          sectionItem.questionsList = previewObj?.questionListArray;
        }
      );
    });
    return templateData;
  };

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${INNOVATION}`}
          className="link-highlight"
        >
          {INNNOVATION_BREADCRUMB_TITLE}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${INNOVATION}/${CREATE_BUSINESS_CHALLENGE}`}
          className="link-highlight"
        >
          {CREATE_BUSINESS_CHALLENGE_TITLE}
        </Link>
      ),
    },
    {
      title: `${PREVIEW_CHALLENGE_TITLE}`,
    },
  ];

  const showSubmitChallengeModal = () => {
    setSubmitModalVisible(true);
  };

  const handleSubmitChallenge = () => {
    const reqBody = {
      id: id,
      responseId: responseId,
      templateId: templateId,
      type: type,
      submittedBy: submittedBy,
      createdDate: createdDate,
      businessChallenge: businessChallenge,
      questionarrieResponse: questionarrieResponse,
      status: status,
    };
    dispatch(
      postCreateChallenge({
        submitFlag: true,
        responseId: responseId,
        reqBody,
      })
    )
      .unwrap()
      .then(() => {
        navigate(`/web/${n.MYWORK_URL}/${INNOVATION}/`);
      });
    hideModal();
  };

  const hideModal = () => {
    setSubmitModalVisible(false);
  };

  return (
    <>
      <Spin spinning={postChallengeLoading}>
        <div
          id="business-chellenge-preview"
          className="dex-header-spacing business-chellenge-preview"
        >
          <BreadcrumbComponent items={breadbrumbItems} />
          <h1 className="dex-title create-business-challenge-title">
            {PREVIEW_CHALLENGE_TITLE}
          </h1>
          <Card>
            {previewObj?.questionListArray?.length > 0 &&
              previewObj?.questionListArray?.map(
                (previewItems: any, previewIndex: any) => {
                  return (
                    <Row className="dex-row-detail-section" key={previewIndex}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h4 className="dex-row-detail-title">
                          {previewItems?.question}
                        </h4>
                        <p className="dex-row-detail-para">
                          {previewItems?.answer}
                        </p>
                      </Col>
                    </Row>
                  );
                }
              )}
          </Card>
          <FixedFooter
            outlineButtonText={BACK}
            editOutlineBtn={true}
            outlineBtnStyles={"footer-outline-btn"}
            outlineBtnId={"footer-outline-btn"}
            editOutlineButtonText={SURVEY_TABLE_EDIT_BTN}
            editOutlineBtnStyles={"footer-edit-outline-btn"}
            editOutlineBtnId={"footer-edit-outline-btn"}
            editOutlineBtnAction={() => {
              navigate(
                `/web/${n.MYWORK_URL}/${INNOVATION}/${CREATE_BUSINESS_CHALLENGE}`,
                {
                  state: {
                    isEdited: true,
                    editedData: getQuestionResponseForEdit(),
                  },
                }
              );
            }}
            outlineBtnAction={() => {
              navigate(
                `/web/${n.MYWORK_URL}/${INNOVATION}/${CREATE_BUSINESS_CHALLENGE}`
              );
            }}
            fillBtnAction={showSubmitChallengeModal}
            fillButtonText={SUBMIT_TITLE}
            fillBtnStyles={"footer-fill-btn"}
            fillBtnId={"footer-fill-btn"}
            fixedFooterBottom={"innovation-footer-btns"}
          />
        </div>
      </Spin>
      <ModalDialog
        openModal={submitModalVisible}
        DialogHeading={SUBMIT_CHALLENGE_TITLE}
        closeIcon={false}
        indexScroll={{ display: "none" }}
        modalCenterImgStyles={{ display: "none" }}
        modalHeadingStyles={{ display: "none" }}
        popupDivider={{ display: "none" }}
        visible={submitModalVisible}
        msgContent={SUBMIT_CHALLENGE_PARA}
        okTxt={SUBMIT_TITLE}
        cancelTxt={CANCEL}
        cancelBtn={hideModal}
        okBtn={handleSubmitChallenge}
        onOk={handleSubmitChallenge}
        onCancel={hideModal}
        modalSubHeading={"modalSubHeading"}
        modalStyles={"delete-milestone-popup submit-challenge-popup"}
        modalContent="archive-goal-sub-title"
        maskClosable={false}
        modalImageClasses={"submit-challenge-popup-img"}
        modalImage={SubmitChallengeImage}
      />
    </>
  );
};

export default BusinessChallengePreview;
