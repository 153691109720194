import { Button } from "antd";
import React from "react";

function OutlineButton(props: any) {
  return (
    <>
      <Button
        className={props.outlineBtnStyles}
        disabled={props.disabled}
        type="default"
        onClick={props.outlineBtnAction}
        id={props.outlineBtnId}
      >
        {props.backText}
      </Button>
    </>
  );
}

export default OutlineButton;
