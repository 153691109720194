import { Button, Modal } from "antd";
import React, { useState } from "react";
import "./TypesOfTimeSheet.css";
import { leadingZero } from "../../utils/util";

const TypesOfTimeSheet = (props) => {
  const { count, imgUrl, name } = props;

  return (
    <div className="timesheet-box-container">
      <div className="timesheet-box">
        <div className="row-view">
          <div id="text-count" className="text-count">
            {count === 0 ? "-" : leadingZero(count)}
          </div>
          <img id="img-type-of-timesheet" src={imgUrl} />
        </div>
        <div id="text-title-type-of-timesheet" className="text-title">
          {name}
        </div>
      </div>
    </div>
  );
};

export default TypesOfTimeSheet;
