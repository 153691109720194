import { createAsyncThunk } from "@reduxjs/toolkit";
import opportunities from "../services/ijp";

//get job opportunities action
export const getJobOpportunitiesList = createAsyncThunk(
  "getGameLanding",
  async (args: any, { rejectWithValue }) => {
    const { jobType } = args;
    const url = `dexijp/api/v1/opportunities/jobMatches?jobType=${jobType}`;
    try {
      const response = await opportunities.get(url);
      const result = { data: response.data, jobType: jobType };
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get job detail action
export const getJobDetail = createAsyncThunk(
  "getJobDetail",
  async (args: any, { rejectWithValue }) => {
    const { jobType, ...restArgs } = args;
    const url = `dexijp/api/v1/opportunities/details?jobType=${jobType}`;
    try {
      const response = await opportunities.post(url, restArgs);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// apply job action
export const applyJob = createAsyncThunk(
  "applyJob",
  async (args: any, { rejectWithValue }) => {
    const url = `dexapi/api/v1/game-leaderboard/landingPage`;
    try {
      const response = await opportunities.post(url, args?.request);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get resume detail
export const getResume = createAsyncThunk(
  "getResume",
  async (args: any, { rejectWithValue }) => {
    const url = `dexijp/api/v1/opportunities/sample-resume`;
    try {
      const response = await opportunities.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get resume detail
export const downloadResume = createAsyncThunk(
  "downloadResume",
  async (args: any, { rejectWithValue }) => {
    const url = `dexijp/api/v1/opportunities/download-resume`;
    try {
      const response = await opportunities.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get resume detail
export const uploadResume = createAsyncThunk(
  "uploadResume",
  async (args: any, { rejectWithValue }) => {
    const url = `dexijp/api/v1/opportunities/upload-resume`;
    const { request } = args;
    try {
      const response = await opportunities.post(url, request, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getApplicationFormDetails = createAsyncThunk(
  "getApplicationFormDetails",
  async (args: any, { rejectWithValue }) => {
    const url = `dexijp/api/v1/opportunities/application-form`;
    try {
      const response = await opportunities.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getIJPVisibleStatus = createAsyncThunk(
  "getIJPVisibleStatus",
  async (args: any, { rejectWithValue }) => {
    const url = `dexijp/api/v1/opportunities/ijp-icon-config`;
    try {
      const response = await opportunities.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLandingPageDetials = createAsyncThunk(
  "getLandingPageDetials",
  async (args: any, { rejectWithValue }) => {
    const url = `dexijp/api/v1/opportunities/trackProgress`;
    try {
      const response = await opportunities.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const submitApplication = createAsyncThunk(
  "submitApplication",
  async (args: any, { rejectWithValue }) => {
    const url = `dexijp/api/v1/opportunities/apply`;
    const { body } = args;
    try {
      const response = await opportunities.post(url, body);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
