import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import {
  START,
  APPROVALS_BREADCRUMB,
  APPLY_BUTTON,
  APPROVE_TIMESHEET,
  SEND_REMINDER,
  CANCEL,
} from "../../constants";
import n from "../navigation/Route.Names";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./index.css";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import { TabPanel, TabView } from "primereact/tabview";
import { useCallback, useEffect, useState, useMemo } from "react";
import {
  Input,
  Switch,
  Select,
  Table,
  Modal,
  TableProps,
  Tabs,
  TabsProps,
  Spin,
  Form,
} from "antd";
import { SearchIcon } from "../common/svg/SearchIcon";
import { ApprovalModal } from "./ApprovalsModal";
import ManagerTimesheet from "./ManagerTimesheet";
import { debounce } from "lodash";
import {
  getManagerLanding,
  getSearchManager,
  sendReminderNotification,
  clearManagerLandingScreenData,
  getDownloadManagerTimesheet,
  postExportManagerTimesheet,
} from "../../redux/actions/timesheet";
import EmptyData from "../../themes/assets/images/empty-data.png";
import ToastMessage from "../common/ToastMsg/toastMessage";
import ToastClose from "../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../themes/assets/images/performance/svg/ToastError.svg";
import ManagerExportModal from "./ManagerExport";
import moment from "moment";

const FiltersBar = (props) => {
  const dispatch = useAppDispatch();
  const {
    isStatus,
    handleStatus,
    groupByWeek,
    handleGroupByWeek,
    statusList,
    disableGrpByWeek,
    searchLength,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const handleStatusChange = (index) => {
    handleStatus(index);
    setSearchValue("");
    disableGrpByWeek(false);
  };
  const handleSearchChange = (e) => {
    const searchValueChange = e.target?.value;
    if (searchValueChange?.length >= 3 || searchValueChange === "") {
      const selectedStatus = isStatus;
      const selectedValue = props?.searchValueChange(searchValueChange);
      const searchBody = {
        searchKey: searchValueChange,
        status: selectedStatus,
        start: 0,
        count: searchLength,
      };
      dispatch(getSearchManager(searchBody));
    }
  };
  const debounceValue = debounce(handleSearchChange, 1000);
  useEffect(() => {
    if (
      (isStatus !== undefined &&
        searchValue !== "" &&
        searchValue?.length >= 3) ||
      (isStatus && searchValue === "")
    ) {
      const searchBody = {
        searchKey: searchValue,
        status: isStatus,
        start: 0,
        count: 10,
      };
      dispatch(getSearchManager(searchBody));
    }
  }, [isStatus, searchValue]);

  useEffect(() => {
    if (groupByWeek) {
      setSearchValue("");
    }
  }, [groupByWeek]);
  return (
    <div id="tab-panel-container" className="tab-panel-container">
      <div id="tab-panel-row-view" className="tab-panel-row-view">
        <Input
          type={"Search"}
          size="large"
          placeholder="Search"
          prefix={<SearchIcon />}
          value={searchValue}
          // defaultValue={searchDataCheck}
          onChange={(e) => {
            setSearchValue(e.target?.value);
            debounceValue(e);
          }}
          disabled={groupByWeek}
        />
        <div id="right-group-view" className="right-group-view">
          <div id="group-week-text" className="group-week-text">
            Group by Week{" "}
          </div>
          <Switch onChange={handleGroupByWeek} checked={groupByWeek} />
          <div id="status-row-view" className="status-row-view">
            {statusList?.map((item, index) => {
              return (
                <button
                  key={item}
                  defaultValue={item?.[0]}
                  className={`status-sub-view ${item}  ${
                    item === isStatus ? "selected" : ""
                  }`}
                  onClick={() => handleStatusChange(item)}
                >
                  {item}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Approvals = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const {
    searchManager,
    loadingSearch,
    managerLanding,
    loadingLanding,
    errorLanding,
    sendReminder,
    loadingSendReminder,
    loadingLandingDownloadExport,
    loadingLandingDownloadExportPost,
  } = useAppSelector((state: any) => state.timesheet);
  const [isApprove, setIsApprove] = useState(false);
  const [bulkApprove, setBulkApprove] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchDataCheck, setSearchData] = useState("");
  const [isStatus, setIsStatus] = useState("Pending");
  const statusList = managerLanding?.data?.timeSheetStatusList;
  const reminderSuccess = !sendReminder?.error;
  const [searchDataValue, setSearchDataValue] = useState({});
  const [visibleReminder, setVisibleReminder] = useState(false);
  const [managerLength, setManagerLength] = useState(0);
  const handleApprove = () => {
    setIsApprove(!isApprove);
  };
  const handleApproveModal = () => {
    setIsApprove(!isApprove);
    setBulkApprove(true);
  };
  useEffect(() => {
    setBulkApprove(false);
  }, [bulkApprove]);
  const handleReject = () => {
    setIsReject(!isReject);
  };
  useEffect(() => {
    dispatch(getManagerLanding({}));
  }, []);
  const searchData =
    (searchManager?.data?.length > 0 && searchValue !== "") ||
    (searchManager?.data?.length === 0 && searchValue !== "")
      ? searchManager?.data
      : null;
  const managerLandingFeature = managerLanding?.data?.featureList;
  const items = [
    {
      title: (
        <Link
          to={`/${START}`}
          // onClick={(e) => redirection(e, TIMESHEET_BREADCRUMB)}
          className="link-highlight"
        >
          {"My Work"}
        </Link>
      ),
    },
    {
      title: APPROVALS_BREADCRUMB,
    },
  ];

  const handleStatus = (key) => {
    setIsStatus(key);
    setSearchData("");
  };
  const handleCancel = () => {
    setSelectedCount(0);
    setBulkApprove(true);
  };
  const handleReminder = () => {
    const args = selectedData;
    dispatch(sendReminderNotification(args));
  };
  useEffect(() => {
    if (!loadingSendReminder) {
      setBulkApprove(true);
    }
  }, [loadingSendReminder]);
  const handleClose = () => {
    setVisibleReminder(false);
  };

  const TimesheetFooter = () => {
    const ITEMS_SELECTED = "timesheets selected";
    return (
      <div className="tsm_approval_footer">
        <div className="tsm_footer_title">{`${seletedCount} ${ITEMS_SELECTED}`}</div>
        <div className="tsm_footer-buttons">
          {isStatus !== "Not Submitted" ? (
            <>
              <div className="cancel-button-footer" onClick={handleCancel}>
                {CANCEL}
              </div>
              <div className="timesheet_submit" onClick={handleApprove}>
                {APPROVE_TIMESHEET}
              </div>
            </>
          ) : (
            <>
              <div className="cancel-button-footer" onClick={handleCancel}>
                {CANCEL}
              </div>
              {loadingSendReminder ? (
                <div className="timesheet_submit">
                  <Spin spinning={loadingSendReminder} />
                </div>
              ) : (
                <div className="timesheet_submit" onClick={handleReminder}>
                  {SEND_REMINDER}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };
  const NoData = () => {
    return (
      <div className="nodata-manager">
        <img className="nodata-img-manger" src={EmptyData} alt="" />
        <div className="preview_company_title">Coming Soon...</div>
      </div>
    );
  };

  const [seletedCount, setSelectedCount] = useState(0);
  const [selectedData, setSelectionData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [visibleDownloadReminder, setVisibleDownloadReminder] = useState(false);
  const [visibleEmailReminder, setVisibleEmailReminder] = useState(false);
  const [dates, setDates] = useState([null, null]); // Store start and end dates

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setIsDownloadEnabled(false);
    setDates([null, null]); // Reset the date range when modal closes
  };

  // Update the download button state based on the selected date range
  const onDateChange = (value, dateString) => {
    setDates(dateString); // Set the selected date range
    // If both start and end dates are selected, enable the download button
    setIsDownloadEnabled(value && value.length === 2 && value[0] && value[1]);
  };
  const handleSelection = (selectionCount) => {
    setSelectedCount(selectionCount?.length);
    setSelectionData(selectionCount);
  };
  useEffect(() => {}, [seletedCount, selectedData]);
  const [groupByWeek, setGroupByWeek] = useState(false);
  const handleGroupByWeek = (checked) => {
    setGroupByWeek(checked);
  };
  useEffect(() => {}, [groupByWeek]);
  const { tab = "" } = useParams();
  const tabsKey = {
    leave: 0,
    certification: 1,
    it: 2,
    Timesheet: 3,
  };
  const [tabsActive, setTabsActive] = useState(tabsKey?.[tab] || 0);
  const handleTabChange = (e: any) => {
    setTabsActive(e.index);
  };
  const handleSearchValue = (search) => {
    setSearchValue(search);
  };
  const handleUserLength = (length: any) => {
    setManagerLength(length);
  };

  useEffect(() => {}, []);

  const handleClosePopup = () => {
    setVisibleDownloadReminder(false);
    setVisibleEmailReminder(false);
  };

  const clickOnDownload = () => {
    dispatch(
      getDownloadManagerTimesheet({
        startDate: moment(dates[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(dates[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    )
      .unwrap()
      .then(() => {
        setIsModalVisible(false);
      });
    setVisibleDownloadReminder(true);
  };
  const clickOnEmail = () => {
    dispatch(
      postExportManagerTimesheet({
        startDate: moment(dates[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(dates[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    )
      .unwrap()
      .then(() => {
        setIsModalVisible(false);
      });
    setVisibleEmailReminder(true);
  };

  return (
    <Spin spinning={loadingLanding}>
      <div className="main_container_approval">
        <div className="hidestricky_breadcrump">
          <BreadcrumbComponent items={items} />
          <div className="Seperation-page-title">
            {APPROVALS_BREADCRUMB}
            <div className="landing-page-footer">
              <ManagerExportModal
                showModal={showModal}
                isModalVisible={isModalVisible}
                clickOnDownload={clickOnDownload}
                clickOnEmail={clickOnEmail}
                isRangeSelected
                handleCancel={handleModalCancel}
                onDateChange={onDateChange}
                isDownloadEnabled={isDownloadEnabled}
                loadingCheckDownload={loadingLandingDownloadExport}
                loadingCheckEmail={loadingLandingDownloadExportPost}
                dates={dates}
                form={form}
              />
            </div>
          </div>
        </div>
        <div className="apprval_tab_parent">
          <TabView
            className="manager-tabs-tsm"
            activeIndex={tabsActive}
            onTabChange={handleTabChange}
          >
            {managerLandingFeature?.map((tabs) => (
              <TabPanel key={tabs?.key} header={tabs?.value}>
                {tabs?.key === "LEAVE" && <NoData />}
                {tabs?.key === "CERTIFICATION" && <NoData />}
                {tabs?.key === "IT" && <NoData />}
                {tabs?.key === "TIMESHEET" && (
                  <>
                    <FiltersBar
                      selected={handleSelection}
                      handleGroupByWeek={handleGroupByWeek}
                      isStatus={isStatus}
                      groupByWeek={groupByWeek}
                      handleStatus={handleStatus}
                      statusList={statusList}
                      searchValueChange={handleSearchValue}
                      disableGrpByWeek={(val) => setGroupByWeek(val)}
                      searchLength={managerLength}
                    />
                    <ManagerTimesheet
                      selected={handleSelection}
                      selectionState={isStatus}
                      groupByWeek={groupByWeek}
                      searchData={searchData ? searchData : null}
                      approvedBulk={bulkApprove}
                      managerUserTotal={handleUserLength}
                    />
                  </>
                )}
              </TabPanel>
            ))}
          </TabView>
        </div>
        {seletedCount > 1 && <TimesheetFooter />}
        {/* <Tabs items={pageTabs2} onChange={} /> */}
        <ApprovalModal
          open={isApprove}
          close={handleApproveModal}
          selectedUsers={seletedCount}
          selectedUsersData={selectedData}
        />
      </div>
      {visibleReminder && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess
              ? sendReminder?.headerMsg
              : sendReminder?.error?.errorHeaderMsg
          }
          toastMsgPara={
            reminderSuccess
              ? sendReminder?.subHeadermsg
              : sendReminder?.error?.errorMessage
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      {visibleDownloadReminder && !loadingLandingDownloadExport && (
        <ToastMessage
          closeAction={handleClosePopup}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess
              ? "Export File Successfully"
              : "Export File Unsuccessfull"
          }
          toastMsgPara={
            reminderSuccess
              ? "File has been successfully downloaded"
              : "Unable to download the file, please try later"
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      {visibleEmailReminder && !loadingLandingDownloadExportPost && (
        <ToastMessage
          closeAction={handleClosePopup}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess ? "Email Sent Successfully" : "File not Delivered"
          }
          toastMsgPara={
            reminderSuccess
              ? "Email has been sent successfully"
              : "Unable to deliver the file, please try later"
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
    </Spin>
  );
};

export default Approvals;
