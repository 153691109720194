import React, { useEffect, useState } from "react";
import "./index.css";
import {
  ApplicantDetails,
  CertificateAndAchievementsCard,
  CheckboxComponent,
  FormBasicDetailComponent,
  ProjectDetaislProgressSteps,
  StickyFooter,
  TextFieldComponent,
} from "../../components/index";

import {
  APPLICATION_FORM,
  PROJECT_DETAILS_TITLE,
  BASIC_DETAILS,
  RESUME_SUPPORTED_FILE,
  THOUSAND_TWENTY_FOUR,
  FILE_TEN_MB,
  FILE_NOTSUPPORTED_ERROR_MESSAGE,
  RESUME_SIZE_ERROR_MESSAGE,
  START,
  MYWORK_URL,
  NOTIFICATION_POSITION,
  NOTIFICATION_DURATION,
  SUCCESS,
  FAILURE,
  WARNING,
  NOTIFICATION_DURATION_COUNT,
  CERTIFICATE_SUPPORTED_FILE,
  OPPORTUNITIES_IJP,
  APPLICATION_URL,
  OPPORTUNITIES,
  JOB_DETAILS,
  PREVIEW_AND_APPLY,
  FILE_THREE_MB,
  IJP_FILE_SIZE_ERROR_MESSAGE,
} from "../../../../constants";
import FileAttachmentIcon from "../../../../themes/assets/images/ijp/FileArrowUp.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import { useSelector } from "react-redux";
import { getApplicationFormDetails } from "../../../../redux/actions/opportunities";
import { notification, Spin } from "antd";
import {
  notificationAction,
  setApplicationDetails,
} from "../../../../redux/reducers/opportunities";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  GoalsErrorIcon,
  GoalsInfoIcon,
  ToastErrorIcon,
  ToastSuccessIcon,
} from "../../../common/svg/svg";
import BreadcrumbComponent from "../../../common/breadcrumb/breadcrumb";
import n from "../../../navigation/Route.Names";
import amplitude from "amplitude-js";

export const ApplicationForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const param = useParams();

  const [inputValue, setInputValue] = useState("");
  const [previewBtn, setPreviewBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageDescription, setErrorMessageDescription] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const { loading, applicationFormDetails, configuration } =
    useAppSelector((state: any) => state.opportunities) || {};
  const [api, contextHolder] = notification.useNotification({
    top: NOTIFICATION_POSITION,
  });

  useEffect(() => {
    if (!applicationFormDetails?.resumeBasicDetails && !loading)
      dispatch(getApplicationFormDetails({}))
        .unwrap()
        .then((s) => {
          mandatoryRuleCheck();
        });
    else mandatoryRuleCheck();
  }, [loading]);

  const onCloseHandler = () => {
    setErrorMessage("");
    setErrorMessageDescription("");
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
        additionalInfo: "",
      })
    );
  };

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: configuration.alertMessage.message,
        description: configuration.alertMessage.additionalInfo,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon:
          type === SUCCESS ? (
            <ToastSuccessIcon />
          ) : type === FAILURE ? (
            <GoalsErrorIcon />
          ) : (
            <GoalsInfoIcon />
          ),
        className:
          type === SUCCESS
            ? `custom-toast-area custom-success-toast`
            : type === FAILURE
            ? `custom-toast-area custom-error-toast`
            : `custom-toast-area custom-info-toast`,

        onClose: onCloseHandler,
      });
    } else if (errorMessage) {
      api.open({
        message: errorMessage,
        description: errorMessageDescription,
        duration: NOTIFICATION_DURATION_COUNT,
        className: "custom-toast-area custom-error-toast",
        icon: <ToastErrorIcon />,
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification, errorMessage]);

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${OPPORTUNITIES_IJP}`}
          className="link-highlight"
        >
          {OPPORTUNITIES}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/${OPPORTUNITIES_IJP}/detail`}
          className="link-highlight"
        >
          {JOB_DETAILS}
        </Link>
      ),
    },
    {
      title: `${APPLICATION_FORM}`,
    },
  ];

  const stepData = applicationFormDetails?.projectDetails?.map((item: any) => {
    const startDate = item?.startDate
      ? new Date(item?.startDate).toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
        })
      : "";
    const endDate = item?.endDate
      ? new Date(item?.endDate).toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
        })
      : "";
    return {
      title: (item?.projectName || "") + " | " + (item?.organizationName || ""),
      subTitle: item?.skillNames || "",
      description:
        (item?.projectDescription || "") + "\n" + startDate + " - " + endDate,
    };
  });

  const mandatoryRuleCheck = () => {
    if (!applicationFormDetails?.educationDetails) return;
    const data = applicationFormDetails?.educationDetails;
    const MandatoryFieldValues = data?.map((element, i) => {
      return element?.value || null;
    });

    MandatoryFieldValues
      ? setPreviewBtn(!MandatoryFieldValues?.includes(null))
      : setPreviewBtn(false);
  };

  const onChangeEducationDetails = (index: number, value: any) => {
    const updatedEducationDetails =
      applicationFormDetails?.educationDetails.map((detail, i) => {
        return i === index ? { ...detail, value } : detail;
      });
    const MandatoryFieldValues = updatedEducationDetails?.map((element, i) => {
      return element?.value || null;
    });

    setPreviewBtn(!MandatoryFieldValues?.includes(null));
    dispatch(
      setApplicationDetails({
        ...applicationFormDetails,
        educationDetails: updatedEducationDetails,
      })
    );
  };

  const beforeUploadHandler = (file: any) => {
    const isValidFormat = CERTIFICATE_SUPPORTED_FILE.indexOf(file.type) > -1;
    const isGt10MB =
      file.size / THOUSAND_TWENTY_FOUR / THOUSAND_TWENTY_FOUR > FILE_THREE_MB;
    setErrorMessage("");
    setErrorMessageDescription("");
    if (!isValidFormat) {
      setErrorMessage(FILE_NOTSUPPORTED_ERROR_MESSAGE);
      setErrorMessageDescription("Accepted file type is pdf");
      return false;
    } else if (isGt10MB) {
      setErrorMessage(IJP_FILE_SIZE_ERROR_MESSAGE);
      setErrorMessageDescription("Upload a file that is less than 3mb");
      return false;
    }
    return true;
  };

  const onCustomRequestHandler = async (options: any) => {
    const { file } = options;
    const attachmentDetail = [
      ...applicationFormDetails?.certificateAttachments,
      file,
    ];
    dispatch(
      setApplicationDetails({
        ...applicationFormDetails,
        certificateAttachments: attachmentDetail,
      })
    );
  };

  const onClickPreviewAndApply = () => {
    amplitude.getInstance().logEvent("Clicked Preview & Apply");
    navigate(
      `/web/my_work/opportunities/detail/application/preview/${param?.id}`
    );
  };

  const deleteAttachment = (index: number) => {
    let attachmentArr = [...applicationFormDetails?.certificateAttachments];
    attachmentArr.splice(index, 1);
    dispatch(
      setApplicationDetails({
        ...applicationFormDetails,
        certificateAttachments: attachmentArr,
      })
    );
  };
  const onChangeHilightAchievements = (value: any) => {
    let hilights = {
      ...applicationFormDetails?.achievementsDetails,
      highlightOfAchievements: value,
    };
    dispatch(
      setApplicationDetails({
        ...applicationFormDetails,
        achievementsDetails: hilights,
      })
    );
  };

  const onClickCheckbox = () => {
    dispatch(
      setApplicationDetails({
        ...applicationFormDetails,
        sendEmail: !checkbox,
      })
    );
    setCheckbox(!checkbox);
  };

  const onchangeAchievementInitiatives = (value: any) => {
    let hilights = {
      ...applicationFormDetails?.achievementsDetails,
      initiativesParticipated: value,
    };
    dispatch(
      setApplicationDetails({
        ...applicationFormDetails,
        achievementsDetails: hilights,
      })
    );
  };

  return (
    <>
      <Spin spinning={loading}>
        {contextHolder}

        <div className="application-page">
          <div className="opportunities-breadcrumb">
            <BreadcrumbComponent items={breadbrumbItems} />
          </div>
          <div className="application-title">{APPLICATION_FORM}</div>
          <ApplicantDetails
            // profileImg={profileImg}
            userName={applicationFormDetails?.profileDetails?.name}
            userEmailAdress={applicationFormDetails?.profileDetails?.email}
            designation={applicationFormDetails?.profileDetails?.role}
          />
          <FormBasicDetailComponent
            formDetails={applicationFormDetails?.resumeBasicDetails}
            title={BASIC_DETAILS}
            disableEdit={true}
          />
          <ProjectDetaislProgressSteps
            projectDetailsArr={stepData}
            title={PROJECT_DETAILS_TITLE}
          />

          <CertificateAndAchievementsCard
            beforeUploadHandler={(file) => beforeUploadHandler(file)}
            onCustomRequestHandler={(file) => onCustomRequestHandler(file)}
            fileDetails={applicationFormDetails?.certificateAttachments}
            onPressDelete={(index) => deleteAttachment(index)}
            onChangeHilightAchievements={(e) => {
              onChangeHilightAchievements(e.target.value);
            }}
            onchangeAchievementInitiatives={(e) => {
              onchangeAchievementInitiatives(e.target.value);
            }}
            inputValue={applicationFormDetails?.achievementsDetails}
          />

          {/* education details */}

          <div className="form-basic-details-card">
            <div className="title-basic-detail">{"Education Details"}</div>
            <div
              className="form-text-fields-card"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              {applicationFormDetails?.educationDetails?.map(
                (item: any, index: any) => {
                  return (
                    <TextFieldComponent
                      inputfielData={item}
                      disableEdit={false}
                      onChangeValue={(e) => {
                        onChangeEducationDetails(index, e.target.value);
                        setInputValue(e.target.value);
                      }}
                      inputValue={item?.value ?? inputValue}
                      name={
                        item?.title === "Highest Qualification"
                          ? "qualification"
                          : "yearPassed"
                      }
                    />
                  );
                }
              )}
            </div>
          </div>
          <CheckboxComponent
            checkbox={applicationFormDetails?.sendEmail || checkbox}
            onClickCheckbox={onClickCheckbox}
          />
        </div>
        <StickyFooter
          onClickApply={onClickPreviewAndApply}
          // onClickEdit={() => null}
          disabled={!previewBtn}
          editBtn={false}
          nextBtnText={PREVIEW_AND_APPLY}
        />
      </Spin>
    </>
  );
};
export default ApplicationForm;
