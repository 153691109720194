import React, { useState, useEffect } from "react";
import "./AdminPanel.css";
import { Table, Spin } from "antd";
import AdminProfileOne from "../../themes/assets/images/svg/admin-profile-one.svg";
import { useNavigate } from "react-router-dom";
import AvatarProfile from "../Avatar/AvatarProfile";
import n from "../navigation/Route.Names";
import emptyData from "../../themes/assets/images/justOut-noData.png";

interface DataType {
  key: string;
  name: string;
  id: number;
  email: string;
  account: string;
  project: string;
  code: string;
  region: string;
}

const AdminTimesheet = (props) => {
  const navigate = useNavigate();
  const { loadingAdmin, adminPanelData } = props;
  const adminProfileList = adminPanelData;
  console.log("check", adminProfileList);
  const otherPageColumns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span className="profile_sec">
          <AvatarProfile
            className="profile-admin-check"
            name={text}
            profilePic={record?.imgUrl}
          />
          <div className="profile_details">
            <div className="profile_text admin">{text}</div>
            <div className="profile_desc admin">{record?.designation}</div>{" "}
          </div>
        </span>
      ),
    },
    {
      title: "Employee Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      render: (text) => <div className="projectname_admin">{text}</div>,
    },
    {
      title: "Account Name",
      dataIndex: "account",
      key: "account",
      render: (text) => <div className="projectname_admin">{text}</div>,
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      render: (text) => <div className="projectname_admin">{text}</div>,
    },
    {
      title: "Project Job Code",
      dataIndex: "code",
      key: "code",
      render: (text) => <div className="projectname_admin">{text}</div>,
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      render: (text) => <div className="projectname_admin">{text}</div>,
    },
  ];

  const adminProfileListAPI = Array.isArray(adminProfileList?.data)
    ? adminProfileList?.data?.map((admin) => ({
        key: admin?.userId,
        name: admin?.displayName,
        id: admin?.userId,
        email: admin?.email,
        imgUrl: admin?.imgUrl,
        designation: admin?.designation,
        account: admin?.accountName,
        project: admin?.projectName,
        code: admin?.projectJobCode,
        region: admin?.region,
      }))
    : [];

  const handleRowClick = (record) => {
    navigate(`../${n.MYWORK_URL}/AdminPanel/EmployeeTimesheet/${record.id}`);
  };

  return (
    <div id="timesheet_panel" className="timesheet_panel timesheet_admin-panel">
      <Spin spinning={loadingAdmin}>
        <div id="all_manager_activities" className="all_manager_activities">
          <Table
            className="manager_activities_table"
            columns={otherPageColumns}
            dataSource={adminProfileListAPI}
            locale={{
              emptyText: (
                <div className="no-admin-total-container">
                  <img
                    className="no-data-admin-img"
                    src={
                      adminProfileList?.data
                        ? adminProfileList?.data?.emptyDataPage
                        : emptyData
                    }
                    alt=""
                  />
                  <div className="no-tsm-admin-title">
                    {adminProfileList?.data
                      ? adminProfileList?.data?.noDataHeaderMessage
                      : adminProfileList?.error?.errorHeaderMsg}
                  </div>
                  <div className="no-tsm-admin-content">
                    {adminProfileList?.data?.noDataMessage}
                  </div>
                </div>
              ),
            }}
            onRow={(record: DataType) => ({
              onClick: () => {
                handleRowClick(record);
              },
            })}
          />
        </div>
      </Spin>
    </div>
  );
};

export default AdminTimesheet;
