import * as React from "react";
export const RadioFillIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <rect width={20} height={20} x={0.09} y={0.951} fill="#0642B5" rx={10} />
    <circle cx={10.091} cy={10.95} r={3.333} fill="#fff" />
  </svg>
);
