import React, { useEffect, useState } from "react";
import n from "../../../navigation/Route.Names";
import "./ViewOverAllFormRating.css";
import BreadcrumbComponent from "../../../common/breadcrumb/breadcrumb";
import { notification, Spin, Steps } from "antd";
import Slider from "react-slick";
import SubmittedGoal from "../../components/SubmittedGoal";
import OverAllCommentCard from "../../components/OverAllCommentCard";
import CompetancyCard from "../../components/CompetancyCard";
import SignatureCard from "../../components/SignatureCard";
import { Link, useNavigate, useParams } from "react-router-dom";
// import "../aspform.css";
import "../../ASPForm/aspform.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  getAspFormSteps,
  getBehaviouralEmployeeDetails,
  getOverallFormRating,
  getReporteeSignatureAndOverAllForm,
  postAspFormeGoalSign,
  submitYearEndReview,
} from "../../../../redux/actions/performance";
import { title } from "process";
import {
  ASP_FORM,
  ASP_FORM_TEXT,
  CALIBRATION,
  CANCEL,
  COMPLETED,
  CONFIRMATION_TITLE_CANCEL,
  DONE,
  EMPLOYEE_SIGNATURE,
  FAILURE,
  IN_PROGRESS,
  MANAGER_REVIEW_BTN_NAME,
  MANAGER_SIGNATURE,
  MYWORK_URL,
  NO,
  NOTIFICATION_DURATION,
  OVERALLRATINGFORM_URL,
  PERFORMANCE_DISCUSSION,
  SIGN_AND_COMPLETE,
  SLIDER_SPLIT_COUNT,
  START,
  SUCCESS,
  TEAM_PERFORMANCE,
  VIEW_ACHIEVEMNTS_DES,
  WARNING,
  YEAR_END_EVALUATION,
  YES,
} from "../../../../constants";
import CommonTable from "../../../common/CommonTable";
import ModalDialog from "../../../common/modal/modal";
import ErrorComponent from "../../../errorComponent/ErrorComponent";
import {
  GoalsErrorIcon,
  GoalsInfoIcon,
  ToastSuccessIcon,
} from "../../../common/svg/svg";
import { notificationAction } from "../../../../redux/reducers/performance";
import OverAllCommentCardManager from "../../components/OverAllCommentCardManager";
import amplitude from "amplitude-js";
import AchievementsCard from "../../components/AchievementsCard";

const StickyFooter = ({
  handleClick,
  currentStep,
  handleOk,
  onClickCancleBtn,
  showButtons,
}) => {
  const navigate = useNavigate();

  if (!showButtons) {
    return null;
  }

  return (
    <div className="footer-sticky-asp-end">
      {currentStep === COMPLETED ||
      currentStep === EMPLOYEE_SIGNATURE ||
      currentStep === CALIBRATION ? (
        <div></div>
      ) : (
        <div className="footer-sticky-asp-submit">
          <div
            onClick={onClickCancleBtn}
            className="button-footer-sticky-cancel"
          >
            {CANCEL}
          </div>
          <div onClick={handleClick} className="button-footer-sticky-sign">
            {currentStep === PERFORMANCE_DISCUSSION
              ? MANAGER_REVIEW_BTN_NAME
              : SIGN_AND_COMPLETE}
          </div>
        </div>
      )}
    </div>
  );
};

const ViewOverAllFormRating = () => {
  const params = useParams();
  const { year = "" } = params;
  const navigate = useNavigate();
  const {
    getAspFormStepsList,
    overallFormRatingManagerLoading,
    viewGoalRawData,
    getAspFormStepsLoading,
    configuration,
    overallFormRatingManagerError,
    postAspFormEmployeeGoalSignLoading,
    isLoading,
  } = useAppSelector((state: any) => state.performance) || {};

  const dispatch = useAppDispatch();

  const [initialStepsValue, setInitialStepsValue] = useState<any>([]);
  const [waitingToast, setWaitingToast] = useState(false);
  const [settings, setSettings] = useState<any>({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  });
  const [sign, setSign] = useState(false);
  const [cancelModalOpen, setCancelModal] = useState(false);
  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });

  const progresStatus = initialStepsValue?.filter(
    (item: any) => item.subTitle === "In Progress"
  );

  const { error = false } = configuration;
  const currentStepValue = initialStepsValue
    ?.map((item: any) => item.current)
    .indexOf(true);

  const firstSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(0, SLIDER_SPLIT_COUNT)
      : [];
  const secondSet =
    initialStepsValue !== undefined
      ? initialStepsValue.slice(SLIDER_SPLIT_COUNT)
      : [];

  const handleClick = (currentStep) => {
    if (currentStep === PERFORMANCE_DISCUSSION) {
      amplitude
        .getInstance()
        .logEvent("Completed Performance Review Discussion");
      dispatch(
        submitYearEndReview({
          year: year,
          reporteeId: params?.reporteeId,
        })
      )
        ?.unwrap()
        ?.then(() => {
          setWaitingToast(true);
        });
    } else if (currentStep === MANAGER_SIGNATURE) {
      setSign(true);
      amplitude.getInstance().logEvent("Completed Manager Signature");
      dispatch(
        postAspFormeGoalSign({
          year: year,
          reporteeId: params?.reporteeId,
        })
      )
        ?.unwrap()
        ?.then(() => {
          setWaitingToast(true);
        });
    }
  };

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`${OVERALLRATINGFORM_URL}/${params?.reporteeId}`}
          className="link-highlight"
        >
          {ASP_FORM_TEXT}
        </Link>
      ),
    },
    {
      title: YEAR_END_EVALUATION,
    },
  ];

  useEffect(() => {
    dispatch(
      getAspFormSteps({
        year: year,
        reporteeId: params?.reporteeId,
      })
    );
  }, []);

  useEffect(() => {
    if (
      params?.currentStep === PERFORMANCE_DISCUSSION ||
      params?.currentStep === EMPLOYEE_SIGNATURE ||
      params?.currentStep === CALIBRATION
    ) {
      dispatch(
        getOverallFormRating({
          year: year,
          reporteeId: params?.reporteeId,
        })
      );
    } else {
      dispatch(
        getReporteeSignatureAndOverAllForm({
          year: year,
          reporteeId: params?.reporteeId,
        })
      );
    }
  }, []);

  useEffect(() => {
    const stepsList = getAspFormStepsList?.stepsList || [];
    setInitialStepsValue(getAspFormStepsList?.stepsList);
    const progresStatus = stepsList?.findIndex(
      (item: any) => item.subTitle === IN_PROGRESS
    );
    setSettings({
      ...settings,
      initialSlide:
        progresStatus > SLIDER_SPLIT_COUNT - 1 || progresStatus === -1 ? 1 : 0,

      // initialSlide: progresStatus > SLIDER_SPLIT_COUNT - 1 ? 1 : 0,
    });
  }, [getAspFormStepsList]);

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: "",
        description: configuration?.alertMessage?.message,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon:
          type === SUCCESS ? (
            <ToastSuccessIcon />
          ) : type === FAILURE ? (
            <GoalsErrorIcon />
          ) : (
            <GoalsInfoIcon />
          ),
        className: "custom-toast-area custom-success-toast",
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  if (overallFormRatingManagerLoading || getAspFormStepsLoading) {
    return (
      <div className="layoutContainer">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="spinner-asp">
          <Spin
            spinning={overallFormRatingManagerLoading || getAspFormStepsLoading}
          />
        </div>
      </div>
    );
  }
  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
        additionalInfo: "",
      })
    );
    navigate(
      `/${START}/${MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/${params?.reporteeId}`
    );
  };

  const handleCancel = () => {
    setCancelModal(false);
  };

  const handleOk = () => {
    navigate(
      `/${START}/${MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/${params?.reporteeId}`
    );
    setCancelModal(false);
  };

  const onClickCancleBtn = () => {
    setCancelModal(true);
  };

  if (error || overallFormRatingManagerError) {
    return (
      <div className="p2p-container">
        <BreadcrumbComponent items={breadbrumbItems} />
        <div className="loadingP2P">
          <ErrorComponent
            image={require("../../../../themes/assets/images/something-went-wrong.png")}
            title={"Something Went Wrong"}
            description={
              "We are looking to fix it. Please try again in few minutes."
            }
          />
        </div>
      </div>
    );
  }

  return (
    <Spin spinning={postAspFormEmployeeGoalSignLoading || isLoading}>
      <div className="view-form-asp-employee layoutContainer">
        {contextHolder}
        <BreadcrumbComponent items={breadbrumbItems} />

        <div className="title-view-asp">{`${year} Year End Evaluation `}</div>

        <Slider className="stepper-process" {...settings}>
          <div className="stepper-section">
            <Steps
              status="process"
              current={currentStepValue}
              items={firstSet}
              labelPlacement="vertical"
              className="steps"
            />
          </div>
          <div className="stepper-section">
            <Steps
              status="process"
              current={currentStepValue}
              items={secondSet}
              labelPlacement="vertical"
              className="steps"
            />
          </div>
        </Slider>

        <div className="section-container-asp-form">
          <div className="form-view-header">
            {viewGoalRawData?.goalDetails?.[0]?.pageTitle || ""}
          </div>
          {viewGoalRawData?.goalInfo?.business?.map((item: any, index: any) => {
            return (
              <SubmittedGoal
                profileData={viewGoalRawData?.userInfo}
                otherProfileData={viewGoalRawData?.reporteeInfo}
                data={item}
                key={index}
              />
            );
          })}
          {viewGoalRawData?.goalInfo?.technology?.map(
            (item: any, index: any) => {
              return (
                <SubmittedGoal
                  profileData={viewGoalRawData?.userInfo}
                  otherProfileData={viewGoalRawData?.reporteeInfo}
                  data={item}
                  key={index}
                />
              );
            }
          )}
          {viewGoalRawData?.goalInfo?.people?.map((item: any, index: any) => {
            return (
              <SubmittedGoal
                profileData={viewGoalRawData?.userInfo}
                otherProfileData={viewGoalRawData?.reporteeInfo}
                data={item}
                key={index}
              />
            );
          })}
          <OverAllCommentCardManager
            profileData={viewGoalRawData?.userInfo}
            otherProfileData={viewGoalRawData?.reporteeInfo}
            title={
              viewGoalRawData?.overAllSelfAssessment?.title ||
              viewGoalRawData?.overAllOtherAssessment?.title
            }
            primaryData={viewGoalRawData?.overAllSelfAssessment}
            secondaryData={viewGoalRawData?.overAllOtherAssessment}
          />
        </div>
        {params?.currentStep === COMPLETED && (
          <AchievementsCard description={VIEW_ACHIEVEMNTS_DES} />
        )}

        <div className="section-container-asp-form">
          <div className="form-view-header">
            {viewGoalRawData?.competencySectionTitle || ""}
          </div>
          {viewGoalRawData?.evaluations?.map((item: any, index: any) => {
            return (
              <CompetancyCard
                profileData={viewGoalRawData?.userInfo}
                otherProfileData={viewGoalRawData?.reporteeInfo}
                key={index}
                data={item}
              />
            );
          })}
        </div>
        <OverAllCommentCardManager
          profileData={viewGoalRawData?.userInfo}
          otherProfileData={viewGoalRawData?.reporteeInfo}
          primaryData={viewGoalRawData?.overallFormAssessment}
          title={viewGoalRawData?.title}
          description={viewGoalRawData?.description}
          hideManagerCard={true}
          showRating={true}
        />

        <div className="rating-calculated-container">
          {viewGoalRawData?.calculatedRateScale && (
            <div className="rating-calculated-title">
              <span className="calculated-title">
                {viewGoalRawData?.calculatedRateScale?.title}
              </span>
              <span className="calculated-rating-btn">
                {viewGoalRawData?.calculatedRateScale?.rating}
              </span>
            </div>
          )}
          <div>
            <CommonTable
              goalDetails={viewGoalRawData?.goalDetails}
              evaluations={viewGoalRawData?.evaluations}
            />
          </div>
        </div>

        {/* signature part */}
        {viewGoalRawData?.webSignatureSection && (
          <div>
            {viewGoalRawData?.webSignatureSection?.signatureInfos && (
              <SignatureCard
                // key={index}
                data={{
                  signstatus:
                    viewGoalRawData?.webSignatureSection.signatureInfos,
                  description:
                    viewGoalRawData?.webSignatureSection?.description,
                  title: viewGoalRawData?.webSignatureSection?.signatureTitle,
                  userSignatureStatus:
                    viewGoalRawData?.webSignatureSection?.signatureInfos[1] ||
                    {},
                  otherUserSignatureStatus:
                    viewGoalRawData?.webSignatureSection?.signatureInfos[0] ||
                    {},
                  userInfo:
                    viewGoalRawData?.webSignatureSection?.managerInfo || {},
                  otherUserInfo:
                    viewGoalRawData?.webSignatureSection?.employeeInfo || {},
                }}
              />
            )}
          </div>
        )}
      </div>
      <StickyFooter
        onClickCancleBtn={() => onClickCancleBtn()}
        handleClick={() => handleClick(params?.currentStep)}
        currentStep={params?.currentStep}
        handleOk={() => handleOk()}
        showButtons={!waitingToast}
      />
      <ModalDialog
        openModal={cancelModalOpen}
        DialogHeading={CONFIRMATION_TITLE_CANCEL}
        closeIcon={false}
        modalImageStyles={{ display: "none" }}
        modalHeadingStyles={{ display: "none" }}
        popupDivider={{ display: "none" }}
        indexScroll={{ display: "none" }}
        modalCenterImgStyles={{ display: "none" }}
        msgContent={`Cancelling ${year} Year End Evaluation `}
        okTxt={YES}
        cancelTxt={NO}
        cancelBtn={handleCancel}
        okBtn={handleOk}
        onOk={handleOk}
        onCancel={handleCancel}
        modalStyles={"backpopup delete-milestone-popup compotency-popup"}
        maskClosable={false}
      />
    </Spin>
  );
};

export default ViewOverAllFormRating;
