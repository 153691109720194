import * as React from "react";
export const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#474747"
      stroke="#474747"
      d="M15.485 9.008a6.385 6.385 0 0 1-6.047 6.375H9.11A6.39 6.39 0 0 1 2.727 9c0-3.523 2.866-6.375 6.383-6.375 3.515 0 6.375 2.86 6.375 6.383ZM9.11 2.875a6.137 6.137 0 0 0-6.133 6.133 6.137 6.137 0 0 0 6.133 6.134 6.13 6.13 0 0 0 6.125-6.134c0-3.384-2.748-6.133-6.125-6.133Z"
    />
    <path
      fill="#474747"
      d="M17.85 18.375a.618.618 0 0 1-.441-.183l-4.267-4.275a.629.629 0 0 1 0-.884.629.629 0 0 1 .883 0l4.267 4.275a.629.629 0 0 1 0 .884.618.618 0 0 1-.442.183Z"
    />
  </svg>
);
