import React from "react";

export const VerticalDotIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10.0004 17.1981C10.5394 17.1981 10.9764 16.7611 10.9764 16.2221C10.9764 15.6831 10.5394 15.2461 10.0004 15.2461C9.46138 15.2461 9.02441 15.6831 9.02441 16.2221C9.02441 16.7611 9.46138 17.1981 10.0004 17.1981Z" fill="#0642B5" />
            <path d="M10.0004 10.9754C10.5394 10.9754 10.9764 10.5385 10.9764 9.99944C10.9764 9.46041 10.5394 9.02344 10.0004 9.02344C9.46138 9.02344 9.02441 9.46041 9.02441 9.99944C9.02441 10.5385 9.46138 10.9754 10.0004 10.9754Z" fill="#0642B5" />
            <path d="M10.0004 4.74888C10.5394 4.74888 10.9764 4.3119 10.9764 3.77287C10.9764 3.23384 10.5394 2.79688 10.0004 2.79688C9.46138 2.79688 9.02441 3.23384 9.02441 3.77287C9.02441 4.3119 9.46138 4.74888 10.0004 4.74888Z" fill="#0642B5" />
        </svg>
    )
}