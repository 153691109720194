import Innovation_o from "../../../themes/assets/images/svg/Innovation_o.svg";
import DownArrowIcon from "../../../themes/assets/images/svg/Innovation/Down-arrow.svg";
import UpArrowIcon from "../../../themes/assets/images/svg/Innovation/Up-arrow.svg";
import {
  Avatar,
  Button,
  Carousel,
  Dropdown,
  Menu,
  MenuProps,
  Radio,
  RadioChangeEvent,
  Skeleton,
  Space,
} from "antd";
import InnovationCat from "../../../themes/assets/images/svg/innovation-cat.svg";
import InnovationCatTwo from "../../../themes/assets/images/svg/innovation-cat-two.svg";
import Frame from "../../common/svg/Frame";
import Quotes from "../../common/svg/Quotes";
import Reactions from "../../common/svg/Reactions";
import "./index.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import NoDataFoundImg from "../../../themes/assets/images/not-found.png";
import SomethingWentWrong from "../../../themes/assets/images/something-went-wrong.png";
import BlueQuotes from "../../common/svg/BlueQuotes";
import AvatarProfile from "../../Avatar/AvatarProfile";
import {
  ASP_VIEW_FORM,
  LANDING_COMMENT_TITLE,
  VIEW_MORE,
  INNOVATION,
  CREATE_PROGRAM,
  CREATE_PROGRAM_TITLE,
  INNOVATE_PROGRAM,
  MANAGE_PROGRAM,
  CREATE_INNOVATION,
  CREATE_INNOVATION_DESC2,
  CREATE_INNOVATION_DESC,
  BUSINESS_CHALLENGE_DETAIL,
  IDEA_LANDING,
  NO_IDEA,
  NO_BUSINESS_CHALLENGES,
  SOMETHING_WENT_WRONG,
} from "../../../constants";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { getMetrics, getPrograms } from "../../../redux/actions/innovation";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";

export const NoDataFoundInfo = ({
  imgClassName = "",
  imageUrl = "",
  altText = "",
  heading = "",
  headingClassName = "",
  subHeading = "",
  subHeadingClassName = "",
}): any => {
  return (
    <div className="nodata-found-section">
      <img
        src={imageUrl || ""}
        alt={altText || ""}
        className={`nodata-found-img-area ${imgClassName || ""}`}
      />
      <p
        className={`no-data-found-heading semibold-text lg-text black-text ${
          headingClassName || ""
        }`}
      >
        {heading}
      </p>
      <p
        className={`no-data-found-sub-heading normal-text sm-text gray600 ${
          subHeadingClassName || ""
        }`}
      >
        {subHeading}
      </p>
    </div>
  );
};

export const GroupAvatars = (props) => {
  const { data } = props;
  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);
    return color;
  }
  function getInitials(string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
  return (
    <Avatar.Group maxCount={2}>
      {data?.map((item) => (
        <Avatar
          style={{ backgroundColor: randomColor() }}
          src={item?.mediaResponse?.referenceUrl}
        >
          {getInitials(item?.user?.displayName)}
        </Avatar>
      ))}
    </Avatar.Group>
  );
};
export const NoDataFound = (props: any) => {
  const { message } = props;
  return (
    <div className="nodata-found-section">
      <img
        src={NoDataFoundImg}
        alt="No data found"
        className="nodata-found-img-area"
      />
      <div>{message}</div>
    </div>
  );
};
export const LPFamebook = (props: any) => {
  const { loading, data, fameBookFailureError } = props;
  return (
    <>
      {fameBookFailureError?.error && (
        <NoDataFoundInfo
          imageUrl={SomethingWentWrong}
          altText="Something went wrong"
          heading={SOMETHING_WENT_WRONG}
          subHeading={fameBookFailureError?.message?.error}
        />
      )}
      {!loading && !fameBookFailureError?.error && data?.length === 0 && (
        <NoDataFound />
      )}
      {data?.length > 0 &&
        data?.map((famebookItem: any, famebookindex: number) => {
          const { desgination, displayName, fameBookCategory, mediaResponse } =
            famebookItem || {};
          return (
            <div
              key={`${famebookindex}`}
              className="famebook-card"
              id="fame-book-card"
            >
              <Skeleton
                loading={loading}
                active
                className="famebook-card-skeleton"
              >
                <div className="image-section-famebook">
                  <div className="sm-text semibold-text dark-black-text center ">
                    {fameBookCategory}
                  </div>
                  <div className="frame">
                    <Frame />
                  </div>

                  <div className="circle">
                    {mediaResponse ? (
                      <img alt="" src={mediaResponse} className="profile-img" />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>

                <div
                  className="sm-text semibold-text dark-black-text center "
                  style={{ marginBottom: 5 }}
                >
                  {displayName || "-"}
                </div>
                <div className="xs-text normal-text gray600 center">
                  {desgination}
                </div>
              </Skeleton>
            </div>
          );
        })}
    </>
  );
};
export const EmployeeCommentsCard = (props) => {
  const { data, indexId } = props;
  const { summary, author, image } = data || {};
  const { displayName, mediaResponse, designation } = author || {};

  return (
    <>
      {!!data && (
        <div className="employee-cmnts-card" id="employee-cmnts-card">
          <div className="top-section">
            <div className="row">
              <AvatarProfile
                profilePic={mediaResponse}
                name={displayName}
                size={50}
              />
              <div className="text-section-empcard">
                {!!displayName && (
                  <div className="semibold-text lg-text dark-black-text">
                    {displayName}
                  </div>
                )}
                {!!designation && (
                  <div className="gray450 md-textnormal-text">
                    {designation}
                  </div>
                )}
                {!!summary && (
                  <div className="black-text-2 md-text normal-text desc">
                    {summary}
                  </div>
                )}
              </div>
            </div>
            <div className="quotes_img">{image}</div>
          </div>
        </div>
      )}
    </>
  );
};
export const LPMyActivitiesIdeas = (props: any) => {
  const { loading, data, ideasFailureError } = props;
  return (
    <>
      {ideasFailureError?.error && (
        <NoDataFoundInfo
          imageUrl={SomethingWentWrong}
          altText="Something went wrong"
          heading={SOMETHING_WENT_WRONG}
          subHeading={ideasFailureError?.message?.error}
        />
      )}
      {!loading && !ideasFailureError?.error && data?.length === 0 && (
        <NoDataFoundInfo
          imageUrl={NoDataFoundImg}
          altText="No idea"
          heading={NO_IDEA}
          subHeading={""}
        />
      )}
      {data?.slice(0, 3)?.map((ideaItem: any, ideaIndex: number) => {
        return <IdeasCard ideaItem={ideaItem} loading={loading} />;
      })}
    </>
  );
};
export const LPIdeaDetail = (props: any) => {
  const { data, loading, topIdeasFailureError } = props;

  const { ideas, viewAll } = data;

  return (
    <>
      {topIdeasFailureError?.error && (
        <NoDataFoundInfo
          imageUrl={SomethingWentWrong}
          altText="Something went wrong"
          heading={SOMETHING_WENT_WRONG}
          subHeading={topIdeasFailureError?.message?.error}
        />
      )}
      {!loading && !topIdeasFailureError?.error && data?.length === 0 && (
        <NoDataFound />
      )}
      {ideas?.length > 0 &&
        ideas?.slice(0, 3)?.map((winnerItem: any, winnerIndex: number) => {
          const {
            ideaIdentifier,
            programTitle,
            title,
            winnerIcon,
            businessProblem,
            teamMembers,
            likesCount,
            commentsCount,
          } = winnerItem || {};
          const { title: businessProblemTitle, description } =
            businessProblem || {};
          const { iconTxt, icon, iconBg } = winnerIcon || {};

          return (
            <div key={`${winnerIndex}`} className="ideas-card" id="ideas-card">
              <div className="space-between">
                <div className="sm-text normal-text gray650">
                  Idea ID: {ideaIdentifier}
                </div>
                {programTitle && <div className="blue-tag">{programTitle}</div>}
              </div>
              <div className="md-text semibold-text dark-black-text">
                {title}
              </div>

              <div className="space-between">
                <GroupAvatars data={teamMembers} />
                <div
                  className="winner-tag"
                  style={{
                    background: iconBg,
                    border: `0.5px solid ${iconBg}`,
                  }}
                >
                  {icon?.referenceUrl ? (
                    <img
                      alt=""
                      src={icon?.referenceUrl}
                      className="winner-img"
                    />
                  ) : (
                    <div></div>
                  )}
                  <div className="sm-text semibold-text black-text">
                    {iconTxt}
                  </div>
                </div>
              </div>
              <div className="divider" />
              <div className="xs-text normal-text dark-black-text">
                Business Challenge:
                <span className="semibold-text">
                  {businessProblemTitle ?? description}
                </span>
              </div>
              <div className="space-between">
                <div className="row-center">
                  <Reactions />
                  <div className="xs-text normal-text gray600">
                    {likesCount}
                  </div>
                </div>
                <div className="xs-text normal-text gray600">
                  {commentsCount} {LANDING_COMMENT_TITLE}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
export const CustomCarousel = (props: any) => {
  const [activeSlide, setActiveSlide] = useState<Number>();
  const {
    RenderComp,
    arrows,
    isBanner,
    data,
    loading,
    bannerFailureError,
    testimonialData,
    isTestimonial,
    testimonialLoading,
    testimonialsFailureError,
  } = props;

  const getCarouselData = () => {
    if (isBanner) {
      return {
        data,
        failureError: bannerFailureError,
      };
    }
    if (isTestimonial) {
      const imgsArr = [<Quotes />, <BlueQuotes />];
      const testimonialDataArray = testimonialData.contentList?.map(
        (item: any, index) => {
          let image = imgsArr[(index % 2) % imgsArr.length];
          return {
            ...item,
            image,
          };
        }
      );
      const pairs =
        testimonialDataArray?.reduce((acc, curr, index, arr) => {
          if (index % 2 === 0) {
            acc.push([curr, arr[index + 1]]);
          }

          return acc;
        }, []) || [];

      return {
        data: pairs,
        failureError: testimonialsFailureError,
      };
    } else return {};
  };

  return (
    <>
      {getCarouselData()?.failureError?.error && (
        <NoDataFoundInfo
          imageUrl={SomethingWentWrong}
          altText="Something went wrong"
          heading={SOMETHING_WENT_WRONG}
          subHeading={getCarouselData()?.failureError?.message?.error}
        />
      )}
      {isBanner && loading && (
        <Skeleton.Node
          style={{ width: "100%", height: 200, borderRadius: 8 }}
          active={true}
        />
      )}
      {!loading &&
        !getCarouselData()?.failureError?.error &&
        getCarouselData()?.data?.length === 0 && <div>no data found</div>}
      {getCarouselData()?.data?.length > 0 && (
        <div className="innovation-carousel-card" id="carousel-card">
          <Carousel
            autoplay
            arrows={arrows && getCarouselData()?.data.length > 1}
            className="innovation-carousel-container"
            afterChange={(current: number) => {
              setActiveSlide(current);
            }}
            autoplaySpeed={4000}
            dots={false}
            initialSlide={0}
          >
            {getCarouselData()?.data?.map((item: any, index: any) => {
              return (
                <div
                  key={`banner_carousel_${index}`}
                  className="innovation-carousel-container"
                >
                  {isBanner && <RenderComp data={item} />}
                  {isTestimonial && <RenderComp data={item} indexId={index} />}
                </div>
              );
            })}
          </Carousel>
          <div className="innovation-indicators-container">
            {getCarouselData()?.data?.map((item: any, index: any) => {
              return (
                <div
                  key={`banner_carousel_indicator${index}`}
                  className={`indicator ${
                    activeSlide === index
                      ? "indicator-active"
                      : "indicator-inactive"
                  } `}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
export const LPBanner = (props: any) => {
  const { data } = props;
  const {
    likesCount,
    commentsCount,
    title,
    description,
    workflowSteps,
    currentStatus,
  } = data || {};
  const grayscale = currentStatus === "closed";
  return (
    <div className="banner" id="lp-banner">
      <div
        className={`row-center  banner-image-section ${
          grayscale ? "grayscale" : ""
        }`}
      >
        <div className="rectangle xl-text bold-text black-text">
          <span className="center">
            Ideath
            <img alt="" src={Innovation_o} className="center" />n{" "}
          </span>

          <span>2024</span>
        </div>
      </div>
      <div className="banner-text-section">
        <div>
          <div className="space-between">
            <div className="bold-text xl-text black-text">{title}</div>
            <div
              className="sm-text semibold-text rounded-30"
              style={{
                backgroundColor: workflowSteps?.bgColor,
                color: workflowSteps?.textColor,
              }}
            >
              {workflowSteps?.displayName}
            </div>
          </div>
          <div className="normal-text black-text sm-text">{description}</div>
        </div>
        <div className="row-center space-between">
          <div className="row-center gap-50">
            <div className="row-center">
              <Reactions />
              <div className="xs-text normal-text gray600">{likesCount}</div>
            </div>
            <div
              className="xs-text normal-text gray600"
              style={{ marginLeft: 50 }}
            >
              {commentsCount} {LANDING_COMMENT_TITLE}
            </div>
          </div>
          <div
            id="view-form-btn"
            className="semibold-text sm-text cursor-pointer create-idea-btn"
          >
            {VIEW_MORE}
          </div>
        </div>
      </div>
    </div>
  );
};
export const LPMyActivitiesChallenges = (props: any) => {
  const { loading, data, challengesFailureError } = props;
  return (
    <>
      {challengesFailureError?.error && (
        <NoDataFoundInfo
          imageUrl={SomethingWentWrong}
          altText="Something went wrong"
          heading={SOMETHING_WENT_WRONG}
          subHeading={challengesFailureError?.message?.error}
        />
      )}
      {!loading && !challengesFailureError?.error && data?.length === 0 && (
        <NoDataFoundInfo
          imageUrl={NoDataFoundImg}
          altText="No challenges"
          heading={NO_BUSINESS_CHALLENGES}
          subHeading={""}
        />
      )}
      {data?.slice(0, 3)?.map((challengeItem: any, challengeIndex: number) => {
        return (
          <ChallengesCard challengeItem={challengeItem} loading={loading} />
        );
      })}
    </>
  );
};

export const OverView = (props: any) => {
  const { activities, programTitle } = props;
  const [selectedActivity, setSelectedActivity] = useState(activities[0] || "");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState({} as any);
  const [programsList, setProgramsList] = useState([] as any);
  const dispatch = useAppDispatch();
  const {
    metrics,
    metricsLoading,
    metricsFailure,
    programs,
    programsLoading,
    programsFailure,
  } = useAppSelector((state: any) => state?.innovation);

  useEffect(() => {
    dispatch(getPrograms({}));
  }, []);

  useEffect(() => {
    const newProgramData = [
      {
        id: "",
        title: "All Programs",
      },
      ...programs,
    ];
    setProgramsList(newProgramData);
  }, [programs]);

  useEffect(() => {
    dispatch(
      getMetrics({
        metricsType:
          selectedActivity === activities[1]
            ? "BUSINESS_PROBLEM"
            : "INNOVATION_IDEA",
        programId: selectedKey?.id,
      })
    );
  }, [activities, dispatch, selectedActivity, selectedKey]);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const selectedLabel = programsList?.filter(({ id }) => id === e.key)?.[0];
    setSelectedKey(selectedLabel);
    setOpen(false);
  };

  const Card = ({ item }: any) => {
    const { metricsName, metricsData, workflowSteps } = item;
    return (
      <div
        className="overview-card-wrapper"
        style={{
          backgroundColor: workflowSteps?.bgColor,
        }}
      >
        <div
          className="xl-text semibold-text count"
          style={{
            color: workflowSteps?.textColor,
          }}
        >
          {metricsData}
        </div>
        <div
          className="lg-text normal-text status"
          style={{
            color: workflowSteps?.textColor,
          }}
        >
          {metricsName}
        </div>
      </div>
    );
  };

  const metricStatusCard = () => {
    if (metricsLoading) {
      return (
        <div className="row-center">
          {Array(5)
            .fill("")
            .map(() => {
              return (
                <Skeleton.Avatar
                  shape="square"
                  size={80}
                  active={true}
                  style={{ borderRadius: 8, marginRight: 10, width: 200 }}
                />
              );
            })}
        </div>
      );
    } else if (metricsFailure?.error) {
      return (
        <NoDataFoundInfo
          imageUrl={SomethingWentWrong}
          altText="Something went wrong"
          heading={SOMETHING_WENT_WRONG}
          subHeading={metricsFailure?.message?.error}
        />
      );
    } else {
      return (
        <div className="overview-list-wrapper">
          {metrics?.metricsList?.map((i) => (
            <Card item={i} />
          ))}
        </div>
      );
    }
  };

  const menuProps: MenuProps = {
    items: programsList?.map(({ title, id }) => ({ key: id, label: title })),
    onClick: handleMenuClick,
  };
  return (
    <div className="overview-wrapper">
      <div className="overview-inner-wrapper">
        <div className="overview-inner-top-section">
          <div className="overview-tab-wrapper tab-row row-center">
            {activities.map((item, index) => (
              <div
                className={`sm-text cursor-pointer ${
                  selectedActivity === item
                    ? "active-tab blue-text semibold-text"
                    : "inactive-tab dark-black-text normal-text"
                }`}
                id={`${item}-tab`}
                onClick={() => setSelectedActivity(item)}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="dropdown-wrapper">
            <Dropdown menu={menuProps} trigger={["click"]}>
              <Button className="dropdown-btn-reuse semibold-text sm-text dark-black-text">
                <Space>
                  {selectedKey?.title || programTitle}
                  {open ? (
                    <img src={UpArrowIcon} className="drop-down-arrow" />
                  ) : (
                    <img src={DownArrowIcon} className="drop-down-arrow" />
                  )}
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>

        {metricStatusCard()}
      </div>
    </div>
  );
};

export const InnovationView = (props: any) => {
  const Card = (props: any) => {
    const { title, desc, img, primaryBtn } = props?.data;
    const navigate = useNavigate();
    return (
      <div className="innovation-card-wrapper">
        <img src={img} className="foxIcon" />
        <div className="details-wrapper">
          <div className="md-text semibold-text .dark-black-text">{title}</div>
          <div className="sm-text normal-text desc">{desc}</div>
          <div className="btn-wrapper">
            <div
              className="semibold-text sm-text cursor-pointer primary-btn"
              onClick={() => navigate(primaryBtn?.navigation)}
            >
              {primaryBtn?.title}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="innovation-view-wrapper">
      <div className="innovation-list-wrapper">
        {[
          {
            title: CREATE_INNOVATION,
            desc: CREATE_INNOVATION_DESC,
            img: InnovationCat,
            primaryBtn: {
              title: CREATE_PROGRAM_TITLE,
              // navigation: `${CREATE_PROGRAM}`,
            },
          },
          {
            title: INNOVATE_PROGRAM,
            desc: CREATE_INNOVATION_DESC2,
            img: InnovationCatTwo,
            primaryBtn: {
              title: MANAGE_PROGRAM,
              //  navigation: `${CREATE_PROGRAM}`,
            },
          },
        ].map((i: any) => {
          return <Card data={i} />;
        })}
      </div>
    </div>
  );
};

export const IdeasCard = (props: any) => {
  const { ideaItem, loading } = props;
  const {
    title,
    programTitle,
    teamMembers,
    workflowSteps,
    commentsCount,
    likesCount,
    updatedAt,
    creationDate,
  } = ideaItem || {};
  const submittedDate = moment(creationDate).format("DD MMM YYYY") || "";

  return (
    <div className="ideas-card" id="ideas-card">
      <Skeleton loading={loading}>
        <div className="space-between">
          <div className="sm-text normal-text gray650">Idea</div>
          {programTitle && <div className="blue-tag">{programTitle}</div>}
        </div>
        <div className="md-text semibold-text dark-black-text">{title}</div>
        <div className="sm-text normal-text gray650">
          Submitted on {submittedDate || "-"}
        </div>
        <div className="space-between">
          <div
            className="sm-text semibold-text status-tag"
            style={{
              backgroundColor: workflowSteps?.bgColor,
              color: workflowSteps?.textColor,
            }}
          >
            {workflowSteps?.displayName}
          </div>
          <GroupAvatars data={teamMembers} />
        </div>
        <div className="space-between">
          <div className="row-center">
            <Reactions />
            <div className="xs-text normal-text gray600">{likesCount}</div>
          </div>
          <div className="xs-text normal-text gray600">
            {commentsCount} {LANDING_COMMENT_TITLE}
          </div>
        </div>
      </Skeleton>
    </div>
  );
};
export const ChallengesCard = (props: any) => {
  const { challengeItem, loading } = props;
  const {
    workflowSteps,
    commentsCount,
    likesCount,
    businessIdentifier,
    title,
    date,
    createdByName,
    responseId,
  } = challengeItem || {};
  const submittedDate = moment(date).format("DD MMM YYYY");
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(`${BUSINESS_CHALLENGE_DETAIL}/${responseId}`);
      }}
      className="ideas-card"
      id="ideas-card"
    >
      <Skeleton className="ideas-card-skeleton" loading={loading} active>
        <div className="space-between">
          {businessIdentifier && (
            <div className="sm-text normal-text gray650">
              Challenge ID {businessIdentifier}
            </div>
          )}
        </div>
        <div className="md-text semibold-text dark-black-text">
          {title || "-"}
        </div>
        <div
          className="sm-text normal-text gray650"
          style={{ overflow: "hidden" }}
        >
          Submitted on {submittedDate || "-"} by {createdByName}
        </div>
        <div className="space-between">
          <div
            className="sm-text semibold-text status-tag"
            style={{
              backgroundColor: workflowSteps?.bgColor,
              color: workflowSteps?.textColor,
            }}
          >
            {workflowSteps?.displayName}
          </div>
        </div>
        <div className="space-between">
          <div className="row-center">
            <Reactions />
            <div className="xs-text normal-text gray600">{likesCount}</div>
          </div>
          <div className="xs-text normal-text gray600">
            {commentsCount} {LANDING_COMMENT_TITLE}
          </div>
        </div>
      </Skeleton>
    </div>
  );
};
interface DropDownWithButtonProps {
  items: { key: string; label: string }[];
  name: string;
}
export const DropDownWithButton: React.FC<DropDownWithButtonProps> = ({
  items,
  name,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState<string>("");
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    setSelectedKey(e.key);
    setOpen(false);
  };

  const menuProps: MenuProps = {
    items: items.map((item) => ({ key: item.label, label: item.label })),
    onClick: handleMenuClick,
  };
  return (
    <Dropdown
      className="dropdown-btn-wrapper"
      menu={menuProps}
      onOpenChange={setOpen}
      open={open}
    >
      <Button className="dropdown-btn-reuse semibold-text sm-text dark-black-text">
        <Space>
          {selectedKey || name}
          {open ? (
            <img src={UpArrowIcon} className="drop-down-arrow" />
          ) : (
            <img src={DownArrowIcon} className="drop-down-arrow" />
          )}
        </Space>
      </Button>
    </Dropdown>
  );
};

export const RadioTabs = () => {
  const [size, setSize] = useState<string>("Explore Teams");

  const onChange = (e: RadioChangeEvent) => {
    setSize(e.target.value);
  };
  return (
    <Radio.Group value={size} onChange={onChange} className="radio-tabs-main">
      <Radio.Button
        className="radio-tabs-explore semibold-text sm-text"
        value="Explore Teams"
      >
        <p>Explore Teams</p>
      </Radio.Button>
      <Radio.Button
        className="radio-tabs-request semibold-text sm-text"
        value="Requests"
      >
        <p>My Requests</p>
      </Radio.Button>
    </Radio.Group>
  );
};
