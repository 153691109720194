import React, { useEffect, useState } from "react";
import "./DropDown.css";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, message, Select, Space, Tooltip } from "antd";
import { useParams } from "react-router-dom";
const DropDown = (props) => {
  const {
    list = [],
    weekHandler,
    getCurrentDropDownObj,
    weekStartDate,
  } = props;
  const [first] = list;
  const param = useParams();
  const { week } = param || {};
  // const [defaultSelection, setDefaultSelection] = useState(
  //   `${first.weekStartDate}-${first.weekEndDate}`
  // );
  const weekFilter = list.filter(
    (item) =>
      item?.weekStartDate === week || item?.weekStartDate === weekStartDate
  );
  const displayName =
    weekFilter.length > 0
      ? weekFilter[0].displayValue || list[0]?.weekDisplayName
      : "";
  const displayDate =
    (weekFilter.length > 0 ? weekFilter[0].weekStartDate : "") || "";
  const [defaultSelection, setDefaultSelection] = useState(
    displayDate || `${first?.weekStartDate}`
  );
  const [defaultLabelSelection, setDefaultLabelSelection] = useState(
    displayName ||
    `${first?.displayValue}` ||
    list[0]?.displayName ||
    weekStartDate
  );

  useEffect(() => {
    if (
      (defaultLabelSelection == "undefined" && ((
        list?.length &&
        first?.weekDisplayName) ||
        props?.isClone))
    ) {
      setDefaultLabelSelection(first?.weekDisplayName);
      getCurrentDropDownObj(first);
    }
  }, [defaultLabelSelection, list]);

  // const items: MenuProps["items"] = list?.map((item: any) => ({
  //   key: `${item.weekStartDate}-${item.weekEndDate}`,
  //   label: item.displayValue,
  // }));
  const items: MenuProps["items"] = list?.map((item: any) => ({
    key: `${item.weekStartDate}`,
    label: item.displayValue || item.weekDisplayName,
  }));
  const handleDropDownClick: MenuProps["onClick"] = (e) => {
    const dateSelection = e?.key;
    const weekFilter = list.filter(
      (item) => item.weekStartDate === dateSelection
    );
    const displayName =
      weekFilter.length > 0
        ? weekFilter[0].displayValue || weekFilter[0].weekDisplayName
        : "";
    setDefaultSelection(dateSelection);
    setDefaultLabelSelection(displayName);
    weekHandler(dateSelection);
    const currentObj = list.find((item) => item.weekStartDate === e.key);
    if (getCurrentDropDownObj) {
      props?.getCurrentDropDownObj(currentObj);
    }
  };
  const menuProps = {
    items,
    selectable: true,
    defaultSelectedKeys: [defaultSelection],
    onClick: handleDropDownClick,
  };
  return (
    <div className="dropdown-container">
      <Dropdown data-testid="dropdown" menu={menuProps}>
        <Button data-testid="button">
          <Space>
            {defaultLabelSelection}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default DropDown;