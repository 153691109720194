import React, { useEffect, useState } from "react";
import AvatarProfile from "../../Avatar/AvatarProfile";
import { Modal, Popover, Space, Steps } from "antd";
import { useNavigate } from "react-router-dom";
import {
  BASIC_DETAILS,
  CERTIFICATE_AND_ACHIEVEMENTS,
  CERTIFICATE_SUPPORTED_FORMATS,
  CERTIFICATE_TOOLTIP_MSG,
  CLICK_HERE_TO_ADD,
  CLICK_HERE_TXT,
  EDIT_BTN_NAME,
  HILIGHTS_OF_ACHIEVEMENTS,
  INITIATIVES_DEC,
  N0_ATTACHMENTS,
  OPPORTUNITIES_CERTIFICATE_WARNING_TEXT,
  PREVIEW_AND_APPLY,
  RESUME_FILE_SUPPORTED_FORMATS,
  UPLOAD_ATTACHMENT,
} from "../../../constants";
import pdf from "../../../themes/assets/images/pdf_image.png";
import close from "../../../themes/assets/images/close.png";
import FileAttachmentIcon from "../../../themes/assets/images/ijp/FileArrowUp.png";
import { Upload } from "antd";
import InfoIcon from "../../../themes/assets/images/info_grey.png";
import checkbox_img from "../../../themes/assets/images/checbox-img.png";
import { fileSize } from "../../utils/util";

export const ApplicantDetails = (props) => {
  const { profileImg = "", designation, userName, userEmailAdress } = props;
  return (
    <div className="profile-section">
      <AvatarProfile name={userName} size={58} profilePic={profileImg} />
      <div className="form-user-details">
        <div className="user-name">{userName}</div>
        <div className="user-designation">{designation}</div>
        <div className="user-designation">{userEmailAdress}</div>
      </div>
    </div>
  );
};

export const TextFieldComponent = (props) => {
  const {
    inputfielData,
    onChangeValue,
    valueEntered,
    disableEdit,
    inputValue,
    onChange,
    name,
  } = props;
  return (
    <div className="form-text-field ">
      <div className="text-field-title-section">
        <div className="text-field-title ">{inputfielData?.title}</div>
        {inputfielData?.isMandatory && <div style={{ color: "red" }}>*</div>}
      </div>
      <input
        type="text"
        name={name}
        // value={inputfielData?.value ?? valueEntered}
        placeholder="Enter Here"
        className={
          !disableEdit
            ? "inputField inputFieldEditable text-field-title"
            : "inputField text-field-title"
        }
        disabled={disableEdit}
        value={inputValue}
        onChange={onChangeValue}
        multiple={false}
      />
    </div>
  );
};

export const FormBasicDetailComponent = (props) => {
  const { formDetails, title, disableEdit = true, onChangeValue } = props;
  const [inputValue, setInputValue] = useState<any>({
    qualification: "",
    yearPassed: "",
  });
  const TextFieldCard = (props) => {
    const { inputfielData } = props;
    return (
      <div className="form-text-field ">
        <div className="text-field-title">{inputfielData?.title}</div>
        <input
          type="text"
          value={inputfielData?.value}
          className="inputField text-field-title"
          disabled={true}
        />
      </div>
    );
  };
  const handleChange = (index, value) => {
    // console.log(inputValue);
  };
  return (
    <div className="form-basic-details-card">
      <div className="title-basic-detail">{title}</div>
      <div
        className="form-text-fields-card"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        {formDetails?.map((item: any, index: any) => {
          return (
            <TextFieldComponent
              inputfielData={item}
              disableEdit={disableEdit}
              onChangeValue={handleChange}
              inputValue={
                (item?.value ? item?.value : "") ??
                (inputValue ? inputValue : "")
              }
              name={
                item?.title === "Highest Qualification"
                  ? "qualification"
                  : "yearPassed"
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export const ProjectDetaislProgressSteps = (props) => {
  const { projectDetailsArr = [], title } = props;
  return (
    <div className="project-detsils">
      <div className="project-detail-title">{title}</div>

      <Steps
        progressDot
        current={projectDetailsArr?.length}
        direction="vertical"
        items={projectDetailsArr}
        className="projectDetailSteps"
      />
    </div>
  );
};

export const StickyFooter = ({
  onClickApply,
  onClickEdit = () => {},
  disabled,
  editBtn = false,
  nextBtnText,
}) => {
  const navigate = useNavigate();
  return (
    <div className="footer-sticky-application-form">
      <div className="footer-sticky-buttons-container">
        {editBtn && (
          <button onClick={onClickEdit} className="footer-sticky-edit-btn">
            {EDIT_BTN_NAME}
          </button>
        )}
        <button
          onClick={onClickApply}
          className={
            disabled
              ? "footer-sticky-apply-btn btn-disablled"
              : "footer-sticky-apply-btn"
          }
          disabled={disabled}
        >
          {nextBtnText}
        </button>
      </div>
    </div>
  );
};

export const AchivementDataCard = ({ data }) => {
  if (!data?.value) return <></>;
  return (
    <div className="achivement-data">
      <div className="achivement-title">{data?.title}</div>
      <div className="achivement-desc">{data?.value}</div>
    </div>
  );
};

export const PDFCard = ({ pdfName, pdfSize, onClickClose }) => {
  return (
    <div className="pdf-card-container">
      <div className="pdf-data">
        <img src={pdf} alt="" className="pdf-image" />
        <div className="pdf-name-details">
          <div className="pdf-card-name">{pdfName}</div>
          <div className="pdf-card-size">{pdfSize}</div>
        </div>
      </div>
      {onClickClose && (
        <img
          onClick={onClickClose}
          src={close}
          alt=""
          className="close-btn-pdf"
        />
      )}
    </div>
  );
};

export const CertificateAndAchievementsCard = (props) => {
  const {
    beforeUploadHandler,
    onCustomRequestHandler,
    fileDetails = [],
    onPressDelete,
    onChangeHilightAchievements,
    inputValue = "",
    onchangeAchievementInitiatives,
  } = props;
  const { Dragger } = Upload;

  const content = (
    <div className="popover-certificate">{CERTIFICATE_TOOLTIP_MSG}</div>
  );
  return (
    <div className="form-basic-details-card">
      <>
        <Space wrap className="title-basic-detail">
          {CERTIFICATE_AND_ACHIEVEMENTS}
          <Popover content={content} trigger="hover">
            <img src={InfoIcon} className="" />
          </Popover>
        </Space>
        <div className="attachment-dotted-border">
          <Dragger
            listType="picture-card"
            beforeUpload={(file) => beforeUploadHandler(file)}
            multiple={true}
            accept={CERTIFICATE_SUPPORTED_FORMATS}
            showUploadList={false}
            customRequest={(file) => onCustomRequestHandler(file)}
          >
            <img className="attach-file-img" src={FileAttachmentIcon}></img>
            <div className={"no_attachment_uploaded"}>
              {fileDetails?.length > 0 ? UPLOAD_ATTACHMENT : N0_ATTACHMENTS}
            </div>
            <div className="click-here-to-add">
              <div className="click_to_add_file">{CLICK_HERE_TO_ADD}</div>
              <span className="click_to_add_file">{CLICK_HERE_TXT}</span>
            </div>
          </Dragger>
        </div>
        <div className="file-type-warning">
          {OPPORTUNITIES_CERTIFICATE_WARNING_TEXT}
        </div>
        {fileDetails && (
          <div className="certificate-containet">
            {fileDetails?.map((item, index) => {
              return (
                <PDFCard
                  pdfName={item?.name}
                  pdfSize={fileSize({ size: item?.size })}
                  onClickClose={() => onPressDelete(index)}
                />
              );
            })}
          </div>
        )}
      </>
      <>
        <div className="click_to_add_file achievements-highlights">
          {HILIGHTS_OF_ACHIEVEMENTS}
        </div>
        <input
          placeholder="Enter Here"
          className="highlights-input"
          inputMode="text"
          value={inputValue?.highlightOfAchievements}
          onChange={onChangeHilightAchievements}
        />
      </>

      <>
        <div className="click_to_add_file achievements-highlights">
          {INITIATIVES_DEC}
        </div>
        <input
          placeholder="Enter Here"
          className="highlights-input"
          inputMode="text"
          value={inputValue?.initiativesParticipated}
          onChange={onchangeAchievementInitiatives}
        />
      </>
    </div>
  );
};

export const CheckboxComponent = ({ onClickCheckbox, checkbox }) => {
  return (
    <div className="checkbox-email">
      <div
        onClick={onClickCheckbox}
        className={!checkbox ? "check-box" : "check-box checkbox-active"}
      >
        {checkbox && <img src={checkbox_img} alt="" className="checkboximg" />}
      </div>

      <div className="checkbox-text">Send me a copy of my updates</div>
    </div>
  );
};

export const PreviewModal = (props) => {
  const {
    isOpen,
    title,
    subtitle,
    cancelBtnText,
    cancelBtnOnClick,
    nextBtnText,
    nextBtnOnClick,
    img,
    customClass,
  } = props;
  return (
    <Modal
      width={374}
      open={isOpen}
      title={null}
      closeIcon={null}
      footer={null}
      centered
    >
      <div className="preview-modal">
        {img && <img src={img} alt="" className="preview-modal-img" />}
        <div className={`preview-modal-text ${customClass ? customClass : ""}`}>
          {title}
        </div>
        <div className="preview-modal-desc">{subtitle}</div>
        <div className="preview-modal-btns">
          {props?.cancelBtnText && (
            <div
              onClick={cancelBtnOnClick}
              className="preview-btn preview-btn-cancel"
            >
              {cancelBtnText}
            </div>
          )}
          <div
            onClick={nextBtnOnClick}
            className="preview-btn  preview-btn-next"
          >
            {nextBtnText}
          </div>
        </div>
      </div>
    </Modal>
  );
};
