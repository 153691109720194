import { Form, Select } from "antd";

const FormSelect = (props: any) => {
  const {
    selectFormLabel,
    selectFormName,
    selectFormDefaultValue,
    selectFormOptions,
    onChange,
    formItemClassName,
    suffixIcon,
    selectFormPlaceholder,
    selectFormRules,
  } = props || {};

  return (
    <Form.Item
      label={selectFormLabel}
      name={selectFormName}
      rules={selectFormRules}
      className={formItemClassName}
    >
      <Select
        defaultValue={selectFormDefaultValue}
        options={selectFormOptions}
        suffixIcon={suffixIcon}
        placeholder={selectFormPlaceholder}
        onChange={onChange}
      />
    </Form.Item>

  );
};

export default FormSelect;
