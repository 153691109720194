import React from "react";
import { Input } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { COMMENT_PLACEHOLDER } from "../../../constants";
import sendIcon from "../../../themes/assets/images/svg/send.svg";
import graySend from "../../../themes/assets/images/svg/graySend.svg";
import message from "../../../themes/assets/images/svg/message-square.svg";
import { getLoggedInUserData } from "../../utils/util";
import AvatarProfile from "../../Avatar/AvatarProfile";
import "./Comment.css";

const { TextArea } = Input;

const Comment = (props: any) => {
  // const { TextArea } = Input;

  const userData = getLoggedInUserData();
  // const {card_detail} = cardDetails
  const { data, value } = props;

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    props.change(e);
  };

  const onSend = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.send();
  };
  const onComment = () => {
    props.comment();
  };

  return (
    <>
      <li className="float-left comment-list">
        <a
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onComment();
          }}
          className="comment"
        >
          <img src={message} className="message-icon" alt="comment" />
          Comment
        </a>
      </li>
      <li className="comment-list-area">
        {(data?.isComment || props?.showComment) && (
          <div className="comment-section">
            <div className="avatar-mainComment">
              <AvatarProfile
                name={userData?.displayName}
                size={40}
                profilePic={""}
              />
            </div>
            <div className="text_area-otr">
              <TextArea
                className="textBox"
                placeholder={COMMENT_PLACEHOLDER}
                value={value}
                name="comment-box"
                rows={2}
                cols={100}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                autoSize
                onChange={onChange}
              ></TextArea>
              <img
                onClick={onSend}
                className="sendCommentMainComment"
                src={value?.length > 0 ? sendIcon : graySend}
              />
            </div>
          </div>
        )}
      </li>
    </>
  );
};

export default Comment;
