import React from "react";

function Frame() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="114"
      height="94"
      fill="none"
      viewBox="0 0 114 94"
    >
      <path
        fill="#FFCC46"
        d="M37.776 0s-2.79 1.785-3.746 4.79c-.889 2.797.125 5.663.27 6.046l.017.044.008-.005C34.95 7.222 36.61 3.707 37.824.126A4.034 4.034 0 0037.777 0z"
      ></path>
      <path
        fill="#FFDD85"
        d="M38.123 1.108a9.892 9.892 0 00-.29-.989C36.62 3.7 34.96 7.214 34.334 10.868c.165-.108 2.813-1.875 3.738-4.785.6-1.889.333-3.81.051-4.975z"
      ></path>
      <path
        fill="#FFCC46"
        d="M34.288 10.901s1.69 2.863 4.643 3.914c2.674.952 5.469.092 5.973-.079-3.715-.413-7.366-1.817-10.434-3.896-.117.037-.182.061-.182.061z"
      ></path>
      <path
        fill="#FFDD85"
        d="M40.341 10.787c-2.532-.901-5.17-.18-5.871.045 3.068 2.08 6.719 3.484 10.434 3.897l.08-.029s-1.69-2.862-4.643-3.913z"
      ></path>
      <path
        fill="#FFCC46"
        d="M25.811 10.234c-.955 3.004.287 6.09.287 6.09l.09-.06c.138-3.795 1.662-7.377 3.264-10.748-.516.358-2.795 2.06-3.64 4.718z"
      ></path>
      <path
        fill="#FFDD85"
        d="M29.451 5.515c-1.602 3.371-3.125 6.954-3.264 10.748.483-.333 2.802-2.043 3.656-4.73.956-3.004-.286-6.09-.286-6.09s-.039.025-.106.072z"
      ></path>
      <path
        fill="#FFCC46"
        d="M26.052 16.337s1.689 2.862 4.642 3.913c2.283.812 4.651.305 5.61.033-3.625-.638-7.18-1.78-10.096-4l-.156.053z"
      ></path>
      <path
        fill="#FFDD85"
        d="M32.107 16.23c-2.563-.912-5.235-.16-5.897.054 2.915 2.22 6.47 3.362 10.096 4 .282-.08.443-.14.443-.14s-1.689-2.863-4.642-3.914z"
      ></path>
      <path
        fill="#FFCC46"
        d="M20.63 13.154c-.107-.225-.179-.351-.179-.351s-2.528 2.145-3.076 5.251c-.548 3.106 1.092 5.997 1.092 5.997s.082-.07.22-.201c-.253-3.588.886-7.241 1.943-10.696z"
      ></path>
      <path
        fill="#FFDD85"
        d="M21.541 18.8c.434-2.461-.505-4.786-.914-5.646-1.056 3.455-2.195 7.108-1.943 10.696.625-.6 2.408-2.5 2.857-5.05z"
      ></path>
      <path
        fill="#FFCC46"
        d="M18.497 24.033l-.061.032s2.053 2.609 5.12 3.253c2.567.54 5.028-.47 5.763-.812-3.728-.392-7.514-.939-10.822-2.473z"
      ></path>
      <path
        fill="#FFDD85"
        d="M29.544 26.405s-2.054-2.61-5.12-3.253c-2.81-.59-5.494.674-5.924.89 3.308 1.534 7.094 2.08 10.822 2.472.143-.067.222-.11.222-.11z"
      ></path>
      <path
        fill="#FFCC46"
        d="M9.515 28.616c.695 2.872 3.073 4.791 3.369 5.022-1.771-3.273-2.523-7.144-2.746-10.872-.307.724-1.25 3.26-.623 5.85z"
      ></path>
      <path
        fill="#FFDD85"
        d="M13.627 27.602c-.74-3.065-3.399-5.045-3.399-5.045s-.035.072-.091.204c.222 3.729.974 7.6 2.745 10.872l.03.024s1.457-2.99.715-6.055zm4.369 2.818c-2.818.603-4.772 2.863-5.068 3.222 3.772-.005 7.33-1.15 10.855-2.42-.71-.331-3.198-1.355-5.787-.802z"
      ></path>
      <path
        fill="#FFCC46"
        d="M12.927 33.647l-.04.05s2.923 1.555 5.988.9c3.064-.655 5.108-3.272 5.108-3.272s-.07-.038-.2-.098c-3.524 1.27-7.084 2.415-10.856 2.42z"
      ></path>
      <path
        fill="#FFDD85"
        d="M8.152 39.581c-1.586-2.551-4.518-3.61-4.783-3.702.714 3.793 3.34 6.81 6.014 9.539.062-.646.233-3.483-1.23-5.837z"
      ></path>
      <path
        fill="#FFCC46"
        d="M3.373 35.878l-.022-.007s-.446 3.3 1.216 5.973c1.663 2.674 4.805 3.71 4.805 3.71s.007-.049.015-.137c-2.674-2.728-5.3-5.745-6.014-9.539z"
      ></path>
      <path
        fill="#FFDD85"
        d="M19.044 40.083a5.49 5.49 0 00.107-.265s-3.262-.548-5.965 1.046-3.825 4.726-3.825 4.726.163.027.446.052c3.414-1.366 6.882-2.77 9.237-5.559z"
      ></path>
      <path
        fill="#FFCC46"
        d="M9.804 45.645c.982.086 3.419.14 5.519-1.098 2.229-1.315 3.382-3.673 3.718-4.461-2.355 2.79-5.822 4.193-9.237 5.559z"
      ></path>
      <path
        fill="#FFDD85"
        d="M9.03 57.718c-.247-.817-1.138-3.292-3.214-4.845C3.3 50.993 0 51.179 0 51.179s.015.06.045.166c2.248 2.947 5.61 4.928 8.986 6.373z"
      ></path>
      <path
        fill="#FFCC46"
        d="M.049 51.35c.196.681 1.07 3.323 3.246 4.951 2.515 1.881 5.816 1.694 5.816 1.694s-.024-.1-.077-.272C5.66 56.277 2.297 54.297.05 51.35z"
      ></path>
      <path
        fill="#FFDD85"
        d="M16.14 49.21v-.136s-3.236.686-5.178 3.161c-1.758 2.242-1.852 5.184-1.855 5.719 2.686-2.65 5.178-5.479 7.033-8.745z"
      ></path>
      <path
        fill="#FFCC46"
        d="M9.107 57.954l.001.085s3.235-.685 5.177-3.16c1.711-2.182 1.847-5.025 1.855-5.67-1.855 3.266-4.347 6.095-7.033 8.745z"
      ></path>
      <path
        fill="#FFDD85"
        d="M7.557 64.595c-2.475-1.25-5.276-.802-5.893-.681 2.915 2.426 6.395 3.721 9.934 4.976-.324-.66-1.636-3.08-4.04-4.295z"
      ></path>
      <path
        fill="#FFCC46"
        d="M1.656 63.912l-.126.026s1.321 3.053 4.121 4.467c2.8 1.414 6.02.655 6.02.655s-.028-.063-.081-.172c-3.539-1.255-7.019-2.55-9.934-4.976z"
      ></path>
      <path
        fill="#FFDD85"
        d="M16.995 59.084c-.475.208-3.13 1.461-4.482 3.984-1.374 2.567-.916 5.547-.839 5.984 3.318-2.068 4.92-6.029 5.321-9.968z"
      ></path>
      <path
        fill="#FFCC46"
        d="M17.07 59.05l-.074.032c-.4 3.94-2.003 7.9-5.321 9.968l.01.056s3.069-1.239 4.556-4.015c1.487-2.777.829-6.04.829-6.04zM6.213 76.657l-.071.031s1.9 2.725 4.926 3.545c3.024.821 6.027-.572 6.027-.572s-.043-.061-.124-.166c-3.737.134-7.724-.705-10.758-2.838z"
      ></path>
      <path
        fill="#FFDD85"
        d="M16.972 79.492c-.465-.602-2.226-2.68-4.802-3.38-2.753-.747-5.487.34-5.956.542 3.033 2.133 7.02 2.971 10.758 2.838z"
      ></path>
      <path
        fill="#FFCC46"
        d="M20.3 68.717l-.027.018c.203 3.858-.668 7.922-3.265 10.88l.016.04s2.76-1.833 3.664-4.853c.905-3.021-.389-6.085-.389-6.085z"
      ></path>
      <path
        fill="#FFDD85"
        d="M20.27 68.734c-.279.193-2.784 1.985-3.637 4.835-.846 2.823.229 5.683.373 6.046 2.598-2.958 3.468-7.023 3.265-10.88z"
      ></path>
      <path
        fill="#FFCC46"
        d="M12.658 87.316c.561.464 2.752 2.128 5.444 2.235 3.13.123 5.75-1.907 5.75-1.907s-.08-.072-.226-.19c-3.6.948-7.4.806-10.968-.138z"
      ></path>
      <path
        fill="#FFDD85"
        d="M23.624 87.448c-.674-.541-2.788-2.063-5.359-2.165-3.13-.124-5.75 1.906-5.75 1.906s.05.046.14.121c3.57.944 7.368 1.086 10.969.138zm.946-11.075c-.421.479-2.224 2.676-2.414 5.454-.166 2.42.94 4.574 1.461 5.44 1.559-3.366 1.565-7.263.953-10.894z"
      ></path>
      <path
        fill="#FFCC46"
        d="M24.661 76.277s-.033.035-.089.099c.612 3.63.606 7.528-.953 10.894.156.26.26.405.26.405s2.287-2.405 2.503-5.552c.216-3.147-1.72-5.846-1.72-5.846z"
      ></path>
      <path
        fill="#FFA42F"
        d="M32.196 94.003a.185.185 0 01-.094-.026 46.987 46.987 0 01-11.32-9.261 47.69 47.69 0 01-7.915-12.169c-4.172-9.236-5.283-19.383-3.213-29.345 1.973-9.492 6.694-18.025 13.651-24.676C30.103 12.027 38.56 7.745 47.758 6.14a.187.187 0 01.218.155.19.19 0 01-.153.218c-19.01 3.314-33.848 17.745-37.8 36.765-4.14 19.917 4.81 40.16 22.268 50.37a.188.188 0 01.087.214.19.19 0 01-.182.14z"
      ></path>
      <path
        fill="#FFDD85"
        d="M55.99 3.622s-.348-.15-.923-.317C53.94 2.98 51.94 2.6 49.95 3.19c-2.512.743-4.208 2.797-4.698 3.452-.067.09-.111.153-.132.183 3.317-1.79 7.101-2.61 10.854-3.18l.014-.022z"
      ></path>
      <path
        fill="#FFCC46"
        d="M45.12 6.82l-.014.022s3.033 1.324 6.038.433C54 6.43 55.801 3.89 55.974 3.64c-3.753.569-7.537 1.39-10.854 3.18zM76.212 0s2.791 1.785 3.746 4.79c.89 2.797-.124 5.663-.269 6.046l-.017.044-.008-.005c-.626-3.653-2.286-7.168-3.499-10.749L76.212 0z"
      ></path>
      <path
        fill="#FFDD85"
        d="M75.876 1.108c.113-.468.228-.815.29-.989 1.213 3.58 2.873 7.095 3.499 10.749-.165-.108-2.813-1.875-3.738-4.785-.601-1.889-.333-3.81-.052-4.975z"
      ></path>
      <path
        fill="#FFCC46"
        d="M79.713 10.901s-1.689 2.863-4.642 3.914c-2.675.952-5.47.092-5.973-.079 3.714-.413 7.365-1.817 10.433-3.896.117.037.182.061.182.061z"
      ></path>
      <path
        fill="#FFDD85"
        d="M73.652 10.787c2.533-.901 5.17-.18 5.872.045-3.069 2.08-6.72 3.484-10.434 3.897l-.08-.029s1.689-2.862 4.642-3.913z"
      ></path>
      <path
        fill="#FFCC46"
        d="M88.192 10.234c.955 3.004-.286 6.09-.286 6.09l-.09-.06c-.139-3.795-1.662-7.377-3.264-10.748.515.358 2.795 2.06 3.64 4.718z"
      ></path>
      <path
        fill="#FFDD85"
        d="M84.553 5.515c1.603 3.371 3.126 6.954 3.264 10.748-.483-.333-2.802-2.043-3.656-4.73-.955-3.004.286-6.09.286-6.09s.04.025.106.072z"
      ></path>
      <path
        fill="#FFCC46"
        d="M87.94 16.337s-1.688 2.862-4.642 3.913c-2.282.812-4.65.305-5.61.033 3.626-.638 7.181-1.78 10.097-4l.156.053z"
      ></path>
      <path
        fill="#FFDD85"
        d="M81.887 16.23c2.563-.912 5.234-.16 5.897.054-2.916 2.22-6.47 3.362-10.097 4-.281-.08-.443-.14-.443-.14s1.69-2.863 4.643-3.914z"
      ></path>
      <path
        fill="#FFCC46"
        d="M93.366 13.154c.107-.225.178-.351.178-.351s2.53 2.145 3.077 5.251c.548 3.106-1.092 5.997-1.092 5.997s-.083-.07-.22-.201c.253-3.588-.886-7.241-1.943-10.696z"
      ></path>
      <path
        fill="#FFDD85"
        d="M92.447 18.8c-.434-2.461.505-4.786.914-5.646 1.057 3.455 2.196 7.108 1.944 10.696-.626-.6-2.408-2.5-2.858-5.05z"
      ></path>
      <path
        fill="#FFCC46"
        d="M95.502 24.033l.061.032s-2.054 2.609-5.12 3.253c-2.567.54-5.029-.47-5.763-.812 3.728-.392 7.514-.939 10.822-2.473z"
      ></path>
      <path
        fill="#FFDD85"
        d="M84.457 26.405s2.054-2.61 5.12-3.253c2.81-.59 5.494.674 5.924.89-3.308 1.534-7.094 2.08-10.822 2.472a5.113 5.113 0 01-.222-.11z"
      ></path>
      <path
        fill="#FFCC46"
        d="M104.479 28.616c-.695 2.872-3.072 4.791-3.369 5.022 1.772-3.273 2.523-7.144 2.746-10.872.307.724 1.25 3.26.623 5.85z"
      ></path>
      <path
        fill="#FFDD85"
        d="M100.366 27.602c.742-3.065 3.4-5.045 3.4-5.045s.035.072.091.204c-.223 3.729-.974 7.6-2.746 10.872a.81.81 0 01-.03.024s-1.456-2.99-.715-6.055zm-4.368 2.818c2.818.603 4.772 2.863 5.068 3.222-3.772-.005-7.331-1.15-10.855-2.42.71-.331 3.198-1.355 5.787-.802z"
      ></path>
      <path
        fill="#FFCC46"
        d="M101.07 33.647l.04.05s-2.923 1.555-5.988.9c-3.064-.655-5.108-3.272-5.108-3.272s.07-.038.2-.098c3.525 1.27 7.084 2.415 10.856 2.42z"
      ></path>
      <path
        fill="#FFDD85"
        d="M105.835 39.581c1.586-2.551 4.518-3.61 4.783-3.702-.714 3.793-3.34 6.81-6.014 9.539-.062-.646-.232-3.483 1.231-5.837z"
      ></path>
      <path
        fill="#FFCC46"
        d="M110.627 35.878l.022-.007s.446 3.3-1.216 5.973c-1.663 2.674-4.805 3.71-4.805 3.71l-.015-.137c2.674-2.728 5.3-5.745 6.014-9.539z"
      ></path>
      <path
        fill="#FFDD85"
        d="M94.956 40.083c-.072-.167-.107-.265-.107-.265s3.26-.548 5.964 1.046c2.703 1.594 3.825 4.726 3.825 4.726s-.163.027-.445.052c-3.415-1.366-6.882-2.77-9.237-5.559z"
      ></path>
      <path
        fill="#FFCC46"
        d="M104.188 45.645c-.982.086-3.418.14-5.519-1.098-2.229-1.315-3.382-3.673-3.718-4.461 2.355 2.79 5.823 4.193 9.237 5.559z"
      ></path>
      <path
        fill="#FFDD85"
        d="M104.97 57.718c.247-.817 1.138-3.292 3.214-4.845 2.515-1.88 5.816-1.694 5.816-1.694s-.014.06-.045.166c-2.248 2.947-5.61 4.928-8.985 6.373z"
      ></path>
      <path
        fill="#FFCC46"
        d="M113.953 51.35c-.196.681-1.07 3.323-3.246 4.951-2.515 1.881-5.816 1.694-5.816 1.694s.024-.1.076-.272c3.376-1.446 6.737-3.426 8.986-6.373z"
      ></path>
      <path
        fill="#FFDD85"
        d="M97.853 49.21v-.136s3.236.686 5.177 3.161c1.759 2.242 1.853 5.184 1.855 5.719-2.685-2.65-5.178-5.479-7.032-8.745z"
      ></path>
      <path
        fill="#FFCC46"
        d="M104.885 57.954l-.001.085s-3.235-.685-5.177-3.16c-1.711-2.182-1.846-5.025-1.854-5.67 1.854 3.266 4.346 6.095 7.032 8.745z"
      ></path>
      <path
        fill="#FFDD85"
        d="M106.443 64.595c2.475-1.25 5.276-.802 5.893-.681-2.915 2.426-6.395 3.721-9.934 4.976.324-.66 1.637-3.08 4.041-4.295z"
      ></path>
      <path
        fill="#FFCC46"
        d="M112.337 63.912l.126.026s-1.321 3.053-4.122 4.467c-2.8 1.414-6.019.655-6.019.655s.028-.063.081-.172c3.539-1.255 7.018-2.55 9.934-4.976z"
      ></path>
      <path
        fill="#FFDD85"
        d="M96.997 59.084c.475.208 3.131 1.461 4.482 3.984 1.374 2.567.916 5.547.839 5.984-3.318-2.068-4.92-6.029-5.32-9.968z"
      ></path>
      <path
        fill="#FFCC46"
        d="M96.92 59.05l.073.032c.4 3.94 2.003 7.9 5.321 9.968l-.01.056s-3.069-1.239-4.556-4.015c-1.487-2.777-.829-6.04-.829-6.04zm10.856 17.606l.071.031s-1.901 2.725-4.925 3.545c-3.025.821-6.028-.572-6.028-.572s.043-.061.124-.166c3.737.134 7.725-.705 10.758-2.838z"
      ></path>
      <path
        fill="#FFDD85"
        d="M97.028 79.492c.466-.602 2.226-2.68 4.802-3.38 2.754-.747 5.488.34 5.957.542-3.034 2.133-7.021 2.971-10.759 2.838z"
      ></path>
      <path
        fill="#FFCC46"
        d="M93.697 68.717l.026.018c-.204 3.858.668 7.922 3.265 10.88l-.016.04s-2.76-1.833-3.664-4.853c-.905-3.021.388-6.085.388-6.085z"
      ></path>
      <path
        fill="#FFDD85"
        d="M93.727 68.734c.28.193 2.784 1.985 3.638 4.835.845 2.823-.23 5.683-.373 6.046-2.598-2.958-3.469-7.023-3.265-10.88z"
      ></path>
      <path
        fill="#FFCC46"
        d="M101.344 87.316c-.561.464-2.752 2.128-5.444 2.235-3.13.123-5.75-1.907-5.75-1.907s.08-.072.226-.19c3.6.948 7.4.806 10.968-.138z"
      ></path>
      <path
        fill="#FFDD85"
        d="M90.37 87.448c.675-.541 2.788-2.063 5.36-2.165 3.13-.124 5.75 1.906 5.75 1.906s-.05.046-.141.121c-3.569.944-7.368 1.086-10.968.138zm-.946-11.075c.422.479 2.224 2.676 2.415 5.454.166 2.42-.941 4.574-1.462 5.44-1.558-3.366-1.564-7.263-.953-10.894z"
      ></path>
      <path
        fill="#FFCC46"
        d="M89.335 76.277l.088.099c-.611 3.63-.605 7.528.954 10.894-.156.26-.26.405-.26.405s-2.288-2.405-2.504-5.552c-.215-3.147 1.722-5.846 1.722-5.846z"
      ></path>
      <path
        fill="#FFA42F"
        d="M81.804 94.003a.186.186 0 00.094-.026 46.99 46.99 0 0011.319-9.261 47.692 47.692 0 007.916-12.169c4.172-9.236 5.283-19.383 3.213-29.345-1.973-9.492-6.694-18.025-13.651-24.676C83.897 12.027 75.44 7.745 66.24 6.14a.186.186 0 00-.19.086.19.19 0 00.126.287c19.01 3.314 33.848 17.745 37.801 36.765 4.139 19.917-4.81 40.16-22.269 50.37a.188.188 0 00-.087.214.19.19 0 00.182.14z"
      ></path>
      <path
        fill="#FFDD85"
        d="M58.008 3.622s.347-.15.922-.317c1.126-.325 3.127-.706 5.117-.116 2.511.743 4.207 2.797 4.697 3.452.068.09.112.153.132.183-3.317-1.79-7.1-2.61-10.853-3.18l-.015-.022z"
      ></path>
      <path
        fill="#FFCC46"
        d="M68.879 6.82l.015.022s-3.034 1.324-6.04.434C60 6.43 58.199 3.891 58.026 3.641c3.753.569 7.537 1.39 10.854 3.18z"
      ></path>
    </svg>
  );
}

export default Frame;
