import React from "react";
import { EditIcon } from "../svg/svg";
import { Button, Card, Col, Row } from "antd";

export function InnovationOtherDataCardPreview(props: any) {
  const {
    innovationRowChallengeIdTitle,
    innovationRowChallengeIdData,
    innovationPreviewPageStatusStyle,
    innovationPreviewPageStatusDetails,
  } = props;
  return (
    <>
      <Row className="dex-row-detail-section">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h4 className="dex-row-detail-title">
            {innovationRowChallengeIdTitle}
          </h4>
          <p className="dex-row-detail-para">{innovationRowChallengeIdData}</p>
        </Col>
      </Row>
      <div
        style={innovationPreviewPageStatusStyle}
        className={"dex-preview-page-status"}
      >
        {innovationPreviewPageStatusDetails}
      </div>
    </>
  );
}

export function InnovationDataCardPreview(props: any) {
  const {
    innovationPreviewChallengeDetails,
    innovationRowChallengeIdTitle,
    innovationRowChallengeIdData,
    innovationRowNatureTitle,
    innovationRowNatureData,
    innovationPreviewPageStatusStyle,
    innovationPreviewPageStatusDetails,
  } = props;
  return (
    <>
      {innovationPreviewChallengeDetails && (
        <Row className="dex-row-detail-section">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h4 className="dex-row-detail-title">{innovationRowNatureTitle}</h4>
            <p className="dex-row-detail-para">{innovationRowNatureData}</p>
          </Col>
        </Row>
      )}
      <div
        style={innovationPreviewPageStatusStyle}
        className={"dex-preview-page-status"}
      >
        {innovationPreviewPageStatusDetails}
      </div>
    </>
  );
}

export function InnovationDataTitle(props: any) {
  const { innovationPreviewPageTitle, editHandler, editIcon } = props;
  return (
    <>
      <div className="dex-heading-edit-section">
        <h1 className="dex-title create-business-challenge-title">
          {innovationPreviewPageTitle}
        </h1>
        {editIcon && (
          <Button onClick={editHandler}>
            <EditIcon />
          </Button>
        )}
      </div>
    </>
  );
}
