import React from "react";
import "./GoalSelection.css";
import { CHOOSE_GOAL, CHOOSE_GOAL_DESC } from "../../../constants";
import { Modal } from "antd";
import PersonalGoal from "../../../themes/assets/images/performance/personal-goal.png";
import ArchivedGoal from "../../../themes/assets/images/performance/archived-goal.png";
import GoalLibrary from "../../../themes/assets/images/performance/goal-library.png";
import CloseBtn from "../../../themes/assets/images/performance/Close.png";
import { useNavigate } from "react-router-dom";
import n from "../../navigation/Route.Names";
import { useAppDispatch } from "../../../redux/store/redux-hooks";
import CopyGoal from "../../../themes/assets/images/performance/copy-goal.png";

const GoalSelectionCard = (props) => {
  return (
    <div
      onClick={props?.onClick}
      className={
        props?.isDisable
          ? "goalSelectionCard disable-card"
          : "goalSelectionCard"
      }
    >
      <img className="goal-selection-img" src={props?.img} />
      <div className="goal-selection-card-title">{props?.title}</div>
      <div className="goal-selection-card-desc">{props?.desc}</div>
    </div>
  );
};

const GoalSelection = (props) => {
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  // const onClickPersonaGoal = (type) => {
  //   // dispatch(resetAllGoalMilestones());
  //   // dispatch(setGoalValues({}));
  //   navigate(`/${n.PERFORMANCE_URL}/${n.NEW_GOAL_URL}`);
  // };

  // const onClickArchivedGoal = (type) => {
  //   navigate(`/${n.PERFORMANCE_URL}/${n.ARCHIVED_GOAL_URL}`);
  // };
  // const onClickGoalLibrary = (type) => {
  //   //TODO: Add Goal Library Navigation
  // };

  const onClickGoal = (type) => {
    props.onClickGoal(type);
  };
  return (
    <>
      <Modal
        open={props?.onClickOpen}
        style={{ top: "20vh" }}
        closeIcon={false}
        footer={false}
        onCancel={props?.onClickCancel}
        maskClosable={true}
        className="goal-selection-container"
      >
        <img
          onClick={props?.onClickCancel}
          src={CloseBtn}
          alt=""
          className="closeBtn"
        />
        <div className="header-goal-selection">{CHOOSE_GOAL}</div>
        <div className="sub-header-goal-selection">{CHOOSE_GOAL_DESC}</div>
        <div className="goal-selection-card-container">
          <GoalSelectionCard
            onClick={() => onClickGoal("personal")}
            img={PersonalGoal}
            title={"Personal Goal"}
            desc={
              "Personal Goal allows you to make up your own goal and assign any metrics you want"
            }
          />
          <GoalSelectionCard
            onClick={() => onClickGoal("archived")}
            img={ArchivedGoal}
            title={"Archived Goal"}
            desc={
              "Archived goals are the goals that have been saved by you for later use"
            }
          />
          <GoalSelectionCard
            onClick={() => onClickGoal("copyGoal")}
            img={CopyGoal}
            title={"Move Goal"}
            desc={
              "You can bring your goals from last year into the current year and make use of it. "
            }
          />
          {/* <GoalSelectionCard
            onClick={() => onClickGoal("goal")}
            img={GoalLibrary}
            title={"Goal Library"}
            desc={
              "Library Goal are selected from an organized library with suggested metrics"
            }
            isDisable={true}
          /> */}
          {/* Will be required in the next release */}
        </div>
      </Modal>
    </>
  );
};

export default GoalSelection;
