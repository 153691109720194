import { useState, useEffect } from "react";
import "./index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import n from "../../navigation/Route.Names";
// import EmptyGoal from "../../../../themes/assets/images/svg/empty-goals.svg";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  Button,
  Dropdown,
  Space,
  Select,
  Table,
  Modal,
  Checkbox,
  notification,
  Spin,
  MenuProps,
} from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
// import MenuIcon from "../../../../themes/assets/images/svg/menu-icon.svg";
import { isChecked } from "../../utils/util";
import {
  GOAL_ACTIVITY_SUB_TITLE,
  GOAL_MAIN_PAGE_SUBTITLE1,
  GOAL_MAIN_PAGE_SUBTITLE2,
  WARNING,
  NOTIFICATION_DURATION,
  TEAM_PERFORMANCE,
  TEAMS_GOAL,
  CASCADE_SEARCH_TXT,
  GOAL_MAIN_PAGE_MANDATORY_GOAL,
  COPY_GOALS,
  PERFORMANCE_BREADCRUMB,
  PERFORMANCE,
  SELECT_GOALS,
  CANCEL,
  COPY,
  FAILURE,
  TOTAL_WEIGHT,
  GOAL_EMPTY,
} from "../../../constants";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import FillButton from "../../common/button/FillButton";
import {
  cascadeToEmployeeList,
  copyGoalsToCurrentYear,
  getDropDownYears,
  getEmployeePreviousGoals,
  postCasecadeGoalCommentManager,
} from "../../../redux/actions/performance";
import { SearchOutlined } from "@ant-design/icons";
import { postCascadeToEmployeeList } from "../../../redux/actions/performance";
import {
  notificationAction,
  previewAction,
  setActiveTeamGoalYear,
} from "../../../redux/reducers/performance";
import {
  CloseIconManagerCascadeView,
  ToastSuccessIcon,
} from "../../common/svg/svg";
import ExpandMore from "../../../themes/assets/images/svg/Expand_more.svg";
import AvatarProfile from "../../Avatar/AvatarProfile";
import amplitude from "amplitude-js";
import EmptyGoal from "../../../themes/assets/images/svg/empty-goals.svg";

interface DataType {
  key: number;
  name: any;
  goalName: any;
  goalDescription: string;
  startDate: string;
  endDate: string;
  outcome: string;
  weight: string;
  status: any;
}

const CopyGoals = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const { configuration, isLoading, isLoadingCopyGoal, totalCopyGoalsWeight } =
    useAppSelector((state: any) => state.performance) || {};

  const goalsList =
    useAppSelector((state: any) => state?.performance?.previousGoals) || [];

  const param = useParams();
  const managerView = param?.page === TEAM_PERFORMANCE;

  const [api, contextHolder] = notification?.useNotification({
    top: 0,
    maxCount: 1,
  });

  const renderStatusButton = (goalStatus, record) => {
    const { goalStatusColor, goalTextColor } = record || {};
    return (
      <Button
        style={{ backgroundColor: goalStatusColor, color: goalTextColor }}
      >
        {goalStatus}
      </Button>
    );
  };

  const [businessselectedRowKeys, setBusinessSelectedRowKeys] = useState<
    React.Key[]
  >([]);
  const [technologyselectedRowKeys, setTechnolgySelectedRowKeys] = useState<
    React.Key[]
  >([]);
  const [peopleselectedRowKeys, setPeopleSelectedRowKeys] = useState<
    React.Key[]
  >([]);

  const { dropDownYears, teamGoalActiveYear: year } = useAppSelector(
    (state: any) => state?.performance
  );

  const onPeopleSelectChange = (newSelectedRowKeys: React.Key[]) => {
    amplitude.getInstance().logEvent("Selected Goal to Copy");
    setPeopleSelectedRowKeys(newSelectedRowKeys);
  };

  const onBusinessSelectChange = (newSelectedRowKeys: React.Key[]) => {
    amplitude.getInstance().logEvent("Selected Goal to Copy");
    setBusinessSelectedRowKeys(newSelectedRowKeys);
  };

  const onTechnolgySelectChange = (newSelectedRowKeys: React.Key[]) => {
    amplitude.getInstance().logEvent("Selected Goal to Copy");
    setTechnolgySelectedRowKeys(newSelectedRowKeys);
  };

  const businessRowSelection = {
    ...businessselectedRowKeys,
    onChange: onBusinessSelectChange,
  };

  const peopleRowSelection = {
    ...peopleselectedRowKeys,
    onChange: onPeopleSelectChange,
  };

  const technologyRowSelection = {
    ...technologyselectedRowKeys,
    onChange: onTechnolgySelectChange,
  };

  const finalSelectedRowKeys = [
    ...businessselectedRowKeys,
    ...technologyselectedRowKeys,
    ...peopleselectedRowKeys,
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Goal Name & Description",
      key: "goalName",
      render: (record) => (
        <div key={record?.sfGoalId}>
          <div className="inner-table-milestone">
            <span className="title-mile">{record.goalName}</span>
            <span className="cont-mile">{record.goalDescription}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
    },
    {
      title: "Due Date",
      dataIndex: "endDate",
    },
    {
      title: "Outcomes",
      dataIndex: "outcome",
    },
    {
      title: "Weightage",
      dataIndex: "weight",
    },
    {
      title: "Status",
      dataIndex: "goalStatus",
      render: (record, item) => renderStatusButton(record, item),
    },
  ];

  const tableProps: TableProps<DataType> = {
    bordered: false,
    showHeader: true,
    pagination: false,
  };

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MY_SPACE}/${PERFORMANCE}`}
          className="link-highlight"
        >
          {PERFORMANCE_BREADCRUMB}
        </Link>
      ),
    },
    {
      title: COPY_GOALS,
    },
  ];

  useEffect(() => {
    dispatch(getEmployeePreviousGoals({ year: params?.year }));
  }, []);

  const cancelCascadeHandler = () => {
    // setBusinessSelectedRowKeys([]);
    // setTechnolgySelectedRowKeys([]);
    // setPeopleSelectedRowKeys([]);
    navigate(-1);
  };
  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
      })
    );
    setBusinessSelectedRowKeys([]);
    setTechnolgySelectedRowKeys([]);
    setPeopleSelectedRowKeys([]);
  };

  useEffect(() => {
    if (
      configuration.isShowNotification &&
      configuration.alertMessage.type === FAILURE
    ) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: ``,
        description: configuration.alertMessage.message,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon: <ToastSuccessIcon />,
        className: "custom-toast-area custom-success-toast",
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  const onClickCopyGoal = () => {
    dispatch(
      copyGoalsToCurrentYear({
        year: params?.year,
        payload: finalSelectedRowKeys,
      })
    )
      .unwrap()
      .then((data: any) => {
        navigate(`/${n.PERFORMANCE_URL}`, {
          state: {
            locationActiveIndex: 1,
          },
        });
      });
  };

  return (
    <>
      <div className="manager-view-container copy-goals-layout">
        {contextHolder}
        <div className="empDetialBreadcrumb">
          <BreadcrumbComponent items={breadbrumbItems} />
        </div>
        <div className="performance-header-empDetail">
          <div className="left-side">
            <div className="detail-username">{SELECT_GOALS}</div>
          </div>
        </div>
        <Spin spinning={isLoading || isLoadingCopyGoal}>
          <div className="">
            <div className="cascade-card-list cascade-third-section">
              <div className="total-weight-cpy">
                {TOTAL_WEIGHT}
                <div
                  className={totalCopyGoalsWeight <= 100 ? "within" : "exceed"}
                >
                  {totalCopyGoalsWeight}%
                </div>
              </div>
              <div className="cascading-goal-card business copy-goals">
                <h5 className="title">{GOAL_ACTIVITY_SUB_TITLE}</h5>
                {goalsList?.business?.length > 0 ? (
                  <div>
                    <Table
                      {...tableProps}
                      columns={columns}
                      dataSource={goalsList?.business}
                      rowKey={(record: any) =>
                        record?.goalId ? record?.goalId : record?.sfGoalId
                      }
                      rowSelection={{
                        ...businessRowSelection,
                        selectedRowKeys: businessselectedRowKeys,
                        onChange: onBusinessSelectChange,
                      }}
                      className="tbl-outer-section"
                    />
                  </div>
                ) : (
                  <div className="empty-goals">
                    <img src={EmptyGoal} alt="" />
                    <span>{GOAL_EMPTY}</span>
                  </div>
                )}
              </div>
              <div className="cascading-goal-card business copy-goals">
                <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE1}</h5>
                {goalsList?.technology?.length > 0 ? (
                  <Table
                    {...tableProps}
                    columns={columns}
                    dataSource={goalsList?.technology}
                    rowKey={(record: any) =>
                      record?.goalId ? record?.goalId : record?.sfGoalId
                    }
                    rowSelection={{
                      ...technologyRowSelection,
                      selectedRowKeys: technologyselectedRowKeys,
                      onChange: onTechnolgySelectChange,
                    }}
                    className="tbl-outer-section"
                  />
                ) : (
                  <div className="empty-goals">
                    <img src={EmptyGoal} alt="" />
                    <span>{GOAL_EMPTY}</span>
                  </div>
                )}
              </div>
              <div className="cascading-goal-card business copy-goals">
                <h5 className="title">{GOAL_MAIN_PAGE_SUBTITLE2}</h5>
                {goalsList?.people?.length > 0 ? (
                  <Table
                    {...tableProps}
                    columns={columns}
                    dataSource={goalsList?.people}
                    rowSelection={{
                      ...peopleRowSelection,
                      selectedRowKeys: peopleselectedRowKeys,
                      onChange: onPeopleSelectChange,
                    }}
                    rowKey={(record: any) =>
                      record?.goalId ? record?.goalId : record?.sfGoalId
                    }
                    className="tbl-outer-section"
                  />
                ) : (
                  <div className="empty-goals">
                    <img src={EmptyGoal} alt="" />
                    <span>{GOAL_EMPTY}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="copy-goal-button-bar">
              <div className="copy-goal-selected-text">
                {businessselectedRowKeys?.length > 0 ||
                technologyselectedRowKeys?.length > 0 ||
                peopleselectedRowKeys?.length > 0
                  ? `${
                      businessselectedRowKeys?.length +
                      technologyselectedRowKeys?.length +
                      peopleselectedRowKeys?.length
                    } goals selected`
                  : ""}
              </div>
              {!isLoadingCopyGoal && (
                <div className="selected-goals-action">
                  <Button
                    className="secondary-btn"
                    onClick={cancelCascadeHandler}
                  >
                    {CANCEL}
                  </Button>
                  <Button
                    className={
                      !(
                        businessselectedRowKeys?.length > 0 ||
                        technologyselectedRowKeys?.length > 0 ||
                        peopleselectedRowKeys?.length > 0
                      )
                        ? "primary-btn-copy"
                        : "primary-btn-copy active-copy-goals"
                    }
                    type="primary"
                    disabled={
                      !(
                        businessselectedRowKeys?.length > 0 ||
                        technologyselectedRowKeys?.length > 0 ||
                        peopleselectedRowKeys?.length > 0
                      )
                    }
                    onClick={() => {
                      onClickCopyGoal();
                    }}
                  >
                    {COPY}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default CopyGoals;
