import { createSlice } from "@reduxjs/toolkit";
import {
  getCreateTemplate,
  getMyActivityChallenges,
  getMyActivityIdeas,
  getFameBooks,
  getBanner,
  postCreateChallenge,
  getTopIdeas,
  getTestimonials,
  getMyActivityChallengeDetails,
  getMetrics,
  getPrograms,
} from "../actions/innovation";

type failure = {
  error: boolean;
  message: string;
};

type cleranceDetailState = {
  templateDataLoading: boolean;
  templateData: any;
  templateDataFailure: failure;

  challengesLoading: boolean;
  challenges: any;
  challengesFailure: failure;

  ideasLoading: boolean;
  ideas: any;
  ideasFailure: failure;

  fameBookLoading: boolean;
  fameBooks: any;
  fameBookFailure: failure;

  bannerLoading: boolean;
  banner: any;
  bannerFailure: failure;

  postChallengeLoading: boolean;
  postChallenge: any;
  postChallengeFailure: failure;

  topIdeas: any;
  topIdeasLoading: boolean;
  topIdeasFailure: failure;

  testimonials: any;
  testimonialsLoading: boolean;
  testimonialsFailure: failure;

  challengeDetails: any;
  challengeDetailsLoading: boolean;
  challengeDetailsFailure: failure;

  metrics: any;
  metricsLoading: boolean;
  metricsFailure: failure;

  programs: any;
  programsLoading: boolean;
  programsFailure: failure;
};

const initialDetailState: cleranceDetailState = {
  templateData: {},
  templateDataLoading: false,
  templateDataFailure: {
    error: false,
    message: "",
  },

  challengesLoading: false,
  challenges: [],
  challengesFailure: {
    error: false,
    message: "",
  },

  ideasLoading: false,
  ideas: [],
  ideasFailure: {
    error: false,
    message: "",
  },

  fameBookLoading: false,
  fameBooks: [],
  fameBookFailure: {
    error: false,
    message: "",
  },

  bannerLoading: false,
  banner: [],
  bannerFailure: {
    error: false,
    message: "",
  },

  postChallenge: {},
  postChallengeLoading: false,
  postChallengeFailure: {
    error: false,
    message: "",
  },

  topIdeas: [],
  topIdeasLoading: false,
  topIdeasFailure: {
    error: false,
    message: "",
  },

  testimonials: {},
  testimonialsLoading: false,
  testimonialsFailure: {
    error: false,
    message: "",
  },

  challengeDetails: {},
  challengeDetailsLoading: false,
  challengeDetailsFailure: {
    error: false,
    message: "",
  },

  metrics: {},
  metricsLoading: false,
  metricsFailure: {
    error: false,
    message: "",
  },

  programs: [],
  programsLoading: false,
  programsFailure: {
    error: false,
    message: "",
  },
};

export const innovation: any = createSlice({
  name: "innovation",
  initialState: initialDetailState,
  reducers: {},
  extraReducers: (builder) => {
    // getCreate Template
    builder.addCase(getCreateTemplate.pending, (state) => {
      state.templateDataLoading = true;
      state.templateDataFailure.error = false;
      state.templateDataFailure.message = "";
    });
    builder.addCase(getCreateTemplate.fulfilled, (state: any, action) => {
      state.templateDataLoading = false;
      state.templateData = action?.payload;
    });
    builder.addCase(getCreateTemplate.rejected, (state: any, action) => {
      state.templateDataLoading = false;
      state.templateDataFailure.error = true;
      state.templateDataFailure.message = action.payload;
    });

    // getchallenges
    builder.addCase(getMyActivityChallenges.pending, (state) => {
      state.challengesLoading = true;
      state.challengesFailure.error = false;
      state.challengesFailure.message = "";
    });
    builder.addCase(getMyActivityChallenges.fulfilled, (state: any, action) => {
      state.challengesLoading = false;
      state.challenges = action?.payload;
    });
    builder.addCase(getMyActivityChallenges.rejected, (state: any, action) => {
      state.challengesLoading = false;
      state.challengesFailure.error = true;
      state.challengesFailure.message = action.payload;
    });

    // getideas
    builder.addCase(getMyActivityIdeas.pending, (state) => {
      state.ideasLoading = true;
      state.ideasFailure.error = false;
      state.ideasFailure.message = "";
    });
    builder.addCase(getMyActivityIdeas.fulfilled, (state: any, action) => {
      state.ideasLoading = false;
      state.ideas = action?.payload;
    });
    builder.addCase(getMyActivityIdeas.rejected, (state: any, action) => {
      state.ideasLoading = false;
      state.ideasFailure.error = true;
      state.ideasFailure.message = action.payload;
    });

    // getfamebooks
    builder.addCase(getFameBooks.pending, (state) => {
      state.fameBookLoading = true;
      state.fameBookFailure.error = false;
      state.fameBookFailure.message = "";
    });
    builder.addCase(getFameBooks.fulfilled, (state: any, action) => {
      state.fameBookLoading = false;
      state.fameBooks = action?.payload;
    });
    builder.addCase(getFameBooks.rejected, (state: any, action) => {
      state.fameBookLoading = false;
      state.fameBookFailure.error = true;
      state.fameBookFailure.message = action.payload;
    });

    // getBanner
    builder.addCase(getBanner.pending, (state) => {
      state.bannerLoading = true;
      state.bannerFailure.error = false;
      state.bannerFailure.message = "";
    });
    builder.addCase(getBanner.fulfilled, (state: any, action) => {
      state.bannerLoading = false;
      state.banner = action?.payload;
    });
    builder.addCase(getBanner.rejected, (state: any, action) => {
      state.bannerLoading = false;
      state.bannerFailure.error = true;
      state.bannerFailure.message = action.payload;
    });

    // postCreateChallenge
    builder.addCase(postCreateChallenge.pending, (state) => {
      state.postChallengeLoading = true;
      state.postChallengeFailure.error = false;
      state.postChallengeFailure.message = "";
    });
    builder.addCase(postCreateChallenge.fulfilled, (state: any, action) => {
      state.postChallengeLoading = false;
      state.postChallenge = action?.payload;
    });
    builder.addCase(postCreateChallenge.rejected, (state: any, action) => {
      console.log("reducer ", action);
      state.postChallengeLoading = false;
      state.postChallengeFailure.error = true;
      state.postChallengeFailure.message = action.payload;
    });

    // getTopIdeas
    builder.addCase(getTopIdeas.pending, (state) => {
      state.topIdeasLoading = true;
      state.topIdeasFailure.error = false;
      state.topIdeasFailure.message = "";
    });
    builder.addCase(getTopIdeas.fulfilled, (state: any, action) => {
      state.topIdeasLoading = false;
      state.topIdeas = action?.payload;
    });
    builder.addCase(getTopIdeas.rejected, (state: any, action) => {
      state.topIdeasLoading = false;
      state.topIdeasFailure.error = true;
      state.topIdeasFailure.message = action.payload;
    });

    // getTestimonials
    builder.addCase(getTestimonials.pending, (state) => {
      state.testimonialsLoading = true;
      state.testimonialsFailure.error = false;
      state.testimonialsFailure.message = "";
    });
    builder.addCase(getTestimonials.fulfilled, (state: any, action) => {
      state.testimonialsLoading = false;
      state.testimonials = action?.payload;
    });
    builder.addCase(getTestimonials.rejected, (state: any, action) => {
      state.testimonialsLoading = false;
      state.testimonialsFailure.error = true;
      state.testimonialsFailure.message = action.payload;
    });

    // getMyActivityChallengeDetails
    builder.addCase(getMyActivityChallengeDetails.pending, (state) => {
      state.challengeDetailsLoading = true;
      state.challengeDetailsFailure.error = false;
      state.challengeDetailsFailure.message = "";
    });
    builder.addCase(
      getMyActivityChallengeDetails.fulfilled,
      (state: any, action) => {
        state.challengeDetailsLoading = false;
        state.challengeDetails = action?.payload;
      }
    );
    builder.addCase(
      getMyActivityChallengeDetails.rejected,
      (state: any, action) => {
        state.challengeDetailsLoading = false;
        state.challengeDetailsFailure.error = true;
        state.challengeDetailsFailure.message = action.payload;
      }
    );

    // getMetrics
    builder.addCase(getMetrics.pending, (state) => {
      state.metricsLoading = true;
      state.metricsFailure.error = false;
      state.metricsFailure.message = "";
    });
    builder.addCase(getMetrics.fulfilled, (state: any, action) => {
      state.metricsLoading = false;
      state.metrics = action?.payload;
    });
    builder.addCase(getMetrics.rejected, (state: any, action) => {
      state.metricsLoading = false;
      state.metricsFailure.error = true;
      state.metricsFailure.message = action.payload;
    });

    // getPrograms
    builder.addCase(getPrograms.pending, (state) => {
      state.programsLoading = true;
      state.programsFailure.error = false;
      state.programsFailure.message = "";
    });
    builder.addCase(getPrograms.fulfilled, (state: any, action) => {
      state.programsLoading = false;
      state.programs = action?.payload;
    });
    builder.addCase(getPrograms.rejected, (state: any, action) => {
      state.programsLoading = false;
      state.programsFailure.error = true;
      state.programsFailure.message = action.payload;
    });
  },
});

export default innovation.reducer;
