import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  DatePicker,
  Input,
  message,
  ConfigProvider,
} from "antd";
import exportclose from "../../../themes/assets/images/svg/ExportClose.svg";
import downloadicon from "../../../themes/assets/images/svg/Downloadicon.svg";
import "./ExportModal.css";
import exportcalendericon from "../../../themes/assets/images/svg/ExportCalendarBlank.svg";
import { classNames } from "primereact/utils";
//import locale from 'antd/es/date-picker/locale/en_GB';
import locale from "antd/es/date-picker/locale/en_GB";

const customLocale = {
  ...locale,
  // Extend the locale with the firstDayOfWeek property
  firstDayOfWeek: 2, // Set to 1 for Monday
  months: [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ],
};

const ExportModal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [isEmailEnabled, setIsEmailEnabled] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setIsDownloadEnabled(false);
    setIsEmailEnabled(false);
  };

  const onFormValuesChange = (_, allFields: any) => {
    const { startDate, endDate, email } = allFields;

    const isDateValid = !!startDate && !!endDate;
    setIsDownloadEnabled(isDateValid);
    setIsEmailEnabled(isDateValid);
  };

  const onChangeHandler = (name, value) => {
    props.onChangeHandler({ target: { name, value } });
  };

  return (
    <div>
      <Button onClick={showModal} className="export-button">
        <img
          src={downloadicon}
          alt="Download Icon"
          className="export-button-icon"
        />
        Export Timesheet
      </Button>

      <Modal
        title="Export Timesheet"
        className="exporttimesheet_modal"
        open={isModalVisible}
        onCancel={handleCancel}
        closeIcon={
          <img
            src={exportclose}
            alt="Close Icon"
            className="custom-close-icon"
          />
        }
        footer={[
          <div className="export-footer-button">
            <div className="footer-download-button">
              <Button
                key="download"
                onClick={() => {
                  setIsModalVisible(false);
                  props?.clickOnDownload();
                }}
                disabled={!isDownloadEnabled}
                style={{
                  backgroundColor: isDownloadEnabled ? "#0642B5" : "#E2E2E2",
                  color: isDownloadEnabled ? "#FFFFFF" : "#858585",
                }}
              >
                Download
              </Button>
            </div>
            <div className="footer-email-button">
              <Button
                key="email"
                onClick={() => {
                  setIsModalVisible(false);
                  props?.clickOnEmail();
                }}
                disabled={!isEmailEnabled}
                style={{
                  backgroundColor: isEmailEnabled ? "#0642B5" : "#E2E2E2",
                  color: isEmailEnabled ? "#FFFFFF" : "#858585",
                }}
              >
                E-mail
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className="modal-subtext" id="modal-subtext">
          Timesheet will export based on the selected date.
        </div>

        <Form
          form={form}
          layout="vertical"
          onValuesChange={onFormValuesChange}
          className="export-popup-form-otr"
        >
          <Form.Item
            label={
              <div className="start-date-label" id="start-date-label">
                Start Date
              </div>
            }
            name="startDate"
            rules={[{ required: true, message: "Start Date is required" }]}
            className="export-form-field_otr"
          >
            <DatePicker
              className="custom-input"
              format={"DD/MM/YYYY"}
              popupClassName="custom-input-popup"
              onChange={(date, dateString) =>
                onChangeHandler("startDate", dateString)
              }
              suffixIcon={
                <img
                  src={exportcalendericon}
                  alt="Calendar Icon"
                  className="Calender-icon"
                />
              }
              locale={customLocale}
            />
          </Form.Item>

          <Form.Item
            label={<div className="End-Date-label">End Date</div>}
            name="endDate"
            className="export-form-field_otr"
            rules={[{ required: true, message: "End Date is required" }]}
          >
            <DatePicker
              className="custom-input"
              format={"DD/MM/YYYY"}
              popupClassName="custom-input-popup"
              onChange={(date, dateString) =>
                onChangeHandler("endDate", dateString)
              }
              suffixIcon={
                <img
                  src={exportcalendericon}
                  alt="Calendar Icon"
                  className="Calender-icon"
                />
              }
              locale={customLocale}
            />
          </Form.Item>
          {/* <Form.Item
            label={<div className="e-mail">E-mail</div>}
            name="email"
            rules={[{ type: "email", message: 'Please enter a valid email address' }]}
            className="export-form-field_otr"
          >
            <Input
              placeholder="Enter the official email id"
              className="custom-input"
              onChange={(e) => onChangeHandler("email", e.target.value)}
            />
          </Form.Item> */}
        </Form>
      </Modal>
    </div>
  );
};

export default ExportModal;
