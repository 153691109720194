import React from "react";

function Reactions() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="17"
      fill="none"
      viewBox="0 0 40 17"
    >
      <circle cx="32" cy="8.5" r="8" fill="#FFAD09"></circle>
      <g clipPath="url(#clip0_2129_33057)">
        <path
          fill="#fff"
          d="M34.25 13.375a.375.375 0 01-.375.375h-3.75a.375.375 0 110-.75h3.75a.375.375 0 01.375.375zm1.875-6a4.112 4.112 0 01-1.575 3.244.754.754 0 00-.3.6v.281a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-.28a.76.76 0 00-.29-.596 4.116 4.116 0 01-1.585-3.225c-.014-2.236 1.795-4.097 4.027-4.149a4.12 4.12 0 014.223 4.125zm-2.358.108a.38.38 0 00-.534 0L32 8.721l-1.233-1.238a.38.38 0 00-.534.535l1.392 1.387v1.72a.375.375 0 10.75 0v-1.72l1.392-1.387a.38.38 0 000-.535z"
        ></path>
      </g>
      <circle cx="20" cy="8.5" r="8" fill="#2A927F"></circle>
      <g clipPath="url(#clip1_2129_33057)">
        <path
          fill="#fff"
          d="M22.438 12.325a5.6 5.6 0 00.75-.399v1.824a.375.375 0 11-.75 0v-1.425zm-2.77-5.503a.385.385 0 00.154.51L22.016 8.5l.797-.427-2.635-1.406a.384.384 0 00-.51.155zm6.135-.155l-5.625-3a.398.398 0 00-.356 0l-5.625 3a.38.38 0 000 .666l1.116.59v2.335c-.001.16.052.317.15.445.37.497 1.795 2.11 4.537 2.11a6.034 6.034 0 002.438-.493V8.725l-.422-.225L20 9.573 15.866 7.37 15.172 7 20 4.426 24.828 7l-.694.37-1.321.703.178.094a.394.394 0 01.197.333v3.426c.518-.325.974-.74 1.35-1.223a.727.727 0 00.15-.445V7.923l1.115-.59a.38.38 0 000-.666z"
        ></path>
      </g>
      <circle cx="8" cy="8.5" r="8" fill="#E65A14"></circle>
      <g clipPath="url(#clip2_2129_33057)">
        <path
          fill="#fff"
          d="M13.212 7.066a.768.768 0 00-.684-.535L9.742 6.34 8.712 3.737a.769.769 0 00-.713-.487.769.769 0 00-.712.487l-1.05 2.616-2.766.178a.773.773 0 00-.684.535.788.788 0 00.244.848l2.128 1.8-.633 2.49a.867.867 0 00.328.918.844.844 0 00.942.028l2.199-1.392h.009l2.367 1.495a.755.755 0 001.018-.184.77.77 0 00.13-.669l-.67-2.723 2.12-1.763a.787.787 0 00.243-.848z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2129_33057">
          <path
            fill="#fff"
            d="M0 0H12V12H0z"
            transform="translate(26 2.5)"
          ></path>
        </clipPath>
        <clipPath id="clip1_2129_33057">
          <path
            fill="#fff"
            d="M0 0H12V12H0z"
            transform="translate(14 2.5)"
          ></path>
        </clipPath>
        <clipPath id="clip2_2129_33057">
          <path
            fill="#fff"
            d="M0 0H12V12H0z"
            transform="translate(2 2.5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Reactions;
