import "./Programs.scss";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import {
  BUSSINESS_DETAILS,
  CANCEL,
  CHALLENGES,
  CREATE_PROGRAM,
  CREATE_PROGRAM_TITLE,
  INNOVATION,
  INNOVATION_TITLE,
  MYWORK_URL,
  PREVIEW_PROGRAM_TITLE,
  PROGRAM_DETAILS,
  START,
  SUBMIT,
} from "../../../constants";
import Edit from "../../common/svg/Edit";
export const BCCard = ({ border }) => {
  return (
    <div
      className="bc-card"
      style={{ border: border ? "1px solid #e8e8e8" : "none" }}
    >
      <div>
        <div className="title small-title">
          Backlog Visibility & Reading Heading 9 with a long title of challenge
        </div>
        <div className="title small-title light">
          Achieve high sprint velocity and on-time product releases consectetur
          adipiscing elit,{" "}
        </div>
        <div className="title small-title light very-light">
          Created on: 05 July 2024
        </div>
      </div>
    </div>
  );
};
const PreviewProgram = (props: any) => {
  const navigate = useNavigate();
  const items = [
    {
      title: (
        <Link
          to={`/${START}/${MYWORK_URL}/${INNOVATION}`}
          className="link-highlight"
        >
          {INNOVATION_TITLE}
        </Link>
      ),
    },

    {
      title: CREATE_PROGRAM_TITLE,
    },
  ];

  const onClickCancel = () => {};
  const onClickSubmit = () => {
    navigate(`/${START}/${MYWORK_URL}/${INNOVATION}`);
  };
  const navToCreateProgram = () =>
    navigate(`/${START}/${MYWORK_URL}/${INNOVATION}/${CREATE_PROGRAM}`);
  const navToChalleneges = () =>
    navigate(
      `/${START}/${MYWORK_URL}/${INNOVATION}/${CREATE_PROGRAM}/${CHALLENGES}`
    );

  return (
    <div id="preview-program-page" className="wrapper">
      <BreadcrumbComponent items={items} />
      <div className="dex-title">{PREVIEW_PROGRAM_TITLE}</div>
      <div className="preview-program-body">
        <div className="section">
          <div className="space-between">
            <div className="title">{PROGRAM_DETAILS}</div>
            <Edit className={"cursor-pointer"} onClick={navToCreateProgram} />
          </div>
          <div className="info-section">
            <div>
              <span className="title small-title">Name of Program</span>
              <br></br>
              <span className="title small light">Technology</span>
            </div>
            <div>
              <span className="title small-title">
                Short Description of the Program
              </span>
              <br></br>
              <span className="title small light">
                Create a comprehensive solution that not only records daily
                in-time and out-time punctuality but also provides real-time
                access to supervisors and team leaders for monitoring and
                managing employee attendance.
              </span>
            </div>
            <div className="last-section">
              <div className="flex">
                <span className="title small-title">Start Date</span>
                <br></br>
                <span className="title small light">20 March 2024</span>
              </div>
              <div className="flex">
                <span className="title small-title">End Date</span>
                <br></br>
                <span className="title small light">20 April 2024</span>
              </div>
              <div className="flex">
                <span className="title small-title">Background</span>
                <br></br>
                <span className="title small light">File.jpg</span>
              </div>
            </div>
          </div>
        </div>
        <div className="section section2">
          <div className="space-between">
            <div className="title">{BUSSINESS_DETAILS}</div>
            <Edit className={"cursor-pointer"} onClick={navToChalleneges} />
          </div>
          <div className="info-section bc-info-section">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 87, 56].map((item) => (
              <BCCard border={true} />
            ))}
          </div>
        </div>
      </div>
      <div className="form-footer">
        <div className="btn-container">
          <div
            id="preview-program-cancel-btn"
            onClick={onClickCancel}
            className="btn cancel"
          >
            {CANCEL}
          </div>

          <div
            id="preview-program-submit-btn"
            onClick={onClickSubmit}
            className={"btn next-enabled"}
          >
            {SUBMIT}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewProgram;
