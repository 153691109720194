import { createSlice } from "@reduxjs/toolkit";
import { DRAFT, FAILURE, SUCCESS } from "../../constants";
import {
  extractHourMinitue,
  timeSheetCalculation,
  timeSheetFormat,
  timeSheetHourCalculation,
  timeSheetNewRowFormat,
  timeSheetProjectFormat,
  timeSheetUpdateRowFormat,
} from "../../components/utils/util";
import {
  getDetailTimeSheet,
  getLandingTimeSheet,
  getMyActivities,
  postCreateTimesheet,
  getManagerTimesheet,
  getGroupByWeek,
  getPreviewManager,
  previewTimesheet,
  putApproveManager,
  putRejectManager,
  getSearchManager,
  getManagerLanding,
  saveTimesheet,
  deleteMyActivities,
  cleareApproveTimesheetData,
  cleareRejectTimesheetData,
  putBulkApproval,
  clearBulkApproveTimesheet,
  getDownloadTimesheet,
  postExportTimesheet,
  sendReminderNotification,
  clearManagerLandingScreenData,
  getCloneTimeSheet,
  getDownloadManagerTimesheet,
  postExportManagerTimesheet,
  getAdminExportData,
  postAdminExportData,
  postEmployeeTimesheetData,
  getAdminFinanceLanding,
  adminPersonaEmployeePreview,
  adminPersonaEmployeeTimesheets,
  adminPersonaDownloadData,
} from "../actions/timesheet";
import dayjs from "dayjs";

type timesheetDetailState = {
  details: any;
  configuration: any;
  myActivities: any;
  postCreateTimesheet: any;
  managerTimesheet: any;
  groupByWeekTsm: any;
  previewSelected: any;
  approvalMananger: any;
  rejectManager: any;
  searchManager: any;
  managerLanding: any;
  bulkApproval: any;
  adminFinanceLanding: any;
  loadingTimesheet: boolean;
  loading: boolean;
  loadingActivities: boolean;
  managerLoading: boolean;
  loadingGroupByWeek: boolean;
  loadingPreview: boolean;
  loadingApproval: boolean;
  loadingRejection: boolean;
  loadingSearch: boolean;
  loadingLanding: boolean;
  loadingBulkApproval: boolean;
  loadingAdminFinanceLanding: boolean;
  loadingLandingDownloadExport: boolean;
  loadingLandingDownloadExportPost: boolean;
  error: null;
  errorActivities: boolean;
  errorTimesheet: boolean;
  errorManager: boolean;
  errorGroupByWeek: boolean;
  errorPreview: boolean;
  errorApproval: boolean;
  errorRejection: boolean;
  errorSearch: boolean;
  errorLanding: boolean;
  errorBulkApproval: boolean;
  errorAdminFinanceLanding: boolean;
  errorPostLanding: boolean;
  sendReminder: any;
  loadingSendReminder: boolean;
  cloneTimesheetDetails: any;
  isCloneTimesheetEnabled: boolean;
  cloneSelectedWeek: any;
  disableCloneTimesheetBtn: boolean;
  cloneTimesheetDetailsSubHeaderMsg: string;
  adminEmployeePreviewData: any;
  adminEmployeePreviewDataLoading: boolean;
  adminPersonaEmployeeTimesheetsData: any;
  adminPersonaEmployeeTimesheetsLoading: boolean;
  adminPersonaDownloadDataLoading: boolean;
  errorDownloadLanding: boolean;
  errorLandingDownload: boolean;
  loadingLandingDownload: boolean;
  errorLandingPagePost: boolean;
  loadingLandingPagePost: boolean;
  selectedWeekStart: any;
  selectedWeekEnd: any;
  originalTimesheets: any;
};

const initialDetailState: timesheetDetailState = {
  details: {},
  configuration: {
    isShowNotification: false,
    isModal: false,
    isEnabled: true,
    alertMessage: { type: "warning", message: "" },
    loading: false,
  },
  myActivities: [],
  postCreateTimesheet: [],
  managerTimesheet: [],
  groupByWeekTsm: {},
  previewSelected: {},
  approvalMananger: {},
  rejectManager: {},
  searchManager: {},
  managerLanding: {},
  bulkApproval: {},
  adminFinanceLanding: {},
  loadingTimesheet: false,
  loading: false,
  loadingActivities: false,
  managerLoading: false,
  loadingGroupByWeek: false,
  loadingPreview: false,
  loadingApproval: false,
  loadingRejection: false,
  loadingSearch: false,
  loadingLanding: false,
  loadingBulkApproval: false,
  loadingAdminFinanceLanding: false,
  loadingLandingDownloadExport: false,
  loadingLandingDownloadExportPost: false,
  error: null,
  errorActivities: false,
  errorTimesheet: false,
  errorManager: false,
  errorGroupByWeek: false,
  errorPreview: false,
  errorApproval: false,
  errorRejection: false,
  errorSearch: false,
  errorLanding: false,
  errorBulkApproval: false,
  errorPostLanding: false,
  errorAdminFinanceLanding: false,
  sendReminder: {},
  loadingSendReminder: false,
  cloneTimesheetDetails: [],
  isCloneTimesheetEnabled: false,
  cloneSelectedWeek: { cloneWeekStart: "", cloneWeekEnd: "" },
  disableCloneTimesheetBtn: false,
  cloneTimesheetDetailsSubHeaderMsg: "",
  adminEmployeePreviewData: {},
  adminEmployeePreviewDataLoading: false,
  adminPersonaEmployeeTimesheetsData: {},
  adminPersonaEmployeeTimesheetsLoading: false,
  adminPersonaDownloadDataLoading: false,
  errorDownloadLanding: false,
  errorLandingDownload: false,
  loadingLandingDownload: false,
  errorLandingPagePost: false,
  loadingLandingPagePost: false,
  selectedWeekEnd: "",
  selectedWeekStart: "",
  originalTimesheets: []
};

export const detail: any = createSlice({
  name: "detail",
  initialState: initialDetailState,
  reducers: {
    notificationAction: (state, action) => {
      const { isShowNotification, message, type, additionalInfo } =
        action.payload;
      state.configuration.isShowNotification = isShowNotification;
      state.configuration.alertMessage = { type, message, additionalInfo };
    },
    payloadAction: (state, action) => {
      const { payload } = action.payload;
      state.details.payload = payload;
    },
    timesheetRemoveRowAction: (state, action) => {
      const { parentindex } = action.payload;
      const timeSheetList = state?.details?.timesheets || [];
      const listFilter = timeSheetList.filter(
        (item, index) => index !== parentindex
      );
      const {
        format_day1,
        format_day2,
        format_day3,
        format_day4,
        format_day5,
        format_day6,
        format_day7,
        totalHours,
        checkListFilter,
      } = timeSheetCalculation(listFilter);
      state.details.day1 = format_day1;
      state.details.day2 = format_day2;
      state.details.day3 = format_day3;
      state.details.day4 = format_day4;
      state.details.day5 = format_day5;
      state.details.day6 = format_day6;
      state.details.day7 = format_day7;
      state.details.totalHours = totalHours;
      state.details.timesheets = checkListFilter;
      //state.details.timesheets = listFilter
    },
    timesheetAddRowAction: (state, action) => {
      const { weekSelection } = action.payload;
      const timeSheetList = state?.details?.timesheets || [];
      const baseFormat = timeSheetFormat(weekSelection);
      //   const cd = {
      //     ...state,
      //     details: {...state.details, timesheets:[...state.details.timesheets,baseFormat]}
      // }
      state.details.timesheets = [...timeSheetList, baseFormat];
    },
    updateWeekendStatus: (state, action) => {
      const { projectIndex, type, value, timesheets } = action.payload;
      // const timeSheetList  = state?.details?.timesheets || []
      let listFilter = timesheets.map((item, rowindex) => {
        if (rowindex === projectIndex) {
          return { ...item, [type]: value };
        } else {
          return { ...item };
        }
      });
      if (type === "enabledWeekend") {
        const filterIndex = [5, 6];
        const finalFilter = listFilter.map((item, rowindex) => {
          if (projectIndex === rowindex) {
            const inner = item.recordsList.map((subitem, subrowindex) => {
              if (filterIndex.indexOf(subrowindex) > -1) {
                return {
                  ...subitem,
                  working_hours: dayjs("00:00", "HH:mm"),
                };
              } else {
                return { ...subitem };
              }
            });
            return { ...item, recordsList: inner };
          } else {
            return { ...item };
          }
        });
        const { listFilterResponse, totalHours } =
          timeSheetHourCalculation(finalFilter);
        state.details.timesheets = listFilterResponse;
        const { hours, minutes } = extractHourMinitue(totalHours, ":");
        state.details.totalHours = { hours, minutes };
      } else {
        state.details.timesheets = listFilter;
      }
    },
    setTimesheetClone: (state, action) => {
      state.isCloneTimesheetEnabled = action.payload || false;
    },
    setSelectedWeekStart: (state, action) => {
      state.selectedWeekStart = action.payload || "";
    },
    setSelectedWeekEnd: (state, action) => {
      state.selectedWeekEnd = action.payload || "";
    },
    setCloneSelectedWeek: (state, action) => {
      state.cloneSelectedWeek = action.payload || {
        cloneWeekStart: "",
        cloneWeekEnd: "",
      };
    },
    setDisableCloneTimesheetBtn: (state, action) => {
      state.disableCloneTimesheetBtn = action.payload || false;
    },
    timesheetUpdateRowAction: (state, action) => {
      const { value, index, type, parentindex, timesheets, originalTimesheets = [] } = action.payload;
      // const timeSheetList  = state?.details?.timesheets || [];
      const listFilter = timesheets.map((item, rowindex) => {
        if (parentindex === rowindex) {
          const inner = item.recordsList.map((subitem, subrowindex) => {
            if (index === subrowindex) {
              let updatedValue = value;
              let working_hours = type === "working_hours" ? updatedValue : timesheets[parentindex]?.recordsList?.[index].working_hours;
              if (type === "task") {
                working_hours = originalTimesheets[parentindex]?.recordsList?.[index].working_hours;
                if (updatedValue === "Client Holiday" || updatedValue === "Holiday") {
                  working_hours = dayjs("00:00", "HH:mm");
                }
              }
              return { ...subitem, [type]: updatedValue, working_hours };
            } else {
              return { ...subitem };
            }
          });
          return { ...item, recordsList: inner };
        } else {
          return { ...item };
        }
      });
      // state.details.totalHours = timeSheetHourCalculation(listFilter)
      /*let day1= 0.0
      let day2= 0.0
      let day3= 0.0
      let day4= 0.0
      let day5= 0.0
      let day6= 0.0
      let day7= 0.0
      const checkListFilter = listFilter.map((item, rowindex) => {
        let single_row = 0.0;
      let hour_1: any =
        item.hour_1 && item.hour_1 !== ""
          ? replaceString(item.hour_1, ":", ".")
          : "0.0";
      let hour_2: any =
        item.hour_2 && item.hour_2 !== ""
          ? replaceString(item.hour_2, ":", ".")
          : "0.0";
      let hour_3: any =
        item.hour_3 && item.hour_3 !== ""
          ? replaceString(item.hour_3, ":", ".")
          : "0.0";
      let hour_4: any =
        item.hour_4 && item.hour_4 !== ""
          ? replaceString(item.hour_4, ":", ".")
          : "0.0";
      let hour_5: any =
        item.hour_5 && item.hour_5 !== ""
          ? replaceString(item.hour_5, ":", ".")
          : "0.0";
      let hour_6: any =
        item.hour_6 && item.hour_6 !== ""
          ? replaceString(item.hour_6, ":", ".")
          : "0.0";
      let hour_7: any =
        item.hour_7 && item.hour_7 !== ""
          ? replaceString(item.hour_7, ":", ".")
          : "0.0";
      single_row =
        single_row +
        parseFloat(hour_1) +
        parseFloat(hour_2) +
        parseFloat(hour_3) +
        parseFloat(hour_4) +
        parseFloat(hour_5) +
        parseFloat(hour_6) +
        parseFloat(hour_7);
        day1+=parseFloat(hour_1)
        day2+=parseFloat(hour_2)
        day3+=parseFloat(hour_3)
        day4+=parseFloat(hour_4)
        day5+=parseFloat(hour_5)
        day6+=parseFloat(hour_6)
        day7+=parseFloat(hour_7)
      const replaceHours = replaceString(single_row.toFixed(2), ".", ":");
      return {...item,totalHours:replaceHours}
      });
      */

      // const {format_day1,format_day2,format_day3,format_day4,format_day5,format_day6,format_day7,totalHours,checkListFilter}=timeSheetCalculation(listFilter)
      // state.details.day1=format_day1
      // state.details.day2=format_day2
      // state.details.day3=format_day3
      // state.details.day4=format_day4
      // state.details.day5=format_day5
      // state.details.day6=format_day6
      // state.details.day7=format_day7
      //state.details.totalHours= totalHours;
      //state.details.timesheets = checkListFilter
      // console.log(listFilter)
      const { listFilterResponse, totalHours } =
        timeSheetHourCalculation(listFilter);
      state.details.timesheets = listFilterResponse;
      const { hours, minutes } = extractHourMinitue(totalHours, ":");
      state.details.totalHours = { hours, minutes };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCloneTimeSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCloneTimeSheet.fulfilled, (state, action) => {
      state.loading = false;
      const { data = [], subHeadermsg = "" } = action.payload;
      state.cloneTimesheetDetails = data;
      state.cloneTimesheetDetailsSubHeaderMsg = subHeadermsg || "";
      state.disableCloneTimesheetBtn = Boolean(subHeadermsg);
    });
    builder.addCase(getCloneTimeSheet.rejected, (state: any, action) => {
      state.loading = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: FAILURE,
        message: "error saved",
      };
      state.error = action.payload;
    });
    builder.addCase(getLandingTimeSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLandingTimeSheet.fulfilled, (state, action) => {
      state.loading = false;
      const { status, msg, error, data } = action.payload;
      //state.configuration.isShowNotification = true;
      //   if(status===SUCCESS){
      //     state.configuration.alertMessage={type:SUCCESS,message:custommessage};
      //   }else{
      //     state.configuration.alertMessage={type:FAILURE,message};
      //   }
      // const baseFormat = timeSheetFormat("2024-09-16");
      state.details = data;
      // state.details.timesheets = [baseFormat];
      // state.details.day1="00:00";
      // state.details.day2="00:00";
      // state.details.day3="00:00";
      // state.details.day4="00:00";
      // state.details.day5="00:00";
      // state.details.day6="00:00";
      // state.details.day7="00:00";
      // state.details.totalHours="00:00"
    });
    builder.addCase(getLandingTimeSheet.rejected, (state: any, action) => {
      state.loading = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: FAILURE,
        message: "error saved",
      };
      state.error = action.payload;
    });
    builder.addCase(getDetailTimeSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDetailTimeSheet.fulfilled, (state, action) => {
      state.loading = false;
      const { status, msg, error, data, weekStartDate } = action.payload;
      const statusConvertion = status.toLowerCase();
      if (statusConvertion === SUCCESS) {
        const {
          empty,
          totalTime,
          tsProjects,
          displayWeek,
          projectTitle,
          icons,
          weekStartDate,
          weekEndDate,
          shiftType,
          defaultHoursAndMinutes,
        } = data;
        //state.configuration.isShowNotification = true;
        // const baseFormat = empty?timeSheetProjectFormat(projects,weekStartDate):timeSheetUpdateRowFormat(data?.tsProjects[0]?.
        //   recordsList
        //   ||[])
        const baseFormat = timeSheetProjectFormat(tsProjects) || [];
        const { listFilterResponse, totalHours } =
          timeSheetHourCalculation(baseFormat);
        state.details.originalTimesheets = listFilterResponse
        state.details.timesheets = listFilterResponse;
        state.details.totalHours = totalTime;
        state.details.displayWeek = displayWeek;
        state.details.projectTitle = projectTitle;
        state.details.icons = icons;
        state.details.weekStartDate = weekStartDate;
        state.details.weekEndDate = weekEndDate;
        state.details.shiftType = shiftType;
        state.details.defaultHoursAndMinutes = defaultHoursAndMinutes;
      } else {
        const { errorMessage, errorHeaderMsg } = error;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: FAILURE,
          message: errorHeaderMsg,
          additionalInfo: errorMessage,
        };
        state.error = action.payload;
        state.details.timesheets = [];
        state.details.totalHours = {};
      }
    });
    builder.addCase(getDetailTimeSheet.rejected, (state: any, action) => {
      state.loading = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: FAILURE,
        message: "error saved",
      };
      state.error = action.payload;
    });
    builder.addCase(getMyActivities.pending, (state) => {
      state.loadingActivities = true;
      state.errorActivities = false;
    });
    builder.addCase(getMyActivities.fulfilled, (state: any, action) => {
      state.loadingActivities = false;
      state.myActivities = action?.payload;
    });
    builder.addCase(getMyActivities.rejected, (state: any, action) => {
      state.loadingActivities = false;
      state.errorActivities = true;
    });
    builder.addCase(deleteMyActivities.pending, (state) => {
      state.loadingActivities = true;
      state.errorActivities = false;
    });
    builder.addCase(deleteMyActivities.fulfilled, (state: any, action) => {
      state.loadingActivities = false;
      state.deleteActivities = action?.payload;
    });
    builder.addCase(deleteMyActivities.rejected, (state: any, action) => {
      state.loadingActivities = false;
      state.errorActivities = true;
    });
    builder.addCase(postCreateTimesheet.pending, (state) => {
      state.loading = true;
      state.loadingTimesheet = true;
      state.errorActivities = false;
    });
    builder.addCase(postCreateTimesheet.fulfilled, (state: any, action) => {
      state.loadingTimesheet = false;
      state.loading = false;
      state.postCreateTimeSheet = action?.payload;
      const { error, status, msg, headerMsg, subHeadermsg } = action?.payload;
      const statusConvertion = status.toLowerCase();
      if (statusConvertion === SUCCESS) {
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: SUCCESS,
          message: headerMsg,
          additionalInfo: subHeadermsg,
        };
      } else {
        state.configuration.isShowNotification = true;
        const { errorMessage, errorHeaderMsg } = error;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: FAILURE,
          message: errorMessage,
        };
      }
    });
    builder.addCase(postCreateTimesheet.rejected, (state: any, action) => {
      state.loadingTimesheet = false;
      state.errorTimesheet = true;
      state.loading = false;
    });
    builder.addCase(previewTimesheet.pending, (state) => {
      state.loading = true;
      state.loadingTimesheet = true;
      state.errorActivities = false;
    });
    builder.addCase(previewTimesheet.fulfilled, (state: any, action) => {
      state.loading = false;
      state.loadingTimesheet = false;
      const { status, msg, error, data } = action?.payload;
      const statusConvertion = status.toLowerCase();
      if (statusConvertion === SUCCESS) {
        const { empty, projects, tsProjects } = data;
        state.details.previewInfo = data;
      }
    });
    builder.addCase(previewTimesheet.rejected, (state: any, action) => {
      state.loading = false;
      state.loadingTimesheet = false;
      state.errorTimesheet = true;
    });
    builder.addCase(getManagerTimesheet.pending, (state) => {
      state.managerLoading = true;
      state.errorManager = false;
    });
    builder.addCase(getManagerTimesheet.fulfilled, (state: any, action) => {
      state.managerLoading = false;
      state.managerTimesheet = action?.payload;
    });
    builder.addCase(getManagerTimesheet.rejected, (state: any, action) => {
      state.managerLoading = false;
      state.errorManager = true;
    });
    builder.addCase(getGroupByWeek.pending, (state) => {
      state.loadingGroupByWeek = true;
      state.errorGroupByWeek = false;
    });
    builder.addCase(getGroupByWeek.fulfilled, (state: any, action) => {
      state.loadingGroupByWeek = false;
      state.groupByWeekTsm = action?.payload;
    });
    builder.addCase(getGroupByWeek.rejected, (state: any, action) => {
      state.loadingGroupByWeek = false;
      state.errorGroupByWeek = true;
    });
    builder.addCase(getPreviewManager.pending, (state) => {
      state.loadingPreview = true;
      state.errorPreview = false;
    });
    builder.addCase(getPreviewManager.fulfilled, (state: any, action) => {
      state.loadingPreview = false;
      state.previewSelected = action?.payload;
    });
    builder.addCase(getPreviewManager.rejected, (state: any, action) => {
      state.loadingPreview = false;
      state.errorPreview = true;
    });
    builder.addCase(putApproveManager.pending, (state) => {
      state.loadingApproval = true;
      state.errorApproval = false;
    });
    builder.addCase(putApproveManager.fulfilled, (state: any, action) => {
      state.loadingApproval = false;
      state.approvalMananger = action?.payload;
    });
    builder.addCase(putApproveManager.rejected, (state: any, action) => {
      state.loadingApproval = false;
      state.errorApproval = true;
      state.approvalMananger = {};
      state.sendReminder = {};
    });
    builder.addCase(putRejectManager.pending, (state) => {
      state.loadingRejection = true;
      state.errorRejection = false;
    });
    builder.addCase(putRejectManager.fulfilled, (state: any, action) => {
      state.loadingRejection = false;
      state.rejectManager = action?.payload;
    });
    builder.addCase(putRejectManager.rejected, (state: any, action) => {
      state.loadingRejection = false;
      state.errorRejection = true;
    });
    builder.addCase(getSearchManager.pending, (state) => {
      state.loadingSearch = true;
      state.errorSearch = false;
    });
    builder.addCase(getSearchManager.fulfilled, (state: any, action) => {
      state.loadingSearch = false;
      state.searchManager = action?.payload;
    });
    builder.addCase(getSearchManager.rejected, (state: any, action) => {
      state.loadingSearch = false;
      state.errorSearch = true;
    });
    builder.addCase(getManagerLanding.pending, (state) => {
      state.loadingLanding = true;
      state.errorLanding = false;
    });
    builder.addCase(getManagerLanding.fulfilled, (state: any, action) => {
      state.loadingLanding = false;
      state.managerLanding = action?.payload;
    });
    builder.addCase(getManagerLanding.rejected, (state: any, action) => {
      state.loadingLanding = false;
      state.errorLanding = true;
    });
    builder.addCase(saveTimesheet.pending, (state) => {
      state.loading = true;
      state.loadingTimesheet = true;
      state.errorActivities = false;
    });
    builder.addCase(saveTimesheet.fulfilled, (state: any, action) => {
      state.loading = false;
      state.loadingTimesheet = false;
      const { status, msg, error, data, headerMsg, subHeadermsg } =
        action?.payload;
      const statusConvertion = status.toLowerCase();
      if (statusConvertion === SUCCESS) {
        const { status: savedStatus } = data;
        const savedStatusConvertion = savedStatus.toUpperCase();
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: savedStatusConvertion === DRAFT ? "info" : SUCCESS,
          message: headerMsg,
          additionalInfo: subHeadermsg,
        };
      } else {
        state.configuration.isShowNotification = true;
        const { errorMessage } = error;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: FAILURE,
          //message: errorMessage,
          additionalInfo: msg,
        };
      }
    });
    builder.addCase(saveTimesheet.rejected, (state: any, action) => {
      state.loading = false;
      state.loadingTimesheet = false;
      state.errorTimesheet = true;
    });

    builder.addCase(
      cleareApproveTimesheetData.fulfilled,
      (state: any, action) => {
        state.approvalMananger = {};
        state.sendReminder = {};
      }
    );
    builder.addCase(
      cleareRejectTimesheetData.fulfilled,
      (state: any, action) => {
        state.rejectManager = {};
      }
    );
    builder.addCase(putBulkApproval.pending, (state) => {
      state.loadingBulkApproval = true;
      state.errorBulkApproval = false;
    });
    builder.addCase(putBulkApproval.fulfilled, (state: any, action) => {
      state.loadingBulkApproval = false;
      state.bulkApproval = action?.payload;
    });
    builder.addCase(putBulkApproval.rejected, (state: any, action) => {
      state.loadingBulkApproval = false;
      state.errorBulkApproval = true;
    });
    builder.addCase(
      clearBulkApproveTimesheet.fulfilled,
      (state: any, action) => {
        state.bulkApproval = {};
      }
    );

    builder.addCase(getDownloadTimesheet.pending, (state) => {
      state.loadingLandingDownload = true;
      state.errorLandingDownload = false;
    });
    builder.addCase(getDownloadTimesheet.fulfilled, (state: any, action) => {
      state.loadingLandingDownload = false;
      state.ExportTimesheet = action?.payload;
    });
    builder.addCase(getDownloadTimesheet.rejected, (state: any, action) => {
      state.loadingLandingDownload = false;
      state.errorLandingDownload = true;
    });
    builder.addCase(postExportTimesheet.pending, (state) => {
      state.loadingLanding = true;
      state.errorLandingPagePost = false;
    });
    builder.addCase(postExportTimesheet.fulfilled, (state: any, action) => {
      state.loadingLanding = false;
      state.ExportReportTimesheet = action?.payload;
    });
    builder.addCase(postExportTimesheet.rejected, (state: any, action) => {
      state.loadingLanding = false;
      state.errorLandingPagePost = true;
    });

    builder.addCase(sendReminderNotification.pending, (state) => {
      state.loadingSendReminder = true;
      state.errorLanding = false;
    });
    builder.addCase(
      sendReminderNotification.fulfilled,
      (state: any, action) => {
        state.loadingSendReminder = false;
        state.sendReminder = action?.payload;
      }
    );
    builder.addCase(sendReminderNotification.rejected, (state: any, action) => {
      state.loadingSendReminder = false;
      state.errorLanding = true;
    });
    builder.addCase(
      clearManagerLandingScreenData.fulfilled,
      (state: any, action) => {
        state.managerTimesheet = [];
      }
    );
    builder.addCase(getDownloadManagerTimesheet.pending, (state) => {
      state.loadingLandingDownloadExport = true;
      state.errorLanding = false;
    });
    builder.addCase(
      getDownloadManagerTimesheet.fulfilled,
      (state: any, action) => {
        state.loadingLandingDownloadExport = false;
        state.ManagerExportTimesheet = action?.payload;
      }
    );
    builder.addCase(
      getDownloadManagerTimesheet.rejected,
      (state: any, action) => {
        state.loadingLandingDownloadExport = false;
        state.errorLanding = true;
      }
    );
    builder.addCase(postExportManagerTimesheet.pending, (state) => {
      state.loadingLandingDownloadExportPost = true;
      state.errorLanding = false;
    });
    builder.addCase(
      postExportManagerTimesheet.fulfilled,
      (state: any, action) => {
        state.loadingLandingDownloadExportPost = false;
        state.ExportManagerReportTimesheet = action?.payload;
      }
    );
    builder.addCase(
      postExportManagerTimesheet.rejected,
      (state: any, action) => {
        state.loadingLandingDownloadExportPost = false;
        state.errorLanding = true;
      }
    );
    builder.addCase(getAdminExportData.pending, (state) => {
      state.loadingLanding = true;
      state.errorLanding = false;
    });
    builder.addCase(getAdminExportData.fulfilled, (state: any, action) => {
      state.loadingLanding = false;
      state.AdminExportDownloadData = action?.payload;
    });
    builder.addCase(getAdminExportData.rejected, (state: any, action) => {
      state.loadingLanding = false;
      state.errorLanding = true;
    });
    builder.addCase(postAdminExportData.pending, (state) => {
      state.loadingLanding = true;
      state.errorLanding = false;
    });
    builder.addCase(postAdminExportData.fulfilled, (state: any, action) => {
      state.loadingLanding = false;
      state.AdminExportDataProfile = action?.payload;
    });
    builder.addCase(postAdminExportData.rejected, (state: any, action) => {
      state.loadingLanding = false;
      state.errorLanding = true;
    });
    builder.addCase(postEmployeeTimesheetData.pending, (state) => {
      state.loadingLanding = true;
      state.errorPostLanding = false;
    });
    builder.addCase(
      postEmployeeTimesheetData.fulfilled,
      (state: any, action) => {
        state.loadingLanding = false;
      }
    );
    builder.addCase(
      postEmployeeTimesheetData.rejected,
      (state: any, action) => {
        state.loadingLanding = false;
        state.errorPostLanding = true;
      }
    );
    builder.addCase(getAdminFinanceLanding.pending, (state) => {
      state.loadingAdminFinanceLanding = true;
      state.errorAdminFinanceLanding = false;
    });
    builder.addCase(getAdminFinanceLanding.fulfilled, (state: any, action) => {
      state.loadingAdminFinanceLanding = false;
      state.adminFinanceLanding = action?.payload;
    });
    builder.addCase(getAdminFinanceLanding.rejected, (state: any, action) => {
      state.loadingAdminFinanceLanding = false;
      state.errorAdminFinanceLanding = true;
    });

    builder.addCase(adminPersonaEmployeePreview.pending, (state) => {
      state.adminEmployeePreviewDataLoading = true;
      state.errorLanding = false;
    });
    builder.addCase(
      adminPersonaEmployeePreview.fulfilled,
      (state: any, action) => {
        state.adminEmployeePreviewDataLoading = false;
        state.adminEmployeePreviewData = action?.payload;
      }
    );
    builder.addCase(
      adminPersonaEmployeePreview.rejected,
      (state: any, action) => {
        state.adminEmployeePreviewDataLoading = false;
        state.errorLanding = true;
      }
    );

    builder.addCase(adminPersonaEmployeeTimesheets.pending, (state) => {
      state.adminPersonaEmployeeTimesheetsLoading = true;
      state.errorLanding = false;
    });
    builder.addCase(
      adminPersonaEmployeeTimesheets.fulfilled,
      (state: any, action) => {
        state.adminPersonaEmployeeTimesheetsLoading = false;
        state.adminPersonaEmployeeTimesheetsData = action?.payload;
      }
    );
    builder.addCase(
      adminPersonaEmployeeTimesheets.rejected,
      (state: any, action) => {
        state.adminPersonaEmployeeTimesheetsLoading = false;
        state.errorLanding = true;
      }
    );

    builder.addCase(adminPersonaDownloadData.pending, (state) => {
      state.adminPersonaDownloadDataLoading = true;
      state.errorDownloadLanding = false;
    });
    builder.addCase(
      adminPersonaDownloadData.fulfilled,
      (state: any, action) => {
        state.adminPersonaDownloadDataLoading = false;
      }
    );
    builder.addCase(adminPersonaDownloadData.rejected, (state: any, action) => {
      state.adminPersonaDownloadDataLoading = false;
      state.errorDownloadLanding = true;
    });
  },
});

export default detail.reducer;
export const {
  payloadAction,
  updateWeekendStatus,
  notificationAction,
  timesheetAddRowAction,
  timesheetRemoveRowAction,
  timesheetUpdateRowAction,
  setTimesheetClone,
  setSelectedWeekStart,
  setSelectedWeekEnd,
  setCloneSelectedWeek,
  setDisableCloneTimesheetBtn,
} = detail.actions;
