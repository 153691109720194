import { Button } from "antd";

function FillButton(props: any) {
  const { isHtmlType = false } = props;
  return (
    <>
      {isHtmlType ? (
        <Button
          disabled={props.disabled}
          className={props.fillBtnStyles}
          type="primary"
          htmlType="submit"
          id={props.fillBtnId}
        >
          {props.fillBtnTxt}
        </Button>
      ) : (
        <Button
          disabled={props.disabled}
          className={props.fillBtnStyles}
          type="primary"
          onClick={props.fillBtnAction}
          id={props.fillBtnId}
          data-testid={props.fillBtnId}
        >
          {props.fillBtnTxt}
        </Button>
      )}
    </>
  );
}

export default FillButton;
