import { useEffect } from "react";
import "./index.scss";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import {
  ACTIVITIES,
  INNOVATION,
  INNOVATION_TITLE,
  MYWORK_URL,
  MY_ACTIVITIES,
  MY_ACTIVITIES_INNOVATION,
  START,
} from "../../../constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  getMyActivityChallenges,
  getMyActivityIdeas,
} from "../../../redux/actions/innovation";
import { ChallengesCard, IdeasCard, NoDataFound } from "../Components";

const InnovationMyActivities = (props: any) => {
  const currentTab = window.location.pathname.split("/").at(-1);
  const items = [
    {
      title: (
        <Link
          to={`/${START}/${MYWORK_URL}/${INNOVATION}`}
          className="link-highlight"
        >
          {INNOVATION_TITLE}
        </Link>
      ),
    },

    {
      title: MY_ACTIVITIES,
    },
  ];
  const dispatch = useAppDispatch();
  const {
    challengesLoading,
    challenges,
    challengesFailure,
    ideasLoading,
    ideas,
    ideasFailure,
  } = useAppSelector((state: any) => state?.innovation);
  useEffect(() => {
    if (currentTab === ACTIVITIES[0].toLowerCase())
      dispatch(getMyActivityIdeas({}));
    else dispatch(getMyActivityChallenges({}));
  }, [currentTab]);
  const navigate = useNavigate();

  const Ideas = () => {
    return ideasFailure?.error ? (
      <div>{ideasFailure?.error?.message}</div>
    ) : ideas.length ? (
      ideas.map((item: any) => (
        <IdeasCard ideaItem={item} loading={ideasLoading} />
      ))
    ) : (
      <NoDataFound message={"No ideas found"} />
    );
  };
  const Challenges = () => {
    return challengesFailure?.error ? (
      <div>{challengesFailure?.error?.message}</div>
    ) : challenges.length ? (
      challenges.map((item: any) => (
        <ChallengesCard challengeItem={item} loading={challengesLoading} />
      ))
    ) : (
      <div>no data</div>
    );
  };
  return (
    <div id="my-activities-page" className="my-activities-wrapper">
      <BreadcrumbComponent items={items} />
      <div className="space-between">
        <div className="dex-title">{MY_ACTIVITIES}</div>
        <div>
          <div></div>
          <div></div>
          <div className="capsule">
            <div
              className={`cursor-pointer sm-text normal-text gray800 tab left-tab ${
                currentTab === ACTIVITIES[0].toLowerCase()
                  ? "tab-active"
                  : "left-tab"
              }`}
              onClick={() => {
                console.log("click");
                navigate(
                  `/${START}/${MYWORK_URL}/${INNOVATION}/${MY_ACTIVITIES_INNOVATION}/${ACTIVITIES[0].toLowerCase()}`
                );
              }}
            >
              {ACTIVITIES[0]}
            </div>
            <div
              className={`cursor-pointer sm-text normal-text gray800  tab right-tab ${
                currentTab === ACTIVITIES[1].toLowerCase()
                  ? "tab-active"
                  : "right-tab"
              }`}
              onClick={() => {
                navigate(
                  `/${START}/${MYWORK_URL}/${INNOVATION}/${MY_ACTIVITIES_INNOVATION}/${ACTIVITIES[1].toLowerCase()}`
                );
              }}
            >
              {ACTIVITIES[1]}
            </div>
          </div>
        </div>
      </div>
      <div className="body-section">
        {currentTab === ACTIVITIES[0].toLowerCase() ? (
          <Ideas />
        ) : (
          <Challenges />
        )}
      </div>
    </div>
  );
};

export default InnovationMyActivities;
