import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Growth from "../../themes/assets/images/png.imgs/growth.png";
import Deal from "../../themes/assets/images/png.imgs/deal.png";
import Medal from "../../themes/assets/images/png.imgs/medal.png";
import dayjs from "dayjs";
import {
  getUserPerformanceStaticData,
  quarterlyCheckinDataEmployee,
  postQuarterlyCheckinCommentEmployee,
  getGoalTemplate,
  saveGoal,
  getEmployeeListData,
  getEmployeeGoalListData,
  getAllGoalTemplate,
  deleteGoal,
  saveGoalComment,
  submitGoal,
  postCasecadeGoalCommentManager,
  cascadeToEmployeeList,
  archiveGoal,
  getAllArchiveGoals,
  deleteArchiveGoal,
  getRestoreArchiveGoal,
  getQuarterlyCheckinComments,
  saveQuarterlyCheckinComments,
  getContributePublicGoalList,
  getContributeViewGoal,
  getAspFormEmployeeDetail,
  getAspFormEmployeeGoalDetails,
  getAspFormEmployeeGoalSignature,
  getAspFormSteps,
  saveAspFormDetails,
  getEmployeeDataManagerView,
  ViewGoalAction,
  getSmartGameTemplate,
  getSubmittedGoals,
  getDropDownYears,
  AnnualYear,
  getEmployeeOverviewDetails,
  submitYearEndReview,
  viewManagerComment,
  getOverallFormRating,
  performanceToPotentialManager,
  submitP2P,
  getPendingReivewsManager,
  postAspFormEmployeeGoalSign,
  postAspFormeGoalSign,
  getReporteeSignatureAndOverAllForm,
  getEmployeeSignatureAndOverAllForm,
  postOverallFormRating,
  getEmployeePreviousGoals,
  getCopyGoalPlanYears,
  copyGoalsToCurrentYear,
} from "../actions/performance";

import _, { groupBy } from "lodash";
import {
  COPY_GOAL_TOAST_DES,
  DRAFT,
  FAILURE,
  MILESTONE,
  MY_SPACE,
  STATUS_READ,
  SUCCESS,
} from "../../constants";
import {
  formObject,
  goalFormObject,
  notificationGoal,
} from "../../components/utils/util";

import { postCascadeToEmployeeList } from "../actions/performance";
import {
  getBehaviouralEmployeeDetails,
  saveBehaviouralEmployeeDetails,
} from "../actions/performance";
import { act } from "@testing-library/react";

type performanceData = {
  toastTitle: string;
  toastMessage: string;
  toastIcon: string;
  showToast: boolean;
  years: Array<Number>;
  quarterlyCheckInEmployeeData: any;
  loadingStaticData: boolean;
  errorLoadingStaticData: boolean; //block the whole view
  loadingTabData: boolean;
  errorTabData: boolean; //block only one tab option
  minorLoading: boolean;
  minorError: boolean; //show a error notification
  minorSuccess: boolean;
  isVisible: boolean; //toastmessage view
  isDrafted: boolean;
  goalTemplateWebData: any;
  goalTemplateMilestoneData: any;
  goalTemplateLoading: boolean;
  goalTemplateError: boolean;
  saveGoalWebData: any;
  saveGoalWebMilestoneData: any;
  saveGoalLoading: boolean;
  saveGoalError: boolean;
  webMilestones: any;
  webGoal: any;
  webMilestoneQuestionList: any;
  goalValues: any;
  milestones: any;
  sectionValues: any;
  milestonequestionDTO: any;
  mandatekeysForQuestions: any;
  getEmployeeData: any;
  getEmployeeDataLoading: boolean;
  getEmployeeDataError: boolean;
  getEmployeeGoalData: any;
  getEmployeeGoalDataLoading: boolean;
  getEmployeeGoalDataError: boolean;
  getEmployeeGoalDetails: any;
  getEmployeeGoalDetailsLoading: boolean;
  getEmployeeGoalDetailsError: boolean;
  allGoalTemplateLoading: boolean;
  allGoalTemplateError: boolean;
  allGoalTemplateData: any;
  goalId: any;
  goalTemplateData: any;
  shouldGetGoals: boolean;
  deleteGoalStatus: string;
  deleteGoalMessage: string;
  deleteGoalGenericMessage: string;
  milestoneFormat: any;
  saveGoalCommentLoading: boolean;
  saveGoalCommentError: boolean;
  saveGoalCommentData: any;
  loadingSubmitGoal: boolean;
  errorloadingSubmitGoal: boolean;
  submittedGoalData: any;
  totalGoals: number;
  goalData: any;
  draftGoalExists: boolean;
  actionNotification: boolean;
  casecadeGoalCommentManager: any;
  casecadeGoalLoading: boolean;
  casecadeGoalError: boolean;
  cascadeToEmployeeDetailList: any;
  casecadeGoalEmployeeLoading: boolean;
  casecadeGoalEmployeeError: boolean;
  archiveGoalLoading: boolean;
  archiveGoalError: boolean;
  allArchiveGoalsLoading: boolean;
  allArchiveGoalsError: boolean;
  allArchiveGoals: any;
  totalArchiveGoals: number;
  //deleteArchiveGoalLoading: boolean;
  deleteArchiveGoalError: boolean;
  deleteArchiveGoal: any;
  shouldGetArchiveGoals: boolean;
  cascadeGoal: any;
  newGoalValues: any;
  restoreArchiveGoalsLoading: boolean;
  resoreArchiveGoalsError: boolean;
  restoreArchiveGoals: any;
  isErrorToast: boolean;
  allQuarterlyCheckinCommentsLoading: boolean;
  allQuarterlyCheckinCommentsError: boolean;
  allQuarterlyCheckinComments: any;
  saveQuarterlyCheckinCommentsLoading: boolean;
  saveQuarterlyCheckinCommentsError: boolean;
  saveQuarterlyCheckin: any;
  saveQuarterlyCheckinCommentsSuccess: boolean;
  toCallMilestoneSave: boolean;
  saveMilestonePayload: {};
  contributePublicGoalLoading: boolean;
  contributePublicGoalError: boolean;
  contributePublicGoalList: any;
  contributeViewGoalLoading: boolean;
  contributeViewGoalError: boolean;
  contributeViewGoalList: any;
  categories: any;
  goalObject: any;
  goalObjectForView: any;
  sectionList: any;
  milestoneForm: any;
  goalForm: any;
  pageId: any;
  milestoneSectionList: any;
  isLoading: boolean;
  configuration: any;
  getAspFormEmployeeView: any;
  getAspFormEmployeeViewLoading: boolean;
  getAspFormEmployeeViewError: boolean;
  getAspFormEmployeeGoalList: any;
  getAspFormEmployeeGoalListLoading: boolean;
  getAspFormEmployeeGoalListError: boolean;
  getAspFormEmployeeGoalSignature: any;
  getAspFormEmployeeGoalSignatureError: boolean;
  postAspFormEmployeeGoalSignError: boolean;
  postAspFormEmployeeGoalSign: any;
  getAspFormStepsList: any;
  getAspFormStepsLoading: boolean;
  getAspFormStepsError: boolean;
  postAspFormCommentData: {};
  postAspFormCommentDataLoading: boolean;
  postAspFormCommentDataError: boolean;
  employeeDataManagerView: any;
  goalPayload: any;
  milestonePayload: any;
  viewGoal: any;
  year: string;
  landing: any;
  isReload: any;
  managerSubmittedGoals: any;
  teamGoalActiveYear: any;
  dropDownYears: any;
  isPreview: any;
  smartGameLoading: boolean;
  smartGameError: boolean;
  smartGame: any;
  postCascadedList: {};
  postCascadedListError: false;
  yearDropdown: any;
  getBehaviouralFormEmployeeList: any;
  getBehaviouralFormEmployeeError: boolean;
  postBehaviouralFormCommentData: {};
  postBehaviouralCommentDataError: boolean;
  employeeOverviewDetails: any;
  isEditCmnt: boolean;
  viewASPData: any;
  viewGoalRawData: any;
  submitYearEndStep: any;
  managerGetDetails: any;
  managerOverallFormRating: any;
  performanceToPotentialData: any;
  loadingP2P: boolean;
  p2psubmissionData: any;
  overallFormRatingData: any;
  overallFormRatingLoading: boolean;
  overallFormRatingError: boolean;
  viewCascade: boolean;
  submitLoading: boolean;
  loadingASPform: boolean;
  overallFormRatingManagerLoading: Boolean;
  overallFormRatingManagerError: Boolean;
  postAspFormEmployeeGoalSignLoading: Boolean;
  submitOverallForm: boolean;
  previousGoals: Array<Object>;
  copyGoalPlanYear: any;
  isLoadingCopyGoal: boolean;
  totalCopyGoalsWeight: any;
  isLoadingCopyGoals: boolean;
};

const initialDetailState: performanceData = {
  toastTitle: "",
  toastMessage: "",
  toastIcon: "",
  showToast: false,
  years: [],
  quarterlyCheckInEmployeeData: {},
  loadingStaticData: false,
  errorLoadingStaticData: false,
  loadingTabData: false,
  errorTabData: false,
  minorError: false,
  minorLoading: false,
  minorSuccess: false,
  isVisible: false,
  isDrafted: false,
  goalTemplateWebData: [],
  goalTemplateMilestoneData: [],
  goalTemplateLoading: false,
  goalTemplateError: false,
  saveGoalWebData: {},
  saveGoalWebMilestoneData: {},
  saveGoalLoading: false,
  saveGoalError: false,
  archiveGoalLoading: false,
  archiveGoalError: false,
  webMilestones: [],
  webGoal: [],
  webMilestoneQuestionList: {},
  goalValues: {},
  milestones: [],
  sectionValues: [],
  milestonequestionDTO: {},
  mandatekeysForQuestions: {},
  getEmployeeData: [],
  getEmployeeDataLoading: false,
  getEmployeeDataError: false,
  getEmployeeGoalData: [],
  getEmployeeGoalDataLoading: false,
  getEmployeeGoalDataError: false,
  getEmployeeGoalDetails: [],
  getEmployeeGoalDetailsLoading: false,
  getEmployeeGoalDetailsError: false,
  allGoalTemplateLoading: true,
  allGoalTemplateError: false,
  allGoalTemplateData: [],
  goalTemplateData: [],
  goalId: "",
  shouldGetGoals: true,
  deleteGoalStatus: "",
  deleteGoalMessage: "",
  deleteGoalGenericMessage: "",
  milestoneFormat: { order: 1 },
  saveGoalCommentLoading: false,
  saveGoalCommentError: false,
  saveGoalCommentData: null,
  loadingSubmitGoal: true,
  errorloadingSubmitGoal: false,
  submittedGoalData: null,
  totalGoals: 0,
  goalData: {},
  draftGoalExists: false,
  actionNotification: false,
  casecadeGoalCommentManager: {},
  casecadeGoalLoading: false,
  casecadeGoalError: false,
  cascadeToEmployeeDetailList: {},
  casecadeGoalEmployeeLoading: false,
  casecadeGoalEmployeeError: false,
  //deleteArchiveGoalLoading: true,
  deleteArchiveGoalError: false,
  deleteArchiveGoal: [],
  allArchiveGoalsLoading: true,
  allArchiveGoalsError: false,
  allArchiveGoals: [],
  totalArchiveGoals: 0,
  shouldGetArchiveGoals: true,
  cascadeGoal: {},
  newGoalValues: {},
  restoreArchiveGoalsLoading: false,
  resoreArchiveGoalsError: false,
  restoreArchiveGoals: [],
  isErrorToast: false,
  allQuarterlyCheckinCommentsLoading: false,
  allQuarterlyCheckinCommentsError: false,
  allQuarterlyCheckinComments: {},
  saveQuarterlyCheckinCommentsLoading: false,
  saveQuarterlyCheckinCommentsError: false,
  saveQuarterlyCheckin: {},
  saveQuarterlyCheckinCommentsSuccess: false,
  toCallMilestoneSave: false,
  saveMilestonePayload: {},
  contributePublicGoalList: {},
  contributePublicGoalLoading: false,
  contributePublicGoalError: false,
  contributeViewGoalList: {},
  contributeViewGoalLoading: false,
  contributeViewGoalError: false,
  categories: [],
  sectionList: [],
  milestoneForm: [],
  goalForm: {},
  pageId: [],
  milestoneSectionList: [],
  goalObject: {},
  goalObjectForView: {},
  isLoading: false,
  configuration: {
    isShowNotification: false,
    isModal: false,
    isEnabled: true,
    alertMessage: { type: "warning", message: "", additionalInfo: "" },
    loading: false,
    error: false,
  },
  getAspFormEmployeeView: {},
  getAspFormEmployeeViewLoading: false,
  getAspFormEmployeeViewError: false,
  getAspFormEmployeeGoalList: [],
  getAspFormEmployeeGoalListLoading: false,
  getAspFormEmployeeGoalListError: false,
  getAspFormEmployeeGoalSignature: {},
  getAspFormEmployeeGoalSignatureError: false,
  postAspFormEmployeeGoalSign: {},
  postAspFormEmployeeGoalSignError: false,
  getAspFormStepsList: [],
  getAspFormStepsLoading: false,
  getAspFormStepsError: false,
  postAspFormCommentData: {},
  postAspFormCommentDataLoading: false,
  postAspFormCommentDataError: false,
  employeeDataManagerView: {},
  goalPayload: {},
  milestonePayload: {},
  viewGoal: {},
  year: "",
  landing: {},
  isReload: true,
  managerSubmittedGoals: {},
  teamGoalActiveYear: new Date().getFullYear(),
  dropDownYears: [],
  isPreview: true,
  smartGameLoading: false,
  smartGameError: false,
  smartGame: {},
  postCascadedList: {},
  postCascadedListError: false,
  yearDropdown: {},
  getBehaviouralFormEmployeeList: {},
  getBehaviouralFormEmployeeError: false,
  postBehaviouralFormCommentData: {},
  postBehaviouralCommentDataError: false,
  employeeOverviewDetails: {},
  isEditCmnt: false,
  viewASPData: {},
  viewGoalRawData: {},
  submitYearEndStep: {},
  managerGetDetails: {},
  managerOverallFormRating: {},
  performanceToPotentialData: {},
  loadingP2P: false,
  p2psubmissionData: false,
  overallFormRatingData: {},
  overallFormRatingLoading: false,
  overallFormRatingError: false,
  viewCascade: false,
  submitLoading: false,
  overallFormRatingManagerLoading: false,
  overallFormRatingManagerError: false,
  loadingASPform: false,
  postAspFormEmployeeGoalSignLoading: false,
  submitOverallForm: false,
  previousGoals: [],
  copyGoalPlanYear: {},
  isLoadingCopyGoal: false,
  totalCopyGoalsWeight: "",
  isLoadingCopyGoals: false,
};

export const performance: any = createSlice({
  name: "performance",
  initialState: initialDetailState,
  reducers: {
    viewState: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    setEditComment: (state, action: PayloadAction<boolean>) => {
      state.isEditCmnt = action.payload;
    },
    resetState: (state) => {
      state.isVisible = false;
      state.isDrafted = false;
    },
    draftState: (state, action: PayloadAction<boolean>) => {
      state.isDrafted = action.payload;
    },
    setWebMilestones: (state, action: PayloadAction<number>) => {
      state.webMilestones = action.payload;
    },
    setWebGoal: (state, action) => {
      state.webGoal = action.payload;
    },
    setGoalData: (state, action) => {
      state.goalData = action.payload;
    },
    setWebMilestoneQuestionList: (state, action) => {
      state.webMilestoneQuestionList = action.payload;
    },
    setMilestoneFormat: (state, action) => {
      state.milestoneFormat = action.payload;
    },
    setGoalValues: (state, action) => {
      state.goalValues = action.payload;
    },
    setCascadedGoal: (state, action) => {
      state.cascadeGoal = action.payload || {};
    },
    setMilestones: (state, action) => {
      state.milestones = action.payload;
    },
    setShouldGetGoals: (state, action) => {
      state.shouldGetGoals = action.payload;
    },
    setMilestoneQuestionDTO: (state, action) => {
      state.milestonequestionDTO = action.payload;
    },
    resetGoalWebMilestones: (state) => {
      state.saveGoalWebMilestoneData = {};
    },
    setGoalWebMilestones: (state, action) => {
      state.saveGoalWebMilestoneData = action.payload || {};
    },
    setSectionValues: (state, action) => {
      state.sectionValues = action.payload;
    },
    setMandatekeys: (state, action) => {
      state.mandatekeysForQuestions = action.payload;
    },
    setNewGoalValues: (state, action) => {
      state.newGoalValues = action.payload || {};
    },
    setToastContainer: (state, action) => {
      const {
        showToast = false,
        toastMessage = "",
        toastTitle = "",
        isErrorToast = false,
      } = action.payload || {};
      state.showToast = showToast;
      state.toastMessage = toastMessage;
      state.toastTitle = toastTitle;
      state.isErrorToast = isErrorToast;
    },
    setGoalId: (state, action) => {
      state.goalId = action.payload || "";
    },
    resetAllGoalMilestones: (state) => {
      state.goalId = "";
      state.mandatekeysForQuestions = {};
      state.saveGoalWebMilestoneData = {};
      state.milestones = [];
      state.sectionValues = {};
      state.webMilestones = [];
      state.allGoalTemplateData = [];
      state.saveGoalWebData = {};
      state.goalTemplateMilestoneData = [];
      state.goalTemplateData = [];
      state.draftGoalExists = false;
      state.cascadeGoal = {};
    },
    clearDeleteGoalStatus: (state) => {
      state.deleteGoalStatus = "";
      state.deleteGoalMessage = "";
      state.deleteGoalGenericMessage = "";
    },
    setSaveGoalWebData: (state, action) => {
      state.saveGoalWebData = action.payload;
    },
    setShouldGetArchiveGoals: (state, action) => {
      state.shouldGetArchiveGoals = action.payload;
    },
    setToCallMilestoneSave: (state, action) => {
      state.toCallMilestoneSave = action.payload || true;
    },
    setSaveMilestonePayload: (state, action) => {
      state.saveMilestonePayload = action.payload || {};
    },
    resetSubmittedGoalData: (state, action) => {
      state.submittedGoalData = action.payload || {};
    },
    notificationAction: (state, action) => {
      const { isShowNotification, message, type, additionalInfo } =
        action.payload;
      state.configuration.isShowNotification = isShowNotification;
      state.configuration.alertMessage = { type, message, additionalInfo };
    },
    updateGoal: (state, action) => {
      state.goalForm = action.payload;
    },
    mileStoneForm: (state, action) => {
      const { value, index } = action.payload;
      const mileForm = state.milestoneForm;
      mileForm[index]["MILESTONE_QUESTION_2"] = value;
      state.milestoneForm = mileForm;
    },
    updateMilestoneForm: (state, action) => {
      //state.milestoneForm = action.payload;
      //state.milestoneForm = action.payload;
      const payload = action.payload;
      const g = payload?.map((item: any) => {
        let q1 = item.MILESTONE_QUESTION_1;
        let q2 = item.MILESTONE_QUESTION_2
          ? dayjs(item.MILESTONE_QUESTION_2, "DD/MM/YYYY")
          : null;
        let q3 = item.MILESTONE_QUESTION_3
          ? dayjs(item.MILESTONE_QUESTION_3, "DD/MM/YYYY")
          : null;
        return {
          MILESTONE_QUESTION_1: q1,
          MILESTONE_QUESTION_2: q2,
          MILESTONE_QUESTION_3: q3,
        };
      });
      state.milestoneForm = g;
    },
    updateMilestone: (state, action) => {
      const {
        milestone,
        milestoneSectionList,
        viewmilestone,
        milestonePayload,
        isReload,
      } = action.payload;
      // state.milestoneSectionList = milestoneSectionList;
      const sectionList = state.sectionList;
      const formattedSectionList = sectionList?.map((section: any) => {
        const questionList = section.questionList?.map((question: any) => {
          const questionId = question.questionDTO.questionId;
          if (questionId === "template_web_goal_v1_Q9") {
            return {
              ...question,
              questionDTO: { ...question.questionDTO, answer: viewmilestone },
            };
          } else {
            return { ...question, questionDTO: { ...question.questionDTO } };
          }
        });
        return { ...section, questionList: questionList };
      });
      state.sectionList = formattedSectionList;
      state.milestoneForm = milestone;
      state.milestonePayload = milestonePayload;
      state.isReload = isReload;
      state.isPreview = false;
    },
    reloadAction: (state, action) => {
      const { isReload } = action.payload;
      state.isReload = isReload;
    },
    previewAction: (state, action) => {
      const { formattedSectionList, goalPayload, viewGoal } = action.payload;
      state.goalPayload = goalPayload;
      state.viewGoal = viewGoal;
    },
    milestonePayload: (state, action) => {},
    updateYear: (state, action) => {
      state.year = action.payload;
    },
    resetGoal: (state, action) => {
      /* this section for local state updation for feature */
      /*const abc = state.sectionList
      if (abc.length > 0) {
        const formattedSectionList = abc?.map((section: any) => {
          const questionList = section.questionList?.map((question: any) => {
            const { questionDTO: { answer } } = question
            return { ...question, questionDTO: { ...question.questionDTO, answer: answer } }
          })
          return { ...section, questionList: questionList }
        })
        state.sectionList = formattedSectionList
        state.goalForm = goalFormObject(formattedSectionList)
      }*/
      state.sectionList = [];
    },
    setActiveTeamGoalYear: (state, action) => {
      state.teamGoalActiveYear = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPerformanceStaticData.pending, (state) => {
      state.loadingStaticData = true;
      state.errorLoadingStaticData = false;
    });
    builder.addCase(
      getUserPerformanceStaticData.fulfilled,
      (state: any, action) => {
        state.loadingStaticData = false;
        state.years = action?.payload?.performanceYears;
      }
    );
    builder.addCase(
      getUserPerformanceStaticData.rejected,
      (state: any, action) => {
        state.loadingStaticData = false;
        state.errorLoadingStaticData = true;
      }
    );
    builder.addCase(submitGoal.pending, (state) => {
      // state.loadingSubmitGoal = true;
      // state.errorloadingSubmitGoal = false;
      state.isLoading = true;
    });
    builder.addCase(submitGoal.fulfilled, (state: any, action) => {
      // state.loadingSubmitGoal = false;
      // state.submittedGoalData = action?.payload;
      const {
        genericMessage = "",
        status = "",
        message = "",
        goalId,
      } = action.payload || {};
      state.isLoading = false;
      // state.allGoalTemplateData = [];
      // state.allGoalTemplateLoading = false;
      // state.isLoading = false;
      // state.allGoalTemplateError = false;
      // state.deleteGoalStatus = status || "";
      // state.deleteGoalMessage = message || "";
      // state.deleteGoalGenericMessage = genericMessage || "";
      // state.showToast = status || "";
      // state.toastMessage = message || "";
      // state.toastTitle = genericMessage || "";
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: status === SUCCESS ? SUCCESS : FAILURE,
        message: genericMessage ? genericMessage : message,
      };
    });
    builder.addCase(submitGoal.rejected, (state: any, action) => {
      // state.loadingSubmitGoal = false;
      // state.errorloadingSubmitGoal = true;
      state.isLoading = false;
    });
    builder.addCase(quarterlyCheckinDataEmployee.pending, (state) => {
      state.isLoading = true;
      // state.errorTabData = false;
      // state.minorError = false;
      // state.minorSuccess = false;
    });
    builder.addCase(
      quarterlyCheckinDataEmployee.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        state.quarterlyCheckInEmployeeData = action?.payload;
      }
    );
    builder.addCase(
      quarterlyCheckinDataEmployee.rejected,
      (state: any, action) => {
        state.loadingTabData = false;
        state.errorTabData = true;
      }
    );
    builder.addCase(saveGoalComment.fulfilled, (state: any, action) => {
      const {
        result,
        index,
        payload,
        category,
        message = "",
      } = action?.payload || {};
      const categoryStr = category?.toLowerCase();
      const { comment = "" } = payload;
      const commentInfo =
        state?.getEmployeeFilteredData[categoryStr][index]?.commentInfo;

      state.getEmployeeFilteredData[categoryStr][index].commentInfo = {
        ...commentInfo,
        comment: comment,
      };

      state.saveGoalCommentData = result || [];
      state.saveGoalCommentLoading = false;
      state.saveGoalCommentError = false;
      state.shouldGetGoals = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: result?.status,
        message: result?.genericMessage ? result?.genericMessage : message,
      };
    });

    builder.addCase(saveGoalComment.rejected, (state: any, action) => {
      state.saveGoalCommentData = [];
      state.saveGoalCommentLoading = false;
      state.saveGoalCommentError = true;
    });
    builder.addCase(saveGoalComment.pending, (state) => {
      state.saveGoalCommentLoading = true;
      state.saveGoalCommentError = false;
    });
    builder.addCase(deleteGoal.pending, (state) => {
      state.allGoalTemplateLoading = true;
      state.isLoading = true;
      state.allGoalTemplateError = false;
      state.shouldGetGoals = false;
    });
    builder.addCase(deleteGoal.fulfilled, (state: any, action) => {
      state.shouldGetGoals = true;
      const {
        genericMessage = "",
        status = "",
        message = "",
        goalId,
        additionalInfo,
      } = action.payload || {};
      state.isLoading = false;
      // state.allGoalTemplateData = [];
      // state.allGoalTemplateLoading = false;
      // state.isLoading = false;
      // state.allGoalTemplateError = false;
      // state.deleteGoalStatus = status || "";
      // state.deleteGoalMessage = message || "";
      // state.deleteGoalGenericMessage = genericMessage || "";
      // state.showToast = status || "";
      // state.toastMessage = message || "";
      // state.toastTitle = genericMessage || "";
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: status,
        message: genericMessage ? genericMessage : message,
        additionalInfo: additionalInfo,
      };

      const filterGoal = state?.allGoalTemplateData?.filter((item: any) => {
        return item.goalId !== null
          ? item.goalId !== parseInt(goalId)
          : item.sfGoalId !== parseInt(goalId);
      });
      const { draftGoalExists, actionNotification } =
        notificationGoal(filterGoal);
      state.draftGoalExists = draftGoalExists;
      state.actionNotification = actionNotification;
      const categories = groupBy(filterGoal, "category");
      state.categories = categories;
      state.allGoalTemplateData = filterGoal;
    });
    builder.addCase(deleteGoal.rejected, (state: any, action) => {
      state.allGoalTemplateData = [];
      state.allGoalTemplateLoading = false;
      state.allGoalTemplateError = true;
      state.shouldGetGoals = false;
      state.isLoading = false;
    });
    builder.addCase(getAllGoalTemplate.pending, (state) => {
      //state.allGoalTemplateLoading = true;
      state.isLoading = true;
      state.allGoalTemplateError = false;
      state.categories = [];
    });
    builder.addCase(getAllGoalTemplate.fulfilled, (state: any, action) => {
      const { result } = action?.payload || {};
      const { goalInfo, landingPageThreeDots, webGoalTabInfo, submitButton } =
        result;
      const { years } = webGoalTabInfo;
      const yearsMap = years.map((item: any) => ({
        label: item.toString(),
        key: item.toString(),
      }));

      state.allGoalTemplateData = goalInfo || [];
      state.totalGoals = result?.goalInfo?.length || 0;
      state.totalWeight = result?.totalWeight;
      // state.allGoalTemplateLoading = false;
      state.isLoading = false;
      state.allGoalTemplateError = false;
      //state.shouldGetGoals = false;
      const { draftGoalExists, actionNotification } =
        notificationGoal(goalInfo);
      state.draftGoalExists = draftGoalExists;
      state.actionNotification = actionNotification;
      /*rowKey Expanded Start*/
      const formattedGoalInfo = goalInfo?.map((item: any) => ({
        ...item,
        rowId: item?.goalId || item?.sfGoalId,
      }));
      const categories = groupBy(formattedGoalInfo, "category");
      const updateCascadeGoal = formattedGoalInfo?.length
        ? formattedGoalInfo.find((item) => item?.cascadeOpen)
        : {};
      /*rowKey Expanded End*/
      state.categories = categories;
      state.cascadeGoal = updateCascadeGoal;
      //console.log(updateCascadeGoal);
      state.landing = {
        landingPageThreeDots,
        webGoalTabInfo: { ...webGoalTabInfo, years: yearsMap },
      };
      //console.log({ landingPageThreeDots, webGoalTabInfo: { ...webGoalTabInfo, years: yearsMap } })
    });
    builder.addCase(getAllGoalTemplate.rejected, (state: any, action) => {
      state.allGoalTemplateData = [];
      //state.allGoalTemplateLoading = false;
      state.isLoading = false;
      state.allGoalTemplateError = true;
    });

    builder.addCase(getAllArchiveGoals.pending, (state) => {
      // state.allArchiveGoalsLoading = true;
      state.isLoading = true;
      state.allArchiveGoalsError = false;
    });
    builder.addCase(getAllArchiveGoals.fulfilled, (state: any, action) => {
      const { result } = action?.payload || {};
      state.allArchiveGoals = result || [];
      state.allGoalTemplateData = result || [];
      state.totalArchiveGoals = result?.length || 0;
      // state.allArchiveGoalsLoading = false;
      state.isLoading = false;
      state.allArchiveGoalsError = false;
      state.shouldGetArchiveGoals = false;
    });
    builder.addCase(getAllArchiveGoals.rejected, (state: any, action) => {
      state.allArchiveGoals = [];
      // state.allArchiveGoalsLoading = false;
      state.isLoading = false;
      state.allArchiveGoalsError = true;
    });
    builder.addCase(deleteArchiveGoal.pending, (state) => {
      //state.deleteArchiveGoalLoading = true;
      state.isLoading = true;
      state.deleteArchiveGoalError = false;
    });
    builder.addCase(deleteArchiveGoal.fulfilled, (state: any, action) => {
      const {
        genericMessage = "",
        status = "",
        message = "",
        goalId,
      } = action.payload || {};
      // state.showToast = status || "";
      // state.toastMessage = message || "";
      // state.toastTitle = genericMessage || "";
      //state.deleteArchiveGoalLoading = false;
      state.isLoading = false;
      state.deleteArchiveGoalError = false;
      state.shouldGetArchiveGoals = true;

      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: status,
        message: genericMessage ? genericMessage : message,
      };

      const filterGoal = state?.allGoalTemplateData?.filter((item: any) => {
        return item.goalId !== null
          ? item.goalId !== parseInt(goalId)
          : item.sfGoalId !== parseInt(goalId);
      });
      const exists = filterGoal?.filter((item) => item?.state === DRAFT);
      state.draftGoalExists = exists.length > 0 ? true : false;
      // const exists = filterGoal?.filter(
      //   (item) => item?.state === DRAFT
      // );
      // state.draftGoalExists = exists.length > 0?true:false;
      const categories = groupBy(filterGoal, "category");
      state.categories = categories;
      //state.allGoalTemplateData = { ...state.allGoalTemplateData, goalInfo: filterGoal }
      state.allGoalTemplateData = filterGoal;
    });
    builder.addCase(deleteArchiveGoal.rejected, (state: any, action) => {
      //state.deleteArchiveGoalLoading = false;
      state.isLoading = false;
      state.deleteArchiveGoalError = true;
    });

    builder.addCase(archiveGoal.pending, (state) => {
      // state.archiveGoalLoading = true;
      state.isLoading = true;
      state.archiveGoalError = false;
    });
    builder.addCase(archiveGoal.fulfilled, (state: any, action) => {
      const {
        genericMessage = "",
        status = "",
        message = "",
        goalId,
      } = action.payload || {};
      // state.showToast = status || "";
      // state.toastMessage = message || "";
      // state.toastTitle = genericMessage || "";
      // state.archiveGoalLoading = false;
      state.isLoading = false;
      state.archiveGoalError = false;
      state.shouldGetGoals = true;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: status,
        message: genericMessage ? genericMessage : message,
      };
      const parseId = goalId.includes("-") ? goalId.split("-")[1] : goalId;
      /*start this is for remove goal from landing screen */
      const filterGoal = state?.allGoalTemplateData?.filter((item: any) => {
        return item.goalId !== null
          ? item.goalId !== parseInt(parseId)
          : item.sfGoalId !== parseInt(parseId);
      });
      const { draftGoalExists, actionNotification } =
        notificationGoal(filterGoal);
      state.draftGoalExists = draftGoalExists;
      state.actionNotification = actionNotification;
      const categories = groupBy(filterGoal, "category");
      state.categories = categories;
      //state.allGoalTemplateData = { ...state.allGoalTemplateData, goalInfo: filterGoal }
      state.allGoalTemplateData = filterGoal;
      /*end this is for remove goal from landing screen */
    });
    builder.addCase(archiveGoal.rejected, (state: any, action) => {
      // state.archiveGoalLoading = false;
      state.isLoading = false;
      state.archiveGoalError = true;
    });
    builder.addCase(getGoalTemplate.pending, (state) => {
      state.goalTemplateLoading = true;
      state.goalTemplateError = false;
    });
    builder.addCase(getGoalTemplate.fulfilled, (state: any, action) => {
      const { result, questionnaireCategory, isEdit, isPublic } =
        action?.payload || {};
      if (result?.pageList) {
        if (questionnaireCategory === "WEB_MILESTONE") {
          state.goalTemplateMilestoneData = result || [];
          const { pageList } = result;
          const [singleRecord] = pageList;
          const { sectionList = [], pageId } = singleRecord;
          state.milestoneSectionList = isEdit
            ? sectionList.length > 1
              ? [sectionList?.[0]]
              : sectionList
            : sectionList;
          const {
            listMilestone: milestoneForm,
            listViewMilestone,
            listMilestoneStatus,
            isPreview,
          } = formObject(sectionList);
          state.milestoneForm = milestoneForm;
          state.pageId = pageId;
          if (isEdit) {
            const goalSectionList = state.sectionList;
            const formattedSectionList = goalSectionList?.map(
              (section: any) => {
                const milestoneStatusDetails = listMilestoneStatus;
                const questionList = section.questionList?.map(
                  (question: any) => {
                    const description = question.questionDTO.description;
                    let answer = question.questionDTO.answer;
                    if (description === "GOAL_QUESTION_10") {
                      //answer = isPublic ? [] : isPreview ? [] : listViewMilestone;
                      answer = isPreview ? [] : listViewMilestone;
                    }
                    return {
                      ...question,
                      questionDTO: {
                        ...question.questionDTO,
                        answer: answer,
                        milestoneStatusDetails,
                      },
                    };
                  }
                );
                return {
                  ...section,
                  questionList: questionList,
                  milestoneStatusDetails,
                };
              }
            );
            state.sectionList = formattedSectionList;
            state.milestones = milestoneForm;
            // state.isPreview = isPublic ? true : isPreview
            state.isPreview = isPreview;
            //state.goalForm.template_web_goal_v1_Q9 = milestoneForm
          } else {
            state.isPreview = true;
          }
        } else {
          state.goalTemplateData = result || [];
          const { pageList } = result;
          const [singleRecord] = pageList;
          const { sectionList, pageId } = singleRecord;
          state.sectionList = sectionList;

          //const { goalObject } = goalFormObject(sectionList)
          state.goalForm = goalFormObject(sectionList);
          state.pageId = pageId;
        }
      } else {
        state.sectionList = [];
      }
      state.goalTemplateLoading = false;
      state.goalTemplateError = false;
    });
    builder.addCase(getGoalTemplate.rejected, (state: any, action) => {
      state.goalTemplateData = [];
      state.goalTemplateMilestoneData = [];
      state.goalTemplateLoading = false;
      state.goalTemplateError = true;
    });
    builder.addCase(saveGoal.pending, (state) => {
      state.isLoading = true;
      state.saveGoalError = false;
    });
    builder.addCase(saveGoal.fulfilled, (state: any, action) => {
      const { result, questionnaireCategory, status } = action?.payload || {};
      const {
        message,
        genericMessage,
        additionalInfo,
        status: resultStatus,
      } = result || {};
      if (questionnaireCategory === "WEB_MILESTONE") {
        state.saveGoalWebMilestoneData = result || {};
        state.toCallMilestoneSave = false;
        state.milestonePayload = {};
      } else {
        state.saveGoalWebData = { ...result, saved: true } || {};
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: status === DRAFT ? "info" : resultStatus,
          message: genericMessage ? genericMessage : message,
          additionalInfo: additionalInfo,
        };
        if (status !== DRAFT) {
          state.sectionList = [];
        }
        state.goalPayload = {};
      }
      // state.showToast = true;
      // state.toastMessage = message || "";
      // state.toastTitle = genericMessage || "";
      // state.saveGoalLoading = false;
      // state.saveGoalError = false;
      state.isLoading = false;
    });
    builder.addCase(saveGoal.rejected, (state: any, action) => {
      state.saveGoalWebData = {};
      state.saveGoalWebMilestoneData = {};
      state.saveGoalLoading = false;
      state.saveGoalError = true;
    });
    builder.addCase(postQuarterlyCheckinCommentEmployee.pending, (state) => {
      state.isLoading = true;
      // state.minorError = false;
      // state.minorSuccess = false;
    });
    builder.addCase(
      postQuarterlyCheckinCommentEmployee.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        // state.minorSuccess = true;
        const {
          genericMessage = "",
          status = "",
          message = "",
        } = action.payload || {};
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: status,
          message: genericMessage ? genericMessage : message,
        };
        //do some manupulation and change the employee comment in state.quarterlyCheckInEmployeeData
      }
    );
    builder.addCase(
      postQuarterlyCheckinCommentEmployee.rejected,
      (state: any, action) => {
        state.isLoading = false;
        // state.minorError = true;
      }
    );
    builder.addCase(getRestoreArchiveGoal.pending, (state, action) => {
      state.isLoading = true;
      state.resoreArchiveGoalsError = false;
    });
    builder.addCase(getRestoreArchiveGoal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.restoreArchiveGoals = action?.payload;
      const {
        genericMessage = "",
        status = "",
        message = "",
        goalId,
      } = action.payload || {};
      // state.showToast = status || "";
      // state.toastMessage = message || "";
      // state.toastTitle = genericMessage || "";
      // state.resoreArchiveGoalsError = false;
      // state.shouldGetArchiveGoals = true;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: status,
        message: genericMessage ? genericMessage : message,
      };

      let goalIds = goalId.split(",");
      goalIds = goalIds.map((item: any) => parseInt(item.trim()));
      const filterGoal = state?.allGoalTemplateData?.filter((item: any) => {
        return item.goalId !== null
          ? goalIds.indexOf(item.goalId) === -1
          : goalIds.indexOf(item.sfGoalId) === -1;
      });
      state.allGoalTemplateData = filterGoal;
    });
    builder.addCase(getRestoreArchiveGoal.rejected, (state, action) => {
      state.isLoading = false;
      state.resoreArchiveGoalsError = true;
    });
    builder.addCase(postCasecadeGoalCommentManager.pending, (state) => {
      state.casecadeGoalLoading = true;
      state.casecadeGoalError = false;
    });
    builder.addCase(
      postCasecadeGoalCommentManager.fulfilled,
      (state: any, action) => {
        state.casecadeGoalLoading = false;
        const goalInfo = action?.payload?.goalInfo || [];
        const sfGoalId = goalInfo.filter(
          (item: any) => item?.sfGoalId !== null
        );
        if (sfGoalId) {
          const business = sfGoalId.filter(
            (item: any) => item?.category === "Business"
          );
          const technology = sfGoalId.filter(
            (item: any) => item?.category === "Technology"
          );
          const people = sfGoalId.filter(
            (item: any) => item?.category === "People"
          );
          state.casecadeGoalCommentManager = { business, technology, people };
        }
      }
    );
    builder.addCase(
      postCasecadeGoalCommentManager.rejected,
      (state: any, action) => {
        state.casecadeGoalLoading = false;
        state.casecadeGoalError = true;
      }
    );

    builder.addCase(cascadeToEmployeeList.pending, (state) => {
      state.casecadeGoalEmployeeLoading = true;
      state.casecadeGoalEmployeeError = false;
    });
    builder.addCase(cascadeToEmployeeList.fulfilled, (state: any, action) => {
      state.casecadeGoalEmployeeLoading = false;
      state.casecadeToEmployeeDetailList = action?.payload;
    });
    builder.addCase(cascadeToEmployeeList.rejected, (state: any, action) => {
      state.casecadeGoalEmployeeLoading = false;
      state.casecadeGoalEmployeeError = true;
    });
    builder.addCase(getEmployeeListData.pending, (state: any, action) => {
      state.isLoading = true;
      state.getEmployeeDataError = false;
    });
    builder.addCase(getEmployeeListData.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.getEmployeeData = action?.payload;
      state.getEmployeeDataError = false;
    });
    builder.addCase(getEmployeeListData.rejected, (state: any, action) => {
      state.isLoading = false;
      state.getEmployeeDataError = true;
    });
    builder.addCase(AnnualYear.pending, (state: any, action) => {
      state.isLoading = true;
      state.configuration.error = false;
    });
    builder.addCase(AnnualYear.fulfilled, (state: any, action) => {
      state.isLoading = false;
      const { years = [], currentYear } = action?.payload;
      const yearsMap = years.map((item: any) => ({
        label: item.toString(),
        key: item.toString(),
      }));

      state.yearDropdown = { years: yearsMap, currentYear };
    });
    builder.addCase(AnnualYear.rejected, (state: any, action) => {
      state.configuration.error = true;
    });
    builder.addCase(getEmployeeGoalListData.pending, (state: any, action) => {
      state.isLoading = true;
      state.getEmployeeFilteredDataError = false;
    });
    builder.addCase(getEmployeeGoalListData.fulfilled, (state: any, action) => {
      state.isLoading = false;
      const goalInfo = action?.payload?.goalSettingPage?.goalDetails || [];
      const goalSettingInfo = action?.payload?.goalSettingPage || [];
      const reporteeInfo = action?.payload?.reporteeInfo || [];
      const business = goalInfo.filter(
        (item: any) => item?.category === "Business"
      );
      const technology = goalInfo.filter(
        (item: any) => item?.category === "Technology"
      );
      const people = goalInfo.filter(
        (item: any) => item?.category === "People"
      );
      state.getEmployeeFilteredData = {
        business,
        technology,
        people,
        reporteeInfo,
        goalSettingInfo,
      };
      state.getEmployeeFilteredDataError = false;
    });
    builder.addCase(getEmployeeGoalListData.rejected, (state: any, action) => {
      state.isLoading = false;
      state.getEmployeeFilteredDataError = true;
    });
    builder.addCase(
      getContributePublicGoalList.pending,
      (state: any, action) => {
        state.isLoading = true;
        state.contributePublicGoalError = false;
      }
    );
    builder.addCase(
      getContributePublicGoalList.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        state.contributePublicGoalList = action?.payload;
        state.contributePublicGoalError = false;
      }
    );
    builder.addCase(
      getContributePublicGoalList.rejected,
      (state: any, action) => {
        state.isLoading = false;
        state.contributePublicGoalError = true;
      }
    );
    builder.addCase(getContributeViewGoal.pending, (state: any, action) => {
      state.isLoading = true;
      state.contributeViewGoalError = false;
    });
    builder.addCase(getContributeViewGoal.fulfilled, (state: any, action) => {
      state.isLoading = false;
      const { result } = action?.payload || {};
      state.contributeViewGoalError = false;
      const { pageList } = result;
      const [singleRecord] = pageList;
      const { sectionList = [] } = singleRecord;
      state.contributeViewGoalList = { sectionList };
      let viewGoalQuestion: any = [];
      let goalObject: any = {};
      let goalObjectForView: any = {};
      sectionList?.forEach((section: any) => {
        section.questionList?.forEach((question: any) => {
          let answer = question.questionDTO.answer;
          // let view_answer = question.questionDTO.answer;
          let obj_question_template = question?.questionDTO?.questionId;
          let obj_question = question?.questionDTO?.question;
          if (
            obj_question_template === "template_web_goal_v1_Q7" ||
            obj_question_template === "template_web_goal_v1_Q8"
          ) {
            answer = dayjs(answer, "DD/MM/YYYY");
            goalObjectForView[obj_question_template] = answer;
          } else {
            goalObjectForView[obj_question] = answer;
          }
          goalObject[obj_question_template] = answer;
        });
      });
      const { template_web_goal_v1_Q7, template_web_goal_v1_Q8 } = goalObject;
      const date = `${dayjs(template_web_goal_v1_Q7).format(
        "DD/MM/YYYY"
      )} - ${dayjs(template_web_goal_v1_Q8).format("DD/MM/YYYY")}`;
      let newobj = _.omit(goalObjectForView, [
        "template_web_goal_v1_Q7",
        "template_web_goal_v1_Q8",
      ]);
      newobj = { ...newobj, ["Start Date - End Date"]: date };
      state.goalObject = goalObject;
      state.goalObjectForView = newobj;
      state.sectionList = sectionList;
    });
    builder.addCase(getContributeViewGoal.rejected, (state: any, action) => {
      state.isLoading = false;
      state.contributeViewGoalError = true;
    });
    builder.addCase(ViewGoalAction.pending, (state: any, action) => {
      state.isLoading = true;
    });
    builder.addCase(ViewGoalAction.fulfilled, (state: any, action) => {
      state.isLoading = false;
      const { result } = action?.payload || {};
      const cascadeStatus = result.cascadeOpen;
      state.viewCascade = cascadeStatus;
      state.viewGoal = result;
    });
    builder.addCase(ViewGoalAction.rejected, (state: any, action) => {
      state.isLoading = false;
    });
    builder.addCase(
      getQuarterlyCheckinComments.pending,
      (state: any, action) => {
        state.allQuarterlyCheckinCommentsLoading = true;
        state.allQuarterlyCheckinCommentsError = false;
        state.saveQuarterlyCheckinCommentsLoading = false;
        state.saveQuarterlyCheckinCommentsError = false;
      }
    );
    builder.addCase(
      getQuarterlyCheckinComments.fulfilled,
      (state: any, action) => {
        state.allQuarterlyCheckinCommentsLoading = false;
        state.allQuarterlyCheckinComments = action?.payload;
        state.allQuarterlyCheckinCommentsError = false;
      }
    );
    builder.addCase(
      getQuarterlyCheckinComments.rejected,
      (state: any, action) => {
        state.allQuarterlyCheckinCommentsLoading = false;
        state.allQuarterlyCheckinCommentsError = true;
      }
    );
    builder.addCase(
      saveQuarterlyCheckinComments.pending,
      (state: any, action) => {
        state.saveQuarterlyCheckinCommentsLoading = true;
        state.saveQuarterlyCheckinCommentsError = false;
        state.saveQuarterlyCheckinCommentsSuccess = false;
      }
    );
    builder.addCase(
      saveQuarterlyCheckinComments.fulfilled,
      (state: any, action) => {
        state.saveQuarterlyCheckinCommentsLoading = false;
        state.saveQuarterlyCheckinCommentsSuccess = true;
        state.allQuarterlyCheckinComments = action?.payload;
        state.saveQuarterlyCheckinCommentsError = false;
      }
    );
    builder.addCase(
      saveQuarterlyCheckinComments.rejected,
      (state: any, action) => {
        state.saveQuarterlyCheckinCommentsLoading = false;
        state.saveQuarterlyCheckinCommentsError = true;
      }
    );
    builder.addCase(getAspFormEmployeeDetail.pending, (state: any, action) => {
      state.loadingASPform = true;
      state.getAspFormEmployeeViewError = false;
    });
    builder.addCase(
      getAspFormEmployeeDetail.fulfilled,
      (state: any, action) => {
        state.loadingASPform = false;
        state.getAspFormEmployeeView = action?.payload;
        state.getAspFormEmployeeViewError = false;
      }
    );
    builder.addCase(getAspFormEmployeeDetail.rejected, (state: any, action) => {
      state.loadingASPform = false;
      state.getAspFormEmployeeViewError = true;
    });
    builder.addCase(
      getAspFormEmployeeGoalDetails.pending,
      (state: any, action) => {
        state.getAspFormEmployeeGoalListLoading = true;
        state.getAspFormEmployeeGoalListError = false;
      }
    );
    builder.addCase(
      getAspFormEmployeeGoalDetails.fulfilled,
      (state: any, action) => {
        state.getAspFormEmployeeGoalListLoading = false;
        state.viewGoalRawData = action?.payload;
        const goalInfo = action?.payload?.goalDetails || [];
        const userId = action?.payload?.userId || [];
        const { totalWeight } = action?.payload;
        const business = goalInfo.filter(
          (item: any) => item?.category === "Business"
        );
        const technology = goalInfo.filter(
          (item: any) => item?.category === "Technology"
        );
        const people = goalInfo.filter(
          (item: any) => item?.category === "People"
        );
        let overallAssessmentInfo =
          action?.payload?.overAllSelfAssessment?.assessmentComment || {};
        let overallOtherAssessmentInfo =
          action?.payload?.overAllOtherAssessment || {};
        let { userInfo, reporteeInfo, progressBarInfo, ratingStarConfig } =
          action?.payload || {};
        const businessFormatted = business.map((item: any) => ({
          goalId: item?.goalId,
          title: item.selfAssessment.title,
          commentTitle: item.selfAssessment.assessmentComment.title,
          rating:
            item?.selfAssessment?.rateScale?.rating !== "0"
              ? item?.selfAssessment?.rateScale?.rating
              : null,
          comment: item?.selfAssessment?.assessmentComment?.comment,
          goalName: item?.goalName,
          start_date: item?.startDate,
          metric: item?.metric,
          end_date: item?.endDate,
          weight: item?.weight,
          goalDescription: item?.goalDescription,
          category: item?.category,
          goalStatus: item?.goalStatus,
          otherAssessment: item?.otherAssessment,
          year: item?.year,
          mandatoryComment:
            item?.selfAssessment?.assessmentComment?.isMandatory,
          mandatoryRating: item?.selfAssessment?.rateScale?.isMandatory,
          ratingTitle: item?.selfAssessment?.rateScale?.title,
          cascadedBy: item?.cascadedBy || null,
          ownerName: item?.ownerName || null,
        }));
        const technologyFormatted = technology.map((item: any) => ({
          goalId: item?.goalId,
          title: item.selfAssessment.title,
          commentTitle: item.selfAssessment.assessmentComment.title,
          rating:
            item?.selfAssessment?.rateScale?.rating !== "0"
              ? item?.selfAssessment?.rateScale?.rating
              : null,
          comment: item?.selfAssessment?.assessmentComment?.comment,
          goalName: item?.goalName,
          start_date: item?.startDate,
          metric: item?.metric,
          end_date: item?.endDate,
          weight: item?.weight,
          goalDescription: item?.goalDescription,
          category: item?.category,
          goalStatus: item?.goalStatus,
          otherAssessment: item?.otherAssessment,
          year: item?.year,
          mandatoryComment:
            item?.selfAssessment?.assessmentComment?.isMandatory,
          mandatoryRating: item?.selfAssessment?.rateScale?.isMandatory,
          ratingTitle: item?.selfAssessment?.rateScale?.title,
          cascadedBy: item?.cascadedBy || null,
          ownerName: item?.ownerName || null,
        }));
        const peopleFormatted = people.map((item: any) => ({
          goalId: item?.goalId,
          title: item.selfAssessment.title,
          commentTitle: item.selfAssessment.assessmentComment.title,
          rating:
            item?.selfAssessment?.rateScale?.rating !== "0"
              ? item?.selfAssessment?.rateScale?.rating
              : null,
          comment: item?.selfAssessment?.assessmentComment?.comment,
          goalName: item?.goalName,
          start_date: item?.startDate,
          metric: item?.metric,
          end_date: item?.endDate,
          weight: item?.weight,
          goalDescription: item?.goalDescription,
          category: item?.category,
          goalStatus: item?.goalStatus,
          otherAssessment: item?.otherAssessment,
          year: item?.year,
          mandatoryComment:
            item?.selfAssessment?.assessmentComment?.isMandatory,
          mandatoryRating: item?.selfAssessment?.rateScale?.isMandatory,
          ratingTitle: item?.selfAssessment?.rateScale?.title,
          cascadedBy: item?.cascadedBy || null,
          ownerName: item?.ownerName || null,
        }));
        const consolidatedCategory = [
          ...businessFormatted,
          ...technologyFormatted,
          ...peopleFormatted,
        ];

        state.getAspFormEmployeeGoalList = {
          consolidatedCategory,
          overallAssessmentInfo,
          userId,
          overallOtherAssessmentInfo,
          progressBarInfo,
          reporteeInfo,
          userInfo,
          ratingStarConfig,
          totalWeight,
        };
        state.getAspFormEmployeeGoalListError = false;
      }
    );
    builder.addCase(
      getAspFormEmployeeGoalDetails.rejected,
      (state: any, action) => {
        state.getAspFormEmployeeGoalListLoading = false;
        state.getAspFormEmployeeGoalListError = true;
      }
    );

    builder.addCase(
      getAspFormEmployeeGoalSignature.pending,
      (state: any, action) => {
        state.isLoading = true;
        state.getAspFormEmployeeGoalSignatureError = false;
      }
    );
    builder.addCase(
      getAspFormEmployeeGoalSignature.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        state.viewGoalRawData = action?.payload;
        const goalInfo = action?.payload?.goalDetails || [];
        const userId = action?.payload?.userId || [];
        const business = goalInfo.filter(
          (item: any) => item?.category === "Business"
        );
        const technology = goalInfo.filter(
          (item: any) => item?.category === "Technology"
        );
        const people = goalInfo.filter(
          (item: any) => item?.category === "People"
        );
        let overallAssessmentInfo =
          action?.payload?.overAllSelfAssessment?.assessmentComment || {};
        let overallOtherAssessmentInfo =
          action?.payload?.overAllOtherAssessment || {};
        let managerInfo = action?.payload?.userInfo || {};
        const businessFormatted = business.map((item: any) => ({
          goalId: item?.goalId,
          title: item.selfAssessment.title,
          commentTitle: item.selfAssessment.assessmentComment.title,
          rating: item?.selfAssessment?.rateScale?.rating,
          comment: item?.selfAssessment?.assessmentComment?.comment,
          goalName: item?.goalName,
          start_date: item?.startDate,
          metric: item?.metric,
          end_date: item?.endDate,
          weight: item?.weight,
          goalDescription: item?.goalDescription,
          category: item?.category,
          goalStatus: item?.goalStatus,
        }));
        const technologyFormatted = technology.map((item: any) => ({
          goalId: item?.goalId,
          title: item.selfAssessment.title,
          commentTitle: item.selfAssessment.assessmentComment.title,
          rating: item?.selfAssessment?.rateScale?.rating,
          comment: item?.selfAssessment?.assessmentComment?.comment,
          goalName: item?.goalName,
          start_date: item?.startDate,
          metric: item?.metric,
          end_date: item?.endDate,
          weight: item?.weight,
          goalDescription: item?.goalDescription,
          category: item?.category,
          goalStatus: item?.goalStatus,
        }));
        const peopleFormatted = people.map((item: any) => ({
          goalId: item?.goalId,
          title: item.selfAssessment.title,
          commentTitle: item.selfAssessment.assessmentComment.title,
          rating: item?.selfAssessment?.rateScale?.rating,
          comment: item?.selfAssessment?.assessmentComment?.comment,
          goalName: item?.goalName,
          start_date: item?.startDate,
          metric: item?.metric,
          end_date: item?.endDate,
          weight: item?.weight,
          goalDescription: item?.goalDescription,
          category: item?.category,
          goalStatus: item?.goalStatus,
        }));
        const consolidatedCategory = [
          ...businessFormatted,
          ...technologyFormatted,
          ...peopleFormatted,
        ];

        state.getAspFormEmployeeGoalList = {
          consolidatedCategory,
          overallAssessmentInfo,
          userId,
          overallOtherAssessmentInfo,
          managerInfo,
        };
        state.viewGoalRawData = {
          ...action?.payload,
          goalInfo: {
            business: business,
            technology: technology,
            people: people,
          },
          reporteeInfo: action?.payload?.webSignatureSection?.managerInfo,
          userInfo: action?.payload?.webSignatureSection?.employeeInfo,
        };
        state.getAspFormEmployeeGoalSignatureError = false;
      }
    );
    builder.addCase(
      getAspFormEmployeeGoalSignature.rejected,
      (state: any, action) => {
        state.isLoading = false;
        state.getAspFormEmployeeGoalSignatureError = true;
      }
    );

    builder.addCase(getAspFormSteps.pending, (state: any, action) => {
      state.getAspFormStepsLoading = true;
      state.getAspFormStepsError = false;
    });
    builder.addCase(getAspFormSteps.fulfilled, (state: any, action) => {
      state.getAspFormStepsLoading = false;

      const stepsPayload = action?.payload || [];
      const formSteps = stepsPayload.map((item: any) => ({
        key: item?.stepName,
        title: item?.stepName,
        subTitle:
          item?.current === true
            ? "In Progress"
            : item?.completionDate
            ? `Completed on ${item?.completionDate}`
            : null,
        current: item?.current,
        status:
          item?.current === true
            ? "process"
            : item?.completionDate
            ? "finish"
            : "wait",
      }));

      const stepsList = [...formSteps];
      state.getAspFormStepsList = {
        stepsList,
      };
      state.getAspFormStepsError = false;
    });
    builder.addCase(getAspFormSteps.rejected, (state: any, action) => {
      state.getAspFormStepsLoading = false;
      state.getAspFormStepsError = true;
    });

    builder.addCase(saveAspFormDetails.pending, (state) => {
      state.isLoading = true;
      state.postAspFormCommentDataError = false;
    });
    builder.addCase(saveAspFormDetails.fulfilled, (state: any, action) => {
      const { result, message, additionalInfo } = action?.payload;
      state.isLoading = false;
      state.postAspFormCommentData = action?.payload?.result;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: result?.status || SUCCESS,
        message: message ? message : "Goals partially saved",
        additionalInfo: additionalInfo,
      };
      state.postAspFormCommentDataError = false;
    });
    builder.addCase(saveAspFormDetails.rejected, (state: any, action) => {
      state.isLoading = false;
      state.postAspFormCommentDataError = true;
    });
    builder.addCase(
      getEmployeeDataManagerView.pending,
      (state: any, action) => {
        state.isLoading = true;
        state.configuration.error = false;
      }
    );
    builder.addCase(
      getEmployeeDataManagerView.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        state.employeeDataManagerView = action?.payload;
      }
    );
    builder.addCase(
      getEmployeeDataManagerView.rejected,
      (state: any, action) => {
        state.isLoading = false;
        state.configuration.error = true;
      }
    );
    builder.addCase(getSubmittedGoals.pending, (state) => {
      state.isLoading = true;
      state.configuration.error = false;
    });
    builder.addCase(getSubmittedGoals.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.managerSubmittedGoals = action?.payload;
    });
    builder.addCase(getSubmittedGoals.rejected, (state: any, action) => {
      state.isLoading = false;
      state.configuration.error = true;
    });
    builder.addCase(getDropDownYears.pending, (state) => {
      state.isLoading = true;
      state.configuration.error = false;
    });
    builder.addCase(getDropDownYears.fulfilled, (state: any, action) => {
      state.isLoading = false;
      state.dropDownYears = action?.payload?.years?.map((item: any) => ({
        key: item,
        label: item,
      }));
    });
    builder.addCase(getDropDownYears.rejected, (state: any, action) => {
      state.isLoading = false;
      state.configuration.error = true;
    });
    builder.addCase(postCascadeToEmployeeList.pending, (state) => {
      state.isLoading = true;
      state.postCascadedListError = false;
    });
    builder.addCase(
      postCascadeToEmployeeList.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        state.postCascadedList = action?.payload;
        const { message, genericMessage, additionalInfo, status } =
          action?.payload || {};
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: status,
          message: genericMessage ? genericMessage : message,
          additionalInfo: additionalInfo,
        };
        state.postCascadedListError = false;
      }
    );
    builder.addCase(
      postCascadeToEmployeeList.rejected,
      (state: any, action) => {
        state.isLoading = false;
        state.postCascadedListError = true;
      }
    );
    builder.addCase(getBehaviouralEmployeeDetails.pending, (state) => {
      state.isLoading = true;
      state.getBehaviouralFormEmployeeError = false;
    });
    builder.addCase(
      getBehaviouralEmployeeDetails.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        state.viewASPData = action?.payload;
        const managerDetails = action?.payload;
        const evaluationList = action?.payload?.evaluations || [];
        const effectiveCommunication = evaluationList.filter(
          (item: any) => item?.name === "Effective communication"
        );
        const selfDevelopment = evaluationList.filter(
          (item: any) => item?.name === "Self Development"
        );
        const teamWork = evaluationList.filter(
          (item: any) => item?.name === "Team Work & Collaboration"
        );
        const effectiveCommunicationFormatted = effectiveCommunication.map(
          (item: any) => ({
            evaluationName: item?.name,
            evaluationDescription: item?.description,
            ratingTitle: item?.overAllSelfAssessment?.rateScale?.title,
            rating:
              item?.overAllSelfAssessment?.rateScale?.rating !== "0"
                ? item?.overAllSelfAssessment?.rateScale?.rating
                : null,
            ratingIsMandatory:
              item?.overAllSelfAssessment?.rateScale?.isMandatory,
            commentTitle: item?.overAllSelfAssessment?.assessmentComment?.title,
            comment: item?.overAllSelfAssessment?.assessmentComment?.comment,
            commentIsMandatory:
              item?.overAllSelfAssessment?.assessmentComment?.isMandatory,
            editable: item?.overAllSelfAssessment?.editable,
            goalId: item?.goalId,
            employeeComment:
              item?.overAllOtherAssessment?.assessmentComment?.comment,
            employeeRating:
              item?.overAllOtherAssessment?.rateScale?.rating !== "0"
                ? item?.overAllOtherAssessment?.rateScale?.rating
                : null,
          })
        );
        const selfDevelopmentFormatted = selfDevelopment.map((item: any) => ({
          evaluationName: item?.name,
          evaluationDescription: item?.description,
          ratingTitle: item?.overAllSelfAssessment?.rateScale?.title,
          rating:
            item?.overAllSelfAssessment?.rateScale?.rating !== "0"
              ? item?.overAllSelfAssessment?.rateScale?.rating
              : null,
          ratingIsMandatory:
            item?.overAllSelfAssessment?.rateScale?.isMandatory,
          commentTitle: item?.overAllSelfAssessment?.assessmentComment?.title,
          comment: item?.overAllSelfAssessment?.assessmentComment?.comment,
          commentIsMandatory:
            item?.overAllSelfAssessment?.assessmentComment?.isMandatory,
          editable: item?.overAllSelfAssessment?.editable,
          goalId: item?.goalId,
          employeeComment:
            item?.overAllOtherAssessment?.assessmentComment?.comment,
          employeeRating:
            item?.overAllOtherAssessment?.rateScale?.rating !== "0"
              ? item?.overAllOtherAssessment?.rateScale?.rating
              : null,
        }));
        const teamWorkFormatted = teamWork.map((item: any) => ({
          evaluationName: item?.name,
          evaluationDescription: item?.description,
          ratingTitle: item?.overAllSelfAssessment?.rateScale?.title,
          rating:
            item?.overAllSelfAssessment?.rateScale?.rating !== "0"
              ? item?.overAllSelfAssessment?.rateScale?.rating
              : null,
          ratingIsMandatory:
            item?.overAllSelfAssessment?.rateScale?.isMandatory,
          commentTitle: item?.overAllSelfAssessment?.assessmentComment?.title,
          comment: item?.overAllSelfAssessment?.assessmentComment?.comment,
          commentIsMandatory:
            item?.overAllSelfAssessment?.assessmentComment?.isMandatory,
          editable: item?.overAllSelfAssessment?.editable,
          goalId: item?.goalId,
          employeeComment:
            item?.overAllOtherAssessment?.assessmentComment?.comment,
          employeeRating:
            item?.overAllOtherAssessment?.rateScale?.rating !== "0"
              ? item?.overAllOtherAssessment?.rateScale?.rating
              : null,
        }));
        const consolidatedEvaluation = [
          ...effectiveCommunicationFormatted,
          ...selfDevelopmentFormatted,
          ...teamWorkFormatted,
        ];
        state.getBehaviouralFormEmployeeList = consolidatedEvaluation;
        state.managerGetDetails = managerDetails;
        state.getBehaviouralFormEmployeeError = false;
      }
    );
    builder.addCase(
      getBehaviouralEmployeeDetails.rejected,
      (state: any, action) => {
        state.isLoading = false;
        state.getBehaviouralFormEmployeeError = true;
      }
    );
    builder.addCase(saveBehaviouralEmployeeDetails.pending, (state) => {
      state.isLoading = true;
      state.postBehaviouralCommentDataError = false;
    });
    builder.addCase(
      saveBehaviouralEmployeeDetails.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        const { result, message, additionalInfo } = action?.payload;
        state.postBehaviouralFormCommentData = action?.payload?.result;
        const { genericMessage = "", status } = action?.payload?.result || {};
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: status || SUCCESS,
          message: message ? message : genericMessage,
          additionalInfo: additionalInfo,
        };
        state.postBehaviouralCommentDataError = false;
      }
    );
    builder.addCase(
      saveBehaviouralEmployeeDetails.rejected,
      (state: any, action) => {
        state.isLoading = false;
        state.postBehaviouralCommentDataError = true;
      }
    );
    //smart game
    builder.addCase(getSmartGameTemplate.pending, (state: any, action) => {
      state.smartGameLoading = true;
      state.smartGameError = false;
    });
    builder.addCase(getSmartGameTemplate.fulfilled, (state: any, action) => {
      state.smartGameLoading = false;
      state.smartGame = action?.payload;
    });
    builder.addCase(getSmartGameTemplate.rejected, (state: any, action) => {
      state.smartGameLoading = false;
      state.smartGameError = true;
    });

    // get Employeeoverview details
    builder.addCase(getEmployeeOverviewDetails.pending, (state) => {
      state.isLoading = true;
      state.configuration.error = false;
    });
    builder.addCase(
      getEmployeeOverviewDetails.fulfilled,
      (state: any, action) => {
        const {
          archiveGoal = false,
          enableContributeGoal = false,
          enableSmartGoal = false,
        } = action?.payload;
        state.employeeOverviewDetails = action.payload;
        const moreInformation: any = [];
        if (archiveGoal) {
          moreInformation.push({
            title: "Archive Goals",
            image: Medal,
            content:
              "Add and manage your achievements and link them to your goals",
            deeplink: `/web/${MY_SPACE}/performance/archived`,
          });
        }
        if (enableContributeGoal) {
          moreInformation.push({
            title: "Contribute Goals",
            image: Deal,
            content:
              "Find a challenging goal for yourself that elevates the organization",
            deeplink: `/web/${MY_SPACE}/performance/ChallengingGoal`,
          });
        }
        if (enableSmartGoal) {
          moreInformation.push({
            title: "SMART Goals",
            image: Growth,
            content: "Read about what separates a good goal from a great goal.",
            deeplink: `/web/${MY_SPACE}/performance/smartgame`,
          });
        }
        state.employeeOverviewDetails.moreInformation = moreInformation || [];
        state.isLoading = false;
      }
    );
    builder.addCase(
      getEmployeeOverviewDetails.rejected,
      (state: any, action) => {
        state.configuration.error = true;
        state.isLoading = false;
      }
    );
    builder.addCase(submitYearEndReview.pending, (state: any, action) => {
      state.isLoading = true;
      state.configuration.error = false;
      state.configuration.isShowNotification = false;
    });
    builder.addCase(submitYearEndReview.fulfilled, (state: any, action) => {
      const { result } = action?.payload;
      state.isLoading = false;
      state.submitYearEndStep = action?.payload;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: result.status,
        message: result?.genericMessage,
      };
    });
    builder.addCase(submitYearEndReview.rejected, (state: any, action) => {
      state.isLoading = false;
      state.configuration.error = true;
    });
    builder.addCase(
      performanceToPotentialManager.pending,
      (state: any, action) => {
        state.loadingP2P = true;
        state.configuration.error = false;
      }
    );
    builder.addCase(
      performanceToPotentialManager.fulfilled,
      (state: any, action) => {
        state.loadingP2P = false;
        state.performanceToPotentialData = action?.payload;
      }
    );
    builder.addCase(
      performanceToPotentialManager.rejected,
      (state: any, action) => {
        state.loadingP2P = false;
        state.configuration.error = true;
      }
    );
    builder.addCase(submitP2P.pending, (state: any, action) => {
      state.submitLoading = true;
      state.configuration.error = false;
    });
    builder.addCase(submitP2P.fulfilled, (state: any, action) => {
      state.submitLoading = false;
      state.p2psubmissionData = action?.payload;
    });
    builder.addCase(submitP2P.rejected, (state: any, action) => {
      state.submitLoading = false;
      state.configuration.error = true;
    });

    // viewManagerCmnt
    builder.addCase(viewManagerComment.pending, (state: any, action) => {
      state.isLoading = true;
      state.configuration.error = false;
    });
    builder.addCase(viewManagerComment.fulfilled, (state: any, action) => {
      const { result, index, category } = action?.payload || {};
      state.isLoading = false;
      state.commemtViewed = action?.payload;
      state.categories[category][index].commentInfo.status = STATUS_READ;
      state.categories[category][index].commentInfo.status = STATUS_READ;
    });
    builder.addCase(viewManagerComment.rejected, (state: any, action) => {
      state.isLoading = false;
      state.configuration.error = true;
    });
    // overallFormRating
    builder.addCase(getOverallFormRating.pending, (state: any, action) => {
      state.overallFormRatingManagerLoading = true;
      state.configuration.error = false;
    });
    builder.addCase(
      getOverallFormRating.fulfilled,
      (state: any, action: any) => {
        state.overallFormRatingManagerLoading = false;
        state.managerOverallFormRating = action?.payload;

        const goalInfo = action?.payload?.goalDetails || [];
        const userId = action?.payload?.userId || [];
        const business = goalInfo.filter(
          (item: any) => item?.category === "Business"
        );
        const technology = goalInfo.filter(
          (item: any) => item?.category === "Technology"
        );
        const people = goalInfo.filter(
          (item: any) => item?.category === "People"
        );
        state.viewGoalRawData = {
          ...action?.payload,
          goalInfo: {
            business: business,
            technology: technology,
            people: people,
          },
        };
        state.overallFormRatingManagerError = false;
      }
    );
    builder.addCase(getOverallFormRating.rejected, (state: any, action) => {
      state.overallFormRatingManagerLoading = false;
      state.configuration.error = true;
    });
    // get Pending Reviews
    builder.addCase(getPendingReivewsManager?.pending, (state: any, action) => {
      state.isLoading = true;
      state.configuration.error = false;
    });
    builder.addCase(
      getPendingReivewsManager?.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        state.managerSubmittedGoals = action?.payload;
      }
    );
    builder.addCase(
      getPendingReivewsManager?.rejected,
      (state: any, action) => {
        state.isLoading = false;
        state.configuration.error = true;
      }
    );

    builder.addCase(postAspFormeGoalSign.pending, (state: any, action) => {
      state.postAspFormEmployeeGoalSignLoading = true;
      state.postAspFormEmployeeGoalSignError = false;
    });
    builder.addCase(postAspFormeGoalSign.fulfilled, (state: any, action) => {
      const { result } = action.payload;
      state.postAspFormEmployeeGoalSignLoading = false;
      state.postAspFormEmployeeGoalSignError = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: result?.status || SUCCESS,
        message: result?.genericMessage || action?.payload?.result || "",
      };
    });
    builder.addCase(
      postAspFormeGoalSign.rejected,
      (state: any, action: any) => {
        const { result } = action.payload;
        state.postAspFormEmployeeGoalSignLoading = false;
        state.postAspFormEmployeeGoalSignError = true;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: result?.status || FAILURE,
          message: result?.genericMessage || action?.payload || "",
        };
      }
    );

    builder.addCase(
      postAspFormEmployeeGoalSign.pending,
      (state: any, action) => {
        state.isLoading = true;
        state.postAspFormEmployeeGoalSignError = false;
      }
    );
    builder.addCase(
      postAspFormEmployeeGoalSign.fulfilled,
      (state: any, action) => {
        state.isLoading = false;
        state.postAspFormEmployeeGoalSignError = false;
      }
    );
    builder.addCase(
      postAspFormEmployeeGoalSign.rejected,
      (state: any, action) => {
        state.isLoading = false;
        state.postAspFormEmployeeGoalSignError = true;
      }
    );

    // get Reportee SignatureAndForm Info
    builder.addCase(
      getReporteeSignatureAndOverAllForm.pending,
      (state: any, action) => {
        state.overallFormRatingManagerLoading = true;
        state.configuration.error = false;
      }
    );
    builder.addCase(
      getReporteeSignatureAndOverAllForm.fulfilled,
      (state: any, action: any) => {
        const { result } = action?.payload;
        state.viewGoalRawData =
          action?.payload?.overallRating?.managerRatingAndComment;
        const goalInfo =
          action?.payload?.overallRating?.managerRatingAndComment
            ?.goalDetails || [];
        const userId = action?.payload?.userId || [];
        const business = goalInfo.filter(
          (item: any) => item?.category === "Business"
        );
        const technology = goalInfo.filter(
          (item: any) => item?.category === "Technology"
        );
        const people = goalInfo.filter(
          (item: any) => item?.category === "People"
        );
        state.viewGoalRawData = {
          ...action?.payload?.overallRating?.managerRatingAndComment,
          goalInfo: {
            business: business,
            technology: technology,
            people: people,
          },
          webSignatureSection: action?.payload?.webSignatureSection,
          reporteeInfo: action?.payload?.webSignatureSection?.employeeInfo,
          userInfo: action?.payload?.webSignatureSection?.managerInfo,
        };
        state.overallFormRatingManagerError = false;
        state.overallFormRatingManagerLoading = false;
      }
    );
    builder.addCase(
      getReporteeSignatureAndOverAllForm.rejected,
      (state: any, action) => {
        state.overallFormRatingManagerLoading = false;
        state.configuration.error = true;
      }
    );

    builder.addCase(postOverallFormRating.pending, (state: any, action) => {
      state.submitOverallForm = true;
      state.configuration.error = false;
    });
    builder.addCase(
      postOverallFormRating.fulfilled,
      (state: any, action: any) => {
        const { result, message, additionalInfo } = action?.payload;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: result?.status || SUCCESS,
          message: message,
          additionalInfo: additionalInfo,
        };
        state.submitOverallForm = false;
      }
    );
    builder.addCase(postOverallFormRating.rejected, (state: any, action) => {
      state.submitOverallForm = false;
      state.configuration.error = true;
    });
    // GET EmployeeSignature And OverAllForm
    builder.addCase(
      getEmployeeSignatureAndOverAllForm.pending,
      (state: any, action) => {
        state.isLoading = true;
        state.configuration.error = false;
      }
    );
    builder.addCase(
      getEmployeeSignatureAndOverAllForm.fulfilled,
      (state: any, action: any) => {
        const { result } = action?.payload;
        state.viewGoalRawData =
          action?.payload?.overallRating?.managerRatingAndComment;
        const goalInfo =
          action?.payload?.overallRating?.managerRatingAndComment
            ?.goalDetails || [];
        const userId = action?.payload?.userId || [];
        const business = goalInfo.filter(
          (item: any) => item?.category === "Business"
        );
        const technology = goalInfo.filter(
          (item: any) => item?.category === "Technology"
        );
        const people = goalInfo.filter(
          (item: any) => item?.category === "People"
        );
        state.viewGoalRawData = {
          ...action?.payload?.overallRating?.managerRatingAndComment,
          goalInfo: {
            business: business,
            technology: technology,
            people: people,
          },
          webSignatureSection: action?.payload?.webSignatureSection,
          reporteeInfo: action?.payload?.webSignatureSection?.managerInfo,
          userInfo: action?.payload?.webSignatureSection?.employeeInfo,
        };
        state.overallFormRatingManagerError = false;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getEmployeeSignatureAndOverAllForm.rejected,
      (state: any, action) => {
        state.isLoading = false;
        state.configuration.error = true;
      }
    );
    //GET Employee Previous Years Goals
    builder.addCase(getEmployeePreviousGoals.pending, (state: any, action) => {
      state.isLoading = true;
      state.configuration.error = false;
    });
    builder.addCase(
      getEmployeePreviousGoals.fulfilled,
      (state: any, action: any) => {
        state.isLoading = false;

        const goalInfo = action?.payload?.goalDetailsResponseList || [];
        state.totalCopyGoalsWeight = action?.payload?.totalWeight;
        const goalId = goalInfo.filter((item: any) => item?.sfGoalId !== null);
        if (goalId) {
          const business = goalId.filter(
            (item: any) => item?.category === "Business"
          );
          const technology = goalId.filter(
            (item: any) => item?.category === "Technology"
          );
          const people = goalId.filter(
            (item: any) => item?.category === "People"
          );
          state.previousGoals = { business, technology, people };
        }
      }
    );
    builder.addCase(getEmployeePreviousGoals.rejected, (state: any, action) => {
      state.isLoading = false;
      state.configuration.error = true;
    });

    //GET Employee Copy Goal Plan years
    builder.addCase(getCopyGoalPlanYears.pending, (state: any, action) => {
      state.isLoadingCopyGoals = true;
      state.configuration.error = false;
    });
    builder.addCase(
      getCopyGoalPlanYears.fulfilled,
      (state: any, action: any) => {
        state.isLoadingCopyGoals = false;
        state.copyGoalPlanYear = action.payload;
      }
    );
    builder.addCase(getCopyGoalPlanYears.rejected, (state: any, action) => {
      state.isLoadingCopyGoals = false;
      state.configuration.error = true;
    });

    // copy goal to current yeay goal
    builder.addCase(copyGoalsToCurrentYear.pending, (state: any, action) => {
      state.isLoadingCopyGoal = true;
      state.configuration.error = false;
    });
    builder.addCase(
      copyGoalsToCurrentYear.fulfilled,
      (state: any, action: any) => {
        const { result } = action?.payload;
        state.isLoadingCopyGoal = false;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: result?.status || SUCCESS,
          message: result?.genericMessage || action?.payload?.result || "",
          additionalInfo: result?.additionalInfo || COPY_GOAL_TOAST_DES,
        };
      }
    );
    builder.addCase(
      copyGoalsToCurrentYear.rejected,
      (state: any, action: any) => {
        const { result } = action?.payload;
        state.isLoadingCopyGoal = false;
        state.configuration.error = true;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: result?.status || FAILURE,
          message: result?.genericMessage || action?.payload || "",
        };
      }
    );
  },
});

export const {
  viewState,
  resetState,
  draftState,
  setGoalValues,
  setMilestoneQuestionDTO,
  setSectionValues,
  setWebMilestones,
  setWebMilestoneQuestionList,
  setMilestoneFormat,
  setMandatekeys,
  setWebGoal,
  resetGoalWebMilestones,
  setGoalWebMilestones,
  setMilestones,
  setGoalId,
  resetAllGoalMilestones,
  clearDeleteGoalStatus,
  setSaveGoalWebData,
  setShouldGetGoals,
  setGoalData,
  setToastContainer,
  setCascadedGoal,
  setNewGoalValues,
  setShouldGetArchiveGoals,
  setToCallMilestoneSave,
  setSaveMilestonePayload,
  resetSubmittedGoalData,
  notificationAction,
  updateMilestone,
  updateGoal,
  updateMilestoneForm,
  previewAction,
  mileStoneForm,
  resetGoal,
  reloadAction,
  setActiveTeamGoalYear,
  setEditComment,
  managerOverallFormRating,
} = performance.actions;
export default performance.reducer;
