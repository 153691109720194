import React from "react";

const Edit = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill="#0642B5"
        d="M7.026 18.27a.618.618 0 01-.442-.183L2.418 13.92a.629.629 0 010-.883L12.526 2.928a.88.88 0 011.242 0l3.816 3.817a.88.88 0 010 1.241L7.476 18.095a.618.618 0 01-.442.183l-.008-.008zm-3.283-4.792l3.283 3.284 9.4-9.409-3.283-3.283-9.4 9.408z"
      ></path>
      <path
        fill="#0642B5"
        d="M14.525 9.933a.618.618 0 01-.442-.184L10.75 6.416a.629.629 0 010-.883.629.629 0 01.883 0l3.333 3.333a.629.629 0 010 .883.618.618 0 01-.441.184zM3.1 18.268a.864.864 0 01-.617-.258.864.864 0 01-.258-.617v-3.916a.63.63 0 01.625-.625.63.63 0 01.625.625v3.541h3.541a.63.63 0 01.625.625.63.63 0 01-.625.625H3.1z"
      ></path>
    </svg>
  );
};

export default Edit;
