import React, { useState } from "react";
import "./index.scss"
import ReactionCount from "../../../common/reactioncount/reactioncount";
import ReactionsPopover from "../../../common/reactionpopover/reactionsPopover";
import Comment from "../../../common/comment/comment";
import CommentSection from "../../../comments/CommentSection";

const ReactionCard = (props) => {
    const [comment, setComment] = useState("")
    const [isCommentModalOpen, setIsCommentsModalOpen] = useState(false);
    const {
        data,
        reactionCount = 10,
        // onClickCommentsCount,
        commentsCount = 10,
        activeReaction,
        sendHandler,
        commentHandler,
        index,
    } = props;

    const renderComment = (input: any) => {
        if (input === 1) return `${input} Comment`;
        return `${input} Comments`;
    };

    const likesHandler = (item: any, contentId: string) => {

    }

    const changeHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setComment(e.target.value);
    };

    const onClickCommentsCount = () => {
        setIsCommentsModalOpen(true);
    };


    const handleCancel = () => {
        setIsCommentsModalOpen(false);
    };

    return (
        <div className="reaction_card_wrapper">
            <div className="card_foot-cmd">
                <div className="card_reactioncount">
                    <div className="reactions_card_count">
                        <ReactionCount
                            data={data}
                            likesCount={reactionCount}
                        />
                    </div>

                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onClickCommentsCount();
                        }}
                        className="cmd-count"
                    >
                        {renderComment(commentsCount)}
                    </span>
                    {isCommentModalOpen && (
                        <CommentSection
                            index={index}
                            data={data}
                            handleCancel={handleCancel}
                            isModalOpen={isCommentModalOpen}
                        />
                    )}
                </div>
            </div>
            <ul>
                <ReactionsPopover
                    data={data}
                    likes={likesHandler}
                    activeReaction={activeReaction}
                />
                <Comment
                    data={data}
                    value={comment}
                    change={changeHandler}
                    send={sendHandler}
                    comment={commentHandler}
                />
            </ul>
        </div>
    )
}

export default ReactionCard