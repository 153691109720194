import React, { useEffect, useState } from "react";
import "./index.css";
import {
  ALL_OPPORTUNITIES_TITLE,
  APPLIED_OPPORTUNITIES_TITLE,
  FINDING_JOBS,
  MYWORK_URL,
  NO_JOBS_TO_SHOW,
  OPPORTUNITIES,
  PLEASE_UPLOAD_RESUME_JOB_OPPORTUNITIES,
  PRIMARY_SKILLS_TITLE,
  RESUME_BANNER_BUTTON,
  RESUME_BANNER_PARA,
  RESUME_BANNER_TITLE,
  REVIEWING_YOUR_RESUME,
  SECONDARY_SKILLS_TITLE,
  START,
  FAILURE,
  NOTIFICATION_DURATION,
  NOTIFICATION_DURATION_COUNT,
  NOTIFICATION_POSITION,
  SUCCESS,
  UPLOAD_FAILED_TITLE,
  WARNING,
  TRACK_PROGRESS,
  OPPORTUNITY_TYPES,
  RESUME_BANNER_UPDATE,
  APPLIED_OPPORTUNITIES_EMPTY_MESSAGE,
} from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import Location from "../../themes/assets/images/Location.png";
import Job from "../../themes/assets/images/Job.png";
import Time from "../../themes/assets/images/Time.png";
import Exp from "../../themes/assets/images/exp-icon.png";
import NoJobs from "../../themes/assets/images/noJobs.png";
import { Alert, Button, notification, Spin, Steps } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import {
  getJobOpportunitiesList,
  getLandingPageDetials,
} from "../../redux/actions/opportunities";
import {
  landingPagePopUpAction,
  notificationAction,
  saveActiveJob,
} from "../../redux/reducers/opportunities";
import { getTimeAgo, jobStatus } from "../utils/util";
import FillButton from "../common/button/FillButton";
import UploadBannerImage from "../../themes/assets/images/ijp/IJPuploadBanner.png";
import findingJob from "../../themes/assets/images/ijp/findingJobs.png";
import { TabPanel, TabView } from "primereact/tabview";
import {
  GoalsErrorIcon,
  GoalsInfoIcon,
  ToastErrorIcon,
  ToastSuccessIcon,
} from "../common/svg/svg";
import inProgess from "../../themes/assets/images/inProgress.png";
import finish from "../../themes/assets/images/finish.png";
import wait from "../../themes/assets/images/wait.png";
import leftArrow from "../../themes/assets/images/leftArrow.png";
import rightArrow from "../../themes/assets/images/rightArrow.png";
import { PreviewModal } from "./components";
import SuccessSubmit from "../../themes/assets/images/success-submit.png";
import amplitude from "amplitude-js";
import { useSelector } from "react-redux";

export const InfoCard = ({ icon, title, bgColor = "" }) => {
  return (
    <div
      className="info-card"
      style={{ backgroundColor: bgColor ? bgColor : "" }}
    >
      <img src={icon} alt="" className="info-icon" />
      <div className="info-title">{title}</div>
    </div>
  );
};

const OpportunityCard = ({ data, alertInfo, trackStatus, activeIndex }) => {
  const { job_status, icon_infos, action_by: isApplied } = data;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [resumeUploaded, setResumeUploaded] = useState(false);

  const onClickJob = () => {
    const activeIndexString = activeIndex;
    const type = OPPORTUNITY_TYPES[activeIndexString];
    dispatch(saveActiveJob({ ...data, jobType: type }));
    amplitude.getInstance().logEvent("Clicked Job Detail");
    navigate("/web/my_work/opportunities/detail");
  };

  return (
    <div
      onClick={onClickJob}
      className={
        alertInfo ? "opportunity-card disabled-card" : "opportunity-card"
      }
    >
      <div className="info-container">
        <div className="logo-container">
          <img src={data?.account_icon_url} alt="" className="logo-company" />
        </div>
        <div className="job-details">
          <div className="job-title">
            {data?.job_title} ({data?.req_id})
          </div>
          <div className="job-desc">{data?.capability_centre}</div>
          <div className="primary-skills">
            <span>{PRIMARY_SKILLS_TITLE}</span>
            <span>{data?.primary_skills?.join(" , ")}</span>
          </div>
          <div className="primary-skills">
            <span>{SECONDARY_SKILLS_TITLE}</span>
            <span>{data?.secondary_skills?.join(" , ")}</span>
          </div>
        </div>
      </div>
      <div
        className={`info-container info-cards-gap ${
          job_status ? "chip-alignment" : ""
        } ${
          job_status && jobStatus(job_status) === "interview_scheduled"
            ? "chip-alignment-interview"
            : ""
        } `}
      >
        {icon_infos.map((info, index) => (
          <InfoCard key={index} icon={info.icon_url} title={info?.label} />
        ))}
      </div>
      {job_status && (
        <div className={`${jobStatus(job_status)} status`}>
          {data?.job_status}
        </div>
      )}
      {isApplied && <div className="applied_by">{isApplied}</div>}
    </div>
  );
};

const TabInformation = ({
  jobsData,
  resumeUploaded,
  alertInfo,
  trackStatus,
  tabName,
  activeIndex,
}) =>
  jobsData?.length === 0 ? (
    <EmptyData
      emptyDataImage={!resumeUploaded ? findingJob : NoJobs}
      emptyDatatitle={!resumeUploaded ? FINDING_JOBS : NO_JOBS_TO_SHOW}
      emptyDatadesc={
        tabName === "Applied"
          ? APPLIED_OPPORTUNITIES_EMPTY_MESSAGE
          : resumeUploaded
          ? REVIEWING_YOUR_RESUME
          : PLEASE_UPLOAD_RESUME_JOB_OPPORTUNITIES
      }
    />
  ) : (
    jobsData?.map((item, index) => {
      return (
        <OpportunityCard
          data={item}
          alertInfo={alertInfo}
          trackStatus={trackStatus}
          activeIndex={activeIndex}
        />
      );
    })
  );

const EmptyData = (props: any) => {
  return (
    <div className="empty-data-card-holder">
      <div className="empty-data-opportunity">
        <img src={props?.emptyDataImage} alt="" className="empty-data-img" />
        <div className="empty-data-title">{props?.emptyDatatitle}</div>
        <div className="empty-data-desc">{props?.emptyDatadesc}</div>
      </div>
    </div>
  );
};

const ProgressBar = ({ alertInfo }) => {
  const stepsPerPage = 5;
  const [current, setCurrent] = useState(0);
  const { landingPageData } = useAppSelector(
    (state: any) => state?.opportunities
  );

  const handleNext = () => {
    if (current + stepsPerPage < landingPageData?.progressTrackReport?.length) {
      setCurrent(
        Math.min(current + 2, landingPageData?.progressTrackReport?.length - 1)
      );
    }
  };

  const handlePrev = () => {
    setCurrent(Math.max(current - 2, 0));
  };

  const visibleSteps = landingPageData?.progressTrackReport?.slice(
    current,
    current + stepsPerPage
  );

  return (
    <div
      className={
        alertInfo
          ? "progress-bar-container alert-margin"
          : "progress-bar-container"
      }
    >
      <div className="progress-bar-title">{TRACK_PROGRESS}</div>
      <div className="progres-bar-data">
        <Steps labelPlacement="vertical">
          {visibleSteps?.map((item, index) => (
            <Steps.Step
              key={index}
              title={<div className="step-title">{item?.trackName}</div>}
              description={
                item?.trackValue && (
                  <div className="step-desc">{item?.trackValue}</div>
                )
              }
              status={
                item?.trackStatus === "completed"
                  ? "finish"
                  : item?.trackStatus === "inprogress"
                  ? "process"
                  : "wait"
              }
              icon={
                <img
                  alt=""
                  className="progress-stepicon"
                  src={
                    item?.trackStatus === "completed"
                      ? finish
                      : item?.trackStatus === "todo"
                      ? wait
                      : inProgess
                  }
                />
              }
            />
          ))}
        </Steps>

        {current < landingPageData?.progressTrackReport?.length - 5 && (
          <Button
            className="btn-progress nxt-btn-progress"
            onClick={handleNext}
          >
            <img src={rightArrow} alt="" className="arrow-progress" />
          </Button>
        )}
        {current > 0 && (
          <Button
            className="btn-progress prev-btn-progress"
            onClick={handlePrev}
          >
            <img src={leftArrow} alt="" className="arrow-progress" />
          </Button>
        )}
      </div>
    </div>
  );
};

const Opportunities = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const {
    loading,
    error,
    jobsData,
    configuration,
    loadingLandingPage,
    landingPageData,
    landingPagePopUp,
  } = useAppSelector((state: any) => state?.opportunities);

  const pageLoading = useSelector((state: any) => state?.webLanding?.loading);

  const alertInfo = landingPageData?.warnMessageDetails || {};
  const isAlertInfo = landingPageData?.warnMessageDetails;
  const {
    message = "",
    bgColor = "",
    textColor = "",
    iconUrl = "",
  } = alertInfo;

  const [api, contextHolder] = notification.useNotification({
    top: NOTIFICATION_POSITION,
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [resumeUploaded, setResumeUploaded] = useState(false);

  const resumeUploadHandler = () => {
    amplitude.getInstance().logEvent("Clicked Upload Resume Now");
    navigate(`/${START}/${MYWORK_URL}/opportunities/resume`);
  };

  useEffect(() => {
    if (param?.tab === "applied") {
      setActiveIndex(1);
    }
  }, [param?.tab]);

  useEffect(() => {
    setResumeUploaded(landingPageData?.resumeUploaded);
  }, [landingPageData]);

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
        additionalInfo: "",
      })
    );
  };

  const onClickContinue = () => {
    dispatch(landingPagePopUpAction());
    amplitude.getInstance().logEvent("Successful Job Apply & Continue");
  };

  useEffect(() => {
    dispatch(getLandingPageDetials({}));
  }, []);

  useEffect(() => {
    const activeIndexString = activeIndex;
    const type = OPPORTUNITY_TYPES[activeIndexString];
    dispatch(getJobOpportunitiesList({ jobType: type }));
  }, [activeIndex]);

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: configuration.alertMessage.message,
        description: configuration.alertMessage.additionalInfo,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon:
          type === SUCCESS ? (
            <ToastSuccessIcon />
          ) : type === FAILURE ? (
            <GoalsErrorIcon />
          ) : (
            <GoalsInfoIcon />
          ),
        className:
          type === SUCCESS
            ? `custom-toast-area custom-success-toast`
            : type === FAILURE
            ? `custom-toast-area custom-error-toast`
            : `custom-toast-area custom-info-toast`,

        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  const AlertComponent = ({ iconUrl }) => {
    return <img src={iconUrl} />;
  };

  if (pageLoading) {
    return <></>;
  }

  return (
    <>
      {contextHolder}
      <div className="opportunities-page">
        <div className="opportunities-title">{OPPORTUNITIES}</div>
        <Spin spinning={loading || loadingLandingPage}>
          <div className="upload-resume-banner">
            <div className="upload-resume-banner-left">
              <h5>{RESUME_BANNER_TITLE}</h5>
              <p>{RESUME_BANNER_PARA}</p>
              <FillButton
                fillBtnStyles="upload-resume-banner-btn"
                fillBtnAction={resumeUploadHandler}
                fillBtnTxt={
                  landingPageData?.resumeUploaded
                    ? RESUME_BANNER_UPDATE
                    : RESUME_BANNER_BUTTON
                }
                fillBtnId={"upload-now"}
              />
            </div>
            <div className="upload-resume-banner-right">
              <img src={UploadBannerImage} alt="upload banner" />
            </div>
          </div>
          <ProgressBar alertInfo={isAlertInfo} />
          {isAlertInfo && (
            <Alert
              message={message}
              type="info"
              showIcon
              icon={<AlertComponent iconUrl={iconUrl} />}
              style={{
                backgroundColor: bgColor,
                color: textColor,
                borderColor: bgColor,
              }}
              className="info-message"
            />
          )}
          <div
            className={
              loading
                ? "ooprtunities-list spinner-opportunity"
                : "ooprtunities-list"
            }
          >
            <TabView
              activeIndex={activeIndex}
              className="ijp-opportunities-tabs manager-tabs"
              onTabChange={(e) => {
                if (e?.index === 1)
                  navigate("/web/my_work/opportunities/applied");
                else navigate("/web/my_work/opportunities/");
                setActiveIndex(e.index);
              }}
            >
              <TabPanel header={ALL_OPPORTUNITIES_TITLE}>
                <TabInformation
                  jobsData={jobsData?.all}
                  resumeUploaded={resumeUploaded}
                  alertInfo={isAlertInfo}
                  trackStatus={false}
                  tabName="All"
                  activeIndex={activeIndex}
                />
              </TabPanel>
              <TabPanel header={APPLIED_OPPORTUNITIES_TITLE}>
                <TabInformation
                  jobsData={jobsData?.applied}
                  resumeUploaded={resumeUploaded}
                  alertInfo={false}
                  trackStatus={false}
                  tabName="Applied"
                  activeIndex={activeIndex}
                />
              </TabPanel>
            </TabView>
          </div>
        </Spin>
      </div>
      <PreviewModal
        isOpen={landingPagePopUp}
        img={SuccessSubmit}
        title={"Job Applied!"}
        subtitle="Your job application has been successfully submitted!"
        nextBtnText={"Continue"}
        nextBtnOnClick={onClickContinue}
      />
    </>
  );
};

export default Opportunities;
