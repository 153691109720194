import React, { useState, useEffect } from "react";
import { Modal, Button, Form, DatePicker, Space, Spin } from "antd";
import exportclose from "../../themes/assets/images/svg/ExportClose.svg";
import downloadicon from "../../themes/assets/images/svg/Downloadicon.svg";
import exportcalendericon from "../../themes/assets/images/svg/ExportCalendarBlank.svg";
import "./ManagerExport.css";

const ManagerExportModal = (props) => {
  const { RangePicker } = DatePicker;
  const isRangeSelected = props?.dates[0] && props?.dates[1];
  const loadingCheckDownload = props?.loadingCheckDownload;
  const loadingCheckEmail = props?.loadingCheckEmail;


  return (
    <div>
      <Button
        onClick={props?.showModal}
        className="export-button manager_export-btn"
      >
        <img
          src={downloadicon}
          alt="Download Icon"
          className="export-button-icon"
        />
        Export
      </Button>

      <Modal
        title="Export Timesheet"
        className="exporttimesheet_modal"
        open={props?.isModalVisible}
        onCancel={() => {
          props?.handleCancel();
          props?.form.resetFields();
        }}
        closeIcon={
          <img
            src={exportclose}
            alt="Close Icon"
            className="custom-close-icon"
          />
        }
        footer={[
          <div className="export-footer-button">
            <div className="footer-download-button">
              {loadingCheckDownload ? (
                <Spin spinning={loadingCheckDownload} />
              ) : (
                <Button
                  key="download"
                  onClick={props?.clickOnDownload}
                  disabled={!props?.isDownloadEnabled}
                  style={{
                    backgroundColor: props?.isDownloadEnabled
                      ? "#0642B5"
                      : "#E2E2E2",
                    color: props?.isDownloadEnabled ? "#FFFFFF" : "#858585",
                  }}
                >
                  Download
                </Button>
              )}
            </div>
            <div className="footer-email-button">
              {loadingCheckEmail ? (
                <Spin spinning={loadingCheckEmail} />
              ) : (
                <Button
                  key="email"
                  onClick={props?.clickOnEmail}
                  disabled={!props?.isDownloadEnabled}
                  style={{
                    backgroundColor: props?.isDownloadEnabled
                      ? "#0642B5"
                      : "#E2E2E2",
                    color: props?.isDownloadEnabled ? "#FFFFFF" : "#858585",
                  }}
                >
                  E-mail
                </Button>
              )}
            </div>
          </div>,
        ]}
      >
        <div className="modal-subtext" id="modal-subtext">
          Timesheet will export based on the selected date.
        </div>

        <Form
          form={props?.form}
          layout="vertical"
          className="export-popup-form-otr"
        >
          <Form.Item
            label={
              <div className="start-date-label" id="start-date-label">
                Start & End Date
              </div>
            }
            name="startEndDate"
            rules={[{ required: true, message: "Start Date is required" }]}
            className="export-form-field_otr"
          >
            <Space direction="vertical" size={8} className="range_sec-otr">
              <RangePicker
                className="custom-input"
                picker="date"
                format={"DD/MM/YYYY"}
                allowClear
                popupClassName="custom-input-popup"
                placeholder={
                  isRangeSelected ? ["Start Date", "End Date"] : ["Select", ""]
                }
                separator={isRangeSelected ? "-" : ""}
                onChange={props?.onDateChange} // Update dates when range is selected
                suffixIcon={
                  <img
                    src={exportcalendericon}
                    alt="Calendar Icon"
                    className="Calender-icon"
                  />
                }
              />
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManagerExportModal;
