import React, { useEffect, useState } from "react";
import { Button } from "antd";
import "../index.css";
import DropDown from "./DropDown";
import CalenderIcon from "../../../themes/assets/images/svg/CalendarBlank.svg";
import { WARNING, WEEK_TITLE } from "../../../constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  notificationAction,
  setCloneSelectedWeek,
  setDisableCloneTimesheetBtn,
} from "../../../redux/reducers/timesheet";

const TimesheetCloneComponent = () => {
  const { cloneTimesheetDetails = [], isCloneTimesheetEnabled = false, cloneTimesheetDetailsSubHeaderMsg = "" } = useAppSelector(
    (state: any) => state.timesheet
  );
  const [selectedWeekDateObj, setSelectedWeekDateObj] = useState<any>({});
  const { noDataMessage = "", tsData = {}, subHeadermsg = "" } = selectedWeekDateObj || {};
  const { totalTime = {}, tsProjects = {}, projectTitle = "" } = tsData || {};
  const { recordsList = [], overallComments = "" } = tsProjects?.[0] || {};
  const dispatch = useAppDispatch();

  const getCurrentDropDownObj = (obj) => {
    dispatch(
      setCloneSelectedWeek({
        cloneWeekStart: obj.weekStartDate,
        cloneWeekEnd: obj.weekEndDate,
      })
    );
    setSelectedWeekDateObj(obj);
  };

  useEffect(() => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: noDataMessage || "",
        isShowNotification: Boolean(noDataMessage),
        additionalInfo: "",
      })
    );
    !cloneTimesheetDetailsSubHeaderMsg && dispatch(setDisableCloneTimesheetBtn(noDataMessage));
  }, [noDataMessage]);

  useEffect(() => {
    if (cloneTimesheetDetails?.length) {
      getCurrentDropDownObj(cloneTimesheetDetails[0]);
    } else {
      setSelectedWeekDateObj({})
    }
  }, [cloneTimesheetDetails]);

  const weekHandler = (week) => {
    console.log("weekHandler", week);
  };


  const getProjectDetails = (project: any) => {
    const { recordsList = [], overallComments = "", projectName = "", hours = "", minutes = "" } = project || {};
    return <>


      {noDataMessage && !tsProjects?.length && subHeadermsg ? (
        subHeadermsg
      ) : (
        <>
          <div className="clone-hr-line clone-calender-week">
            <h3 className="timesheet-title">{projectName}</h3>
          </div>
          <div className="hours-list">
            {recordsList.map((item) => (
              <div key={item.dayOfWeek} className="hours-row">
                <span>{item.dayOfWeek}</span>
                <span>
                  {`${item?.hours.toString()?.length === 1 ? "0" : ""}${item?.hours
                    }:${item.minutes || "00"}`}{" "}
                  hrs
                </span>
              </div>
            ))}
          </div>

          {totalTime?.hours ? (
            <div className="total-hours">
              <span>Total</span>
              <span className="total-hours-value">
                {`${hours || ""}:${minutes || "00"}`} hrs
              </span>
            </div>
          ) : null}

          <div>
            <div>
              {recordsList.map(({ comments = "", dayOfWeek = "" }, index) =>
                comments ? (
                  <div className="clone-hr-line" key={index}>
                    {!index ? (
                      <p className="clone-daily-comment">Daily Comment</p>
                    ) : null}
                    <p className="clone-daily-comment-res">
                      <span className="clone-comment-day">{dayOfWeek} : </span>
                      <span className="comments">{comments}</span>
                    </p>
                  </div>
                ) : null
              )}
            </div>
            <div className="clone-hr-line">
              {overallComments?.length ? (
                <>
                  <p className="clone-overall-comment">Overall Comments</p>
                  <p className="clone-overall-comment-res">{overallComments}</p>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  }

  return (
    <div className="timesheet-container">
      <div>
        <div className="clone-hr-line clone-calender-week">
          <h3 className="timesheet-title">{projectTitle}</h3>


          <div className="week-selector">
            {cloneTimesheetDetailsSubHeaderMsg ? <span>{cloneTimesheetDetailsSubHeaderMsg}</span> :
              <span> <img src={CalenderIcon} /> {WEEK_TITLE}</span>
            }


            {cloneTimesheetDetails?.length && !cloneTimesheetDetailsSubHeaderMsg ? (
              <DropDown
                list={cloneTimesheetDetails}
                getCurrentDropDownObj={(obj) => getCurrentDropDownObj(obj)}
                weekHandler={weekHandler}
                className="week-selector-dropdown"
                weekStartDate={selectedWeekDateObj?.weekDisplayName || ""}
                isClone={true}
              />
            ) : ""}
          </div>
        </div>
      </div>
      {tsProjects?.length ? tsProjects.map(project => getProjectDetails(project)) : ""}
      {totalTime?.hours ? (
        <div className="total-hours">
          <span>Overall Total</span>
          <span className="total-hours-value">
            {`${totalTime?.hours || ""}:${totalTime.minutes || "00"}`} hrs
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default TimesheetCloneComponent;