export const Export = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#474747"
        d="M17.083 18.082H2.917a1.042 1.042 0 01-1.042-1.041v-5.025a.63.63 0 01.625-.625.63.63 0 01.625.625v4.816h13.75v-4.816a.63.63 0 01.625-.625.63.63 0 01.625.625v5.025c0 .575-.467 1.041-1.042 1.041zM6.574 6.49a.618.618 0 01-.442-.184.629.629 0 010-.883l3.242-3.258a.864.864 0 011.233 0l3.242 3.25a.629.629 0 010 .883.629.629 0 01-.883 0L9.99 3.315 7.007 6.306a.618.618 0 01-.441.184h.008z"
      ></path>
      <path
        fill="#474747"
        d="M10 1.914a.63.63 0 01.625.625v9.483a.63.63 0 01-.625.625.63.63 0 01-.625-.625V2.54A.63.63 0 0110 1.914z"
      ></path>
    </svg>
  );
};

export const Frame = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#fff" rx="8"></rect>
      <g clipPath="url(#clip0_1389_28152)">
        <g clipPath="url(#clip1_1389_28152)">
          <mask id="path-2-inside-1_1389_28152" fill="#fff">
            <path
              fillRule="evenodd"
              d="M8.668 6a1 1 0 00-1 1v18a1 1 0 001 1h14.667a1 1 0 001-1V9.333L21 6H8.668z"
              clipRule="evenodd"
            ></path>
          </mask>
          <path
            fill="#E9F0FA"
            fillRule="evenodd"
            d="M8.668 6a1 1 0 00-1 1v18a1 1 0 001 1h14.667a1 1 0 001-1V9.333L21 6H8.668z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#1976D2"
            d="M24.335 9.333h.25V9.23l-.074-.073-.176.176zM21 6l.177-.177-.073-.073H21V6zM7.918 7a.75.75 0 01.75-.75v-.5c-.69 0-1.25.56-1.25 1.25h.5zm0 18V7h-.5v18h.5zm.75.75a.75.75 0 01-.75-.75h-.5c0 .69.56 1.25 1.25 1.25v-.5zm14.667 0H8.668v.5h14.667v-.5zm.75-.75a.75.75 0 01-.75.75v.5c.69 0 1.25-.56 1.25-1.25h-.5zm0-15.667V25h.5V9.333h-.5zm.426-.176l-3.333-3.334-.354.354 3.334 3.333.353-.353zM8.668 6.25h12.333v-.5H8.668v.5z"
            mask="url(#path-2-inside-1_1389_28152)"
          ></path>
          <path
            fill="#1976D2"
            fillRule="evenodd"
            d="M21.002 6v3.571h3.571L21.002 6z"
            clipRule="evenodd"
          ></path>
        </g>
        <rect
          width="18.333"
          height="8.333"
          x="6.834"
          y="14.336"
          fill="#1976D2"
          rx="1"
        ></rect>
        <path
          fill="#fff"
          d="M12.275 16.636h.784v3.069a1.438 1.438 0 01-.179.726 1.195 1.195 0 01-.494.467c-.21.108-.455.162-.735.162-.256 0-.486-.046-.69-.137a1.125 1.125 0 01-.484-.409 1.195 1.195 0 01-.18-.673h.787a.61.61 0 00.077.305.51.51 0 00.21.196c.09.045.193.068.31.068a.618.618 0 00.321-.079.506.506 0 00.2-.239.972.972 0 00.073-.387v-3.069zM13.917 21v-4.364h1.636c.335 0 .617.063.844.188.229.125.401.297.518.515.117.218.176.465.176.742 0 .28-.059.528-.176.746a1.264 1.264 0 01-.522.513c-.23.124-.514.186-.85.186h-1.085v-.65h.978a1 1 0 00.481-.103.645.645 0 00.277-.28.89.89 0 00.092-.412.877.877 0 00-.092-.41.623.623 0 00-.279-.274 1.024 1.024 0 00-.483-.1h-.725V21h-.79zm6.784-2.97a1.129 1.129 0 00-.147-.31.948.948 0 00-.221-.236.89.89 0 00-.294-.147 1.226 1.226 0 00-.363-.05c-.234 0-.443.058-.626.176a1.188 1.188 0 00-.433.52 1.994 1.994 0 00-.155.83c0 .33.052.61.155.838.104.229.248.403.433.522.185.118.4.177.643.177.222 0 .414-.043.576-.128a.901.901 0 00.377-.362c.088-.158.132-.342.132-.554l.179.028h-1.185v-.618h1.771v.524c0 .374-.08.697-.239.97a1.63 1.63 0 01-.656.63 2.03 2.03 0 01-.959.22c-.402 0-.755-.09-1.059-.27a1.874 1.874 0 01-.71-.774c-.168-.336-.253-.733-.253-1.194 0-.352.05-.666.15-.943.1-.277.24-.513.421-.706.18-.194.392-.342.635-.443.243-.102.507-.153.793-.153.241 0 .466.035.675.106.21.07.394.17.556.299a1.62 1.62 0 01.61 1.048H20.7z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1389_28152">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(6 6)"></path>
        </clipPath>
        <clipPath id="clip1_1389_28152">
          <path
            fill="#fff"
            d="M0 0H16.667V20H0z"
            transform="translate(7.668 6)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};
