import React from "react";
import { Button, Dropdown, Popover, Row } from "antd";
import { VerticalDotIcon } from "../../../common/svg/verticalDots";
import "./index.scss";

const IdeaActionModal = ({ visible, setActionPopOver, onSelect, options }) => {
    const handleAction = (e: any) => {
        onSelect(e);
    };
    const renderContent = () => {
        return (
            <div>
                {options?.length > 0 && options?.map((e: any, index: number) => (
                    <div
                        key={e.id}
                        className={
                            index === 0
                                ? "activePopover-button"
                                : "popover-button"
                        }
                        onClick={() => handleAction(e)}
                    >
                        {e.label}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Popover
            overlayStyle={{ right: 90, paddingTop: 20, width: 270 }}
            placement="bottomRight"
            arrow={false}
            content={renderContent}
            trigger="click"
            open={visible}
            onOpenChange={(staus) => setActionPopOver(staus)}
        />
    );
};

export default IdeaActionModal;
