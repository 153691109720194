import { Form, Input } from "antd";

const FormInput = (props: any) => {
  const {
    inputFormLabel,
    inputFormName,
    handleChange,
    formItemClassName,
    inputFormPlaceholder,
    inputRules,
    inputValue,
  } = props || {};

  return (
    <Form.Item
      label={inputFormLabel}
      name={inputFormName}
      rules={inputRules}
      className={formItemClassName}
    >
      <Input defaultValue={inputValue} placeholder={inputFormPlaceholder} onChange={handleChange} />
    </Form.Item>
  );
};

export default FormInput;
