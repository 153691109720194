import React, { useState } from "react";
import ellipse from "../../../themes/assets/images/ellipse.png";
import lightbulb from "../../../themes/assets/images/lightbulb.png";
import starfill from "../../../themes/assets/images/starfill.png";
import likeActive from "../../../themes/assets/images/likeActive.png";
import ReactionPopup from "../ReactionPopup/ReactionPopup";
const ReactionCount = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    //setActiveIndex(0);
  };
  return (
    <>
      <div onClick={(e) => showModal(e)} className="card_reactions">
        <img className="imgicons-reactions" src={likeActive} />
        <img className="imgicons-reactions" src={starfill} />
        <img className="imgicons-reactions" src={ellipse} />
        <img className="imgicons-reactions" src={lightbulb} />
      </div>
      <span className="count">{props?.likesCount}</span>
      {isModalOpen && (
        <ReactionPopup
          data={props?.data}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ReactionCount;
