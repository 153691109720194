import { useEffect } from "react";
import { notification } from "antd";
import "./ToastNotification.scss";

const ToastNotification = (props) => {
  const {
    type,
    message,
    description,
    placement,
    onClose,
    topValue,
    maxCount,
    notificationDuration,
    toastClassName,
    icon,
    closeIcon,
  } = props;
  const [api, contextHolder] = notification.useNotification({
    top: topValue,
    maxCount: maxCount,
  });
  useEffect(() => {
    api[type]({
      duration: notificationDuration,
      message: message,
      description: description,
      placement: placement,
      onClose: onClose,
      className: toastClassName,
      icon: icon,
      closeIcon: closeIcon,
    });
  }, []);
  return <>{contextHolder}</>;
};

export default ToastNotification;
